
export const boarding = [
    {
      image: "https://i.postimg.cc/Jnh31rZr/holahola-onboarding.png",
      imageAlt: "",
    },
    {
      image: "https://i.postimg.cc/jqFcS7MG/holahola-onboarding-bonus.png",
      imageAlt: "",
    },
    {
      image: "https://i.postimg.cc/QMhkykMW/holahola-onboarding-kyc.png",
      imageAlt: "",
    },
    {
      image: "https://i.postimg.cc/fLgcyGfx/holahola-onboarding-payment.png",
      imageAlt: ""
    },
    {
      image: "https://i.postimg.cc/QMhkykMW/holahola-onboarding-kyc.png",
      imageAlt: "",
      linkRoute: "/limits",
      linkClass:"txt-theme-brand-1"
    },
  ]
 