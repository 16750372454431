export function formatDatePicker(date: any) {
  let formattedDate;
  if (Object.prototype.toString.call(date) === "[object Date]") {
    formattedDate = date.toJSON();
  } else {
    formattedDate = date;
  }
  formattedDate =
    formattedDate.substring(0, 10) + " " + formattedDate.substring(11, 19);

  return formattedDate;
}
