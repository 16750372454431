export const genders = [
  {
    wrapperClass: "radio-style-btn-wrapper",
    class: "flex flex-center btn-normal radio-style-btn",
    inputId: "male",
    labelText: "auth.confirm.desktop.male",
    labelIcon: "icon-male",
    value: "m",
    name: "gender",
  },
  {
    wrapperClass: "radio-style-btn-wrapper",
    class: "flex flex-center btn-normal radio-style-btn",
    inputId: "female",
    labelText: "auth.confirm.desktop.female",
    labelIcon: "icon-female",
    value: "f",
    name: "gender",
  },


];

export interface GenderInterface {
  wrapperClass: string;
  class: string;
  inputId: string;
  labelText: string;
  labelIcon: string;
  value: string;
  name: string;
}
