<script setup lang="ts">
import VisaAdditionalDataFormComponent from "@/brands/bit1111/components/deposit/additionalData/VisaAdditionalDataFormComponent.vue";
import NetellerAdditionalDataFormComponent from "@/brands/bit1111/components/deposit/additionalData/NetellerAdditionalDataFormComponent.vue";
import type { Deposit } from "@/services/payment/depositAbstract/deposit";
import { type PropType, onMounted, ref } from "vue";

const props = defineProps({
  deposit: {
    required: true,
    type: Object as PropType<Deposit>,
  },
});

const emit = defineEmits(['back'])
const back = () => {
   emit("back");
}
const selector = ref("none");



onMounted(() => {
  if (props.deposit) {
    const handlerResponse = props.deposit.getHandlerResponse();
    if (handlerResponse.additionalDataSelector) {
      selector.value = handlerResponse.additionalDataSelector;
    }
  }
});
</script>

<template>
  <h1 v-if="selector == 'none'">NO ADDITIONAL DATA TEMPLATE</h1>
  <VisaAdditionalDataFormComponent
    v-if="selector == 'creditCard'"
    :deposit="deposit"
    @back="back"
  />
  <NetellerAdditionalDataFormComponent
    v-if="selector == 'neteller'"
    :deposit="deposit"
    @back="back"
  />
</template>
