import type {
  DepositHandler,
  DepositInitiator,
  HandlerResponse,
  InitiatorResponse,
} from "@/services/payment/depositAbstract/handleDeposit";

export class Deposit {
  private depositHandler: DepositHandler;
  private depositInitiator: DepositInitiator;
  private data: DepositPayload;
  private handlerResponse: HandlerResponse;
  private initiateResponse: InitiatorResponse;

  constructor(
    depositHandler: DepositHandler,
    depositInitiator: DepositInitiator,
    handleData: HandleDepositData
  ) {
    this.depositHandler = depositHandler;
    this.depositInitiator = depositInitiator;
    const host = window.location.origin;
    this.handlerResponse = {
      isAdditionalData: false,
      isError: false,
      responseMessage: "",
    };
    this.initiateResponse = {
      link: "",
      isError: false,
      responseMessage: "",
    };
    this.data = {
      supplier: handleData.supplier,
      provider: handleData.provider,
      amount: handleData.amount,
      walletType: handleData.walletType,
      bonus_id: handleData.bonus_id,
      bonus_code: handleData.bonus_code,
      paymentType: "deposit",
      redirectUrls: {
        baseUrl: `${host}/deposit-redirect`,
        pending: `${host}/deposit-redirect?status=pend`,
        success: `${host}/deposit-redirect?status=success`,
        error: `${host}/deposit-redirect?status=err`,
      },
    };
  }
  public async injectAdditionalData(
    additionalData: VisaAdditionalData | NetellerAdditionalData
  ) {
    this.data.additionalData = additionalData;
  }
  public async handleDeposit() {
    this.handlerResponse = await this.depositHandler.handleDeposit();
    if (this.handlerResponse.isError) {
      return this.handlerResponse;
    }
    let initiatorResponse: InitiatorResponse;
    if (!this.handlerResponse.isAdditionalData) {
      initiatorResponse = await this.initiateDeposit();
      this.handlerResponse = {
        isAdditionalData: false,
        isError: initiatorResponse.isError,
        responseMessage: initiatorResponse.responseMessage,
      };
    }
    return this.handlerResponse;
  }
  public async initiateDeposit() {
    this.initiateResponse = await this.depositInitiator.initiateDeposit(
      this.data
    );
    if (this.initiateResponse.isError) {
      return this.initiateResponse;
    }
    this.redirect(this.initiateResponse.link);
    return this.initiateResponse;
  }
  public getAmount(): number {
    return this.data.amount;
  }
  public getAdditonalDataFlag(): boolean {
    return this.handlerResponse.isAdditionalData;
  }
  public getHandlerResponse(): HandlerResponse {
    return this.handlerResponse;
  }
  private redirect(link: string) {
    window.location.href = link;
  }
}

export interface DepositPayload {
  supplier: string;
  provider: string;
  amount: number;
  walletType: number;
  paymentType: string;
  redirectUrls: {
    baseUrl: string;
    pending: string;
    success: string;
    error: string;
  };
  bonus_code: string;
  bonus_id: string | null;
  additionalData?: VisaAdditionalData | NetellerAdditionalData;
}

export interface HandleDepositData {
  supplier: string;
  provider: string;
  amount: number;
  walletType: number;
  bonus_code: string;
  bonus_id: string | null;
}

export interface VisaAdditionalData {
  cardHolder: string;
  encCreditcardNumber: string;
  encCvv: string;
  expiryYear: string;
  expiryMonth: string;
}
export interface NetellerAdditionalData {
  account: string;
}


