<script setup lang="ts">
import { useBrandStore } from "../../stores/brand";
import type { PropType } from "vue";

const brandStore = useBrandStore();

interface LogoPropsInterface {
  test: string;
}

const props = defineProps({
  props: {
    required: false,
    type: Object as PropType<LogoPropsInterface>,
  },
});
</script>

<template>
  <div class="logo-wrapper">
    <h1 v-if="props.props">{{ props.props.test ?? "nema props" }}</h1>
    <RouterLink :to="{ name: 'home' }">
      <img
        id="logo"
        class="logo align-middle"
        :src="brandStore.getLogo"
        :alt="brandStore.getName"
      />
    </RouterLink>
  </div>
</template>
