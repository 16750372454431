import { useAuthStore } from "@/stores/auth";
import { defineStore } from "pinia";
import axios from "axios";
import type { BonusInterface } from "@/services/interface/bonuses";
import type { BannerInterface } from "@/services/interface/banner";
import { useBannerStore } from "@/stores/banners";

export const useBonusesStore = defineStore({
  id: "bonuses",
  state: () => ({
    // alreadyWon = status 1
    availableBonuses: <Array<BonusInterface>>[],
    // appliedBonuses = status 2 and 3
    appliedBonuses: <Array<BonusInterface>>[],
    unavailableBonuses: <Array<BonusInterface>>[],
    bonusesWhileLogedOut: <Array<BonusInterface>>[],
    isLoggedInBonusesLoaded: <boolean>false,
    isLoggedOutBonusesLoaded: <boolean>false,
  }),
  actions: {
    async loadAllBonuses() {
      const store = useAuthStore();
      let data: Array<BonusInterface> = [];
      this.availableBonuses = [];
      this.appliedBonuses = [];
      this.unavailableBonuses = [];
      await axios
        .get(`/obtained/bonuses`)
        .then((response) => {
          data = response.data.data;
          this.isLoggedInBonusesLoaded = true;
        })
        .catch((error) => {
          console.log("error at getBonuses", error);
          this.isLoggedInBonusesLoaded = false;
        });

      const bonusesWithBanners = await this.handleBanners(data);
      bonusesWithBanners.forEach((bonus: BonusInterface) => {
        if (bonus.status === 2 || bonus.status === 3) {
          //check for the same ID before pushing
          const idExists = this.appliedBonuses.find(
            (newBonus) => newBonus.id === bonus.id
          );
          if (!idExists) {
            this.appliedBonuses.push(bonus);
          }
        } else if (bonus.status === 1) {
          const idExists = this.availableBonuses.find(
            (newBonus) => newBonus.id === bonus.id
          );
          if (!idExists) {
            this.availableBonuses.push(bonus);
          }
        }
      });
      if(store.player){
        let unavailableBonusesData: Array<BonusInterface> = [];
        await axios
        .get(`/obtained/bonus/unavailable`)
        .then((response) => {
          unavailableBonusesData = response.data.data;
        })
        .catch((error) => {
          console.log("error at getBonuses", error);
        });

        this.unavailableBonuses = await this.handleBanners(unavailableBonusesData);
      }
      
    },
    getBonusById(id: number) {
      let bonus = null;
      this.appliedBonuses.forEach((element) => {
        if (element.id == id) bonus = element;
      });
      this.availableBonuses.forEach((element) => {
        if (element.id == id) bonus = element;
      });
      this.unavailableBonuses.forEach((element) => {
        if (element.id == id) bonus = element;
      });
      return bonus;
    },
    getBonusByIdWhileLogedOut(id: number) {
      let bonus = null;
      this.bonusesWhileLogedOut.forEach((element) => {
        if (element.id == id) bonus = element;
      });
      return bonus;
    },
    async getAllBonuses() {
      if (!this.isLoggedInBonusesLoaded) {
        await this.loadAllBonuses();
      }
      return {
        appliedBonuses: this.appliedBonuses,
        availableBonuses: this.availableBonuses,
        unavailableBonuses: this.unavailableBonuses,
      };
    },

    async acceptOrDeclineOrClaimLaterBonus(bonusId: number, data: number) {
      /* OBTAINED = 1; ACCEPTED = 2; APPLIED = 3; CLAIM_LATER = 4; DECLINED = 5;   */
      await axios
        .post(`/obtained/bonus/${bonusId}/action`, {
          status: data,
        })
        .then(() => {
          this.loadAllBonuses();
        })
        .catch(() => {
          console.log("Error claiming bonus id: ", bonusId);
        });
    },

    async getBonusesWhileLoggedOut() {
      let data: Array<BonusInterface> = [];
      if (!this.isLoggedOutBonusesLoaded) {
        await axios
          .get("/bootstrap/available-bonuses")
          .then((response) => {
            data = response.data.data;
            this.isLoggedOutBonusesLoaded = true;
          })
          .catch(() => {
            console.log("Error getting bonuses while logged out");
            this.isLoggedOutBonusesLoaded = false;
          });
        this.bonusesWhileLogedOut = await this.handleBanners(data);
      }
    },

    async checkForBonuses() {
      const store = useAuthStore();
      let activeBonuses;
      if (store.player) {
        await this.getAllBonuses();
        activeBonuses = this.availableBonuses;
      } else {
        await this.getBonusesWhileLoggedOut();
        activeBonuses = this.bonusesWhileLogedOut;
      }
      return activeBonuses;
    },

    binarySearch(banners: Array<BannerInterface>, value: string) {
      let start = 0;
      let end = banners.length - 1;
      while (start <= end) {
        const mid = Math.floor((start + end) / 2);
        if (banners[mid].data.name === value) return mid;
        else if ((banners[mid].data.name ?? "") < value) start = mid + 1;
        else end = mid - 1;
      }
      return -1;
    },

    bannerNameSort(a: BannerInterface, b: BannerInterface) {
      const textA = a.data.name ? a.data.name.toUpperCase() : "";
      const textB = b.data.name ? b.data.name.toUpperCase() : "";

      return textA < textB ? -1 : textA > textB ? 1 : 0;
    },

    async handleBanners(data: Array<BonusInterface>) {
      const store = useAuthStore();
      const isUserLogged = store.player ? true : false;
      const bannerStore = useBannerStore();
      const banners = await bannerStore.getBanners("bonus");
      const bannersMobile = await bannerStore.getBanners("mobile-bonus");
      const banners_main = await bannerStore.getBanners("bonus-readmore");
      const bannersMobile_main = await bannerStore.getBanners("mobile-bonus-readmore");

      banners.sort(this.bannerNameSort);
      banners_main.sort(this.bannerNameSort);
      bannersMobile_main.sort(this.bannerNameSort);
      bannersMobile.sort(this.bannerNameSort);

      if (Array.isArray(data)) {
        data.forEach((element: BonusInterface) => {
          let index = isUserLogged
            ? this.binarySearch(banners, element.bonusAward.bonus.name)
            : this.binarySearch(banners, element.name);
          if (index != -1) {
            element.banner = banners[index];
          }
          index = isUserLogged
            ? this.binarySearch(bannersMobile, element.bonusAward.bonus.name)
            : this.binarySearch(bannersMobile, element.name);
          if (index != -1) {
            element.bannerMobile = bannersMobile[index];
          }

          index = isUserLogged
            ? this.binarySearch(banners_main, element.bonusAward.bonus.name)
            : this.binarySearch(banners_main, element.name);
          if (index != -1) {
            element.banner_main = banners_main[index];
          }

          index = isUserLogged
            ? this.binarySearch(bannersMobile_main, element.bonusAward.bonus.name)
            : this.binarySearch(bannersMobile_main, element.name);
          if (index != -1) {
            element.bannerMobile_main = bannersMobile_main[index];
          }

          
        });
      } else {
        data = [];
      }
      return data;
    },
  },
});
