<script setup lang="ts">
import IconComponent from "../../../icons/IconComponent.vue";
import { ref, watch } from "vue";

const props = defineProps({
  isChecked: Boolean,
  checkboxTitle: String,

  iconClass: String,
  labelClass: String,
  spanClass: String,

  checkboxId: String,
  testId: String,
});

const emit = defineEmits(["onCheckboxChange"]);
const checkedState = ref(props.isChecked);

const onCheckbox = (event: any) => {
  emit("onCheckboxChange", checkedState.value);
};
watch(
  props,
  () => {
    checkedState.value = props.isChecked;
  },
  { deep: true }
);
</script>

<template>
  <div :id="checkboxId" class="checkbox-wrapper card-form-checkbox relative mgy-4">
    <label :class="props?.labelClass" class="inline-block">
      <input
        :data-testid="testId ? `input-${testId}` : null"
        type="checkbox"
        @change="onCheckbox($event)"
        class="block "
        v-model="checkedState"
        :value="checkedState"
      />
      <span
        class="checkmark flex flex-center bg-theme-bg-1 border-input"
        tabindex="0"
        :class="iconClass"
      >
        <IconComponent
          v-if="checkedState"
          :data-testid="testId ? `icon-${testId}` : null"
          :iconClasses="'icon-12 inline-block icon-checkbox bg-theme-bg-1'"
        ></IconComponent>
      </span>
      <span
        class="w-85 text-smallest txt-theme-txt-2"
        v-if="checkboxTitle"
        :class="spanClass"
        :data-testid="testId ? `title-${testId}` : null"
        >{{ checkboxTitle }}</span
      >
    </label>
  </div>
</template>
