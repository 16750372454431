<script setup>
import { useContentPagesStore } from "@/stores/contentPages";
import ContentPage from "@/brands/bit1111/components/content/ContentPage.vue";
import { ref } from "vue";
import UserAccountSideMenuVue from "@/brands/bit1111/components/navigation/UserAccountSideMenu.vue";
import { useAuthStore } from "@/stores/auth";
import { translate } from "@/services/translations/translation";
const contentStore = useContentPagesStore();
const authStore = useAuthStore();
const content = ref();
const isLoaded = ref(false);

const init = async () => {
  content.value = await contentStore.getContent("about");
  isLoaded.value = true;
};

init();
</script>
<template>
<div class="background-img-profile">
  <div class="flex form-size-desktop">
    <div class="mgt-10" v-if="authStore.player"><UserAccountSideMenuVue /></div>
    <div v-if="isLoaded" class="mgt-10 w-100">
      <h3
        class="txt-size-24 txt-weight-600 txt-spacing-20px txt-center mgt-6 to-upper-case txt-1-1"
      >
        {{ translate("common.about") }}
      </h3>
      <div class="mgx-10">
       <div v-html="content[0]?.text"></div>
        <!-- <ContentPage :content="content" /> -->
      </div>
    </div>
  </div>
    </div>
</template>
