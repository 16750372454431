<script setup lang="ts">
import IconComponent from "@/components/icons/IconComponent.vue";
import { translate } from "@/services/translations/translation";
import { useAuthStore } from "@/stores/auth";
import MainNavComponent from "@/brands/holahola/components/navigation/header/MainNavComponent.vue";

const authStore = useAuthStore();

const props = defineProps({
  navigationFlag: Boolean,
});

const emit = defineEmits(["toggleNav"]);

const toggleCallback = () => {
  emit("toggleNav");
};

const items = [
  {
    name: translate("common.home").toUpperCase(),
    ico: "home-filled",
    url: "/",
  },
  {
    name: translate("common.promotions").substring(0, 5).toUpperCase(),
    ico: "present",
    url: "/promotions",
  },
  {
    name: translate("common.deposit").toUpperCase(),
    ico: "wallet-filled",
    url: "/deposit",
  },
];
</script>
<template>
  <div
    class="fixed bottom-0 right-0 left-0 h-70px bottom-nav flex flex-center zindex-header"
  >
    <RouterLink
      :to="item.url"
      v-for="item in items"
      class="w-100 flex flex-center flex-column pdt-3"
    >
      <IconComponent
        :iconClasses="`svg-icon icon-22 inline-block bg-light icon-${item.ico}`"
      ></IconComponent>
      <p class="font-poppins txt-light txt-size-12 pdy-2">{{ item.name }}</p>
    </RouterLink>

    <div class="w-100 flex flex-center flex-column pdt-3">
      <MainNavComponent
        @toggleMenu="toggleCallback"
        :navigationFlag="navigationFlag"
      />
      <p class="font-poppins txt-light txt-size-12 pdy-2">{{ translate('navigation.header.desktop.menu').toUpperCase() }}</p>
    </div>
  </div>
</template>
<style scoped>
.bottom-nav {
  background: transparent linear-gradient(180deg, #23008a, #000635) 0% 0%
    no-repeat padding-box;
  border-top-left-radius: 20pt;
  border-top-right-radius: 20pt;
}
</style>
