import { useAuthStore } from "@/stores/auth";
import { useBalanceStore } from "@/stores/balance";
import type { MercureEventListener } from "./EventListenerInterface";

class CasinoWageringEvent implements MercureEventListener {
  listener(event: MessageEvent) {
    const balanceStore = useBalanceStore();
    const eventData = JSON.parse(event.data);

    balanceStore.setWagering(
      eventData.allowedPayoutAmount,
      eventData.totalWageringAmount,
      eventData.remainingWageringAmount,
      "casino"
    );

  }

  topic() {
    const authStore = useAuthStore();
    const player = authStore.$state.player;
    if (player == null) {
      throw new Error("Player not logged In");
    }
    const topic = "player/wagering/" + player?.wallets.casino.id;
    return topic;
  }

  on() {
    return "message";
  }
}

export default CasinoWageringEvent;
