<script setup lang="ts">
import { computed, ref, onMounted, watch } from "vue";
import { translate } from "@/services/translations/translation";
import ButtonComponent from "../../shared/buttons/ButtonComponent.vue";
import type { Notification } from "@/services/interface/notifications";
import { isMobileCheck } from "@/services/interface/clientInformation";
import { goToRoute } from "@/router/routingService";
import { NotificationHandler } from "@/services/mappers/notificationMapper";

const props = defineProps({
  data: {
    type: Array<Notification>,
    default: [],
  },
});

const emit = defineEmits(["closeMenu", "deleteAllMessages"]);
const notificationArray = ref<Array<Notification>>(props.data);
const formatNotifications = () => {
  notificationArray.value = [];
  props.data?.forEach((element) => {
    const notifHandler = new NotificationHandler(element.data.action);
    const notifObject = notifHandler.getNotificationObject();
    element.url = notifObject?.url;
    notificationArray.value?.push(element);
  });
};

formatNotifications();

const handleClose = () => {
  emit("closeMenu", false);
};

const deleteAllMessages = () => {
  emit("deleteAllMessages");
};
onMounted(() => {});

const goTo = (route: string) => {
  if(route){
    goToRoute(route);
  emit("closeMenu", false);
  }
  
};

watch(props, () => {
  props.data ? formatNotifications() : null;
});
</script>

<template>
  <div class="backdrop" @click.self="handleClose">
    <div class="w-100 maxw-1184px absolute top-0">
      <div
        :class="
          isMobileCheck()
            ? 'header-notification-wrapper-mobile'
            : 'header-notification-wrapper'
        "
      >
        <div
          class="mgt-7 absolute container-dropdown zindex-dropdown radius-10 bg-5-1 pdy-5 pdl-5 pdr-2 scrollbar-style"
        >
          <div class="notification-container flex flex-column pdr-4">
            <p v-if="props.data?.length == 0">
              {{ translate("globalNotificationMessages.noMessages") }}
            </p>
            <div
             
              v-if="notificationArray!.length > 0"
              v-for="(item, i) in notificationArray"
              @click="goTo(item.url as string)"
              :class="notificationArray?.length == i+ 1 ? null : 'border-1-1'"
              class="hover-bg-1-6 border-width-1 border-top-none border-left-none border-right-none pdy-2 pdx-1 flex"
            >
              <p class="w-100 cursor-pointer">{{ item.data.message }}</p>
            </div>
          </div>
          <!--  <ButtonComponent
            @click="deleteAllMessages"
            v-if="props.data?.length > 0"
            class="mgt-5"
            :text="translate('globalNotificationMessages.clearAll')"
            :heightPx="35"
          /> -->
        </div>
      </div>
    </div>
  </div>
</template>
