<script setup lang="ts">
import InputPasswordComponent from "@/components/shared/forms/input/InputPasswordComponent.vue";
import { ref } from "vue";
import axios from "axios";
import { isMobileCheck } from "@/services/interface/clientInformation";
import NotificationComponent from "@/components/shared/notification/NotificationComponent.vue";
import { translate } from "@/services/translations/translation";
import UserAccountSideMenuVue from "@/brands/holahola/components/navigation/UserAccountSideMenu.vue";

const oldPassword = ref("");
const isMobile = ref(isMobileCheck());
const newPassword = ref("");
const confirmPassword = ref();
const showNotify = ref(false);
const notifyMsg = ref("");
const notifyStatus = ref("");
const responseErrorOldPassword = ref<any>([]);
const responseErrorNewPassword = ref<any>([]);

const checkContainsUppercaseAndLowercase = () => {
  if (newPassword.value) {
    if (
      newPassword.value.match(/[A-Z]/g) &&
      newPassword.value.match(/[a-z]/g)
    ) {
      return true;
    }
  }
  return false;
};

const checkContains = (regex: any) => {
  if (newPassword.value) {
    if (newPassword.value.match(regex)) {
      return true;
    }
  }
  return false;
};

const handleError = (errors: any) => {
  if (Array.isArray(errors)) {
    errors.forEach((item) => {
      if (item.property == "oldPassword") {
        responseErrorOldPassword.value.push(item.message);
      } else {
        responseErrorNewPassword.value.push(item.message);
      }
    });
  } else {
    if (errors.oldPassword) {
      responseErrorOldPassword.value = errors;
    } else {
      responseErrorNewPassword.value = errors;
    }
  }
};

// VALIDATION START
const newValidationList = ref({
  oldPassword: String,
  newPassword: String,
  confirmPassword: String,
});
type ValidationKey = keyof typeof newValidationList.value;

const formisValid = ref();
const callbackValid = (
  pointer: ValidationKey,
  value: BooleanConstructor & StringConstructor & NumberConstructor
) => {
  newValidationList.value[pointer] = value;
  computeFormValidation();
};
const computeFormValidation = () => {
  let flag = true;
  let key: ValidationKey;
  for (key in newValidationList.value) {
    if (!newValidationList.value[key]) flag = false;
  }
  formisValid.value = flag;
};
// VALIDATION END

const changePassword = () => {
  responseErrorOldPassword.value = [];
  responseErrorNewPassword.value = [];
  axios
    .put("players/reset-password", {
      oldPassword: oldPassword.value,
      newPassword: newPassword.value,
    })
    .then((response) => {
      oldPassword.value = "";
      newPassword.value = "";
      confirmPassword.value = "";
      showNotification("success", "Success");
    })
    .catch((error) => {
      const { errors } = error.response.data;
      handleError(errors);
    });
};

const showNotification = (status: string, message: string) => {
  notifyMsg.value = message;
  showNotify.value = true;
  notifyStatus.value = `bg-${status}`;
};
const submitNewPassword = () => {
  confirmPassword.value === newPassword.value
    ? changePassword()
    : showNotification(
        "error",
        translate("auth.change_password.desktop.wrong_confirmation_password")
      );
};

const closeNotification = (event: boolean) => {
  showNotify.value = !event;
};
</script>
<template>
  <div class="flex form-size-desktop">
    <div v-if="!isMobile" class="mgt-10"><UserAccountSideMenuVue /></div>
    <div class="mgl-10 mgt-10 flex flex-column w-100">
      <span :class="isMobile ? 'title-large' : 'title-gigantic'" class="block txt-theme-txt-1 mgb-5">{{
      translate("auth.change_password.desktop.titlePage") }}
    </span>
      <div
        class="mgt-5 flex flex-column"
        :class="[isMobile ? 'w-90 flex-center' : 'w-385px flex-start']"
      >
        <div class="w-100">
          <InputPasswordComponent
            :borderClass="'border-input radius-8 border-color-theme-brand-1 '"
            passId="piPassword"
            v-model="oldPassword"
            label="Old Password"
            @isValid="callbackValid('oldPassword', $event)"
            :minLength="8"
            :maxLength="25"
            :responseErrors="responseErrorOldPassword"
          ></InputPasswordComponent>
        </div>
        <div class="w-100">
          <InputPasswordComponent
            :borderClass="'border-input radius-8 border-color-theme-brand-1 '"
            passId="piNewPassword"
            v-model="newPassword"
            label="New Password"
            @isValid="callbackValid('newPassword', $event)"
            :minLength="8"
            :maxLength="25"
            :responseErrors="responseErrorNewPassword"
          ></InputPasswordComponent>
        </div>
        <div class="w-100">
          <div class="flex flex-align-center h-20px">
            <div>
              <div
                v-if="checkContainsUppercaseAndLowercase()"
                class="svg-icon icon-36 scale-0-4 inline-block bg-theme-brand-1 icon-success cursor-pointer"
              ></div>
              <div
                v-else
                class="svg-icon icon-36 scale-0-7 mgt-1 inline-block bg-theme-brand-2 icon-form-clear cursor-pointer"
              ></div>
            </div>
            <div class="txt-theme-txt-2">
              {{
                translate(
                  "auth.change_password.desktop.password_must_contain.lower_upper_case"
                )
              }}
            </div>
          </div>
          <div class="flex flex-align-center h-20px">
            <div>
              <div
                v-if="checkContains(/[0-9]/g)"
                class="svg-icon icon-36 scale-0-4 inline-block bg-theme-brand-1 icon-success cursor-pointer"
              ></div>
              <div
                v-else
                class="svg-icon icon-36 scale-0-7 mgt-1 inline-block bg-theme-brand-2 icon-form-clear cursor-pointer"
              ></div>
            </div>
            <div class="txt-theme-txt-2">
              {{
                translate(
                  "auth.change_password.desktop.password_must_contain.number"
                )
              }}
            </div>
          </div>
          <div class="flex flex-align-center h-20px">
            <div>
              <div
                v-if="checkContains(/\W|_/g)"
                class="svg-icon icon-36 scale-0-4 inline-block bg-theme-brand-1 icon-success cursor-pointer"
              ></div>
              <div
                v-else
                class="svg-icon icon-36 scale-0-7 mgt-1 inline-block bg-theme-brand-2 icon-form-clear cursor-pointer"
              ></div>
            </div>
            <div class="txt-theme-txt-2">
              {{
                translate(
                  "auth.change_password.desktop.password_must_contain.special_character"
                )
              }}
            </div>
          </div>
          <div class="flex flex-align-center h-20px">
            <div>
              <div
                v-if="newPassword && newPassword.length >= 8"
                class="svg-icon icon-36 scale-0-4 inline-block bg-theme-brand-1 icon-success cursor-pointer"
              ></div>
              <div
                v-else
                class="svg-icon icon-36 scale-0-7 mgt-1 inline-block bg-theme-brand-2 icon-form-clear cursor-pointer"
              ></div>
            </div>
            <div class="txt-theme-txt-2">
              {{
                translate(
                  "auth.change_password.desktop.password_must_contain.minimum_characters"
                )
              }}
            </div>
          </div>
        </div>
        <div class="w-100">
          <InputPasswordComponent
            :borderClass="'border-input radius-8 border-color-theme-brand-1 '"
            :bgColor="
              confirmPassword && confirmPassword !== newPassword
                ? 'bg-error-light'
                : 'bg-theme-bg-1'
            "
            passId="piConfirmPassword"
            v-model="confirmPassword"
            label="Confirm New Password"
            @isValid="callbackValid('confirmPassword', $event)"
            :minLength="8"
            :maxLength="25"
          ></InputPasswordComponent>
        </div>
        <div class="w-100">
          <NotificationComponent
            v-if="showNotify"
            :messageBody="notifyMsg"
            :bgClass="notifyStatus"
            :iconClass="'success'"
            :textColor="'txt-theme-txt-2 text-large'"
            @emitDismiss="closeNotification($event)"
          />
        </div>
        <div class="w-100">
          <button
            id="submit"
            class="btn btn-normal btn-brand-light mgx-auto mgy-6 txt-bold"
            :disabled="!formisValid"
            @click="submitNewPassword"
          >
            {{ translate("auth.change_password.desktop.submitButton") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
