<script setup lang="ts">
import { ref } from "vue";
const props = defineProps({
  step: Number,
  isMobile: {
    type: Boolean,
    default: false,
  },
});

const divClassSelected =
  "border-input border-2-3 radius-20 square-40 txt-center bg-2-3 mgx-1";

const pClassSelected = "txt-4-1";
const divClassEmpty =
  "border-input border-2-3 radius-20 square-40 txt-center bg-5-1 mgx-1";

const pClassEmpty = "txt-2-3";
</script>
<template>
  <div
    class="flex flex-align-center space-between mgy-6 subtitle-large txt-bold"
    v-if="!isMobile"
  >
    <div class="flex flex-center" :class="step == 1 ? divClassSelected : divClassEmpty">
      <p :class="step == 1 ? pClassSelected : pClassEmpty">1</p>
    </div>
    <div class="h-2px w-20 bg-2-1"></div>
    <div class="flex flex-center" :class="step == 2 ? divClassSelected : divClassEmpty">
      <p :class="step == 2 ? pClassSelected : pClassEmpty">2</p>
    </div>
    <div class="h-2px w-20 bg-2-1"></div>
    <div class="flex flex-center" :class="step == 3 ? divClassSelected : divClassEmpty">
      <p :class="step == 3 ? pClassSelected : pClassEmpty">3</p>
    </div>
  </div>
  <div
    class="flex flex-align-center space-between w-50 mgx-auto subtitle-large txt-bold mgy-5"
    v-else
  >
    <div class="flex flex-center" :class="step == 1 ? divClassSelected : divClassEmpty">
      <p :class="step == 1 ? pClassSelected : pClassEmpty">1</p>
    </div>
    <div class="h-2px w-50 bg-2-1"></div>
    <div class="flex flex-center" :class="step == 2 ? divClassSelected : divClassEmpty">
      <p :class="step == 2 ? pClassSelected : pClassEmpty">2</p>
    </div>
  </div>
</template>
