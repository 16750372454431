export const sideGroups = [
    {
      id: 1,
      name: "account.user_account_menu.desktop.account",
      icon: "profile",
      link: "",
      active: false,
      children: [],
    },
    {
      id: 2,
      name: "account.user_account_menu.desktop.transactions",
      icon: "transfer",
      link: "",
      active: false,
      children: [],
    },
    {
      id: 3,
      name: "account.user_account_menu.desktop.history",
      icon: "time",
      link: "",
      active: false,
      children: [],
    },
    {
      id: 4,
      name: "account.user_account_menu.desktop.help",
      icon: "help",
      link: "",
      active: false,
      children: [],
    },
  ];
  
  export const profileNavigation = [
    {
      group: "Transactions",
      translation: "navigation.transactions",
      icon: "soccer",
      linkGroup: [
        {
          group: "transactions",
          link: "/deposit",
          icon: "gift",
          iconAlt: "Icon gift",
          translation: "navigation.deposit",
          linkText: "Deposit",
          linkGroup: [],
        },
        {
          group: "transactions",
          link: "/withdraw",
          translation: "navigation.withdrawal",
          icon: "gift",
          iconAlt: "Icon gift",
          linkText: "Withdrawal",
          linkGroup: [],
        },
         {
          group: "transactions",
          link: "/transfer",
          translation: "navigation.transfer",
          icon: "gift",
          iconAlt: "Icon gift",
          linkText: "Transfer",
          linkGroup: [],
        }, 
      ],
    },
    {
      group: "Account",
      translation: "navigation.account",
      icon: "soccer",
      linkGroup: [
        {
          group: "Account",
          link: "/profile",
          icon: "gift",
          translation: "navigation.profile",
          iconAlt: "Icon gift",
          linkText: "Profile",
          linkGroup: [],
        },
        {
          group: "Account",
          link: "/personal-information",
          icon: "gift",
          iconAlt: "Icon gift",
          translation: "navigation.personalInfo",
          linkText: "Personal Information",
          linkGroup: [],
        },
        {
            group: "Account",
            link: "/limits",
            icon: "gift",
            iconAlt: "Icon gift",
            translation: "navigation.protectionSettings",
            linkText: "Protection Settings",
            linkGroup: [],
          },
        {
          group: "Account",
          link: "/change-password",
          icon: "gift",
          translation: "navigation.changePass",
          iconAlt: "Icon gift",
          linkText: "Change Password",
          linkGroup: [],
        },
        {
          group: "Account",
          link: "/kyc",
          icon: "gift",
          translation: "navigation.kyc",
          iconAlt: "Icon gift",
          linkText: "KYC",
          linkGroup: [],
        },
      ],
    },
    {
      group: "Promotions",
      icon: "soccer",
      translation: "navigation.promotions",
      linkGroup: [
        {
          group: "promotions",
          link: "/promotions",
          icon: "gift",
          iconAlt: "Icon gift",
          translation: "navigation.promotions",
          linkText: "Promotions",
          linkGroup: [],
        },
      ],
    },
    {
      group: "History",
      icon: "gift",
      translation: "navigation.history",
      linkGroup: [
        {
          group: "history",
          link: "/bets-history",
          icon: "gift",
          iconAlt: "Icon gift",
          translation: "navigation.betsHistory",
          linkText: "Bets History",
        },
        {
          group: "history",
          link: "/bonus-history",
          icon: "gift",
          iconAlt: "Icon gift",
          translation: "navigation.bonusHistory",
          linkText: "Bonus History",
        },
        {
          group: "history",
          link: "/transaction-history",
          icon: "gift",
          iconAlt: "Icon gift",
          translation: "navigation.transactionHistory",
          linkText: "Transaction History",
        },
      ],
    },
    {
      group: "Help",
      icon: "help",
      translation: "navigation.help",
      linkGroup: [
        {
          group: "support",
          link: "/support",
          icon: "gift",
          iconAlt: "Icon gift",
          translation: "navigation.support",
          linkText: "Support",
          linkGroup: [],
        },
        {
          group: "faq",
          link: "/faq",
          icon: "gift",
          iconAlt: "Icon gift",
          translation: "navigation.faq",
          linkText: "FAQ",
          linkGroup: [],
        },
        {
          group: "terms",
          link: "/terms",
          icon: "gift",
          iconAlt: "Icon gift",
          translation: "navigation.terms",
          linkText: "Terms & Conditions",
          linkGroup: [],
        },
        {
          group: "security",
          link: "/security",
          icon: "gift",
          iconAlt: "Icon gift",
          translation: "navigation.privacy",
          linkText: "Privacy Policy",
          linkGroup: [],
        },
        {
          group: "gameExplanations",
          link: "/game-explanations",
          icon: "gift",
          iconAlt: "Icon gift",
          translation: "navigation.gameExplanations",
          linkText: "Game Explanations",
          linkGroup: [],
        },
        {
          group: "bonusRules",
          link: "/bonus-rules",
          icon: "gift",
          iconAlt: "Icon gift",
          translation: "navigation.bonusRules",
          linkText: "Bonus Rules",
          linkGroup: [],
        }
      ],
    },
  ];
  