import axios from "axios";

export async function axiosGet(url: string) {
  let response;
  try {
    response = await axios.get(`${url}`);
  } catch (error) {
    return false;
  }

  return response.data.data;
}

export async function axiosPut(url: string, body: any) {
  let response = null;
  try {
    response = await axios.put(`${url}`, body);
  } catch (error) {
    return false;
  }

  return response;
}

export async function axiosPost(url: string, body: any) {
  let response = null;
  try {
    response = await axios.post(`${url}`, body);
  } catch (error) {
    return false;
  }

  return response;
}
