<script setup lang="ts">
import { useLayoutModalsStore } from "@/stores/layoutModals";
import InputValueComponent from "@/brands/bit1111/components/shared/forms/input/InputValueComponent.vue";
import DropDownComponent from "@/brands/bit1111/components/shared/forms/dropdown/DropDownComponent.vue";
import { ref, onMounted } from "vue";
import { useLoaderStore } from "@/stores/loader";
import { useAuthStore } from "@/stores/auth";
import { registerStepTwo } from "@/services/auth/registration";
import type {
  RegisterInitialPayloadInterface,
  RegisterConfirmPayLoadInterface,
} from "@/services/interface/registrationInterface";
import type { PropType } from "vue";
import { RouterSingleton } from "@/router/index";
import InputPhoneComponent from "@/brands/bit1111/components/shared/forms/input/InputPhoneComponent.vue";
import CheckBoxTermsAndConditions from "@/brands/bit1111/components/auth/TermsAndConditionsBit.vue";
import RegistrationStepCompoment from "@/brands/bit1111/components/auth/RegistrationStepComponent.vue";
import IconComponent from "@/components/icons/IconComponent.vue";
import { translate } from "@/services/translations/translation";
import ButtonComponent from "@/brands/bit1111/components/shared/buttons/ButtonComponent.vue";
import { isMobileCheck } from "@/services/interface/clientInformation";
import { computeFormValidation } from "@/services/validation/formValidation";
import { goToRoute } from "@/router/routingService";

const props = defineProps({
  stepOneData: {
    required: true,
    type: Object as PropType<RegisterInitialPayloadInterface>,
  },
});

const borderClass = ref("border-input radius-10");
const loader = useLoaderStore();
const authStore = useAuthStore();
const layoutModalsStore = useLayoutModalsStore();

const registerData = ref<RegisterConfirmPayLoadInterface>({
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  gender: "",
});

const close = () => {
  layoutModalsStore.closeModal("registerConfirmLast");
};

const selectedCountry = ref();
const city = ref("");
const address = ref("");
const zipCode = ref("");
const phoneCode = ref("+");
const phoneNumber = ref("");

const submit = async () => {
  let key: ValidationKey;
  for (key in validationList.value) {
    validationList.value[key].isTouched = true;
  }
  if (formisValid.value) {
  const data = {
    address: address.value,
    city: city.value,
    country: selectedCountry.value.id,
    dateOfBirth: registerData.value.dateOfBirth,
    firstName: registerData.value.firstName,
    lastName: registerData.value.lastName,
    gender: registerData.value.gender,
    zipCode: zipCode.value,
    phone: phoneNumber.value,
    phoneCode: phoneCode.value,
  };
  loader.toggleLoader(true);
  registerStepTwo(data)
    .then(async () => {
      await authStore.setPlayerData(true);
      RouterSingleton.getInstance().getRouter().push("/");
      loader.toggleLoader(false);
    })
    .catch((error) => {
      loader.toggleLoader(false);
    });

  close();
  }
};

onMounted(() => {
  registerData.value = JSON.parse(JSON.stringify(props.stepOneData));
});

// VALIDATION START
const validationList = ref({
  country: { isTouched: false, valid: false },
  city: { isTouched: false, valid: false },
  address: { isTouched: false, valid: false },
  zipCode: { isTouched: false, valid: false },
  phone: { isTouched: false, valid: false },
  checkboxComponent: { isTouched: false, valid: false },
});
type ValidationKey = keyof typeof validationList.value;

const formisValid = ref<boolean>(false);
const callbackValid = (pointer: ValidationKey, value: boolean) => {
  validationList.value[pointer].valid = value;
  computeFormValidation(validationList.value, formisValid);
};
// VALIDATION END
</script>
<template>
  <div>
    <div class="backdrop"></div>
    <form @submit.prevent>
      <div class="auth-modal-bit1111 bg-4-6 pdx-7 pdy-5">
        <div type="" class="flex flex-right">
          <IconComponent
            :iconClasses="'svg-icon block icon-16 icon-form-clear bg-1-1 cursor-pointer'"
            @click="close"
          ></IconComponent>
        </div>
        <h1
          class="title-large mgt-5 txt-1-1 txt-bold mgx-auto fit-content to-upper-case title-xxl"
        >
          {{ translate("common.register").toUpperCase() }}
        </h1>
        <p class="txt-light mgx-auto fit-content text-small txt-bold">
          {{ translate("auth.register_init.mobile.registerComplete.almostThere") }}
        </p>
        <RegistrationStepCompoment :step="3" />
        <DropDownComponent
          dropdownId="country"
          :borderClass="borderClass"
          :label="translate('common.country')"
          :url="'/geo-resources/countries'"
          :selected="selectedCountry"
          :selector="'name'"
          textCutBorder="label-cut-border-light"
          @emitSelected="selectedCountry = $event"
          v-model:isTouched="validationList.country.isTouched"
          @isValid="callbackValid('country', $event)"
        >
        </DropDownComponent>

        <InputValueComponent
          :borderClass="borderClass"
          inputId="city"
          :placeholder="translate('common.city')"
          regexValidator="city"
          v-model="city"
          :minLength="1"
          :maxLength="200"
          textCutBorder="label-cut-border-light"
          v-model:isTouched="validationList.city.isTouched"
          @isValid="callbackValid('city', $event)"
        ></InputValueComponent>
        <InputValueComponent
          :borderClass="borderClass"
          inputId="adress"
          :placeholder="translate('common.address')"
          regexValidator="address"
          v-model="address"
          :minLength="2"
          :maxLength="40"
          textCutBorder="label-cut-border-light"
          v-model:isTouched="validationList.address.isTouched"
          @isValid="callbackValid('address', $event)"
        ></InputValueComponent>
        <InputValueComponent
          :borderClass="borderClass"
          inputId="zip"
          :placeholder="translate('common.zipCode')"
          regexValidator="zipCode"
          v-model="zipCode"
          :minLength="2"
          :maxLength="10"
          textCutBorder="label-cut-border-light"
          v-model:isTouched="validationList.zipCode.isTouched"
          @isValid="callbackValid('zipCode', $event)"
        ></InputValueComponent>
        <div class="mgt-4">
          <InputPhoneComponent
            :selectedCode="phoneCode"
            :selectedPhoneNumber="Number(phoneNumber)"
            codeRegexValidator="phoneCode"
            phoneRegexValidator="phone"
            :codeMinLength="2"
            :codeMaxLength="10"
            :phoneMinLength="5"
            :phoneMaxLength="15"
            @changePhoneCode="phoneCode = $event"
            @changePhoneNumber="phoneNumber = $event"
            @isValid="callbackValid('phone', $event)"
            :labelClass="'label-cut-border-light'"
            v-model:isTouched="validationList.phone.isTouched"
          />
        </div>
        <CheckBoxTermsAndConditions
          @isValid="callbackValid('checkboxComponent', $event)"
          v-model:isTouched="validationList.checkboxComponent.isTouched"
        />
        <ButtonComponent
          class="mgb-6 mgt-5"
          id="submit"
          :text="translate('auth.register_init.mobile.registerComplete.funBegin')"
          :width="100"
          :heightPx="40"
          @click="submit()"
        />
      </div>
    </form>
  </div>
</template>
