<script setup lang="ts">
import { computed } from "vue";
import { useBonusesStore } from "@/stores/bonuses";
import { useAuthStore } from "@/stores/auth";
import { isMobileCheck } from "@/services/interface/clientInformation";
import ButtonComponent from "@/brands/bit1111/components/shared/buttons/ButtonComponent.vue";
import { translate } from "@/services/translations/translation";

const isMobile = isMobileCheck();
const bonusesStore = useBonusesStore();
const authStore = useAuthStore();
const bonuses = computed(() =>
  bonusesStore.$state.appliedBonuses ? bonusesStore.$state.appliedBonuses : null
);
/* const boarding = computed(() =>
  authStore.$state.player ? authStore.$state.player.onboarding : null
); */

const bonusAnswer = (bonusId: number, value: number) => {
  bonusesStore.acceptOrDeclineOrClaimLaterBonus(bonusId, value);
};
</script>

<template>
  <div class="backdrop" v-if="false">
    <div
      class="bg-4-1 relative border-piq radius-12"
      :class="isMobile ? 'sign-up-wrapper-mobile' : 'sign-up-wrapper'"
    >
      <div class="mgt-7" v-for="bonus in bonuses" :key="bonus.id">
        <h3 class="txt-center">
          {{ translate("common.bonus") }}:
          <span class="txt-1-1">{{ bonus?.bonusAward.bonus.name }}</span>
        </h3>
        <div class="space-between flex pdx-3 mgb-5">
          <p>{{ translate("common.amount") }}:</p>
          <p id="balance" class="txt-1-1 txt-bold">
            {{ bonus?.amount }} {{ bonus?.currencyCode }}
          </p>
        </div>
        <div v-if="bonus?.obtainableUntil" class="space-between flex pdx-3 mgb-5">
          <p>{{ translate("promotions.desktop.obtainableUntil") }}:</p>
          <p id="balance" class="txt-1-1 txt-bold">
            {{ bonus?.obtainableUntil }}
          </p>
        </div>
        <div class="space-evenly flex">
          <ButtonComponent
            class="mgx-3"
            text="Claim"
            @click="bonusAnswer(bonus.id, 2)"
            :heightPx="38"
          />
          <ButtonComponent
            class="mgx-3"
            type="errorFilled"
            text="Decline"
            @click="bonusAnswer(bonus.id, 5)"
            :heightPx="38"
          />
          <ButtonComponent
            class="mgx-3"
            type="secondaryFilled"
            text="Claim Later"
            @click="bonusAnswer(bonus.id, 4)"
            :heightPx="38"
          />
        </div>
      </div>
    </div>
  </div>
</template>
