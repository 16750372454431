<script setup lang="ts">
import { useLayoutModalsStore } from "@/stores/layoutModals";
import InputValueComponent from "@/brands/bit1111/components/shared/forms/input/InputValueComponent.vue";
import InputPasswordComponent from "@/brands/bit1111/components/shared/forms/input/InputPasswordComponent.vue";
import DropDownComponent from "@/brands/bit1111/components/shared/forms/dropdown/DropDownComponent.vue";
import { ref, onMounted } from "vue";
import { registerStepOne } from "@/services/auth/registration";
import { useLoaderStore } from "@/stores/loader";
import { useAuthStore } from "@/stores/auth";
import SocialsComponent from "@/brands/bit1111/components/auth/SocialsTwoComponent.vue";
import RegistrationStepCompoment from "@/brands/bit1111/components/auth/RegistrationStepComponent.vue";
import { goToRoute } from "@/router/routingService";
import type { FormResponseValidation } from "@/services/interface/registrationInterface";
import type { LooseObject } from "@/services/interface/utils";
import { ErrorHandler } from "@/lib/exceptions/ErrorHandler";
import { translate } from "@/services/translations/translation";
import IconComponent from "@/components/icons/IconComponent.vue";
import ButtonComponent from "@/brands/bit1111/components/shared/buttons/ButtonComponent.vue";
import { useQuickRegistration } from "@/stores/quickRegistration";
import type { RegisterInitialPayloadInterface } from "@/brands/bit1111/interface/registrationInterface";
import { useBrandStore } from "@/stores/brand";
import { computeFormValidation } from "@/services/validation/formValidation";
import { useDropdownsStore } from "@/stores/dropdowns.js";

const store = useDropdownsStore();

const registerData = ref<RegisterInitialPayloadInterface>({
  currency: "",
  email: "",
  password: "",
  username: "",
  affiliateCode: "",
  affiliateType: "",
});

const formResponseValidation = ref<LooseObject>({
  username: [],
  password: [],
  email: [],
});

const brandStore = useBrandStore();
const isMultiCurrency = brandStore.getModule("multi_currency");
const quickRegistrationStore = useQuickRegistration();
const loader = useLoaderStore();
const authStore = useAuthStore();
const layoutModalsStore = useLayoutModalsStore();
const selectedCurrency = ref<any>();
const hideResponseErrors = ref(false);
const affiliateError = ref();
const affiliateDropdown =  ref(); 

// VALIDATION START
const validationList = ref({
  username: { isTouched: false, valid: false },
  password: { isTouched: false, valid: false },
  email: { isTouched: false, valid: false },
  currency: { isTouched: false, valid: false },
});
type ValidationKey = keyof typeof validationList.value;

const formisValid = ref<boolean>(false);
const callbackValid = (pointer: ValidationKey, value: boolean) => {
  validationList.value[pointer].valid = value;
  computeFormValidation(validationList.value, formisValid);
};
// VALIDATION END
const close = () => {
  layoutModalsStore.closeModal("register");
};

const reRoute = (url: string) => {
  goToRoute("/login");
  close();
};

const submit = async() => {
 
  const data = {
    username: registerData.value.username.toLowerCase(),
    email: registerData.value.email,
    currency: isMultiCurrency
      ? selectedCurrency.value.code.toString()
      : selectedCurrency.value,
    password: registerData.value.password,
    affiliateCode: registerData.value.affiliateCode,
    affiliateType: registerData.value.affiliateType,
  };
  let key: ValidationKey;
  for (key in validationList.value) {
    validationList.value[key].isTouched = true;
  }
  if (formisValid.value) {
    loader.toggleLoader(true);
    registerStepOne(data)
      .then(async (response) => {
        hideResponseErrors.value = true;
        loader.toggleLoader(false);
        await authStore.login(data.username, data.password);
        // await authStore.setPlayerData();
        goToRoute(`/register/mailsent?email=${data.email}`);
        close();
      })
      .catch((error) => {
        hideResponseErrors.value = false;
        loader.toggleLoader(false);
        error.response.data
          ? (affiliateError.value = error.response.data.detail)
          : null;
        for (const key of Object.keys(formResponseValidation.value)) {
          formResponseValidation.value[key] = [];
        }
        const handler = new ErrorHandler();
        const err = handler.handle(error);
        err.errors?.forEach((element) => {
          formResponseValidation.value[
            element.property as keyof FormResponseValidation
          ].push(element.message);
        });
      });
  }
 
};

 
const getAffiliateParam = async(param: string) => {
  let affiliateId = null;
  for(let i = 0; i < affiliateDropdown.value?.length; i++) {
    if(param === affiliateDropdown.value[i].param){
     affiliateId = affiliateDropdown.value[i].id;
    }
  }
  return affiliateId;
}

onMounted(async() => {
  const modal = layoutModalsStore.getModal("register");
  let queryParam = "";
  const data = quickRegistrationStore.getData;   

  if(modal.query) {
    queryParam = Object.keys(modal.query)[0];
    affiliateDropdown.value = await store.getDropdown('/dropdown/affiliate-providers');
  }
  
  registerData.value = {
    currency: "",
    email: data.email,
    password: data.password,
    username: data.username,
    affiliateCode: modal.query ? modal.query[queryParam] : null,
    affiliateType: modal.query ? await getAffiliateParam(queryParam) : null 
  };

  isMultiCurrency ? null : (selectedCurrency.value = "EUR");
});
</script>
<template>
  <div>
    <div class="backdrop"></div>
    <form @submit.prevent>
      <div class="auth-modal-bit1111 bg-4-6 pdy-5 pdx-7">
        <div type="" class="flex flex-right">
          <IconComponent
            :iconClasses="'svg-icon block icon-16  icon-form-clear bg-1-1 cursor-pointer'"
            @click="close"
          ></IconComponent>
        </div>

        <h1
          class="mgt-5 txt-1-1 txt-bold mgx-auto fit-content to-upper-case title-xxl"
        >
          {{ translate("auth.register_init.desktop.header") }}
        </h1>
        <p class="txt-light mgx-auto fit-content text-small txt-bold">
          {{ translate("auth.register_init.desktop.header_description") }}
        </p>
        <RegistrationStepCompoment :step="1" />
        <InputValueComponent
          :responseErrors="formResponseValidation.username"
          :hideResponseErrors="hideResponseErrors"
          @hideErrors="hideResponseErrors = $event"
          inputId="name"
          :placeholder="translate('auth.register_init.desktop.username')"
          regexValidator="username"
          v-model="registerData.username"
          :minLength="5"
          :maxLength="25"
          textCutBorder="label-cut-border-light"
          :borderClass="'border-input radius-10'"
          v-model:isTouched="validationList.username.isTouched"
          @isValid="callbackValid('username', $event)"
        />
        <DropDownComponent
          v-if="isMultiCurrency"
          dropdownId="currency"
          :label="translate('auth.register_init.desktop.currency')"
          url="/currencies"
          :selector="'code'"
          :preselect="true"
          :selected="selectedCurrency"
          textCutBorder="label-cut-border-light"
          @emitSelected="selectedCurrency = $event"
          :borderClass="'border-input radius-10 '"
          @isValid="callbackValid('currency', $event)"
        />
        <InputValueComponent
          v-if="!isMultiCurrency"
          :responseErrors="formResponseValidation.currency"
          :hideResponseErrors="hideResponseErrors"
          @hideErrors="hideResponseErrors = $event"
          inputId="currency"
          :placeholder="translate('auth.register_init.desktop.currency')"
          v-model="selectedCurrency"
          textCutBorder="label-cut-border-light"
          :borderClass="'border-input radius-10'"
          v-model:isTouched="validationList.currency.isTouched"
          @isValid="callbackValid('currency', $event)"
          :isDisabled="true"
        />
        <InputValueComponent
          inputId="email"
          :placeholder="translate('auth.register_init.desktop.email')"
          regexValidator="email"
          v-model="registerData.email"
          :minLength="5"
          :maxLength="64"
          :borderClass="'border-input radius-10'"
          textCutBorder="label-cut-border-light"
          :responseErrors="formResponseValidation.email"
          :hideResponseErrors="hideResponseErrors"
          @hideErrors="hideResponseErrors = $event"
          v-model:isTouched="validationList.email.isTouched"
          @isValid="callbackValid('email', $event)"
        />
        <InputPasswordComponent
          passId="password"
          :label="translate('auth.register_init.desktop.password')"
          v-model="registerData.password"
          :minLength="8"
          :maxLength="25"
          :borderClass="'border-input radius-10'"
          textCutBorder="label-cut-border-light"
          regexValidator="password"
          :responseErrors="formResponseValidation.password"
          :hideResponseErrors="hideResponseErrors"
          :isTouched="validationList.password.isTouched"
          @hideErrors="hideResponseErrors = $event"
          @isValid="callbackValid('password', $event)"
        />
        <!-- <CheckBoxTermsAndConditions /> -->

        <div class="txt-error-1 pdx-4">
          {{ affiliateError }}
        </div>

        <ButtonComponent
          class="mgb-6 mgt-5"
          id="submit"
          :text="translate('auth.register_init.desktop.submit')"
          :width="100"
          :heightPx="40"
          @click="submit()"
        />

        <!-- <SocialsComponent separatorColor="bg-2-1" /> -->
        <div>
          <p class="txt-light text-small mgx-auto fit-content">
            {{ translate("auth.register_init.desktop.already_have_account") }}
            <span
              class="txt-1-1 cursor-pointer underlined"
              @click="reRoute('/login')"
              >{{ translate("auth.register_init.desktop.login") }}</span
            >
          </p>
        </div>
      </div>
    </form>
  </div>
</template>
