<script setup lang="ts">
import { ref } from "vue";
import { useAuthStore } from "../../stores/auth";
import DropDownComponent from "../../components/shared/forms/dropdown/DropDownComponent.vue";
import InputPasswordComponent from "../../components/shared/forms/input/InputPasswordComponent.vue";
import InputValueComponent from "../../components/shared/forms/input/InputValueComponent.vue";
import { useLoaderStore } from "../../stores/loader";
import axios from "axios";
import InputPhoneComponent from "@/components/shared/forms/input/InputPhoneComponent.vue";
import NotificationComponent from "@/components/shared/notification/NotificationComponent.vue";

const store = useAuthStore();
const loader = useLoaderStore();
const user = store.player;
const selectedLanguage = ref(user?.language);
const selectedCurrency = ref(user?.wallets.casino.currency);
const password = ref();
const confirmPassword = ref("");
const address = ref();
const zipcode = ref(user?.zipCode);
const city = ref();
const phoneCode = ref(user?.phoneCode);
const phoneNumber = ref();
const notificationSuccess = ref(false);
const notificationError = ref(false);
const notificationErrorMsg = ref();

const getUser = () => {
  city.value = user?.city;
  address.value = user?.address;
  phoneNumber.value = user?.phone;
};
getUser();

const info = ref([
  {
    name: "Name",
    data: user?.firstName,
  },
  {
    name: "Username",
    data: user?.username,
  },
  {
    name: "Email",
    data: user?.email,
  },
  {
    name: "Date of Birth",
    data: user?.dateOfBirth,
  },
  {
    name: "Country",
    data: user?.country.name,
  },
]);
// VALIDATION START
const newValidationList = ref({
  language: Boolean,
  currency: Boolean,
  password: String,
  confirmPassword: String,
  address: String,
  zipcode: Number,
  city: String,
  phoneNumber: Number,
});
type ValidationKey = keyof typeof newValidationList.value;

const formisValid = ref();
const callbackValid = (
  pointer: ValidationKey,
  value: BooleanConstructor & StringConstructor & NumberConstructor
) => {
  newValidationList.value[pointer] = value;
  computeFormValidation();
};
const computeFormValidation = () => {
  let flag = true;
  let key: ValidationKey;
  for (key in newValidationList.value) {
    if (!newValidationList.value[key]) flag = false;
  }
  formisValid.value = flag;
};
// VALIDATION END

//TODO: Waiting on BE endpoint
const submit = async () => {
  notificationSuccess.value = false;
  notificationError.value = false;
  loader.toggleLoader(true);
  if (password.value == confirmPassword.value) {
    await axios
      .put("/players", {
        language: selectedLanguage.value?.id,
        currency: selectedCurrency.value?.id,
        password: password.value,
        address: address.value,
        zipCode: zipcode.value,
        city: city.value,
        phoneCode: phoneCode.value,
        phone: phoneNumber.value,
      })
      .then(() => {
        notificationSuccess.value = true;
        useAuthStore().setPlayerData();
        loader.toggleLoader(false);
      })
      .catch((error) => {
        notificationError.value = true;
        notificationErrorMsg.value = error.response.data.errors[0].message;
        loader.toggleLoader(false);
      });
  }
};
</script>

<template>
  <div class="form-size">
    <div class="mgx-5">
      <form
        autocomplete="off"
        class="complete-registration-form pdx-4 form-size"
        @submit.prevent
      >
        <NotificationComponent
          v-show="notificationSuccess"
          class="mgy-3"
          title="Success!"
          messageBody="Changes saved successfuly"
          bgClass="bg-success"
          @emitDismiss="notificationSuccess = false"
        ></NotificationComponent>
        <NotificationComponent
          v-show="notificationError"
          class="mgy-3"
          title="Saving data failed!"
          :messageBody="notificationErrorMsg"
          bgClass="bg-error"
          @emitDismiss="notificationError = false"
        ></NotificationComponent>
        <h3 class="txt-theme-txt-1 title-small text-spacing-large mgt-6 txt-bold">
          Personal Info
        </h3>
        <div v-for="(item, i) in info" :key="i" class="space-between flex">
          <p class="text-small text-spacing-xl txt-theme-txt-2">
            {{ item.name }}
          </p>
          <p
            :data-testid="`player-data-${i}`"
            class="text-small text-spacing-xl txt-theme-brand-2"
          >
            {{ item.data }}
          </p>
        </div>
        <DropDownComponent
          dropdownId="language"
          label="Language Preference"
          url="/dropdown/languages"
          :selector="'name'"
          :selected="selectedLanguage"
          @emitSelected="selectedLanguage = $event"
          @isValid="callbackValid('language', $event)"
        ></DropDownComponent>
        <DropDownComponent
          dropdownId="currency"
          label="Currency"
          url="/currencies"
          :selector="'code'"
          :selected="selectedCurrency"
          @emitSelected="selectedCurrency = $event"
          @isValid="callbackValid('currency', $event)"
        ></DropDownComponent>
        <h3 class="txt-theme-txt-1 title-small text-spacing-large mgt-6 txt-bold">
          Password
        </h3>
        <InputPasswordComponent
          passId="piPassword"
          v-model="password"
          label="New Password"
          @isValid="callbackValid('password', $event)"
          :minLength="8"
        ></InputPasswordComponent>
        <InputPasswordComponent
          :bgColor="
            confirmPassword.length > 0 && confirmPassword !== password
              ? 'bg-error-light'
              : 'bg-theme-bg-1'
          "
          passId="piConfirmPassword"
          v-model="confirmPassword"
          label="Confirm Password"
          @isValid="callbackValid('confirmPassword', $event)"
          :minLength="8"
        ></InputPasswordComponent>
        <h3 class="txt-theme-txt-1 title-small text-spacing-large mgt-6 txt-bold">
          Address
        </h3>
        <InputValueComponent
          inputId="address"
          placeholder="Address"
          inputType="text"
          v-model="address"
          :minLength="0"
          :maxLength="255"
          @isValid="callbackValid('address', $event)"
        ></InputValueComponent>
        <InputValueComponent
          inputId="zip"
          placeholder="Zipcode"
          inputType="text"
          v-model="zipcode"
          :minLength="2"
          :maxLength="10"
          @isValid="callbackValid('zipcode', $event)"
        ></InputValueComponent>
        <InputValueComponent
          inputId="city"
          placeholder="City"
          inputType="text"
          v-model="city"
          :minLength="0"
          :maxLength="255"
          @isValid="callbackValid('city', $event)"
        ></InputValueComponent>
        <h3 class="txt-theme-txt-1 title-small text-spacing-large mgt-6 txt-bold">
          Contact
        </h3>
        <InputPhoneComponent
          :selectedCode="phoneCode"
          :selectedPhoneNumber="phoneNumber"
          @changePhoneCode="phoneCode = $event"
          @changePhoneNumber="phoneNumber = $event"
          @isValid="callbackValid('phoneNumber', $event)"
        ></InputPhoneComponent>
        <button
          id="submit"
          class="btn btn-large btn-brand-light mgx-auto mgy-6 txt-bold"
          :disabled="!formisValid"
          @click="submit"
        >
          SAVE CHANGES
        </button>
      </form>
    </div>
  </div>
</template>
