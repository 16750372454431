export function validateMultipleCheckbox(value: any, number: number) {
  if (number == 0) {
    return { isValid: true, message: "" };
  } else {
    let counter = 0;
    for (let index = 0; index < value.length; index++) {
      if (value[index].isChecked) counter++;
    }
    if (counter >= number) return { isValid: true, message: "" };
    else if (number == value.length) {
      return {
        isValid: false,
        message: "All checkboxes are required",
      };
    }
    return {
      isValid: false,
      message: `Select at least ${number} option${number > 1 ? "s" : ""}`,
    };
  }
}
