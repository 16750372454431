import { useAuthStore } from "@/stores/auth";
import { useBrandStore } from "@/stores/brand";
import { useLoaderStore } from "@/stores/loader";

export const getAccessToken = () => {
  const store = useAuthStore();
  return store.$state.accessToken;
};

export const getBrandName = () => {
  const store = useBrandStore();
  return store.$state.name;
};

export async function sendingRefreshToken() {
  const store = useAuthStore();
  const refreshToken = store.getRefreshToken();

  if (refreshToken == null) {
    logout();
    return;
  }
  return store.sendRefreshToken();
}

export const logout = () => {
  const loaderStore = useLoaderStore();
  loaderStore.toggleLoader(false);
  const store = useAuthStore();
  return store.logout();
};

export const gettingActivity = () => {
  const store = useAuthStore();
  return store.getActivity();
};

export const setingActivity = () => {
  const store = useAuthStore();
  store.setActivity();
};

export async function snoozeRequest(iterationCount = 5, iterationTime = 1000) {
  let refreshTokenWait = null;
  let counter = 0;
  while (counter < iterationCount) {
    refreshTokenWait = localStorage.getItem("refreshTokenWait");
    if (refreshTokenWait == null) {
      break;
    }
    await sleep(iterationTime);
    counter++;
  }
}

const sleep = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
