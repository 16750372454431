<script setup lang="ts">
import { computed, ref, onMounted, watch } from "vue";
import { useAuthStore } from "@/stores/auth";
import { links, balances } from "@/services/data/personalMenu";
import { useBalanceStore } from "@/stores/balance";
import LanguageComponent from "@/brands/bit1111/components/shared/language/LanguageComponent.vue";
import { RouterSingleton } from "@/router/index";
import { translate } from "@/services/translations/translation";
import ButtonComponent from "@/brands/bit1111/components/shared/buttons/ButtonComponent.vue";
import ConfirmationModalComponent from "../../shared/modal/ConfirmationModalComponent.vue";
import axios from "axios";
import CategorySliderComponent from "@/brands/bit1111/components/casino/CategorySliderComponent.vue";
import { useBonusesStore } from "@/stores/bonuses";
import { useRoute } from "vue-router";
// import resp from "./res.json";
import IconComponent from "@/components/icons/IconComponent.vue";
import { goToRoute } from "@/router/routingService";

const route = useRoute();

const balanceStore = useBalanceStore();
const sportbookBalance = computed(() => balanceStore.getWallet("sportbook"));
const casinoBalance = computed(() => balanceStore.getWallet("casino"));
const isConfirmationModalActive = ref(false);

const availableBonuses = ref();
const bonusScrollPane = ref();

const scrl = (type:boolean) => {
  bonusScrollPane.value.scrollBy({
    left: type ? 200 : -200,
    behavior: "smooth",
  });

};

const bonusStore = ref(useBonusesStore());

const depositPage = () => {
  handleClose();
  RouterSingleton.getInstance().getRouter().push("/deposit");
};

const goToUrl = (item: string) => {
  handleClose();
  RouterSingleton.getInstance().getRouter().push(item);
};

const emit = defineEmits(["closeMenu"]);

const handleClose = () => {
  emit("closeMenu", false);
};

const authStore = useAuthStore();
const logout = () => {
  emit("closeMenu", false);
  authStore.logout();
};

/* const modalAnswer = async (event: boolean) => {
  isConfirmationModalActive.value = false;
  if (event) {
    await axios
      .post("/obtained/bonus/active/cancel", {
        wagering: null,
        bonus_balance: null,
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  }
}; */

const percentage = ref(3);
const sportsbookPercentage = ref(0);
const roundedPercentageClass = ref("w-0");
const roundedPercentageSportsbookClass = ref("w-0");

const categories = ref([]);
onMounted(() => {
  try {
    if (authStore.$state.player) {

      bonusStore.value.getAllBonuses().then((allbonus) => {
        availableBonuses.value = allbonus.availableBonuses;
      });
      // availableBonuses.value = resp.data;
    }

    // availableBonuses.value = bonusStore.value.availableBonuses;
    const remain = casinoBalance.value.remainingWageringAmount ?? 0;
    const total = casinoBalance.value.totalWageringAmount ?? 0;
    percentage.value = (remain / (total == 0 ? 1 : total)) * 100;
    const sportsbookRemain =
      sportbookBalance.value.remainingWageringAmount ?? 0;
    const sportsbookTotal = sportbookBalance.value.totalWageringAmount ?? 0;
    sportsbookPercentage.value =
      (sportsbookRemain / (sportsbookTotal == 0 ? 1 : total)) * 100;

    axios
      .get("/dropdown/game-category")
      .then((response) => {
        categories.value = response.data.data;
      })
      .catch((error) => {
   //
      });
  } catch (err) {
    percentage.value = 0;
  }

  roundedPercentageClass.value = "w-" + Math.round(percentage.value);
  roundedPercentageSportsbookClass.value =
    "w-" + Math.round(sportsbookPercentage.value);
});

watch(
  route,
  (value) => {
    handleClose();
  },
  { deep: true }
);
</script>

<template>
  <div class="backdrop scrollbar-style " @click.self="handleClose">
    <!-- <ConfirmationModalComponent
      v-if="isConfirmationModalActive"
      :title="translate('navigation.header.desktop.confirmBonusDisable')"
      :positive-answer="translate('common.yes').toUpperCase()"
      :negative-answer="translate('common.no').toUpperCase()"
      @answer-from-modal="modalAnswer($event)"
    /> -->
    <div class="w-100 maxw-1184px absolute top-0">
      <div class="personal-menu-desktop-wrapper">
        <div
          class="absolute container-dropdown zindex-dropdown radius-10 bg-5-1 background-img-personal"
        >
          <div class="flex space-between pdx-5">
            <div class="tap-area flex flex-left-center">
              <p class="txt-18 txt-1-1">
                {{ authStore.$state.player?.firstName }}
                {{ authStore.$state.player?.lastName }}
              </p>
            </div>
            <div class="maxw-500px flex flex-justify-end flex-align-center">
              <LanguageComponent
                :divider="false"
                bgTheme="bg-4-6"
                :toggleDropdown="true"
                :absolutePosition="false"
                :personalMenuWrapper="true"
                topMargin="70px"
                :backdrop="false"
                :isInsideModal="true"
              >
              </LanguageComponent>
              <button
                class="bg-1-1 hover-bg-1-2 txt-light mgr-6 mgl-4 pdx-5 h-40px radius-5"
                @click="goToUrl('/profile')"
              >
                {{ translate("navigation.header.desktop.account") }}
              </button>
              <div id="logout" @click="logout" class="flex cursor-pointer">
                <div class="w-100">
                  <a class="txt-16 txt-error-1">
                    {{
                      translate("account.user_account_menu.desktop.logout")
                    }}</a
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- BALANCE -->

          <div class="flex flex-center flex-align-start">
            <div class="flex flex-column pdx-10 mgt-5 w-50">
              <p class="txt-18">
                {{ translate("common.casino") }}
              </p>
              <span class="pdl-5">
                <span class="flex space-between">
                  <p class="txt-18 no-wrap txt-light pda-0">
                    {{ translate("account.profile.desktop.available_balance") }}
                  </p>

                  <div class="dots w-100 pdt-3 mgx-2"></div>

                  <p class="txt-16 no-wrap pda-0">
                    {{
                      casinoBalance.balance?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) +
                      " " +
                      casinoBalance.currency?.code
                    }}
                  </p>
                </span>
                <span class="flex space-between">
                  <p class="txt-18 no-wrap txt-light pda-0">
                    {{ translate("account.profile.desktop.reserved_balance") }}
                  </p>

                  <div class="dots w-100 pdt-3 mgx-2"></div>

                  <p class="txt-16 no-wrap pda-0">
                    {{
                      casinoBalance.reservedFunds?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) +
                      " " +
                      casinoBalance.currency?.code
                    }}
                  </p>
                </span>
                <span class="flex flex-center">
                  <p class="txt-18 no-wrap txt-light pda-0">
                    {{ translate("common.bonus") }}
                  </p>
                  <div class="dots w-100 pdt-3 mgx-2"></div>

                  <p class="txt-16 pda-0 no-wrap">
                    {{
                      casinoBalance.bonusBalance?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) +
                      " " +
                      casinoBalance.currency?.code
                    }}
                  </p>
                </span>
                <!-- WAGERING -->
                <div
                  v-if="casinoBalance.remainingWageringAmount! > 0"
                  class="w-100 mgt-6"
                >
                  <span class="block txt-light txt-size-14 mgb-3 txt-bold"
                    >{{
                      translate("account.profile.desktop.wagering_requirement")
                    }}
                    {{ translate("common.casino") }}</span
                  >
                  <div class="flex space-between txt-size-12">
                    <span class="block txt-light txt-bold">
                      {{
                        casinoBalance.remainingWageringAmount?.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      }}
                      {{ casinoBalance.currency?.code }}
                    </span>
                    <span class="block txt-light txt-bold">
                      {{ percentage.toFixed() }}%
                    </span>
                    <span class="block txt-light txt-bold">
                      {{
                        casinoBalance.totalWageringAmount?.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      }}
                      {{ casinoBalance.currency?.code }}</span
                    >
                  </div>
                  <div class="radius-6 w-100 bg-1-3 h-6px">
                    <div
                      class="radius-6 bg-1-1 h-6px"
                      :class="roundedPercentageClass"
                    ></div>
                  </div>
                  <p class="txt-size-10 mgt-3 txt-light">
                    {{ translate("account.profile.desktop.text") }}
                  </p>
                </div>
              </span>
            </div>

            <div class="flex flex-column pdx-10 mgt-5 sportsbook w-50">
              <p class="txt-18">
                {{ translate("common.sportsbook") }}
              </p>
              <span class="pdl-5">
                <span class="flex space-between">
                  <p class="txt-18 no-wrap txt-light pda-0">
                    {{ translate("account.profile.desktop.available_balance") }}
                  </p>

                  <div class="dots w-100 pdt-3 mgx-2"></div>
                  <p class="txt-16 no-wrap pda-0">
                    {{
                      sportbookBalance.balance?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) +
                      " " +
                      sportbookBalance.currency?.code
                    }}
                  </p>
                </span>
                <span class="flex space-between">
                  <p class="txt-18 no-wrap txt-light pda-0">
                    {{ translate("account.profile.desktop.reserved_balance") }}
                  </p>

                  <div class="dots w-100 pdt-3 mgx-2"></div>
                  <p class="txt-16 no-wrap pda-0">
                    {{
                      sportbookBalance.reservedFunds?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) +
                      " " +
                      sportbookBalance.currency?.code
                    }}
                  </p>
                </span>
                <span class="flex flex-center">
                  <p class="txt-18 no-wrap txt-light pda-0">
                    {{ translate("common.bonus") }}
                  </p>
                  <div class="dots w-100 pdt-3 mgx-2"></div>

                  <p class="txt-16 no-wrap pda-0">
                    {{
                      sportbookBalance.bonusBalance?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) +
                      " " +
                      sportbookBalance.currency?.code
                    }}
                  </p>
                </span>
                <!-- WAGERING -->
                <div
                  v-if="sportbookBalance.remainingWageringAmount! > 0"
                  class="w-100 mgt-6"
                >
                  <span class="block txt-light txt-size-14 mgb-3 txt-bold"
                    >{{
                      translate("account.profile.desktop.wagering_requirement")
                    }}
                    {{ translate("common.sportsbook") }}</span
                  >
                  <div class="flex space-between txt-size-12">
                    <span class="block txt-light txt-bold">
                      {{
                        sportbookBalance.remainingWageringAmount?.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      }}
                      {{ sportbookBalance.currency?.code }}
                    </span>
                    <span class="block txt-light txt-bold">
                      {{ sportsbookPercentage.toFixed() }}%
                    </span>
                    <span class="block txt-light txt-bold">
                      {{
                        sportbookBalance.totalWageringAmount?.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      }}
                      {{ sportbookBalance.currency?.code }}</span
                    >
                  </div>
                  <div class="radius-6 w-100 bg-1-3 h-6px">
                    <div
                      class="radius-6 bg-1-1 h-6px"
                      :class="roundedPercentageSportsbookClass"
                    ></div>
                  </div>
                  <p class="txt-size-10 mgt-3 txt-light">
                    {{ translate("account.profile.desktop.text") }}
                  </p>
                </div>
              </span>
            </div>
          </div>

          <div class="pdx-12px w-100 flex flex-center flex-column">
            <ButtonComponent
              id="depositBtn"
              class="mgt-7 mgb-29px"
              :text="translate('account.profile.desktop.deposit').toUpperCase()"
              :widthPx="250"
              :heightPx="48"
              @click="depositPage"
            />
          </div>
          <h3 class="mgt-7 mgl-6 txt-weight-600">Bonuses</h3>

          <div class="flex mgx-auto fit-content mgy-7">
            <div class="flex-align-center flex mgr-5">
              <IconComponent
                @click="scrl(false)"
                iconClasses="svg-icon  icon-32 icon-angle bg-1-1 block mgx-2 cursor-pointer slider-arrow"
              ></IconComponent>
            </div>

            <div class="w-890px">
              <div
                ref="bonusScrollPane"
                class="scroll-overflow-x flex no-wrap scrollbar-hidden pdy-3"
              >
                <div
                  v-for="(bonus, i) in availableBonuses"
                  :key="i"
                  :style="'box-shadow: 0px 2px 6px 2px #a92779;'"
                  class="w-300px h-300px mgx-5 radius-10 bg-4-1"
                >
                  <img
                    :src="bonus.bonusAward.bonus.image"
                    alt=""
                    width="300"
                    height="150"
                    class="radius-top-10"
                  />

                  <p class="txt-1-1 txt-center txt-size-16 txt-weight-600">
                    {{ bonus.bonusAward.bonus.name }}
                  </p>
                  <p class="txt-weight-600 txt-center">
                    Max Bonus: {{ bonus.bonusAward.bonusMax }}%
                  </p>
                  <p class="txt-weight-600 txt-center">
                    Max Bonus: {{ bonus.amount
                    }}{{ bonus.bonusAward.currencyCode }}
                  </p>
                  <ButtonComponent
                    id="depositBtn"
                    class="mgx-auto mgt-5"
                    :text="'CLAIM NOW'"
                    :widthPx="250"
                    :heightPx="48"
                    @click="
                        goToRoute(`/promotions/${bonus.id}`)
                    "
                  />
                </div>
              </div>
            </div>

            <div class="flex-align-center flex mgl-5">
              <IconComponent
                @click="scrl(true)"
                iconClasses="svg-icon rotate-180 icon-32 icon-angle bg-1-1 block mgx-2 cursor-pointer slider-arrow"
              ></IconComponent>
            </div>
          </div>

          <div v-if="categories[0]" class="col-12 pdx-10">
            <CategorySliderComponent
              :gameWidth="284"
              :gameHeight="213"
              :rows="1"
              :visibleGames="3"
              :isCarousel="true"
              :category="categories[0]"
              :searchActive="false"
              :carouselTransitionValue="1200"
              :buttonWidth="180"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.backdrop {
  background: rgba(0, 0, 0, 0.9);
}
.sportsbook {
  opacity: 0.6;
  position: relative;
  z-index: 0;
}

.sportsbook:before {
  content: "Coming Soon";
  text-align: center;
  position: absolute;
  color: purple;
  font-size: 20px;
  font-weight: bold;
  z-index: 2;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  /* Safari */
  -webkit-transform: rotate(30deg);

  /* Firefox */
  -moz-transform: rotate(30deg);

  /* IE */
  -ms-transform: rotate(30deg);

  /* Opera */
  -o-transform: rotate(30deg);
}
.background-img-personal{
 
  background-image: url("https://i.ibb.co/kXjxDyH/bg-2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

}
</style>
