<script setup lang="ts">
import { ref } from "vue";
import DropDownComponent from "../shared/forms/dropdown/DropDownComponent.vue";
import InputValueComponent from "../shared/forms/input/InputValueComponent.vue";
import { useLoaderStore } from "../../stores/loader";
import { isMobileCheck } from "../../services/interface/clientInformation";

const emit = defineEmits(["emitSave"]);
const props = defineProps({
  type: String,
  timeFrame: String,
  limit: Number,
  coverage: String,
  hasCoverage: Boolean,
  isSet: Boolean,
  periodTo: String,
  periodFrom: String,
  locked: Boolean,

  testId: String,
});

const isMobile = isMobileCheck();
const loaderStore = useLoaderStore();
const editing = ref(false);
const limitEdit = ref();
const selectedWallet = ref();
const currency = ref("$");

const wallets = ref([
  {
    name: "Casino",
  },
  {
    name: "SportsBook",
  },
  {
    name: "All",
  },
]);

const save = () => {
  loaderStore.toggleLoader(true);
  editing.value = false;
  let limitData = {};
  if (props.hasCoverage) {
    limitData = {
      limit: limitEdit.value,
      coverage: selectedWallet.value.name.toUpperCase(),
      type: props.timeFrame,
    };
  } else {
    limitData = {
      limit: limitEdit.value,
      type: props.timeFrame,
    };
  }

  emit("emitSave", limitData);
  loaderStore.toggleLoader(false);
};

const cancel = () => {
  editing.value = false;
  limitEdit.value = props.limit;
};
</script>

<template>
  <div :class="isMobile ? '' : 'pdy-5 limit-atimic-component'" class="text-small">
    <div v-if="editing">
      <p
        id="atomicLimitHeader"
        class="capitalize pdb-3 mgb-2 txt-theme-brand-1 txt-bold title-small"
        :data-testid="`title-${testId}`"
      >
        {{ timeFrame }} {{ props.type }} limit
      </p>
      <div class="mgy-2">
        <InputValueComponent
          :inputId="`amount-input-${testId}`"
          :placeholder="timeFrame + ' ' + props.type + ' limit' + `(${currency})`"
          v-model="limitEdit"
          :inputType="'number'"
        />
      </div>
      <div class="mgy-2" v-if="hasCoverage">
        <DropDownComponent
          dropdownId="bonus"
          :label="'Coverage'"
          :options="wallets"
          :selector="'name'"
          @emitSelected="selectedWallet = $event"
        />
      </div>
      <div class="flex mgb-3">
        <button
          id="login"
          class="btn-auth bg-btn-secondary txt-theme-brand-2 mgr-2"
          @click="cancel"
        >
          CANCEL
        </button>
        <button
          id="register"
          class="btn-auth bg-btn-primary txt-dark-primary mgl-2"
          @click="save"
          :disabled="
            hasCoverage ? !(selectedWallet && limitEdit) || limitEdit == 0 : !limitEdit
          "
        >
          SAVE
        </button>
      </div>
    </div>
    <template v-else>
      <div v-if="isSet">
        <p
          class="capitalize pdb-3 mgb-2 txt-theme-brand-1 txt-bold title-small"
          :data-testid="`title-${testId}`"
        >
          {{ timeFrame }} {{ props.type }} limit
        </p>
        <div class="space-between flex mgy-2">
          <p class="txt-theme-txt-2">Limit</p>
          <p :data-testid="`limit-value-isSet-${testId}`" class="txt-theme-brand-2">
            {{ currency }}{{ props.limit }}
          </p>
        </div>
        <div class="space-between flex mgy-2">
          <p class="txt-theme-txt-2">Period(from)</p>
          <p :data-testid="`period-from-${testId}`" class="txt-theme-brand-2">
            {{ periodFrom }}
          </p>
        </div>
        <div class="space-between flex mgy-2">
          <p class="txt-theme-txt-2">Period(to)</p>
          <p :data-testid="`period-to-${testId}`" class="txt-theme-brand-2">
            {{ periodTo }}
          </p>
        </div>
        <div class="space-between flex mgy-2" v-if="hasCoverage">
          <p class="txt-theme-txt-2">Coverage</p>
          <p :data-testid="`coverage-${testId}`" class="txt-theme-brand-2">
            {{ props.coverage }}
          </p>
        </div>
        <button
          id="edit"
          class="btn-auth bg-btn-secondary txt-theme-brand-2 mgr-2 mgy-2"
          @click="editing = true"
          :disabled="locked"
        >
          EDIT LIMIT
        </button>
      </div>
      <div v-else>
        <p
          class="capitalize pdb-3 txt-theme-brand-1 txt-bold title-small"
          :data-testid="`title-${testId}`"
        >
          {{ timeFrame }} {{ props.type }} limit
        </p>
        <div class="space-between flex">
          <p class="txt-theme-txt-2">Limit</p>
          <p class="txt-theme-brand-2">Not Set</p>
        </div>
        <button
          id="edit"
          class="btn-auth bg-btn-secondary txt-theme-txt-1 mgr-2 txt-theme-brand-2"
          @click="editing = true"
          :disabled="locked"
        >
          SET LIMIT
        </button>
      </div>
    </template>
  </div>
</template>
