<script setup>
import { ref } from "vue";
import { axiosGet } from "@/services/axios/crudOperations";
import IconComponent from "@/components/icons/IconComponent.vue";

const mainHeaders = ref(["amount", "date", "expand"]);
const additionalHeaders = ref(["type", "time", "origin", "wallet"]);
const tableData = ref([]);

const init = async () => {
  const res = await axiosGet("/history/payments");
  const tempData = res;

  tempData.forEach((element) => {
    //format date
    const tempDate = element.date.date;
    element.date = tempDate.slice(0, -16);
    element.time = tempDate.slice(10, -7);
    //join currency and amount
    element.amount = element.amount + " " + element.currency;
    element.expand = false;
  });
  tableData.value = tempData;
};

init();

const expander = (index) => {
  tableData.value.forEach((element, i) => {
    element.expand = i == index ? !element.expand : false;
  });
};
</script>

<template>
  <div class="form-size pdb-10">
    <h3 class="txt-center txt-theme-brand-1 mgy-5">Transaction history</h3>

    <table class="mg-center text-smallest">
      <tr class="table-border">
        <th class="pda-3 txt-theme-brand-1" v-for="header in mainHeaders" :key="header">
          {{ header }}
        </th>
      </tr>

      <template v-for="(item, i) in tableData" :key="i">
        <tr class="table-border txt-theme-txt-2" @click="expander(i)">
          <td class="pda-3 txt-center" v-for="header in mainHeaders" :key="header">
            <span v-if="header == 'expand'">
              <IconComponent
                :class="item.expand ? ' rotate-up' : 'ico-size-16'"
                :iconClasses="'icon-18 inline-block bg-theme-brand-1 icon-dropdown'"
              ></IconComponent>
            </span>

            <span v-else> {{ item[header] }}</span>
          </td>
        </tr>

        <tr v-if="item.expand">
          <td :colspan="3" class="border-brand-1 bg-theme-bg-2">
            <p
              class="flex space-between pda-2"
              v-for="header in additionalHeaders"
              :key="header"
            >
              <span class="txt-theme-brand-1 txt-bold">{{ header }}</span>
              <span class="txt-theme-txt-2">{{ item[header] }}</span>
            </p>
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>
