<script setup lang="ts">
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";
import ButtonComponent from "@/brands/bit1111/components/shared/buttons/ButtonComponent.vue";
import { translate } from "@/services/translations/translation";
import { isMobileCheck } from "@/services/interface/clientInformation";
const route = useRoute();
const isMobile = ref(isMobileCheck());
// Amount
// Bank
// Brand
// Card%20Holder%20Name
// Company
// CreditCard%20Number
// Currency
// CurrentStatus
// CurrentStatusTime
// Descriptor
// Expiration%20Date
// PaymentID
// Reference%20Number
// Response
// Result
// Signature
const entireQuery = ref();
const status = ref("");
const amount = ref("");
const currency = ref("");
const loaded = ref(false);
const statusClass = ref("");

const expand = ref(false);
onMounted(() => {
  entireQuery.value = route.query;
  status.value = entireQuery.value["CurrentStatus"];
  amount.value = entireQuery.value["Amount"];
  currency.value = entireQuery.value["Currency"];

  switch (status.value) {
    case "DECLINED":
      statusClass.value = "txt-error-1";
      break;
    case "PENDING":
      statusClass.value = "txt-warning-3";
      break;

    case "ACCEPTED":
      statusClass.value = "txt-success-1";
      break;

    default:
      break;
  }
  loaded.value = true;
});
</script>
<template>
  <h1 class="txt-1-1 txt-center mgy-6">
    {{ translate("transactions.deposit.desktop.generals.depositStatus") }}
  </h1>
  <div
    class="bg-4-2 radius-10 pda-5 txt-300"
    :class="isMobile ? 'mgx-5' : 'w-50 mgx-auto'"
    v-if="loaded"
  >
    <div class="txt-size-24 txt-bold mgb-3">
      <p>
        {{ translate("common.status") }}: <span :class="statusClass">{{ status }}</span>
      </p>
      <p>{{ translate("common.amount") }}: {{ amount }} {{ currency }}</p>
    </div>
  <!--   <ButtonComponent
      class="mgx-auto mgy-5"
      id="expand"
      :text="translate('common.details')"
      @click="expand = !expand"
      :widthPx="180"
      :heightPx="40"
    /> -->
    <div>
      <p class="mgy-1" v-for="(qr, key) in entireQuery" :key="key">
        {{ key }}: <span class="txt-light txt-bold">{{ qr }}</span>
      </p>
    </div>
  </div>
</template>
