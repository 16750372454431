<script setup lang="ts">
import type { NameIdInterface } from "@/brands/bit1111/interface/casinoInterface";
import axios from "axios";
import { ref, watch } from "vue";
import GameLinkComponent from "@/brands/bit1111/components/casino/GameLinkComponent.vue";
import { translate } from "@/services/translations/translation";
import type { PropType } from "vue";
import imgUrl from "@/assets/icons/img-placeholder.svg";
import type { GameInterface } from "@/services/data/casino";

const props = defineProps({
  category: {
    required: false,
    type: Object as PropType<NameIdInterface>,
  },
  supplier: {
    required: false,
    type: Object as PropType<NameIdInterface>,
  },
  searchedName: {
    required: false,
    type: String,
    default: "",
  },
});

const axiosBodyDefault = ref({
  start: 0,
  limit: 1000,
  filters: ref<Array<{ name: string; value: string | number | undefined }>>([
    {
      name: "device",
      value: "1",
    },
    { name: "category", value: 0 },
  ]),
});
const games = ref();
const localLoader = ref(false);
const gamesBig = ref();
const gamesSmall = ref();
const notificationError = ref({ is: false, msg: "" });
const axiosBody = ref();
const selectedGame = ref({
  index: null,
  isBig: true,
} as any);
const init = async () => {
  localLoader.value = true;
  axiosBody.value = JSON.parse(JSON.stringify(axiosBodyDefault.value));
  if (props.searchedName != "") {
    axiosBody.value.filters.push({
      name: "name",
      value: props.searchedName,
    });
  }
  axiosBody.value.filters[1].value = props.category?.id;
  if (props.supplier) {
    const SupplierFilter = {
      name: "provider",
      value: props.supplier.id,
    };
    if (props.supplier.name != "All")
      axiosBody.value.filters.push(SupplierFilter);
  }
  await axios
    .post("/games/list", axiosBody.value)
    .then(function (response) {
      games.value = response.data.data.results;
      gamesBig.value = games.value.slice(0, games.value.length / 2);
      gamesSmall.value = games.value.slice(games.value.length / 2);
    })
    .catch((error) => {
      notificationError.value.is = true;
    });
  localLoader.value = false;
};
init();

watch(
  props,
  (value) => {
    init();
  },
  { deep: true }
);
const fallbackLogo = imgUrl;
const gameLogo = (game: GameInterface) => {
  let imgSrc = game.images.mobile ? game.images.mobile : game.images.desktop;
  if (imgSrc == null) {
    imgSrc = fallbackLogo;
  } else {
    imgSrc.includes("http") ? null : (imgSrc = "https://" + imgSrc);
  }
  return imgSrc;
};
</script>
<template>
  <div class="row w-100">
    <div class="col-6 pdb-4 txt-center w-100">
      <h2 class="txt-light mgl-5 txt-bold mgb-3 txt-size-28">
        <span>{{ props.category?.name }}</span>
        <span class="txt-1-1 mgl-3">{{ translate("common.games") }}</span>
      </h2>
    </div>
  </div>
  <p v-if="notificationError.is">{{ notificationError.msg }}</p>

  <div class="mgy-7" v-if="localLoader">
    <div class="lds-spinner mgx-auto">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <template v-if="!localLoader">
    <div class="scroll-overflow-x flex no-wrap scrollbar-hidden mgb-5">
      <div v-for="(item, i) in gamesBig" :key="i" class="mgx-3">
        <RouterLink
          :to="{ path: 'game', query: { gamename: item.uri, mode: 'real' } }"
        >
          <img
            style="background-repeat: no-repeat"
            class="w-200px h-167px radius-10"
            :src="gameLogo(item)"
          />
        </RouterLink>
      </div>
    </div>
    <div class="scroll-overflow-x flex no-wrap scrollbar-hidden mgy-5">
      <div v-for="(item, i) in gamesSmall" :key="i" class="mgx-3">
        <RouterLink
          :to="{ path: 'game', query: { gamename: item.uri, mode: 'real' } }"
        >
          <img
            style="background-repeat: no-repeat"
            class="w-167px h-140px radius-10"
            :src="gameLogo(item)"
          />
        </RouterLink>
      </div>
    </div>
  </template>
</template>
<style lang="css" scoped></style>
