import { useAuthStore } from "@/stores/auth";
import type { MercureEventListener } from "./EventListenerInterface";
import { useLayoutModalsStore } from "@/stores/layoutModals";


class CasinoAlertEvent implements MercureEventListener {
  listener(event: MessageEvent) {
    const authStore = useAuthStore();
    if (authStore.$state.player?.onboarding) {
      console.log("Alert", authStore.$state.player)
      const layoutModalsStore = useLayoutModalsStore();
      const data = JSON.parse(event.data);
      layoutModalsStore.activateModal({
        name: "playerAlertMessage",
        data: data,
      });
    }
  }

  topic() {
    const authStore = useAuthStore();
    const player = authStore.$state.player;
    if (player == null) {
      throw new Error("Player not logged In");
    }
    const topic = "alert/player/" + player?.id;

    return topic;
  }

  on() {
    return "message";
  }
}
export default CasinoAlertEvent;
