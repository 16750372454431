<script setup lang="ts">
import MainNavDesktopComponent from "./MainNavDesktopComponent.vue";
import LogoComponent from "@/components/icons/LogoComponent.vue";
import { ref, computed, onMounted } from "vue";
import { useAuthStore } from "@/stores/auth";
import { goToRoute } from "@/router/routingService";
import { useRoute } from "vue-router";
import FlagWrapper from "@/components/icons/FlagWrapperComponent.vue";
import { useLoaderStore } from "@/stores/loader";
import { useLayoutModalsStore } from "@/stores/layoutModals";
import { useBalanceStore } from "@/stores/balance";
import { translate } from "@/services/translations/translation";
import IconComponent from "@/components/icons/IconComponent.vue";

const props = defineProps({
  navigationFlag: Boolean,
});
const balanceStore = useBalanceStore();
const loader = useLoaderStore();
const authStore = useAuthStore();
const layoutModalsStore = useLayoutModalsStore();
const casinoBalance = computed(() => balanceStore.getWallet("casino"));
const sportbookBalance = computed(() => balanceStore.getBalance("sportbook"));
const finishedLoading = ref(false);
const emit = defineEmits(["toggleNav", "finishedLoading"]);
const route = useRoute();
const languagesShow = ref(false);
const playerRole = computed(() =>
  authStore.$state.player ? authStore.$state.player.role : ""
);

const isFocused = computed(() => {
  return route ? route.meta?.isFocusedHeader : false;
});

const langIconSrc = ref();
const personalMenu = ref(false);

const toggleCallback = () => {
  emit("toggleNav");
};

const finishedLoadingEmit = () => {
  emit("finishedLoading");
};

onMounted(async () => {
  finishedLoading.value = true;
  setTimeout(finishedLoadingEmit, 5);
});
const displayMenu = ref(true);
</script>

<template>
  <header
    class="mobile-header w-100 bg-theme-bg-1 zindex-header relative c-header h-95px"
    v-if="finishedLoading"
  >
    <template v-if="playerRole && playerRole === 'ROLE_PLAYER_INACTIVE'">
      <LogoComponent></LogoComponent>

      <div class="lang-account">
        <button
          id="flag"
          @click="languagesShow = !languagesShow"
          class="lang-head-btn mgl-auto mgr-3"
        >
          <FlagWrapper :selectedFlag="langIconSrc" />
        </button>
      </div>

      <div>
        <div class="lang-account">
          <button
            id="user"
            class="lang-head-btn mgl-auto mgr-5"
            v-if="authStore.$state.player !== null"
            @click="personalMenu = !personalMenu"
          >
            <img src="@/assets/icons/img-user-menu.svg" />
          </button>
        </div>
        <PersonalMenu
          v-if="personalMenu"
          @closeMenu="personalMenu = $event"
        ></PersonalMenu>
      </div>
    </template>
    <template v-else>
      <MainNavDesktopComponent
        @toggleMenu="toggleCallback"
        :navigationFlag="navigationFlag"
      />
      <LogoComponent></LogoComponent>
      <template v-if="!authStore.$state.player">
        <div class="lang-account flex flex-right-center mgr-3">
          <div class="auth-login mgl-2" v-if="!authStore.$state.player">
            <button
              id="login"
              class="btn-auth txt-theme-txt-2 pdx-5"
              @click="goToRoute('/login')"
            >
              {{ translate("common.login") }}
            </button>
          </div>

          <div class="auth-register mgr-6" v-if="!authStore.$state.player">
            <button
              id="register"
              class="btn-auth bg-theme-brand-2 txt-theme-bg-1 pdx-5"
              @click="goToRoute('/register')"
            >
              {{ translate("common.register") }}
            </button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="lang-account">
          <div class="flex flex-right mgr-6">
            <div class="w-146px">
              <button
                @click="goToRoute('deposit')"
                class="btn btn-transaction btn-brand txt-theme-txt-9 txt-bold no-wrap"
              >
                +
                {{ translate("navigation.header.desktop.deposit").toUpperCase() }}
              </button>
            </div>
            <div
              @click="personalMenu = !personalMenu"
              class="h-38px radius-6 bg-theme-bg-5 pdx-3 mgl-5 flex flex-center cursor-pointer"
            >
              <button
                id="user"
                class="lang-head-btn mgr-3 no-border flex flex-center"
                v-if="authStore.$state.player !== null"
              >
                <IconComponent
                  :iconClasses="'icon-30 inline-block icon-solid-profile bg-theme-txt-1'"
                ></IconComponent>
              </button>
              <span class="flex flex-center-left flex-column">
                <span class="txt-theme-txt-2 text-small no-wrap"
                  >{{ translate("navigation.header.desktop.welcome") }}
                  <span class="txt-theme-txt-1 no-wrap"
                    >{{ authStore.$state.player?.firstName }}
                    {{ authStore.$state.player?.lastName }}
                  </span></span
                >
                <!-- WAITING ON DESIGN DESISION -->
                <!--  <span class="txt-theme-txt-2 text-small no-wrap"
                  >{{ translate("navigation.header.desktop.balance") }}
                  <span
                    v-if="route.path == '/sportsbook'"
                    class="txt-theme-txt-1"
                    >${{
                      sportbookBalance?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}</span
                  >
                  <span v-else class="txt-theme-txt-1"
                    >${{
                      casinoBalance.total?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}</span
                  ></span> -->
              </span>
              <span class="scale-0-7 flex flex-center"
                ><IconComponent
                  :iconClasses="
                    personalMenu
                      ? 'rotate-left90 icon-30 inline-block icon-angle bg-theme-txt-2'
                      : 'rotate-right90 icon-30 inline-block icon-angle bg-theme-txt-2'
                  "
                ></IconComponent
              ></span>
            </div>
          </div>
        </div>
        <PersonalMenu
          v-if="personalMenu"
          @closeMenu="personalMenu = $event"
        ></PersonalMenu>
      </template>
    </template>
  </header>
</template>
