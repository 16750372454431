const notificationMapper = {
  bonus: {
    url: "/promotions",
  },
  transaction: {
    url: "/transaction-history",
  },
};

export class NotificationHandler {
  private notificationObject;
  constructor(tag: String) {
    if (tag) {
      this.notificationObject = notificationMapper[tag as keyof typeof notificationMapper];
    }
  }

  public getNotificationObject() {
    return this.notificationObject;
  }
}
