export function loadService(comp: string) {
  return new Promise((resolve, reject) => {
    try {
      let module: Promise<any>;
      switch (comp) {
        case "RoutingService":
            module = import(`@/router/routingService`);
          break;
          case "HTTPInterceptorService":
            module = import(`./../../services/auth/httpInterceptor`);
          break;

        default:
            module = import(
            `./../../components/shared/sectionGroup/GroupSectionComponent.vue`
          );
          break;
      }
      // module = import(`./../../services/auth/httpInterceptor`);
      resolve(module);
    } catch (error) {
      reject(error);
    }
  });
}