<script setup lang="ts">
import MainNavComponent from "@/brands/holahola/components/navigation/header/MainNavComponent.vue";
import LogoComponent from "@/brands/holahola/components/navigation/header/LogoComponent.vue";
import LanguageComponent from "@/brands/holahola/components/shared/language/LanguageComponent.vue";
// import AuctionsComponent from "./AuctionsComponent.vue";
import { ref, computed, onMounted } from "vue";
import { useAuthStore } from "@/stores/auth";
import { goToRoute } from "@/router/routingService";
import { useRoute } from "vue-router";
import BackButton from "@/components/shared/backButton/BackButtonComponent.vue";
import FlagWrapper from "@/components/icons/FlagWrapperComponent.vue";
import { useLoaderStore } from "@/stores/loader";
import { translate } from "@/services/translations/translation";
import IconComponent from "@/components/icons/IconComponent.vue";
// import ButtonComponent from "@/brands/holahola/components/shared/buttons/ButtonComponent.vue";

const loader = useLoaderStore();

const props = defineProps({
  navigationFlag: Boolean,
});

const under1250 = ref(false);

const resizeEvent = () => {
  if (window.innerWidth < 1250) {
    under1250.value = true;
  } else {
    under1250.value = false;
  }
};

const emit = defineEmits(["toggleNav", "finishedLoading"]);
const route = useRoute();
const languagesShow = ref(true);
const authStore = useAuthStore();
const playerRole = computed(() =>
  authStore.$state.player ? authStore.$state.player.role : ""
);

const isFocused = computed(() => {
  return route ? route.meta.isFocusedHeader : false;
});
const langIconSrc = ref();
const personalMenu = ref(false);

const toggleCallback = () => {
  emit("toggleNav");
};

const finishedLoadingEmit = () => {
  emit("finishedLoading");
};

onMounted(() => {
  window.addEventListener("resize", resizeEvent);
  resizeEvent();
  setTimeout(finishedLoadingEmit, 5);
});
</script>

<template>
  <header
    class="w-100 bg-linear-1-1-1-5 zindex-header flex flex-center sticky-top"
    style="box-shadow: 0px 2px 10px #00094c"
  >
    <div class="flex center-vertical h-81px w-100">
      <MainNavComponent @toggleMenu="toggleCallback" :navigationFlag="navigationFlag" />
      <div
        class="fit-content cursor-pointer zindex-headerlogo flex-center"
        style="
          position: absolute;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
        "
      >
        <LogoComponent></LogoComponent>
      </div>

      <div class="flex flex-right-center pdr-5 relative">
        <div class="pdx-2" v-if="!authStore.$state.player">
          <button
            @click="goToRoute('register')"
            class="bg-2-1 txt-1-3 radius-10 txt-size-16 txt-weight-600 pdx-2 font-Poppins hover-border"
            style="min-width: 110px; height: 50px"
          >
            {{ translate("common.register")?.toUpperCase() }}
          </button>
        </div>
        <div class="pdx-2 mgx-3" v-if="!authStore.$state.player">
          <button
            @click="goToRoute('/login')"
            class="bg-1-3 txt-light radius-10 txt-size-16 txt-weight-600 pdx-2 box-shadow-down-1-2 font-Poppins hover-border"
            style="width: 110px; height: 50px"
          >
            {{ translate("common.login")?.toUpperCase() }}
          </button>
        </div>
        <div class="lang-account" v-if="!authStore.$state.player">
          <LanguageComponent
            :backdrop="true"
            :personalMenuWrapper="true"
            :toggleDropdown="true"
            v-if="languagesShow"
            bgTheme="bg-linear-1-1-1-5 "
            :isMobile="false"
            topMargin="85px"
            bgHover="hover-bg-1-5"
          ></LanguageComponent>
        </div>

        <div class="flex" v-else>
          <div class="flex mgr-6">
            <div class="txt-right no-wrap">
              <p class="txt-size-12 font-Poppins">{{ translate('navigation.header.yourMoney') }}</p>
              <p class="txt-size-16 font-Poppins txt-weight-600 txt-2-1">$0.00</p>
            </div>
            <button
              class="w-100px h-50px bg-2-1 radius-10 font-Poppins txt-weight-600 txt-size-16 txt-1-3 mgx-3 hover-border"
              @click="goToRoute('deposit')"
            >
              {{ translate("common.deposit") }}
            </button>
            <div class="txt-left no-wrap">
              <p class="txt-size-12 font-Poppins">{{ translate('navigation.header.yourBonus') }}</p>
              <p class="txt-size-16 font-Poppins txt-weight-600 txt-2-1">$0.00</p>
            </div>
          </div>

          <div class="mgr-3">
            <LanguageComponent
              :backdrop="true"
              :personalMenuWrapper="true"
              :toggleDropdown="true"
              v-if="languagesShow"
              bgTheme="bg-linear-1-1-1-5 "
              :isMobile="false"
              topMargin="85px"
              bgHover="hover-bg-1-5"
            ></LanguageComponent>
          </div>

          <button
            id="user"
            class="w-50px h-45px bg-1-3 radius-10 box-shadow-down-1-2"
            v-if="!under1250"
            @click="goToRoute('profile')"
          >
            <img src="https://i.ibb.co/ccq2nLc/Icon-awesome-user.png" />
          </button>

          <IconComponent
            @click="authStore.logout"
            :iconClasses="'icon-50 inline-block icon-logout bg-light cursor-pointer mgx-3'"
          ></IconComponent>
        </div>
      </div>
    </div>
  </header>
</template>
