import { years, months } from "@/services/datepicker/initData";

export class DatePicker {
  private years: Array<number>;
  private months: Array<Month>;
  private days: Array<number> = [];

  private selectedDay: number | null = null;
  private selectedMonth: Month | null = null;
  private selectedYear: number | null = null;

  constructor() {
    this.years = years;
    this.months = months;
    this.generateDays();
  }
  private isLeap() {
    return this.selectedYear ? this.selectedYear % 4 == 0 : false;
  }

  private generateDays() {
    this.days = [];
    if (this.selectedMonth != null) {
      const dayCount = this.isLeap()
        ? this.selectedMonth.leap
        : this.selectedMonth.days;
      for (let index = 1; index <= dayCount; index++) {
        this.days.push(index);
      }
    } else {
      for (let index = 1; index <= 31; index++) {
        this.days.push(index);
      }
    }

    if (this.selectedDay != null && this.selectedDay > this.days.length)
      this.selectedDay = this.days.length;
  }

  public getSelectedDay() {
    return this.selectedDay;
  }
  public getSelectedMonth() {
    return this.selectedMonth;
  }
  public getSelectedYear() {
    return this.selectedYear;
  }

  public setDay(day: number) {
    this.selectedDay = day;
  }
  public setMonth(month: Month) {
    this.selectedMonth = month;
    this.generateDays();
  }
  public setYear(year: number) {
    this.selectedYear = year;
    if (this.selectedMonth) this.generateDays();
  }

  public getMonths() {
    return this.months;
  }
  public getYears() {
    return this.years;
  }
  public getDays() {
    return this.days;
  }
}

export interface Month {
  long: string;
  short: string;
  numeric: number;
  days: number;
  leap: number;
}
