<script setup lang="ts">
import MainNavComponent from "@/brands/bit1111/components/navigation/header/MainNavComponent.vue";
import LogoComponent from "@/brands/holahola/components/navigation/header/LogoComponent.vue";
import LanguageComponent from "@/brands/holahola/components/shared/language/LanguageComponent.vue";
import PersonalMenu from "@/brands/bit1111/components/navigation/header/PersonalMenuComponent.vue";
// import AuctionsComponent from "./AuctionsComponent.vue";
import { ref, computed, onMounted } from "vue";
import { useAuthStore } from "@/stores/auth";
import { goToRoute } from "@/router/routingService";
import { useRoute } from "vue-router";
import BackButton from "@/components/shared/backButton/BackButtonComponent.vue";
import FlagWrapper from "@/components/icons/FlagWrapperComponent.vue";
import { useLoaderStore } from "@/stores/loader";
import { translate } from "@/services/translations/translation";
import ButtonComponent from "@/brands/bit1111/components/shared/buttons/ButtonComponent.vue";
import IconComponent from "@/components/icons/IconComponent.vue";

const loader = useLoaderStore();

const emit = defineEmits(["toggleNav", "finishedLoading"]);
const route = useRoute();
const languagesShow = ref(true);
const authStore = useAuthStore();
const playerRole = computed(() =>
  authStore.$state.player ? authStore.$state.player.role : ""
);

const isFocused = computed(() => {
  return route ? route.meta.isFocusedHeader : false;
});
const mobileMenu = ref(true);
const langIconSrc = ref();
const personalMenu = ref(false);

const menuDisplay = () => {
  if (window.innerWidth < 768) {
    mobileMenu.value = true;
  } else {
    mobileMenu.value = false;
  }
};

const finishedLoadingEmit = () => {
  emit("finishedLoading");
};

onMounted(() => {
  window.addEventListener("resize", menuDisplay);
  menuDisplay();
  setTimeout(finishedLoadingEmit, 5);
});
</script>

<template>
  <header
    class="hola-mobile-header w-100 bg-linear-1-1-1-5 h-52px zindex-header c-header sticky-top pdr-5"
    v-if="mobileMenu && !isFocused"
  >
    <template v-if="playerRole && playerRole === 'ROLE_PLAYER_INACTIVE'">
      <LogoComponent></LogoComponent>
      <button
        class="pdx-4 h-32px bg-2-1 radius-5 user-wrapper"
        @click="authStore.logout()"
      >
        LOGOUT
      </button>
    </template>
    <template v-else>
      <div v-if="authStore.$state.player" class="flex user-wrapper">
        <div class="flex flex-justify-center flex-align-end flex-column">
          <div class="txt-right no-wrap flex">
            <p class="txt-size-13 font-Poppins pdr-2">
              {{ translate("navigation.header.yourMoney") }}
            </p>
            <p class="txt-size-13 font-Poppins txt-weight-600 txt-2-1">$0.00</p>
          </div>
          <div class="txt-left no-wrap flex">
            <p class="txt-size-13 font-Poppins pdr-2">
              {{ translate("navigation.header.yourBonus") }}
            </p>
            <p class="txt-size-13 font-Poppins txt-weight-600 txt-2-1">$0.00</p>
          </div>
        </div>
        <!-- <button
          @click="goToRoute('/deposit')"
          class="w-56px h-32px bg-2-1 radius-10 font-Poppins txt-weight-600 txt-size-10 txt-1-3 flex flex-center mgx-3"
        >
          {{ translate("common.deposit").toUpperCase() }}
        </button> -->
      </div>
      <LogoComponent></LogoComponent>
      <div class="user-wrapper flex">
        <div class="pdx-2 mgx-3" v-if="!authStore.$state.player">
          <button
            @click="goToRoute('/login')"
            class="bg-1-3 border-2-1 border-width-1 txt-light radius-10 txt-size-14 txt-weight-600 pdx-2 font-Poppins"
            style="width: 76px; height: 32px"
          >
            {{ translate("common.login")?.toUpperCase() }}
          </button>
        </div>
      <div class="" v-if="!authStore.$state.player">
          <button
            @click="goToRoute('register')"
            class="bg-2-1 txt-1-3 radius-10 txt-size-14 txt-weight-600 pdx-2 font-Poppins"
            style="min-width: 76px; height: 32px"
          >
            {{ translate("navigation.header.desktop.signUp")?.toUpperCase() }}
          </button>
        </div>
        </div>
    </template>
  </header>

  <header
    class="w-100 bg-4-1 zindex-header relative c-header txt-light sticky-top"
    v-if="isFocused && mobileMenu"
  >
    <BackButton class="w-100" />
  </header>

  <header
    class="w-100 bg-dark zindex-header flex flex-center sticky-top"
    v-if="!mobileMenu"
  >
    <div class="flex flex-center h-95px w-100 maxw-1184px">
      <LogoComponent></LogoComponent>
      <div class="w-100 maxw-360px flex flex-right-center pdr-5 relative">
        <div class="pdx-2" v-if="!authStore.$state.player">
          <ButtonComponent
            id="register"
            :text="translate('common.register').toUpperCase()"
            :widthPx="100"
            :heightPx="40"
            @click="goToRoute('register')"
          />
        </div>
        <div class="pdx-2" v-if="!authStore.$state.player">
          <ButtonComponent
            type="primaryText"
            id="login"
            :text="translate('common.login').toUpperCase()"
            :widthPx="100"
            :heightPx="40"
            @click="goToRoute('/login')"
          />
        </div>
        <div class="lang-account" v-if="!authStore.$state.player">
          <LanguageComponent
            :backdrop="true"
            :personalMenuWrapper="true"
            :toggleDropdown="true"
            v-if="languagesShow"
            bgTheme="bg-4-6"
            :isMobile="mobileMenu"
            topMargin="95px"
            bgHover="bg-3-2"
          ></LanguageComponent>
        </div>

        <div v-else>
          <button
            id="user"
            class="lang-head-btn mgr-3"
            @click="personalMenu = !personalMenu"
          >
            <img src="@/assets/icons/img-user-menu.svg" />
          </button>
          <PersonalMenu
            v-if="personalMenu"
            @closeMenu="personalMenu = false"
          ></PersonalMenu>
        </div>
      </div>
    </div>
  </header>
</template>
