<script setup lang="ts">
import IconComponent from "@/components/icons/IconComponent.vue";
import { ref } from "vue";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import { useLoaderStore } from "@/stores/loader";
import axios from "axios";

const route = useRoute();
const userEmail = ref(route.query.email);
const authStore = useAuthStore();
const loader = useLoaderStore();

const resendConfirmationEmail = async () => {
  loader.toggleLoader(true);
  axios
    .get(
      `/api/auth/registration/resend-email?username=${
        authStore.$state.player
          ? authStore.$state.player.username
            ? authStore.$state.player.username
            : ""
          : ""
      }`
    )
    .then((response) => {
      //
    })
    .catch((error) => {
      console.log("error at resendConfirmationEmail()", error);
    });
  loader.toggleLoader(false);
};
</script>

<template>
  <div class="email-confirm-wrapper relative">
    <div>
      <div class="email-confirmation txt-center pdx-4">
        <IconComponent
          :iconClasses="'svg-icon block icon-90 mgb-5 mgx-auto icon-email-confirm bg-theme-brand-1'"
        ></IconComponent>
        <p class="title-large txt-theme-brand-1">Confirm your account</p>
        <p class="txt-theme-txt-1">An email has been sent to</p>
        <p class="txt-bold txt-theme-brand-1">{{ userEmail }}</p>
        <p class="txt-theme-txt-1">
          Please visit your inbox to
          <span class="txt-bold">confirm you iGaming account</span> and get started
        </p>
        <p class="txt-theme-txt-1">
          If you don't see the email, please
          <span class="txt-bold">check your spam folder.</span>
        </p>
        <p class="txt-theme-txt-1">
          Didn't receive the email?
          <a
            type="button"
            class="txt-theme-brand-1 inline txt-inherit"
            @click="resendConfirmationEmail()"
          >
            Click here to resend
          </a>
        </p>
        <p class="txt-theme-txt-1">
          If you still haven't received the email, please contact our
          <a class="txt-theme-brand-1" routerLink="/help">support</a> for help or tap the
          yellow support icon in the bottom right corner to start a chat with our support
          agent.
        </p>
      </div>
    </div>
  </div>
</template>
