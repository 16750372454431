<script setup lang="ts">
import IconComponent from "@/components/icons/IconComponent.vue";
import { bottomLinks, helpLinks } from "@/services/data/navList";
import { ref } from "vue";
import { goToRoute } from "@/router/routingService";
import { useAuthStore } from "@/stores/auth";
import { isMobileCheck } from "@/services/interface/clientInformation";
import { translate } from "@/services/translations/translation";

const authStore = useAuthStore();
const isMobile = ref(isMobileCheck());

const calcMobile = () => {
  isMobile.value = isMobileCheck();
};

window.addEventListener("resize", calcMobile);
</script>

<template>
  <div
    class="bottom-nav-wrapper-hola w-100 flex zindex-header"
    v-if="!authStore?.$state?.player && isMobile"
  >
    <button
      class="w-50 bg-theme-brand-3 txt-bold to-upper-case title-medium-small txt-theme-txt-2"
      @click="goToRoute('/login')"
    >
      {{ translate("common.login") }}
    </button>
    <button
      class="w-50 bg-theme-brand-2 txt-bold to-upper-case title-medium-small"
      @click="goToRoute('/register')"
    >
      {{ translate("common.register") }}
    </button>
  </div>
</template>
