<script setup>
import { useContentPagesStore } from "@/stores/contentPages";
import ContentPage from "@/brands/bit1111/components/content/ContentPage.vue";
import { ref } from "vue";
import { translate } from "@/services/translations/translation";

const contentStore = useContentPagesStore();
const content = ref();
const isLoaded = ref(false);

const init = async () => {
  content.value = await contentStore.getContent("bonus-info");
  isLoaded.value = true;
};

init();
</script>
<template>
  <div v-if="isLoaded" class="w-100 background-img-profile pdb-10">
    <h3
      class="txt-size-24 txt-weight-600 txt-spacing-20px txt-center mgt-6 to-upper-case txt-1-1"
    >
      {{ translate("navigation.bonusInfo") }}
    </h3>
    <!-- <ContentPage :content="content" /> -->
    <div class="mgx-10">
      <div v-html="content[0]?.text"></div>
    </div>
  </div>
</template>
