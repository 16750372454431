<script setup lang="ts">
import { goToRoute } from "@/router/routingService";
import { useLoaderStore } from "@/stores/loader";
import { ref } from "vue";
import { useRoute } from "vue-router";
import InputPasswordComponent from "@/components/shared/forms/input/InputPasswordComponent.vue";
import axios from "axios";
import { ErrorHandler } from "@/lib/exceptions/ErrorHandler";
import { sleep } from "@/services/helper";

const route = useRoute();
const token = ref(route.query.token);
const loader = useLoaderStore();
const message = ref("");
const newPassword = ref();
const reset = async () => {
  loader.toggleLoader(true);
  const body = {
    token: token.value,
    password: newPassword.value,
  };
  await axios
    .post("api/auth/forgot-password/reset", body)
    .then(async () => {
      message.value = "Reset successfully";
      await sleep(3000);
      loader.toggleLoader(false);
      goToRoute("/login");
    })
    .catch((error) => {
      const err = new ErrorHandler();
      const errObj = err.handle(error);
      error.value = true;
      message.value = errObj.message;
      loader.toggleLoader(false);
    });
};
</script>

<template>
  <div class="forgot-password-wrapper pda-4 form-size">
    <h1 class="txt-center title-large mgt-5">Enter new password</h1>
    <div v-if="token">
      <InputPasswordComponent
        passId="password"
        :placeholder="'Enter new password'"
        v-model="newPassword"
      ></InputPasswordComponent>
      <div class="txt-error" v-if="message != ''">{{ message }}</div>
      <button @click="reset" class="block btn-brand-light btn-large mgb-6 mgt-4 mgx-auto">
        RESET
      </button>
    </div>
  </div>
</template>
