<script setup lang="ts">
import AtomicLimitComponent from "../../../components/limits/AtomicLimitComponent.vue";
import { ref, onMounted } from "vue";
import { isMobileCheck } from "../../../services/interface/clientInformation";
import NotificationComponent from "../../../components/shared/notification/NotificationComponent.vue";
import { axiosGet, axiosPost } from "../../../services/axios/crudOperations";

const wageringData = ref({
  daily: 0,
  dailyStartsAt: "",
  dailyExpiresAt: "",
  weekly: 0,
  weeklyStartsAt: "",
  weeklyExpiresAt: "",
  monthly: 0,
  monthlyStartsAt: "",
  monthlyExpiresAt: "",
  dailyCoverage: "",
  weeklyCoverage: "",
  monthlyCoverage: "",
  dailyLocked: false,
  weeklyLocked: false,
  monthlyLocked: false,
  walletTypes: [1, 2],
});

const notificationData = ref({
  isShown: false,
  message: "",
  title: "",
  icon: "",
  bg: "",
});

const isMobile = isMobileCheck();

const init = async () => {
  const response = await axiosGet("/player-limits/wagering");
  if (response) {
    wageringData.value = response;
  }
};

init();

const test = async (event: any) => {
  switch (event.type) {
    case "daily":
      wageringData.value.daily = parseInt(event.limit);
      wageringData.value.dailyCoverage = event.coverage;
      break;
    case "weekly":
      wageringData.value.weekly = parseInt(event.limit);
      wageringData.value.weeklyCoverage = event.coverage;
      break;
    case "monthly":
      wageringData.value.monthly = parseInt(event.limit);
      wageringData.value.monthlyCoverage = event.coverage;
      break;
    default:
      console.log("Limit type failure.");
  }
  wageringData.value.walletTypes = [1, 2];
  const response = await axiosPost("/player-limits/wagering", wageringData.value); //IF RESPONSE SUCCESS
  if (response) {
    wageringData.value = await axiosGet("/player-limits/wagering");
    notificationData.value = {
      isShown: true,
      message: `Your ${event.type} wagering limit has been updated successfully.`,
      title: "Limits updated!",
      bg: "bg-success ",
      icon: "icon-success-small",
    };
  } else {
    notificationData.value = {
      isShown: true,
      message: `There has been an error while trying to update limits.`,
      title: "Something went wrong!",
      bg: "bg-error ",
      icon: "icon-warning",
    };
  }
};
</script>
<template>
  <div class="form-size" :class="isMobile ? ' mgy-10 ' : ''">
    <div class="mgx-5">
      <h2 class="mgb-5" v-if="!isMobile">Wagering limit</h2>

      <p class="text-smallest text-height-small text-spacing-xl txt-theme-txt-2 mgb-5">
        Wagering limits allow you to limit the amount of money you can bet over a given
        period. Once you set a limit, it cannot be revoked or increased before 24 hours
        have passed since the initial setting.
      </p>
      <NotificationComponent
        v-if="notificationData.isShown"
        :title="notificationData.title"
        :messageBody="notificationData.message"
        :bgClass="`${notificationData.bg} mgy-5`"
        :iconClass="`${notificationData.icon} bg-theme-txt-1 icon-40  mga-5`"
        :textColor="'txt-theme-txt-1'"
        @emitDismiss="notificationData.isShown = false"
      ></NotificationComponent>
      <div :class="!isMobile ? 'limit-grid mgb-20' : ''">
        <AtomicLimitComponent
          timeFrame="daily"
          type="wagering"
          :isSet="wageringData.daily > 0 ? true : false"
          :limit="wageringData.daily"
          :coverage="wageringData.dailyCoverage"
          :periodTo="wageringData.dailyExpiresAt"
          :periodFrom="wageringData.dailyStartsAt"
          :locked="wageringData.dailyLocked"
          :hasCoverage="true"
          @emitSave="test"
        />
        <AtomicLimitComponent
          timeFrame="weekly"
          type="wagering"
          :isSet="wageringData.weekly > 0 ? true : false"
          :limit="wageringData.weekly"
          :coverage="wageringData.weeklyCoverage"
          :periodTo="wageringData.weeklyExpiresAt"
          :periodFrom="wageringData.weeklyStartsAt"
          :locked="wageringData.weeklyLocked"
          :hasCoverage="true"
          @emitSave="test"
        />
        <AtomicLimitComponent
          timeFrame="monthly"
          type="wagering"
          :isSet="wageringData.monthly > 0 ? true : false"
          :limit="wageringData.monthly"
          :coverage="wageringData.monthlyCoverage"
          :periodTo="wageringData.monthlyExpiresAt"
          :periodFrom="wageringData.monthlyStartsAt"
          :locked="wageringData.monthlyLocked"
          :hasCoverage="true"
          @emitSave="test"
        />
      </div>
    </div>
  </div>
</template>
