import type { Deposit } from "@/services/payment/depositAbstract/deposit";
import type { HandleDepositData } from "@/services/payment/depositAbstract/deposit";
import {
  PIQVisa,
  PIQSkrill,
  PIQNeteller,
  NotSuported,
  MandatoCC,
  INTERNALDirect,
  MANDATODirect,
  BitPaceFixedDeposit
} from "@/services/payment/depositImplementations/deposit";

export function generateDepositObject(handleData: HandleDepositData): Deposit {
  let deposit: Deposit;
  switch (handleData.supplier + "," + handleData.provider) {
    case "PIQ,creditcard":
      deposit = new PIQVisa(handleData);
      break;
    case "PIQ,skrill":
      deposit = new PIQSkrill(handleData);
      break;

    case "PIQ,neteller":
      deposit = new PIQNeteller(handleData);
      break;
    case "MANDATO,cc":
      deposit = new MandatoCC(handleData);
      break;
      case "MANDATO,directbanking":
      deposit = new MANDATODirect(handleData);
      break;
      case "INTERNAL,direct":
      deposit = new INTERNALDirect(handleData);
      break;
      case "BITPACE,bitpacefixeddeposit":
      deposit = new BitPaceFixedDeposit(handleData);
      break;
      
    default:
      deposit = new NotSuported(handleData);
      break;
  }
  return deposit;
}
