<script setup lang="ts">
import FooterCarouselComponent from "./FooterCarouselComponent.vue";
import { translate } from "@/services/translations/translation";
import partnerIco1 from "@/assets/icons/partners/190893056-neteller_payment_method.png";
import partnerIco2 from "@/assets/icons/partners/visa.png";
import partnerIco3 from "@/assets/icons/partners/mastercard.png";
import partnerIco4 from "@/assets/icons/partners/190893065-safetypay_payment_method.png";
import partnerIco5 from "@/assets/icons/partners/190893068-siirto_payment_method.png";
import partnerIco6 from "@/assets/icons/partners/190893070-skrill_payment_method.png";
import partnerIco7 from "@/assets/icons/partners/190893072-sofort_payment_method.png";
import partnerIco8 from "@/assets/icons/partners/190893074-trustly_payment_method.png";
import partnerIco9 from "@/assets/icons/partners/190893076-zimpler_payment_method.png";
import partnerIco10 from "@/assets/icons/partners/190893077-astropay_payment_method.png";
import partnerIco11 from "@/assets/icons/partners/190893081-ecopayz_payment_method.png";
import partnerIco12 from "@/assets/icons/partners/190893084-euteller_payment_method.png";
import partnerIco13 from "@/assets/icons/partners/190893086-funanga_payment_method.png";
import partnerIco14 from "@/assets/icons/partners/190893088-idebit_payment_method.png";
import partnerIco15 from "@/assets/icons/partners/190893089-instadebit_payment_method.png";
import partnerIco16 from "@/assets/icons/partners/cash-to-code.png.b16c7caa0aab0ffb2555fd5d7cf4e767.png";
import partnerIco17 from "@/assets/icons/partners/clipart1653993.png";
import partnerIco18 from "@/assets/icons/partners/Coinify.png";
import partnerIco19 from "@/assets/icons/partners/eco-01.png";
import partnerIco20 from "@/assets/icons/partners/ecoPayz logo withe.png";
import partnerIco21 from "@/assets/icons/partners/muchbetter-01.png";
import partnerIco22 from "@/assets/icons/partners/neosurf-01.png";
import partnerIco23 from "@/assets/icons/partners/Neteller_logo_logotype.png";
import partnerIco24 from "@/assets/icons/partners/paysafecard-01.png";
import partnerIco25 from "@/assets/icons/partners/Payvision_logo_PNG2 (1).png";
import partnerIco26 from "@/assets/icons/partners/TWUKLogo.png";
import partnerIco27 from "@/assets/icons/partners/upi-01.png";
import partnerIco28 from "@/assets/icons/partners/venuspoint.png";
import partnerIco29 from "@/assets/icons/partners/wonderlandlogo.png";
import partnerIco30 from "@/assets/icons/partners/Logo_Primary_Gray.svg";

import paymentIcon1 from "@/assets/icons/partners/wonderlandlogo.png";
import paymentIcon2 from "@/assets/icons/payment/Avatar_logo_black.png.694244d07ba77eb6e945fd5f0c5b2750.png";
import paymentIcon3 from "@/assets/icons/payment/red-tiger-logo-523x423.png";
import paymentIcon4 from "@/assets/icons/payment/quickspin-provider.png";
import paymentIcon5 from "@/assets/icons/payment/Playson-banner.png";
import paymentIcon6 from "@/assets/icons/payment/OneTouch-Slots-Gaming-logo.png";
import paymentIcon7 from "@/assets/icons/payment/nolimit-city.png";
import paymentIcon8 from "@/assets/icons/payment/netent-logo-white.png";
import paymentIcon9 from "@/assets/icons/payment/logo-pg-b.png";
import paymentIcon10 from "@/assets/icons/payment/Kalamba-games-logo.png";
import paymentIcon11 from "@/assets/icons/payment/Image-of-Playson-Gaming-logo.png";
import paymentIcon12 from "@/assets/icons/payment/HACKSAW.png";
import paymentIcon13 from "@/assets/icons/payment/Habanero-logo.png";
import paymentIcon14 from "@/assets/icons/payment/evolution_logo_print.png";
import paymentIcon15 from "@/assets/icons/payment/elk.png";
import paymentIcon16 from "@/assets/icons/payment/bombay-live-.png";
import paymentIcon17 from "@/assets/icons/payment/betsoft-logo.png";
import paymentIcon18 from "@/assets/icons/payment/3Oaks.png";
import paymentIcon19 from "@/assets/icons/payment/mandato_logo.png";

const props = defineProps({
  isMobile: {
    type: Boolean,
    default: false

  }
})

const partnerIcons = [

  paymentIcon19,
  /* partnerIco1, */
  partnerIco2,
  partnerIco3,
  /* partnerIco4,
  partnerIco5,
  partnerIco6,
  partnerIco7,
  partnerIco8,
  partnerIco9,
  partnerIco10,
  partnerIco11,
  partnerIco12,
  partnerIco13,
  partnerIco14,
  partnerIco15,
  partnerIco16,
  partnerIco17,
  partnerIco18,
  partnerIco19,
  partnerIco20,
  partnerIco21,
  partnerIco22,
  partnerIco23,
  partnerIco24,
  partnerIco25,
  partnerIco26,
  partnerIco27,
  partnerIco28,
  partnerIco29, */
  partnerIco30,
];
const paymentIcons = [
  
 /*  paymentIcon1, */
  paymentIcon2,
  paymentIcon3,
  /* paymentIcon4, */
  /* paymentIcon5, */
  paymentIcon6,
  paymentIcon7,
  paymentIcon8,
  paymentIcon9,
  paymentIcon10,
  /* paymentIcon11, */
  paymentIcon12,
 /*  paymentIcon13, */
  paymentIcon14,
  paymentIcon15,
  paymentIcon16,
 /*  paymentIcon17, */
  paymentIcon18,
];
</script>
<template>
  <div class="w-100 h-1px bg-800 mgb-7"></div>
  <div class="pdb-10">
    <FooterCarouselComponent
      :title="translate('navigation.footer.partnersAndProviders')"
      :iconList="paymentIcons"
      :isMobile="isMobile"
    />
  </div>
  <FooterCarouselComponent
    :title="translate('navigation.footer.paymentOptions')"
    :iconList="partnerIcons"
    :isMobile="isMobile"
  />
 
  <div class="w-100 h-1px bg-800 mgy-7"></div>
</template>
