<script setup lang="ts">
import { useLayoutModalsStore } from "@/stores/layoutModals";
import InputValueComponent from "@/brands/bit1111/components/shared/forms/input/InputValueComponent.vue";
import { ref } from "vue";
import { genders } from "@/brands/bit1111/data/gender";
import type { GenderInterface } from "@/services/data/genders";
import type { RegisterInitialPayloadInterface } from "@/services/interface/registrationInterface";
import SocialsComponent from "@/brands/bit1111/components/auth/SocialsTwoComponent.vue";
import RegistrationStepCompoment from "@/brands/bit1111/components/auth/RegistrationStepComponent.vue";
import IconComponent from "@/components/icons/IconComponent.vue";
import { translate } from "@/services/translations/translation";
import ButtonComponent from "@/brands/bit1111/components/shared/buttons/ButtonComponent.vue";
import DatePicker from "@/brands/bit1111/components/shared/DatePicker.vue";
import { computeFormValidation } from "@/services/validation/formValidation";
import FormWarningComponent from "../shared/forms/FormWarningComponent.vue";

const emit = defineEmits(["emitData"]);
const layoutModalsStore = useLayoutModalsStore();

const borderClass = ref(
  "border-input radius-20 border-color-theme-brand-1 w-100"
);

const genderSelected = ref<GenderInterface>(genders[0]);
const registerData = ref<RegisterInitialPayloadInterface>({
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  gender: "",
});

const close = () => {
  layoutModalsStore.closeModal("registerConfirm");
};

const submit = () => {
  let key: ValidationKey;
  for (key in validationList.value) {
    validationList.value[key].isTouched = true;
  }
  if (formisValid.value) {
    registerData.value.gender = genderSelected.value.value;
    emit("emitData", registerData.value);
    layoutModalsStore.activateModal({ name: "registerConfirmLast" });
    close();
  }
};

// VALIDATION START
const validationList = ref({
  firstName: { isTouched: false, valid: false },
  lastName: { isTouched: false, valid: false },
  dateOfBirth: { isTouched: false, valid: false },
});
type ValidationKey = keyof typeof validationList.value;

const formisValid = ref<boolean>(false);
const callbackValid = (pointer: ValidationKey, value: boolean) => {
  validationList.value[pointer].valid = value;
  computeFormValidation(validationList.value, formisValid);
};
// VALIDATION END
const underAge = ref(false);
const getDOB = async (dob: string) => {
  // UNDERAGE VALIDATION
  const today = new Date();
  let age = today.getFullYear() - Number(dob.slice(6));
  const month = today.getMonth() + 1 - Number(dob.slice(3, 5));
  if (month < 0 || (month === 0 && today.getDate() < Number(dob.slice(0, 2)))) {
    age--;
  }
  /////////

  if (age >= 18) {
    underAge.value = false;
    callbackValid("dateOfBirth", true);
  } else {
    underAge.value = true;
    callbackValid("dateOfBirth", false);
  }
  registerData.value.dateOfBirth = dob;

  computeFormValidation(validationList.value, formisValid);
};
</script>
<template>
  <div>
    <div class="backdrop"></div>
    <form @submit.prevent>
      <div class="auth-modal-bit1111 bg-4-6 pdx-7 pdy-5">
        <div type="" class="flex flex-right">
          <IconComponent
            :iconClasses="'svg-icon block icon-16  icon-form-clear bg-1-1 cursor-pointer'"
            @click="close"
          ></IconComponent>
        </div>
        <h1 class="title-xxl mgt-5 txt-1-1 txt-bold mgx-auto fit-content">
          {{ translate("common.register").toUpperCase() }}
        </h1>
        <p class="txt-light mgx-auto fit-content text-small txt-bold">
          {{ translate("auth.register_init.desktop.header_description") }}
        </p>
        <RegistrationStepCompoment :step="2" />
        <InputValueComponent
          inputId="firstName"
          :placeholder="translate('common.firstName')"
          regexValidator="firstName"
          v-model="registerData.firstName"
          :minLength="2"
          :maxLength="25"
          :borderClass="'border-input radius-10'"
          textCutBorder="label-cut-border-light"
          v-model:isTouched="validationList.firstName.isTouched"
          @isValid="callbackValid('firstName', $event)"
        />
        <InputValueComponent
          inputId="lastName"
          :placeholder="translate('common.lastName')"
          regexValidator="lastName"
          v-model="registerData.lastName"
          :minLength="2"
          :maxLength="25"
          :borderClass="'border-input radius-10'"
          textCutBorder="label-cut-border-light"
          v-model:isTouched="validationList.lastName.isTouched"
          @isValid="callbackValid('lastName', $event)"
        />
        <p class="txt-light txt-size-14 mgt-3">
          {{ translate("common.dob") }}
        </p>
        <DatePicker @emitDate="getDOB($event)" v-model:isTouched="validationList.dateOfBirth.isTouched" />
        <FormWarningComponent
          v-if="underAge"
          :text="translate('auth.register_init.desktop.underage')"
        />
        <FormWarningComponent
          v-if="validationList.dateOfBirth.isTouched && !validationList.dateOfBirth.valid"
          :text="translate('auth.register_init.desktop.dob_required')"
        />
        <div class="bit-gender-wrapper mgt-4 flex">
          <div class="w-25 gender-left">
            <p
              class="txt-light text-smallest to-upper-case fit-content mgx-auto mgy-5"
            >
              {{ translate("common.gender") }}
            </p>
          </div>
          <div class="w-75 flex">
            <label
              class="container txt-light text-smallest mgy-5"
              v-for="gender in genders"
              :key="gender.value"
              @click="genderSelected = gender"
              >{{gender.labelText.toUpperCase() }}

              <span class="checkmark mgl-2">
                <span
                  class="checkmark-inside"
                  v-if="gender.value == genderSelected.value"
                ></span>
              </span>
            </label>
          </div>
        </div>
        <ButtonComponent
          class="mgb-6 mgt-4"
          id="submit"
          :text="translate('common.continue').toUpperCase()"
          :width="100"
          :heightPx="40"
          @click="submit()"
        />

        <!-- <SocialsComponent /> -->
       
      </div>
    </form>
  </div>
</template>
