import { RolePlayerInactive } from "@/services/auth/roles";
import { useAuthStore } from "@/stores/auth";
import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized,
} from "vue-router";
import { generateRoutes } from "@/router/routingService";
import { realRoutes } from "@/router/defaultRoutes";
import { useLayoutModalsStore } from "@/stores/layoutModals";

export class RouterSingleton {
  private static instance: RouterSingleton;
  router: any;
  brandRoutes: any;

  async recreateRouter() {
    /*  realRoutes[23].meta.requiresAuth = true */
    const newRoutes = generateRoutes(realRoutes, this.brandRoutes);

    newRoutes.forEach(async (newRoute) => {
      await this.router.addRoute(newRoute);
    });
    this.router.push(this.router.options.history.state.current);
  }
  async reloadPage() {
    const currentRoute = this.router.options.history.state.current;
    await this.router.push("/404");
    await this.router.push(currentRoute);
  }
  constructor(routes?: any) {
    this.brandRoutes = routes ?? [];
    this.router = createRouter({
      history: createWebHistory(import.meta.env.BASE_URL),
      routes: generateRoutes(realRoutes, this.brandRoutes),
    });

    this.router.beforeEach(async (to: any, from: any, next: any) => {
      const authStore = useAuthStore();
      const layoutModalsStore = useLayoutModalsStore();

      if (
        layoutModalsStore.getModal("login").active &&
        authStore.$state.player
      ) {
        layoutModalsStore.closeModal("login");
      }
      if (
        layoutModalsStore.getModal("loginMobile").active &&
        authStore.$state.player
      ) {
        layoutModalsStore.closeModal("loginMobile");
      }


      if (to.name === "game") {
        const queryMode = to.query.mode;
        if (!authStore.$state.player && queryMode == "real") {
          next({ name: "login" });
        }
      }

      //Registration token in link, while not logged in
      if (to.query.token && !authStore.$state.player) {
        await authStore.sendRegistrationToken(to.query.token);
      }
      if (
        authStore.$state.role == RolePlayerInactive &&
        !to.fullPath.includes("registration/confirm") &&
        !to.fullPath.includes("register/mailsent") &&
        !to.fullPath.includes("support") &&
        !to.fullPath.includes("terms") &&
        authStore.$state.player
      ) {
        
        next(`/register/mailsent?email=${authStore.$state.player.email}`);
        return;
      }

      if (to.meta.requiresAuth && !authStore.$state.player && !to.fullPath.includes("registration/confirm")) {
        next('/login')
        return;
      }
      
      
      //block authorized user from accessing non-auth-only routes
      if (to.meta.requiresNotAuth) {
        if (authStore.$state.player) {
          next("/");
          return;
        }
      }
     
      //Page to Modal logic
      if (to.meta.pageToModal && window.innerWidth > 768) {
        layoutModalsStore.activateModal({name: to.name,query: to.query ? to.query : null});
        if (from.name && !from.meta.pageToModal) {
          next(from);
        } else {
          next({ name: "home" });
        }
      }
      if (to.meta.mobilePageToModal && window.innerWidth <= 768) {
        layoutModalsStore.activateModal({name: to.name + 'Mobile',query: to.query ? to.query : null});
        if (from.name && !from.meta.mobilePageToModal) {
          next(from);
        } else {
          next({ name: "home" });
        }
      }

      
      next();
      return;
    });
  }

  public static getInstance(file?: any): RouterSingleton {
    if (!RouterSingleton.instance) {
      RouterSingleton.instance = new RouterSingleton(file);
    }
    return RouterSingleton.instance;
  }

  getRouter() {
    return this.router;
  }

  registrationTokenCheck = async (to: RouteLocationNormalized) => {
    const auth = useAuthStore();
    if (to.meta.checkRegistrationToken) {
      if (!auth.$state.role && to.query.token) {
        if (typeof to.query.token == "string") {
          await auth.sendRegistrationToken(to.query.token);
        }
      }
    }
  };
}
