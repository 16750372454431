// import require

export class DomainResolver {
  api(): string {
    const hos = import.meta.env.VITE_API_CASINO;
    // if (window.location.host.includes("condor-business.com")) {
    //   hos = "https://stage-api-www.condor-business.com";
    // }
    return hos;
  }
  async brandName() {
    const domain = window.location.host;
    const brandMap = await this.brandMap();
    return brandMap[domain as keyof typeof brandMap] ?? "Bit1111";
  }
  async brandMap() {
    const file = await import("../../../brandMap.json");
    return file.default;
  }
  getEnv(): string {
    let env = process.env.NODE_ENV ?? "default";
    if (["development", "dev"].includes(env)) {
      env = "dev";
    }
    if (["production", "prod"].includes(env)) {
      env = "prod";
    }
    return env;
  }
}
