<script setup lang="ts">
import InputValueComponent from "@/components/shared/forms/input/InputValueComponent.vue";
import InputPasswordComponent from "@/components/shared/forms/input/InputPasswordComponent.vue";
import DropDownComponent from "@/components/shared/forms/dropdown/DropDownComponent.vue";
import { ref } from "vue";
import ValidationHover from "@/components/shared/forms/ValidationHover.vue";
import { registerStepOne } from "@/services/auth/registration";
import { useLoaderStore } from "@/stores/loader";
import { useAuthStore } from "@/stores/auth";
import { ErrorHandler } from "@/lib/exceptions/ErrorHandler";
import type { FormResponseValidation } from "@/services/interface/registrationInterface";
import type { LooseObject } from "@/services/interface/utils";
import CheckBoxTermsAndConditions from "./CheckBoxTermsAndConditions.vue";

const loader = useLoaderStore();
const authStore = useAuthStore();
const hover = ref(false);

const formResponseValidation = ref<LooseObject>({
  username: [],
  password: [],
  email: [],
});

const selectedCurrency = ref();
const userName = ref();
const email = ref();
const password = ref();
const hideResponseErrors = ref(true);

const errorMsg = ref();

// VALIDATION START
const newValidationList = ref({
  // gender: Boolean,
  username: Boolean,
  email: Boolean,
  password: Boolean,
  currency: Boolean,
  check: Boolean,
  checkData: Boolean,
  // phoneCodeMock:Boolean
});
type ValidationKey = keyof typeof newValidationList.value;

const formisValid = ref();
const callbackValid = (pointer: ValidationKey, value: BooleanConstructor) => {
  newValidationList.value[pointer] = value;
  computeFormValidation();
};
const computeFormValidation = () => {
  let flag = true;
  let key: ValidationKey;
  for (key in newValidationList.value) {
    if (!newValidationList.value[key]) flag = false;
  }
  formisValid.value = flag;
};
// VALIDATION END

const onSubmit = async () => {
  errorMsg.value = null;
  const data = {
    username: userName.value.toLowerCase(),
    email: email.value,
    currency: selectedCurrency.value.code,
    password: password.value,
  };
  loader.toggleLoader(true);
  registerStepOne(data)
    .then(async (response) => {
      hideResponseErrors.value = true;
      await authStore.login(data.username, data.password);
      // await authStore.setPlayerData();
      loader.toggleLoader(false);
    })
    .catch((error) => {
      loader.toggleLoader(false);
      hideResponseErrors.value = false;
      console.log("registerStepOne error", error);
      for (const key of Object.keys(formResponseValidation.value)) {
        formResponseValidation.value[key] = [];
      }
      const handler = new ErrorHandler();
      const err = handler.handle(error);
      errorMsg.value = err.errors;
      err.errors?.forEach((element) => {
        formResponseValidation.value[
          element.property as keyof FormResponseValidation
        ].push(element.message);
      });
    });
};
</script>
<template>
  <div class="registration-form">
    <h1 class="txt-center mgt-4">Register</h1>
    <form class="pda-4 form-size" @submit.prevent>
      <InputValueComponent
        :responseErrors="formResponseValidation.username"
        inputId="name"
        placeholder="Username"
        regexValidator="username"
        v-model="userName"
        :minLength="5"
        :maxLength="25"
        :hideResponseErrors="hideResponseErrors"
        @hideErrors="hideResponseErrors = $event"
        @isValid="callbackValid('username', $event)"
      >
      </InputValueComponent>
      <InputValueComponent
        :responseErrors="formResponseValidation.email"
        inputId="email"
        placeholder="Email"
        regexValidator="email"
        v-model="email"
        :minLength="5"
        :maxLength="64"
        :hideResponseErrors="hideResponseErrors"
        @hideErrors="hideResponseErrors = $event"
        @isValid="callbackValid('email', $event)"
      >
      </InputValueComponent>
      <DropDownComponent
        dropdownId="currency"
        :label="'Currency'"
        url="/currencies"
        :selector="'code'"
        :preselect="true"
        :selected="selectedCurrency"
        @emitSelected="selectedCurrency = $event"
        @isValid="callbackValid('currency', $event)"
      />

      <InputPasswordComponent
        :responseErrors="formResponseValidation.password"
        passId="password"
        label="Password 8+ characters"
        v-model="password"
        @isValid="callbackValid('password', $event)"
        :minLength="8"
        :maxLength="25"
        :hideResponseErrors="hideResponseErrors"
        @hideErrors="hideResponseErrors = $event"
      ></InputPasswordComponent>

      <CheckBoxTermsAndConditions @isValid="callbackValid('checkData', $event)" />
      <button
        id="submit"
        class="btn-large btn-brand-light mgx-auto mgy-6 txt-bold p-0"
        @click="onSubmit()"
        :disabled="!formisValid"
        @mouseover="hover = true"
        @mouseleave="hover = false"
      >
        let the fun begin!
      </button>
      <ValidationHover
        :hover="hover"
        :formisValid="formisValid"
        :list="newValidationList"
      />
    </form>
  </div>
</template>
