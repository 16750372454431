<script setup lang="ts">
import { useLayoutModalsStore } from "@/stores/layoutModals";
import AuthSelectModal from "@/brands/holahola/components/auth/AuthSelectModal.vue";
import InputValueComponent from "@/brands/holahola/components/shared/forms/input/InputValueComponent.vue";
import InputPasswordComponent from "@/brands/holahola/components/shared/forms/input/InputPasswordComponent.vue";
import DropDownComponent from "@/brands/holahola/components/shared/forms/dropdown/DropDownComponent.vue";
import { ref, onMounted } from "vue";
import { registerStepOne } from "@/services/auth/registration";
import { useLoaderStore } from "@/stores/loader";
import { useAuthStore } from "@/stores/auth";
import DividerComponent from "@/components/shared/divider/DividerComponent.vue";
import SocialsComponent from "@/brands/holahola/components/auth/SocialsTwoComponent.vue";
import RegistrationStepCompoment from "@/brands/holahola/components/auth/RegistrationStepComponent.vue";
import { goToRoute } from "@/router/routingService";
import type { FormResponseValidation } from "@/services/interface/registrationInterface";
import type { LooseObject } from "@/services/interface/utils";
import { ErrorHandler } from "@/lib/exceptions/ErrorHandler";
import { translate } from "@/services/translations/translation";
import IconComponent from "@/components/icons/IconComponent.vue";
import { useBrandStore } from "@/stores/brand";
import { computeFormValidation } from "@/services/validation/formValidation";

const layoutModalsStore = useLayoutModalsStore();
const brandStore = useBrandStore();
const isMultiCurrency = brandStore.getModule("multi_currency");
const loader = useLoaderStore();
const authStore = useAuthStore();
const selectedCurrency = ref<any>();
const hideResponseErrors = ref(false);

const closeModal = () => {
  layoutModalsStore.closeModal("registerMobile");
};

interface RegisterInitialPayloadInterface {
  currency: string;
  email: string;
  password: string;
  username: string;
}

const registerData = ref<RegisterInitialPayloadInterface>({
  currency: "",
  email: "",
  password: "",
  username: "",
});

const formResponseValidation = ref<LooseObject>({
  username: [],
  password: [],
  email: [],
});

// VALIDATION START
const validationList = ref({
  username: { isTouched: false, valid: false },
  password: { isTouched: false, valid: false },
  email: { isTouched: false, valid: false },
  currency: { isTouched: false, valid: false },
});
type ValidationKey = keyof typeof validationList.value;

const formisValid = ref<boolean>(false);
const callbackValid = (pointer: ValidationKey, value: boolean) => {
  validationList.value[pointer].valid = value;
  computeFormValidation(validationList.value, formisValid);
};
// VALIDATION END

const close = () => {
  layoutModalsStore.closeModal("registerMobile");
};

const reRoute = (url: string) => {
  goToRoute("/login");
  close();
};

const submit = () => {
  const data = {
    username: registerData.value.username.toLowerCase(),
    email: registerData.value.email,
    currency: isMultiCurrency
      ? selectedCurrency.value.code.toString()
      : selectedCurrency.value,
    password: registerData.value.password,
  };
  loader.toggleLoader(true);
  let key: ValidationKey;
  for (key in validationList.value) {
    validationList.value[key].isTouched = true;
  }
  if (formisValid.value) {
  registerStepOne(data)
    .then(async (response) => {
      hideResponseErrors.value = true;

      await authStore.login(data.username, data.password);
      // await authStore.setPlayerData();
      goToRoute(`/register/mailsent?email=${data.email}`);
      close();
    })
    .catch((error) => {
      hideResponseErrors.value = false;

      for (const key of Object.keys(formResponseValidation.value)) {
        formResponseValidation.value[key] = [];
      }
      const handler = new ErrorHandler();
      const err = handler.handle(error);
      err.errors?.forEach((element) => {
        formResponseValidation.value[
          element.property as keyof FormResponseValidation
        ].push(element.message);
      });
      
    });
  }loader.toggleLoader(false);
};
onMounted(() => {
  isMultiCurrency ? null : (selectedCurrency.value = "EUR");
});
</script>
<template>
  <div class="backdrop">
    <div
      class="absolute top-8 left-3 right-3 bg-light zindex-modal radius-8 pdx-5 pdt-5 login-modal"
    >
      <div class="w-100 flex flex-justify-end">
        <div
          @click="closeModal()"
          class="w-29px h-29px border-dark radius-circle txt-dark txt-bold flex flex-center txt-size-20 cursor-pointer"
        >
          <p class="pdt-1">X</p>
        </div>
      </div>
      <AuthSelectModal selected="register" />
      <form @submit.prevent class="flex flex-center" >
        <div class=" pdy-3 w-80">
          <RegistrationStepCompoment :step="1" />
          <div class="mgt-5 w-100">
            <InputValueComponent
              :responseErrors="formResponseValidation.username"
              :hideResponseErrors="hideResponseErrors"
              @hideErrors="hideResponseErrors = $event"
              inputId="name"
              :inputType="'text'"
              :placeholder="translate('auth.register_init.desktop.username')"
              regexValidator="username"
              v-model="registerData.username"
              :minLength="5"
              :maxLength="25"
              textCutBorder="label-cut bg-1-3 border-top-1-2 border-width-1"
              textCutBorderFocused="label-cut bg-1-3 border-top-1-2 border-width-1"
              bgColor="bg-1-3 radius-26"
              :borderClass="' radius-26 border-1-2 border-width-1'"
              v-model:isTouched="validationList.username.isTouched"
              @isValid="callbackValid('username', $event)"
            />
          </div>
          <div class="mgt-5 w-100">
            <DropDownComponent
              v-if="isMultiCurrency"
              dropdownId="currency"
              :label="translate('auth.register_init.desktop.currency')"
              url="/currencies"
              :selector="'code'"
              :preselect="true"
              :selected="selectedCurrency"
              @emitSelected="selectedCurrency = $event"
              :borderClass="'  border-1-2 border-width-1'"
              :textCutBorder="'label-cut bg-1-3 border-top-1-2 border-width-1 txt-light'"
              :bgClass="'bg-1-3 '"
              :radiusClass="'radius-26'"
              @isValid="callbackValid('currency', $event)"
            />
            <InputValueComponent
              v-if="!isMultiCurrency"
              :responseErrors="formResponseValidation.currency"
              :hideResponseErrors="hideResponseErrors"
              @hideErrors="hideResponseErrors = $event"
              inputId="currency"
              :placeholder="translate('auth.register_init.desktop.currency')"
              v-model="selectedCurrency"
              textCutBorder="label-cut bg-1-3 border-top-1-2 border-width-1"
              textCutBorderFocused="label-cut bg-1-3 border-top-1-2 border-width-1"
              bgColor="bg-1-3 radius-26"
              :borderClass="' radius-26 border-1-2 border-width-1'"
              v-model:isTouched="validationList.currency.isTouched"
              @isValid="callbackValid('currency', $event)"
              :isDisabled="true"
            />
          </div>

          <div class="mgt-5 w-100">
            <InputValueComponent
              inputId="email"
              :placeholder="translate('auth.register_init.desktop.email')"
              regexValidator="email"
              v-model="registerData.email"
              :minLength="5"
              :maxLength="64"
              textCutBorder="label-cut bg-1-3 border-top-1-2 border-width-1"
              textCutBorderFocused="label-cut bg-1-3 border-top-1-2 border-width-1"
              bgColor="bg-1-3 radius-26"
              :borderClass="' radius-26 border-1-2 border-width-1'"
              :responseErrors="formResponseValidation.email"
              :hideResponseErrors="hideResponseErrors"
              @hideErrors="hideResponseErrors = $event"
              v-model:isTouched="validationList.email.isTouched"
              @isValid="callbackValid('email', $event)"
            />
          </div>
          <div class="mgt-5 w-100">
            <InputPasswordComponent
              passId="password"
              :label="translate('auth.register_init.desktop.password')"
              v-model="registerData.password"
              :minLength="8"
              :maxLength="25"
              textCutBorder="label-cut bg-1-3 border-top-1-2 border-width-1"
              textCutBorderFocused="label-cut bg-1-3 border-top-1-2 border-width-1"
              bgColor="bg-1-3 radius-26"
              :borderClass="' radius-26 border-1-2 border-width-1'"
              :responseErrors="formResponseValidation.password"
              :hideResponseErrors="hideResponseErrors"
              @hideErrors="hideResponseErrors = $event"
              v-model:isTouched="validationList.password.isTouched"
              @isValid="callbackValid('password', $event)"
            />
          </div>
          <!-- <CheckBoxTermsAndConditions /> -->
          <button
            id="submit"
            class="mgx-auto mgy-7 bg-2-1 radius-10 w-150px h-50px font-Poppins txt-size-18 txt-weight-600 txt-light"
            @click="submit()"
          >
            {{ translate("auth.register_init.desktop.submit") }}
          </button>
          <div>
            <p class="txt-theme-txt-2 text-smallest mgx-auto fit-content">
              {{ translate("auth.register_init.desktop.already_have_account") }}
              <span
                class="txt-theme-brand-2 cursor-pointer"
                @click="reRoute('/login')"
                >{{ translate("auth.register_init.desktop.login") }}</span
              >
            </p>
          </div>
          <DividerComponent
            :dividerClass="'w-90 mgx-auto mgb-6 mgt-6 bg-dark400'"
          ></DividerComponent>
          <!--  <SocialsComponent /> -->
        </div>
      </form>
    </div>
  </div>
</template>
<style scoped>
.login-modal {
  background: transparent linear-gradient(180deg, #23008a, #000635) 0% 0%
    no-repeat padding-box;
}
</style>
