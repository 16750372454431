export function isEmpty(data) {
  if (isObject(data)) {
    return Object.keys(data).length === 0;
  }
  if (Array.isArray(data)) {
    return data.length === 0;
  }
}

export function isObject(obj) {
  return typeof obj === "object";
}

export function ObjectToArray(obj) {
  return Object.entries(obj);
}
export function getOS() {
  let os = "web";
  const platform =
    navigator?.userAgentData?.platform || navigator?.platform || "unknown";

  if (/Android/.test(navigator.userAgent)) {
    os = "android";
  }
  if (
    platform.includes("iP") ||
    platform.includes("Mac") ||
    platform.includes("mac")
  ) {
    os = "ios";
  }

  return os;
}

export const sleep = async (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
