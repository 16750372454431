<script setup lang="ts">
import { useLayoutModalsStore } from "@/stores/layoutModals";
import InputValueComponent from "@/components/shared/forms/input/InputValueComponent.vue";
import { ref } from "vue";
import { genders } from "@/services/data/genders";
import type { GenderInterface } from "@/services/data/genders";
import type { RegisterInitialPayloadInterface } from "@/services/interface/registrationInterface";
import DividerComponent from "@/components/shared/divider/DividerComponent.vue";
import SocialsComponent from "@/brands/holahola/components/auth/SocialsTwoComponent.vue";
import RegistrationStepCompoment from "@/brands/holahola/components/auth/RegistrationStepComponent.vue";
import IconComponent from "@/components/icons/IconComponent.vue";

const emit = defineEmits(["emitData"]);
const layoutModalsStore = useLayoutModalsStore();

const borderClass = ref("border-input radius-20 border-color-theme-brand-1 w-100");

const genderSelected = ref<GenderInterface>(genders[0]);
const registerData = ref<RegisterInitialPayloadInterface>({
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  gender: "",
});

const close = () => {
  layoutModalsStore.closeModal("registerConfirm");
};

const submit = () => {
  registerData.value.gender = genderSelected.value.value;
  emit("emitData", registerData.value);
  layoutModalsStore.activateModal({ name: "registerConfirmLast" });
  close();
};

// VALIDATION START
const newValidationList = ref({
  firstName: Boolean,
  lastName: Boolean,
  dateOfBirth: Boolean,
});
type ValidationKey = keyof typeof newValidationList.value;

const formisValid = ref(false);
const callbackValid = (pointer: ValidationKey, value: BooleanConstructor) => {
  newValidationList.value[pointer] = value;
  computeFormValidation();
};
const computeFormValidation = () => {
  let flag = true;
  let key: ValidationKey;
  for (key in newValidationList.value) {
    if (!newValidationList.value[key]) flag = false;
  }
  formisValid.value = flag;
};
// VALIDATION END
</script>
<template>
  <div>
    <div class="backdrop"></div>
    <form @submit.prevent>
      <div class="auth-modal bg-theme-bg-1 pda-5">
        <div type="" class="flex flex-right">
          <IconComponent
            :iconClasses="'svg-icon block icon-16  icon-form-clear bg-theme-bg-7 cursor-pointer'"
            @click="close"
          ></IconComponent>
        </div>
        <h1 class="title-large mgt-5 txt-theme-txt-1 txt-bold mgx-auto fit-content">
          REGISTER
        </h1>
        <p class="txt-theme-txt-2 mgx-auto fit-content text-smallest txt-bold">
          Let's start with your personal details
        </p>
        <RegistrationStepCompoment :step="2" />
        <InputValueComponent
          inputId="firstName"
          placeholder="First Name"
          regexValidator="firstName"
          v-model="registerData.firstName"
          :minLength="2"
          :maxLength="25"
          :borderClass="'border-input radius-5 border-color-theme-brand-1'"
          @isValid="callbackValid('firstName', $event)"
        />
        <InputValueComponent
          inputId="lastName"
          placeholder="Last Name"
          regexValidator="lastName"
          v-model="registerData.lastName"
          :minLength="2"
          :maxLength="25"
          :borderClass="'border-input radius-5 border-color-theme-brand-1'"
          @isValid="callbackValid('lastName', $event)"
        />
        <InputValueComponent
          :borderClass="'border-input radius-5 border-color-theme-brand-1'"
          inputId="date"
          placeholder="Date of Birth (dd-mm-yyyy)"
          :inputType="'date'"
          regexValidator="date"
          :maxLength="10"
          v-model="registerData.dateOfBirth"
          @isValid="callbackValid('dateOfBirth', $event)"
        ></InputValueComponent>
        <div class="hola-gender-wrapper mgt-4 flex">
          <div class="w-25 gender-left">
            <p class="txt-theme-txt-2 text-smallest fit-content mgx-auto mgy-5">Gender</p>
          </div>
          <div class="w-75 flex">
            <label
              class="container txt-theme-txt-2 text-smallest mgy-5"
              v-for="gender in genders"
              :key="gender.value"
              @click="genderSelected = gender"
              >{{ gender.labelText.toUpperCase() }}

              <span class="checkmark mgl-2">
                <span
                  class="checkmark-inside"
                  v-if="gender.value == genderSelected.value"
                ></span>
              </span>
            </label>
          </div>
        </div>
        <button
          id="submit"
          class="bg-theme-brand-2 mgb-6 mgt-4 txt-bold pdx-5 pdy-3 radius-5 w-100"
          @click="submit()"
          :disabled="!formisValid"
        >
          CONTINUE
        </button>
        <div>
          <p class="txt-theme-txt-2 text-smallest mgx-auto fit-content">
            Already have an account? <span class="txt-theme-brand-2">Login</span>
          </p>
        </div>
        <DividerComponent
          :dividerClass="'w-90 mgx-auto mgb-6 mgt-6 bg-dark400'"
        ></DividerComponent>
        <SocialsComponent />
      </div>
    </form>
  </div>
</template>
