import { useBalanceStore } from "@/stores/balance";
import type { PlayerData } from "./contracts/PlayerData";
import { useBonusesStore } from "@/stores/bonuses";
import { defineStore } from "pinia";
// import router from "../router/index.js";
import { RouterSingleton } from "@/router";
import { useCookies } from "vue3-cookies";
import axios, { AxiosError, type AxiosResponse } from "axios";
import eventRegister from "@/lib/mercury/eventRegister";
import { useLayoutModalsStore } from "@/stores/layoutModals";
import { usePaymentStore } from "@/stores/payments";
import { useNotificationStore } from "@/stores/notifications";
const baseUrl = import.meta.env.VITE_API_CASINO;

const casinoAccessToken = "CASINO_ID_TOKEN";
const casinoRefreshToken = "CASINO_ID_RF_TOKEN";
const casinoPlayer = "CASINO_PL";
const isRemembered = "CASINO_PL_REMEMBERED";
const casinoActivity = "CASINO_ACTIV";
const sessionStart = "SESSION_START";

const { cookies } = useCookies();
export const useAuthStore = defineStore({
  id: "authentication",
  state: () => ({
    accessToken: <string | null>cookies.get(casinoAccessToken),
    refreshToken: <string | null>pullRefreshToken(),
    activity: <string | Date | null>pullActivity(),
    expiresIn: <number>1000,
    realityCheckStart: <string | null>null,
    player: <PlayerData | null>null,
    role: <string | null>null,

  }),
  getters: {
    getPlayer: (state) => {
      return state.player;
    },
    getBoardingFlag: (state) =>
      state.player
        ? state.player.role == "ROLE_PLAYER"
          ? state.player.onboarding
          : true
        : true,
  },
  actions: {
    async login(username: string, password: string): Promise<string | null> {
      let errorMessage = null;
      let response = null;

      try {
        response = await axios.post(`${baseUrl}/api/auth/token`, {
          grant_type: "password",
          username: username,
          password: password,
        });
        this.setActivity();
        this.setTokens(response);
      } catch (e: unknown) {
        if (!(e instanceof AxiosError)) {
          throw e;
        }
        errorMessage = e.response?.data.message;
      }
      if (errorMessage) {
        return errorMessage;
      }

      return await this.setPlayerData(true);
    },
    async setPlayerData(redirect = false): Promise<string | null> {
      let errorMessage = null;
      try {
        const response = await axios.get(`${baseUrl}/players/me`, {
          headers: {
            Authorization: "Bearer " + this.accessToken,
          },
        });

        this.player = response.data.data as PlayerData;

        eventRegister.authorizationHook();
        //this.setCookiePlayer(this.player);
        this.role = this.player.role;
        await RouterSingleton.getInstance().recreateRouter();
        const bonusesStore = useBonusesStore();
        const layoutsStore = useLayoutModalsStore();
        bonusesStore.loadAllBonuses();
        
        if (!this.player.onboarding && this.player.role == "ROLE_PLAYER") {

          layoutsStore.activateModal({name:"boarding"});
        } else {
          layoutsStore.closeModal("boarding");
        }
        if (redirect) {
          RouterSingleton.getInstance().getRouter().push({ name: "home" });
        }
        const balanceStore = useBalanceStore();

        balanceStore.loadBalance(this.player);
      } catch (e) {
        console.log(e);
        this.clearState();
        errorMessage =
          "Something is wrong with Player data, please contact our support";
      }

      const paymentStore = usePaymentStore();
      const notifStore = useNotificationStore();
      if (this.player?.role == "ROLE_PLAYER") {
        notifStore.getNotifications();
        // paymentStore.loadMethods();
      }



      return errorMessage;
    },
    async sendRefreshToken() {
      axios
        .post(`${baseUrl}/api/auth/token`, {
          grant_type: "refresh_token",
          refresh_token: this.refreshToken,
        })
        .then((response) => {
          this.setTokens(response);
          this.setPlayerData();
        })
        .catch((error) => {
          console.log("error at refresh token", error);
        });
    },
    async sendRegistrationToken(token: string) {
      axios
        .post(`${baseUrl}/api/auth/token`, {
          grant_type: "registration_token",
          registration_token: token,
        })
        .then((response) => {
          this.setTokens(response);
          this.setPlayerData();
        })
        .catch((error) => {
          console.log("error getting registration token", error);
        });
    },
    async logout() {
      await RouterSingleton.getInstance().getRouter().push({ name: "home" });
      this.clearState();
      eventRegister.logoutAuthorizationHook();
      await RouterSingleton.getInstance().recreateRouter();
      await RouterSingleton.getInstance().getRouter().push({ name: "home" });
    },
    clearState() {
      this.role = null;
      this.player = null;
      this.accessToken = null;
      this.refreshToken = null;
      cookies.remove(casinoPlayer);
      cookies.remove(casinoAccessToken);
      localStorage.removeItem(casinoRefreshToken);
      localStorage.removeItem(isRemembered);
      localStorage.removeItem("refreshTokenWait");
    },
    getAccessToken() {
      return this.accessToken;
    },
    setTokens(response: AxiosResponse<any, any>) {
      cookies.set(sessionStart, JSON.stringify(new Date()));
      this.accessToken = response.data.access_token;

      this.expiresIn = response.data.expires_in;
      if (this.accessToken !== null) {
        cookies.set(
          casinoAccessToken,
          this.accessToken,
          this.expiresIn,
          "",
          "",
          true
        );
      }
      if (response.data.refresh_token) {
        this.refreshToken = response.data.refresh_token;
        localStorage.setItem(
          casinoRefreshToken,
          JSON.stringify(this.refreshToken)
        );
      }
    },
    setActivity() {
      if (this.player) {
        this.activity = new Date();
        cookies.set(
          casinoActivity,
          JSON.stringify(this.activity),
          this.expiresIn
        );
      }
    },
    setCookiePlayer(player: PlayerData) {
      cookies.set(casinoPlayer, JSON.stringify(player), this.expiresIn);
    },
    getActivity() {
      if (this.activity == null) {
        this.activity = pullActivity();
      }
      return this.activity !== null;
    },
    getRefreshToken() {
      if (this.refreshToken == null) {
        this.refreshToken = pullRefreshToken();
      }
      return this.refreshToken;
    },
  },
});

const pullActivity = () => {
  return cookies.get(casinoActivity);
};

const pullRefreshToken = () => {
  const rfToken = localStorage.getItem(casinoRefreshToken);
  if (rfToken !== null) {
    return JSON.parse(rfToken);
  }
  return null;
};
