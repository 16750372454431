<script setup lang="ts">
import InputValueComponent from "@/components/shared/forms/input/InputValueComponent.vue";
import DividerComponent from "@/components/shared/divider/DividerComponent.vue";
import { goToRoute } from "@/router/routingService";
import { useLoaderStore } from "@/stores/loader";
import { ref } from "vue";
import NotificationComponent from "@/components/shared/notification/NotificationComponent.vue";
import { useRoute } from "vue-router";
import InputPasswordComponent from "@/components/shared/forms/input/InputPasswordComponent.vue";
import axios from "axios";
import { ErrorHandler } from "@/lib/exceptions/ErrorHandler";
import { translate } from "@/services/translations/translation";

const route = useRoute();
const token = ref(route.query.token);
const loader = useLoaderStore();
const email = ref("");
const sentFlag = ref(false);
const errorMessage = ref("");
const newPassword = ref();
const sendMail = async () => {
  sentFlag.value = false;
  loader.toggleLoader(true);
  const body = {
    email: email.value,
  };
  await axios
    .post("/api/auth/forgot-password/send-email", body)
    .then(() => {
      sentFlag.value = true;
    })
    .catch((error) => {
      const err = new ErrorHandler();
      const errObj = err.handle(error);
      errorMessage.value = errObj.message;
    });

  loader.toggleLoader(false);
};
</script>

<template>
  <div class="forgot-password-wrapper pda-4 form-size">
    <h1 class="txt-center title-large mgt-5 txt-theme-brand-1">
      {{ translate("auth.forgotPassword.desktop.sendEmail") }}
    </h1>
    <div>
      <NotificationComponent
        v-if="sentFlag"
        :title="translate('auth.forgotPassword.desktop.success')"
        :messageBody="'Recovery link sent to the following ' + email"
        :bgClass="'bg-success'"
        :iconClass="'success'"
        :textColor="'txt-theme-txt-1'"
      ></NotificationComponent>
      <div class="form-size" v-if="!sentFlag">
        <form method="post" class="pda-4 form-size" @submit.prevent>
          <InputValueComponent
            :placeholder="translate('auth.forgotPassword.desktop.enterEmail')"
            :inputType="'text'"
            v-model="email"
          ></InputValueComponent>
          <p class="txt-error" v-if="errorMessage != ''">{{ errorMessage }}</p>
          <button
            @click="sendMail"
            class="block btn-brand-light btn-large mgb-6 mgt-4 mgx-auto"
          >
            {{ translate("common.send").toUpperCase() }}
          </button>
        </form>

        <DividerComponent
          :dividerClass="'w-95 mgx-auto mgy-6 bg-dark400'"
        ></DividerComponent>
        <p class="txt-center mgy-6 txt-theme-txt-1">
          {{ translate("auth.login.desktop.dont_have_account") }}
        </p>
        <button
          class="btn btn-large btn-brand-light-secondary mgx-auto mgy-6 txt-bold"
          @click="goToRoute('/register')"
        >
          {{ translate("common.register") }}
        </button>
      </div>

      <div class="form-size" v-else>
        <div class="reset_password_msg pda-4">
          <p class="flex flex-center pda-4">
            {{ translate("auth.forgotPassword.desktop.noEmail") }}
          </p>
          <p class="flex flex-center pda-4">
            {{ translate("auth.forgotPassword.desktop.noEmail2") }}
          </p>
        </div>
        <button
          class="btn-large btn-brand-light mgx-auto mgy-6 txt-bold p-0"
          @click="sendMail"
        >
          {{ translate("auth.forgotPassword.desktop.resend") }}
        </button>
        <button
          class="btn btn-large btn-brand-light-secondary mgx-auto mgy-6 txt-bold"
          @click="sentFlag = false"
        >
          {{ translate("auth.forgotPassword.desktop.change") }}
        </button>
      </div>
    </div>
  </div>
</template>
