import { useAuthStore } from "@/stores/auth";
import type { MercureEventListener } from "./EventListenerInterface";
import { useNotificationStore } from "@/stores/notifications";

class CasinoNotificationEvent implements MercureEventListener {
  async listener(event: MessageEvent) {
    const authStore = useAuthStore();
    const player = authStore.$state.player;
    if (player) {
    const notificationStore = useNotificationStore();
    await notificationStore.fetchNotifications();
    await notificationStore.getUnseenNotifications();
    }
  }

  topic() {
    const authStore = useAuthStore();
    const player = authStore.$state.player;
    if (player == null) {
      throw new Error("Player not logged In");
    }
    const topic = "notification/player/" + player?.id;
    
    return topic;
  }

  on() {
    return "message";
  }
}

export default CasinoNotificationEvent;
