<script setup lang="ts">
import { useRoute } from "vue-router";
import { ref, watch, onMounted } from "vue";
import { useAuthStore } from "@/stores/auth";
import eventRegister from "@/lib/mercury/eventRegister";
import HeaderComponentBit1111 from "@/brands/bit1111/components/navigation/header/HeaderComponent.vue";
import BottomNav from "@/brands/holahola/components/navigation/BottomNavigationComponent.vue";
import FooterBit111 from "@/brands/bit1111/components/navigation/footer/FooterDesktopComponent.vue";
import Navigation from "@/components/navigation/header/DesktopNavigation.vue";
import ModalsDesktop from "@/brands/bit1111/layout/ModalsDesktop.vue";

const emit = defineEmits(["finishedLoading"]);


onMounted(async () => {
  if (player !== null && player.role == "ROLE_PLAYER") {
    eventRegister.authorizationHook();
  }
  eventRegister.nonAuthorizationHook();
});

const authStore = useAuthStore();
const route = useRoute();
const routeAuth = ref(false);

const player = authStore.$state.player;
const navigationToggle = ref(false);

const finishedLoading = () => {
  emit("finishedLoading");
};

watch(
  route,
  () => {
    routeAuth.value = ["login", "register"].includes(
      typeof route.name == "string" ? route.name : ""
    );
    window.scrollTo(0, 0);
  },
  { deep: true }
);
</script>

<template>
  <div id="layout-body" class="main">
    <!-- <ChatComponent /> -->
    <HeaderComponentBit1111
      @toggleNav="navigationToggle = !navigationToggle"
      :navigationFlag="navigationToggle"
      @finishedLoading="finishedLoading"
    />
    <ModalsDesktop />
    <div class="c-body">
      
      <Navigation
        v-show="navigationToggle"
        @toggleMenu="navigationToggle = !navigationToggle"
      />
      <RouterView />
    </div>
    <BottomNav />
    <FooterBit111 />
  </div>
</template>
