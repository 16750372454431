<script setup lang="ts">
import RangeSlider from "../../../components/shared/slider/RangeSliderComponent.vue";
import { ref, computed, onMounted } from "vue";
import InputValueComponent from "../../../components/shared/forms/input/InputValueComponent.vue";
import { isMobileCheck } from "../../../services/interface/clientInformation";
import NotificationComponent from "../../../components/shared/notification/NotificationComponent.vue";
import { axiosGet, axiosPost } from "../../../services/axios/crudOperations";
import vueSlider from "vue3-slider";

const timeData = ref({
  minutes: 0,
  startsAt: "",
  expiresAt: "",
  locked: false,
  countAutoPlay: false,
});

const myNumberEditing = ref();
const myNumberSaved = ref();
const editing = ref(false);
const isSet = ref(false);
const notificationData = ref({
  isShown: false,
  message: "",
  title: "",
  icon: "",
  bg: "",
});

const isMobile = isMobileCheck();

const init = async () => {
  const response = await axiosGet("/player-limits/time");
  if (response) {
    timeData.value = response;
    if (timeData.value.minutes) {
      isSet.value = true;
    }
  }
};

init();

const tooltip = computed(() => {
  if (myNumberEditing.value) {
    let tempStr = "";
    tempStr += Math.floor(myNumberEditing.value / 60) + "h";
    tempStr += myNumberEditing.value - Math.floor(myNumberEditing.value / 60) * 60 + "m";
    return tempStr;
  } else return "0h0m";
});

const save = async () => {
  timeData.value.minutes = myNumberEditing.value;
  timeData.value.countAutoPlay = false;
  const response = await axiosPost("/player-limits/time", timeData.value); //IF RESPONSE SUCCESS
  if (response) {
    isSet.value = true;
    editing.value = false;
    timeData.value = await axiosGet("/player-limits/time");
    notificationData.value = {
      isShown: true,
      message: `Your time limit has been updated successfully.`,
      title: "Limits updated!",
      bg: "bg-success ",
      icon: "icon-success-small",
    };
  } else {
    notificationData.value = {
      isShown: true,
      message: `There has been an error while trying to update limits.`,
      title: "Something went wrong!",
      bg: "bg-error ",
      icon: "icon-warning",
    };
  }
};

const cancel = () => {
  myNumberEditing.value = myNumberSaved.value;
  editing.value = false;
};
</script>

<template>
  <div :class="isMobile ? 'form-size  mgy-10 ' : 'form-size'">
    <div class="mgx-5">
      <!-- <RangeSlider /> -->
      <h2 class="mgb-5" v-if="!isMobile">Time limit</h2>
      <p class="text-smallest text-height-small text-spacing-xl txt-theme-txt-2 mgb-5">
        Time limits allow you to limit the time for your game play session. Once you set a
        limit, it cannot be revoked or increased before 24 hours have passed since the
        initial setting.
      </p>
      <NotificationComponent
        v-if="notificationData.isShown"
        :title="notificationData.title"
        :messageBody="notificationData.message"
        :bgClass="`${notificationData.bg} mgy-5`"
        :iconClass="`${notificationData.icon} bg-theme-txt-1 icon-40  mga-5`"
        :textColor="'txt-theme-txt-1'"
        @emitDismiss="notificationData.isShown = false"
      ></NotificationComponent>
      <div
        :class="
          !isMobile
            ? 'limit-grid mgb-20 limit-atimic-component'
            : 'limit-atimic-component'
        "
      >
        <div>
          <p class="capitalize pdb-3 mgt-5">Daily Time Limit</p>
          <div v-if="editing">
            <div class="mgx-10 mgy-3">
              <vueSlider
                v-model="myNumberEditing"
                color="#FFD500"
                track-color="#5b5b5b"
                :handleScale="2"
                :alwaysShowHandle="true"
                :height="8"
                :max="1440"
                :tooltip="true"
                :tooltipColor="'#FFD500'"
                :tooltipText="`${tooltip} (%v)`"
              />
              <div class="space-between flex">
                <p class="txt-theme-txt-1">0h</p>
                <p class="txt-theme-txt-1">24h</p>
              </div>
            </div>
            <InputValueComponent
              inputId="time-limit-input"
              placeholder="Daily Time Limit (min)"
              v-model="myNumberEditing"
              :inputType="'number'"
              :disableValidation="true"
            />
            <div class="flex mgy-3">
              <button
                id="login"
                class="btn-auth bg-btn-secondary txt-theme-txt-1 mgr-2"
                @click="cancel"
              >
                CANCEL
              </button>
              <button
                id="register"
                class="btn-auth bg-btn-primary txt-dark-primary mgl-2"
                @click="save"
                :disabled="!myNumberEditing"
              >
                SAVE
              </button>
            </div>
          </div>
          <template v-else>
            <div v-if="isSet">
              <div class="space-between flex mgy-2">
                <p class="txt-theme-txt-2">Limit</p>
                <p>{{ timeData.minutes }} minutes</p>
              </div>
              <div class="space-between flex mgy-2">
                <p class="txt-theme-txt-2">Period(from)</p>
                <p>{{ timeData.startsAt }}</p>
              </div>
              <div class="space-between flex mgy-2">
                <p class="txt-theme-txt-2">Period(to)</p>
                <p>{{ timeData.expiresAt }}</p>
              </div>
              <button
                id="edit"
                class="btn-auth bg-btn-secondary txt-theme-txt-1 mgr-2 mgy-2"
                @click="editing = true"
                :disabled="timeData.locked"
              >
                EDIT LIMIT
              </button>
            </div>
            <div v-else>
              <div class="space-between flex">
                <p class="txt-theme-txt-2">Limit</p>
                <p>Not Set</p>
              </div>
              <button
                id="edit"
                class="btn-auth bg-btn-secondary txt-theme-txt-1 mgr-2"
                @click="editing = true"
              >
                SET LIMIT
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
