<script setup lang="ts">
import IconComponent from "@/components/icons/IconComponent.vue";
import { ref, computed, watch, onMounted, watchEffect } from "vue";
import { validateInput } from "@/services/validation/inputValidation";

const props = defineProps({
  //style
  placeholder: String,
  isInlineInput: Boolean,
  inputType: String,
  inputId: String,
  disableTopMargin: Boolean,
  borderClass: String,
  textCutBorder: String,
  labelTextColor: {
    type: String,
    required: false,
    default: "light",
  },
  textColor: {
    type: String,
    required: false,
    default: "light",
  },
  //model
  modelValue: [String, Number],
  isDisabled: Boolean,
  isTouched: Boolean,
  //validation params
  maxLength: Number,
  minLength: Number,
  regexValidator: String,
  responseErrors: Array,
  hideResponseErrors: Boolean,
  testId: String,
  disableValidation: Boolean,
});
const myinput = ref(null);
const isTouched = ref(false);
const responseErrorsList = ref(props.responseErrors);
const hideErrors = ref(props.hideResponseErrors);
const underaged = ref(false);

const clearInput = async () => {
  if (!props.isDisabled) {
    emit("update:modelValue", "");
    emit("update:isTouched", false)
    isTouched.value = false;
  }
};

//DOUBLE BINDING + VALIDATION
//const initial = ref(false);
const emit = defineEmits(["isValid", "update:modelValue", "hideErrors", "update:isTouched"]);
const validation = computed(() =>
  validateInput(
    props.modelValue ? props.modelValue.toString() : "",
    props.minLength ? props.minLength : 0,
    props.maxLength ? props.maxLength : 255,
    props.regexValidator ? props.regexValidator : "",
    underaged.value ? underaged.value : false
  )
);
const inputHandler = async (value: string | number | undefined) => {
  emit("update:modelValue", value);
  if (value) {
    if (
      props.inputType == "number" &&
      !value.toString().match("^[0-9]*$") &&
      value.toString() != ""
    ) {
      let tempStr = value.toString();
      tempStr = tempStr.replace(/\D/g, "");
      await emit("update:modelValue", tempStr);
    }

    //this IF adds "-" automaticaly while user enters date of birth
    if (
      props.inputType == "date" &&
      (value.toString().length == 2 || value.toString().length == 5)
    ) {
      let tempStr = value.toString();
      tempStr += "-";
      await emit("update:modelValue", tempStr);
    }
    //this IF checks if user is underaged
    if (props.inputType == "date") {
      let dateParts = value.toString().split("-");

      let birthDate = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
      let today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      let m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      if (age < 18) {
        underaged.value = true;
      } else {
        underaged.value = false;
      }
    }
    isTouched.value = true;
  }
  emit("isValid", validation.value.isValid);
};

const inputEvent = async (event: any) => {
  emit("hideErrors", true);
  if (event) {
    await inputHandler(event.target.value);
  }
};

inputHandler(props.modelValue);

const propListener = () => {
  hideErrors.value = props.hideResponseErrors;
  
  if (props.modelValue != "") {
    emit("update:isTouched", true)
  } 
  if (props.responseErrors != null) {
    responseErrorsList.value = props.responseErrors;
  }
  isTouched.value = props.isTouched;
  emit("isValid", validation.value.isValid);
};
watch(
  props,
  (value) => {
    propListener();
  },
  { deep: true }
);
//DOUBLE BINDING + VALIDATION
</script>

<template>
  <div>
    <div :class="disableTopMargin ? 'mgt-0' : 'mgt-4'">
      <label class="w-100 block relative mgt-0 bg-5-1 radius-12">
        <input
          ref="myinput"
          :id="inputId"
          :data-testid="testId ? `input-${testId}` : null"
          v-on:keyup.esc="clearInput"
          @click="isTouched = true"
          :type="'text'"
          class="w-100 pdl-2rem shared-input input-value"
          :class="[
            !isInlineInput
              ? `${borderClass ?? 'border-input radius-10'} `
              : `${borderClass ?? ''} `,
            isDisabled ? 'txt-light cursor-disabled' : `txt-${props.textColor} `,
          ]"
          :value="modelValue"
          @input="inputEvent($event)"
          :disabled="isDisabled"
        />
        <span
          :class="
            isTouched
              ? ['floating-label-touched absolute txt-light', props.textCutBorder]
              : `floating-label txt-${props.labelTextColor} absolute text-smallest`
          "
          :data-testid="testId ? `placeholder-${testId}` : null"
          >{{ placeholder }}</span
        >
        <button
          :data-testid="testId ? `clearInput-${testId}` : null"
          tabindex="-1"
          type="button"
          class="input-right-btn h-100"
          title="Clear field"
          @click="clearInput"
        >
          <IconComponent
            :iconClasses="'icon-10 inline-block icon-form-clear bg-400'"
          ></IconComponent>
        </button>
      </label>
    </div>
    <div
      v-if="!validation.isValid && isTouched && !disableValidation"
      class="txt-error-1 pdl-4"
      :data-testid="testId ? `validation-${testId}` : null"
    >
      {{ validation.message }}
    </div>
    <div v-if="responseErrorsList && !hideErrors">
      <div class="txt-error-1 my-1 pdl-4" v-for="(error, i) in responseErrorsList" :key="i">
        {{ error }}
      </div>
    </div>
  </div>
</template>
