import type { DepositInitiator } from "@/services/payment/depositAbstract/handleDeposit";
import type { DepositPayload } from "@/services/payment/depositAbstract/deposit";
import axios from "axios";
import { findUrlInString } from "@/services/payment/payment";

export class DirectInitiator implements DepositInitiator {
  public async initiateDeposit(data: DepositPayload) {
    try {
      const response = await axios.post("/payment/initiate", data);
      const url = response.data.data.redirectionData.url;
      return { link: url, isError: false, responseMessage: "success" };
    } catch (error) {
      return {
        link: "",
        isError: true,
        responseMessage: "Something went wrong, please try again.",
      };
    }
  }
}

export class SkrillMidStepInitiator implements DepositInitiator {
  public async initiateDeposit(data: DepositPayload) {
    try {
      const response1 = await axios.post("/payment/initiate", data);
      const response2 = await axios.post(
        response1.data.data.redirectionData.url,
        response1.data.data.redirectionData.parameters
      );
      const url = await findUrlInString(response2.data);
      return { link: url, isError: false, responseMessage: "success" };
    } catch (error) {
      return {
        link: "",
        isError: true,
        responseMessage: "Something went wrong, please try again.",
      };
    }
  }
}

export class NotSupported implements DepositInitiator {
  public async initiateDeposit(data: DepositPayload) {
    return {
      link: "",
      isError: true,
      responseMessage: "Payment not supported.",
    };
  }
}
