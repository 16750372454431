export const brandMapper = {
    Holahola: {
      theme: "holahola_main",
      folder: "holahola",
      chatId: null
    },
    "B-iGaming": {
      theme: "templateDark",
      folder: "igaming",
      chatId: null
    },
    "Betting-High": {
      theme: "templateCosmic",
      folder: "bettinghigh",
      chatId: null
    },
    Bit1111: {
      theme: "bit1111_theme",
      folder: "bit1111",
      chatId: "880609d6-fd03-4695-b81c-ad54abf148b6"
    },
  };