<script setup lang="ts">
import DividerComponent from "../shared/divider/DividerComponent.vue";
import CheckBoxComponent from "../shared/forms/checkbox/CheckBoxComponent.vue";
import { ref } from "vue";
import { useAuthStore } from "../../stores/auth";
import { useCookies } from "vue3-cookies";
import {
  formatDate,
  formatTime,
  milisToTime,
} from "../../services/formatting/dateFormatting";

const sessionStart = "SESSION_START";

const REALITY_TIME_ALLOWED = 30;
const intervalId = ref();
const realityTimer = ref(REALITY_TIME_ALLOWED);

const { cookies } = useCookies();
const isChecked = ref(false);
const emit = defineEmits(["closeRC", "logout"]);
const authStore = useAuthStore();

const sessionStartTime = ref(new Date(JSON.parse(cookies.get(sessionStart))));
const sessionStartDateFormatted = ref("");
const sessionStartTimeFormatted = ref("");
const realityPopTime = ref(new Date());

const playingTime = ref("");

const init = () => {
  sessionStartDateFormatted.value = formatDate(sessionStartTime.value);
  sessionStartTimeFormatted.value = formatTime(sessionStartTime.value);
  playingTime.value = milisToTime(sessionStartTime.value, realityPopTime.value);
  intervalId.value = setInterval(() => {
    realityTimer.value = realityTimer.value - 1;
    if (realityTimer.value <= 0) logout();
  }, 1000 * 1);
};

const closeRealityCheck = () => {
  clearInterval(intervalId.value);
  emit("closeRC", true);
};

const logout = () => {
  clearInterval(intervalId.value);
  emit("logout", true);
};

init();
</script>

<template>
  <div class="rc-backdrop">
    <div class="bg-theme-bg-2 pda-4 form-size rc-wrapper txt-theme-txt-1">
      <div class="rc txt-bold txt-center pdx-2">
        <h3 class="mga-5">{{ "Reality Check" }}</h3>
        <p class="rc-details txt-center mgb-5 txt-theme-txt-2">
          Please find the details about your current session below
        </p>
        <DividerComponent
          :dividerClass="'w-95 mgx-auto mgt-2 bg-dark400'"
        ></DividerComponent>
        <div class="session flex mgy-4 pdx-3">
          <p>Session start</p>
          <div class="timestamp flex">
            <div class="format-date txt-right">{{ sessionStartDateFormatted }}</div>
            <div class="login-time">{{ sessionStartTimeFormatted }}</div>
          </div>
        </div>
        <DividerComponent
          :dividerClass="'w-95 mgx-auto mgt-2 bg-dark400'"
        ></DividerComponent>
        <div class="time flex mgy-4 pdx-3">
          <p>Time playing</p>
          <p>{{ playingTime }}</p>
        </div>
        <DividerComponent
          :dividerClass="'w-95 mgx-auto mgt-2 bg-dark400'"
        ></DividerComponent>
        <div class="loss flex mgy-4 pdx-3">
          <p>Loss during this time frame</p>
          <p>$0.00</p>
        </div>
        <DividerComponent
          :dividerClass="'w-95 mgx-auto mgt-2 bg-dark400'"
        ></DividerComponent>
        <div class="win flex mgy-4 pdx-3">
          <p>Winnings during this time frame</p>
          <p>$0.00</p>
        </div>
        <DividerComponent
          :dividerClass="'w-95 mgx-auto mgt-2 bg-dark400'"
        ></DividerComponent>
        <button class="block btn-gray btn-thin-large mgb-6 mgt-5 mgx-auto rc-btn">
          Transaction History
        </button>
        <div class="mg-center inline-block">
          <CheckBoxComponent
            :isChecked="isChecked"
            :checkboxTitle="'I acknowledge and confirm'"
            @onCheckboxChange="isChecked = $event"
          />
        </div>

        <div class="rc-cta-buttons flex flex-center mgt-3">
          <button class="btn btn-account btn-gray inline-block mgr-2" @click="logout">
            End Session
          </button>
          <button
            class="btn btn-account btn-brand inline-block"
            :disabled="!isChecked"
            @click="closeRealityCheck"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
