<script setup lang="ts">
import InputValueComponent from "@/components/shared/forms/input/InputValueComponent.vue";
import RadioInputGroup from "@/components/shared/forms/radio/RadioInputGroup.vue";
import RadioInputComponent from "@/components/shared/forms/radio/RadioInputComponent.vue";
import { genders } from "@/services/data/genders";
import type { GenderInterface } from "@/services/data/genders";
import DropDownComponent from "@/components/shared/forms/dropdown/DropDownComponent.vue";
import { ref } from "vue";
import DividerComponent from "@/components/shared/divider/DividerComponent.vue";
import { registerStepTwo } from "@/services/auth/registration";
import { useLoaderStore } from "@/stores/loader";
import { RouterSingleton } from "@/router/index";
import { useAuthStore } from "@/stores/auth";
import { ErrorHandler } from "@/lib/exceptions/ErrorHandler";
import type { FormResponseValidation } from "@/services/interface/registrationInterface";
import type { LooseObject } from "@/services/interface/utils";
import InputPhoneComponent from "@/components/shared/forms/input/InputPhoneComponent.vue";

const loader = useLoaderStore();
const authStore = useAuthStore();

const genderSelected = ref<GenderInterface>(genders[0]);
const selectedCountry = ref();
const firstName = ref();
const lastName = ref();
const dateOfBirth = ref();
const city = ref();
const address = ref();
const zipCode = ref();
const phoneNumber = ref();
const phoneCode = ref("+1");
const hideResponseErrors = ref(true);

// VALIDATION START
const formResponseValidation = ref<LooseObject>({
  firstName: [],
  lastName: [],
  dateOfBirth: [],
  city: [],
  address: [],
  zipCode: [],
  phone: [],
});

const newValidationList = ref({
  country: Boolean,
  firstName: Boolean,
  lastName: Boolean,
  dateOfBirth: Boolean,
  city: Boolean,
  address: Boolean,
  zipCode: Boolean,
  phoneNumber: Boolean,
});
type ValidationKey = keyof typeof newValidationList.value;

const formisValid = ref(false);
const callbackValid = (pointer: ValidationKey, value: BooleanConstructor) => {
  newValidationList.value[pointer] = value;
  computeFormValidation();
};
const computeFormValidation = () => {
  let flag = true;
  let key: ValidationKey;
  for (key in newValidationList.value) {
    if (!newValidationList.value[key]) flag = false;
  }
  formisValid.value = flag;
};
// VALIDATION END


const selectGender = (gender: GenderInterface) => {
  genderSelected.value = gender;
};

const submit = async () => {
  const data = {
    address: address.value,
    city: city.value,
    country: selectedCountry.value.id,
    dateOfBirth: dateOfBirth.value,
    firstName: firstName.value,
    lastName: lastName.value,
    gender: genderSelected.value.value,
    zipCode: zipCode.value,
    phone: phoneNumber.value,
    phoneCode: phoneCode.value,
  };
  loader.toggleLoader(true);
  registerStepTwo(data)
    .then(async () => {
      hideResponseErrors.value = true;
      await authStore.setPlayerData();
      RouterSingleton.getInstance().getRouter().push("/");
      loader.toggleLoader(false);
    })
    .catch((error) => {
      loader.toggleLoader(false);
      hideResponseErrors.value = false;
      for (const key of Object.keys(formResponseValidation.value)) {
        formResponseValidation.value[key] = [];
      }
      const handler = new ErrorHandler();
      const err = handler.handle(error);
      err.errors?.forEach((element) => {
        formResponseValidation.value[
          element.property as keyof FormResponseValidation
        ].push(element.message);
      });
    });
};

const logoutUser = () => {
  console.log("logout");
};
</script>

<template>
  <div>
  <h1 class="txt-theme-txt-1 txt-center mgt-3">Complete Profile</h1>
  <form class="complete-registration-form pdx-4 form-size" @submit.prevent>
    <InputValueComponent
      :responseErrors="formResponseValidation.firstName"
      inputId="name"
      placeholder="First Name"
      regexValidator="firstName"
      v-model="firstName"
      :minLength="2"
      :maxLength="30"
      :hideResponseErrors="hideResponseErrors"
        @hideErrors="hideResponseErrors = $event"
      @isValid="callbackValid('firstName', $event)"
    ></InputValueComponent>
    <InputValueComponent
      :responseErrors="formResponseValidation.lastName"
      inputId="lastName"
      placeholder="Last Name"
      regexValidator="lastName"
      v-model="lastName"
      :minLength="2"
      :maxLength="30"
      :hideResponseErrors="hideResponseErrors"
        @hideErrors="hideResponseErrors = $event"
      @isValid="callbackValid('lastName', $event)"
    ></InputValueComponent>
    <InputValueComponent
      :responseErrors="formResponseValidation.dateOfBirth"
      inputId="date"
      placeholder="Date of Birth (dd-mm-yyyy)"
      :inputType="'date'"
      regexValidator="date"
      v-model="dateOfBirth"
      :hideResponseErrors="hideResponseErrors"
        @hideErrors="hideResponseErrors = $event"
      @isValid="callbackValid('dateOfBirth', $event)"
    ></InputValueComponent>
    <RadioInputGroup :class="'border-input radius-5 mgt-4 w-100 hidden-overflow '">
      <RadioInputComponent
        @click="selectGender(gender)"
        v-for="(gender, index) in genders"
        :inputId="gender.labelText"
        v-bind:key="index"
        :wrapperClass="gender.wrapperClass + ' w-50'"
        :labelText="gender.labelText"
        :labelIcon="
          gender.value == genderSelected.value
            ? `bg-theme-bg-1 ${gender.labelIcon}`
            : `bg-theme-brand-1 ${gender.labelIcon}`
        "
        :classLabel="
          gender.value == genderSelected.value
            ? `bg-brand ${gender.class}`
            : `no-border ${gender.class}`
        "
      >
      </RadioInputComponent>
    </RadioInputGroup>
    <DropDownComponent
      dropdownId="country"
      :label="'Country'"
      :url="'/geo-resources/countries'"
      :selected="selectedCountry"
      :selector="'name'"
      @emitSelected="selectedCountry = $event"
      @isValid="callbackValid('country', $event)"
    >
    </DropDownComponent>
    <InputValueComponent
      :responseErrors="formResponseValidation.city"
      inputId="city"
      placeholder="City"
      regexValidator="city"
      v-model="city"
      :minLength="1"
      :maxLength="200"
      :hideResponseErrors="hideResponseErrors"
        @hideErrors="hideResponseErrors = $event"
      @isValid="callbackValid('city', $event)"
    ></InputValueComponent>
    <InputValueComponent
      :responseErrors="formResponseValidation.address"
      inputId="adress"
      placeholder="Address"
      regexValidator="address"
      v-model="address"
      :minLength="2"
      :maxLength="40"
      :hideResponseErrors="hideResponseErrors"
        @hideErrors="hideResponseErrors = $event"
      @isValid="callbackValid('address', $event)"
    ></InputValueComponent>
    <InputValueComponent
      :responseErrors="formResponseValidation.zipCode"
      inputId="zip"
      placeholder="Zip Code"
      regexValidator="zipCode"
      v-model="zipCode"
      :minLength="2"
      :maxLength="10"
      :hideResponseErrors="hideResponseErrors"
        @hideErrors="hideResponseErrors = $event"
      @isValid="callbackValid('zipCode', $event)"
    ></InputValueComponent>
    <div class="mgt-4">
      <InputPhoneComponent
        :responseErrors="formResponseValidation.phone"
        :selectedCode="phoneCode"
        :selectedPhoneNumber="Number(phoneNumber)"
        codeRegexValidator="phoneCode"
        phoneRegexValidator="phone"
        :codeMinLength="2"
        :codeMaxLength="10"
        :phoneMinLength="5"
        :phoneMaxLength="15"
        @changePhoneCode="phoneCode = $event"
        @changePhoneNumber="phoneNumber = $event"
        @isValid="callbackValid('phoneNumber', $event)"
      />
    </div>
    <div class="registration-cta">
      <button
        id="submit"
        class="btn-success mgy-4 mg-center btn-large"
        :disabled="!formisValid"
        @click="submit"
      >
        let the fun begin!
      </button>
    </div>
  </form>
  <DividerComponent
    :dividerClass="'w-90 mgx-auto mgb-6 mgt-6 bg-dark400'"
  ></DividerComponent>
  <div class="mgb-6">
    <button
      id="logout"
      class="btn-dark700 mgy-4 mg-center btn-large"
      @click="logoutUser()"
    >
      logout
    </button>
  </div>
  <p class="mgb-6 txt-center">
    Do you need assistance?
    <a :routerLink="'/support/faq'" id="faq" class="txt-theme-brand-1">Click here</a> for
    support
  </p>
</div>
</template>
