<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { desktopHeaderLinks } from "@/brands/bit1111/data/navigation/desktopNavigation";
import { goToRoute } from "@/router/routingService";
import { useAuthStore } from "@/stores/auth";
import { useRoute } from "vue-router";

const props = defineProps({
  navigationFlag: Boolean,
});
const route = useRoute();
const authStore = useAuthStore();
const mobileMenu = ref(true);
const displayMenu = ref(false);

const emit = defineEmits(["toggleMenu"]);

const onMenuClick = () => {
  if (mobileMenu.value) {
    displayMenu.value = !displayMenu.value;
    window.scrollTo(0, 0);
  }
  emit("toggleMenu");
};
const goToLink = (link: string) => {
  // onMenuClick();
  goToRoute(link);
};

const menuDisplay = () => {
  if (window.innerWidth < 768) {
    mobileMenu.value = true;
    displayMenu.value = false;
  } else {
    mobileMenu.value = false;
    displayMenu.value = true;
  }
};

const isMounted = ref(false);
onMounted(() => {
  window.addEventListener("resize", menuDisplay);
  menuDisplay();
  isMounted.value = true;
});

watch(
  props,
  () => {
    displayMenu.value = props.navigationFlag;
  },
  { deep: true }
);
</script>

<template>
  <div class="menu-wrapper zindex-menu w-100 maxw-700px pda-0 mgl-2">
    <div v-if="mobileMenu">
      <button
        type="button"
        class="menu block bg-transparent pda-2 mobile-menu-toggle"
        :class="mobileMenu && displayMenu ? 'menu-active' : ''"
        @click="onMenuClick()"
      >
        <div
          :class="!displayMenu ? 'line  bg-light' : 'line  bg-light transformMenu'"
          v-for="line in [1, 2, 3]"
          v-bind:key="line"
        ></div>
      </button>
    </div>

    <nav class="" v-if="!mobileMenu && displayMenu">
      <ul class="bit-links pdr-10">
        <li v-for="(link, i) in desktopHeaderLinks" class="" v-bind:key="i">
          <div
            v-if="!link.requiresAuth || (link.requiresAuth && authStore.$state.player)"
          >
            <RouterLink :to="link.link">
              <p
                :class="route.fullPath == link.link ? 'txt-1-1' : 'txt-light'"
                class="mgx-5 txt-size-16 hover-txt-1-1"
                :id="`${link.group}-group`"
              >
                {{ link.group }}
              </p>
            </RouterLink>
          </div>
          <ul></ul>
        </li>
      </ul>
    </nav>
  </div>
</template>
