<script setup lang="ts">
import IconComponent from "../../icons/IconComponent.vue";
import { useRoute } from "vue-router";
import { computed } from "vue";
// import router from "@/router";'
import { RouterSingleton } from "@/router/index";

const route = useRoute();
const pathName = computed(() => (route.name ? route.name.toString() : ""));
const goBack = () => {
  RouterSingleton.getInstance().getRouter().go(-1);
};
</script>

<template>
  <div @click="goBack" class="w-100 bg-theme-bg-4 pdy-3 flex cursor-pointer">
    <div class="flex flex-center scale-1-5 pdl-4">
      <IconComponent
        :iconClasses="`icon-18 ico-size inline-block bg-theme-txt-2 icon-back`"
      ></IconComponent>
    </div>
    <p class="txt-theme-txt-2 txt-bold title-small pdl-4 capitalize">{{ pathName }}</p>
  </div>
</template>
