<script setup>
import { ref } from "vue";
import placeholderImg from "@/assets/icons/img-placeholder.svg";
import { isMobileCheck } from "@/services/interface/clientInformation";
import { translate } from "@/services/translations/translation";

const props = defineProps({
  page: {},
  i: {
    type: Number
  }
});

const htmlContent = [
  {
    content: `<p class="txt-theme-txt-4">${translate(`onboarding.desktop.content_part_one_${props.i}`)}</p> <br> 
        <p class="txt-theme-txt-4">${translate(`onboarding.desktop.content_part_two_${props.i}`)}</p>`
  },
  {
    content: `<p class="txt-theme-txt-4">${translate(`onboarding.desktop.content_part_one_${props.i}`)}</p> <br>
        <p class="txt-theme-txt-4">${translate(`onboarding.desktop.content_part_two_${props.i}`)}<span class="txt-bold txt-theme-txt-2">${translate(`onboarding.desktop.content_part_three_${props.i}`)}</span>${translate(`onboarding.desktop.content_part_four_${props.i}`)}<span class="txt-theme-txt-2 txt-bold">${translate(`onboarding.desktop.content_part_five_${props.i}`)}</span>${translate(`onboarding.desktop.content_part_six_${props.i}`)}</p>`,
  },
  {
    content: `<p class="txt-theme-txt-4">${translate(`onboarding.desktop.content_part_one_${props.i}`)}<span class="txt-bold txt-theme-txt-2" >${translate(`onboarding.desktop.content_part_two_${props.i}`)}</span>,</p> <br>
        <p>${translate(`onboarding.desktop.content_part_three_${props.i}`)}</p>`,
  },
  {
    content: `<p class="txt-theme-txt-4">${translate(`onboarding.desktop.content_part_one_${props.i}`)}<span class="txt-bold txt-theme-txt-2">${translate(`onboarding.desktop.content_part_two_${props.i}`)}</span>${translate(`onboarding.desktop.content_part_three_${props.i}`)}<span class="txt-bold txt-theme-txt-2">${translate(`onboarding.desktop.content_part_four_${props.i}`)}</span>${translate(`onboarding.desktop.content_part_five_${props.i}`)}</p> <br>
        <p class="txt-theme-txt-4">${translate(`onboarding.desktop.content_part_six_${props.i}`)}</p>`,
  },
  {
    content: `<p class="txt-theme-txt-4">${translate(`onboarding.desktop.content_part_one_${props.i}`)}</p> `,
  },
]

const emit = defineEmits(["redirect"]);
const isMobile = ref(isMobileCheck());
const goToProtectionSettings = async (link) => {
  emit("redirect", link);
};
</script>

<template>
  <div :class="isMobile ? 'hola-boarding-page-wrapper-mobile' : 'hola-boarding-page-wrapper'" class="flex txt-center" v-if="page">
    <figure class="alt-image">
      <div v-if="page.image">
        <img
          :src="page.image"
          :alt="page.imageAlt"
          class="block fallback-img"
          loading="lazy"
        />
      </div>

      <div v-else>
        <img
          :src="placeholderImg"
          alt="Placeholder image"
          class="block fallback-img"
          loading="lazy"
        />
      </div>
    </figure>
    <h2 class="title-huge mgy-3 txt-theme-txt-1 text-bold">
      {{ translate(`onboarding.desktop.title_${i}`).toUpperCase() }}
    </h2>
    <span class="txt-theme-txt-2" v-html="htmlContent[props.i].content"></span>

    <button
      v-if="page.linkTitle"
      @click="goToProtectionSettings(page.linkRoute)"
      class="btn btn-60 btn-brand mgt-6"
    >
      {{ translate(`onboarding.desktop.button_text`) }}
    </button>
  </div>
</template>
