<script setup lang="ts">
import IconComponent from "@/components/icons/IconComponent.vue";
import { bottomLinks, helpLinks } from "@/services/data/navList";
import { ref } from "vue";
import { goToRoute } from "@/router/routingService";
import { useAuthStore } from "@/stores/auth";
import { isMobileCheck } from "@/services/interface/clientInformation";
import { translate } from "@/services/translations/translation";
import ChatComponentVue from "../homepage/ChatComponent.vue";

const emit = defineEmits(["toggleMenu"]);

const authStore = useAuthStore();
const isMobile = ref(isMobileCheck());
const chatIsVisible = ref(false);

const goTo = (route: string) => {
  emit("toggleMenu");
  goToRoute(route);
};

const calcMobile = () => {
  isMobile.value = isMobileCheck();
};

window.addEventListener("resize", calcMobile);
</script>

<template>
  <!-- <ChatComponentVue v-if="chatIsVisible" :isMobile="true" @close="chatIsVisible = false" /> -->
  <div
    class="fixed bottom-0 left-0 w-100 bg-dark h-64px flex flex-center zindex-header pdx-6 shdw"
    v-if="isMobile"
  >
    <!-- <div class="w-20 flex flex-left-center" @click="chatIsVisible = !chatIsVisible">
    <IconComponent
      :iconClasses="`icon-40 pdr-6 inline-block bg-1-1 icon-chat`" />
  </div> -->
    <div class="fit-content mgx-auto flex flex-center" v-if="!authStore?.$state?.player">
      <button
        class="w-100px h-40px txt-bold to-upper-case text-small txt-1-1 mgr-6 radius-10"
        @click="goTo('/login')"
      >
        {{ translate("common.login") }}
      </button>
      <button
        class="w-100px h-40px bg-1-1 txt-light txt-bold to-upper-case text-small radius-10"
        @click="goTo('/register')"
      >
        {{ translate("common.register") }}
      </button>
    </div>
  </div>
</template>
<style lang="css">
.shdw {
  box-shadow: 0px 0px 4px #a92779;
}
</style>
