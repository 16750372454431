import { defineStore } from "pinia";
import type { BrandData, BrandModules } from "./contracts/BrandData";

export const useBrandStore = defineStore({
  id: "brand",
  state: () => ({
    template: <string>"",
    structure: <any>"",
    logo: <string>"",
    name: <string>"",
    folder: <string>"",
    modules: <BrandModules>{},
  }),
  getters: {
    getTemplate: (state) => state.template,
    getLogo: (state) =>state.logo,//'https://i.ibb.co/kcm0019/holahola-logo-13.png',// 
    getTemplateClass: (state) => state.template,
    getName: (state) => state.name,
    getFolder: (state) => state.folder,
  },
  actions: {
    dataInitialization(brandData: BrandData) {
      this.template = brandData.brandSettings.template;
      this.structure = brandData.brandSettings.structure;
      this.folder = brandData.brandSettings.folder;
      this.logo = brandData.brandSettings?.logo?.url;
      this.name = brandData.name;
      this.modules = brandData.modules;
    },
    getModule(moduleName: string) {
      const moduleValue = this.modules[moduleName as keyof typeof this.modules];
      return moduleValue
    }
  },
});
