<script setup lang="ts">
import IconComponent from "../../components/icons/IconComponent.vue";

const title = "Unavailable";
const textBold = `We're sorry, this website is not available in your country`;
const firstParagraph =
  "B-Bets is required to comply with MGA licence rules and regulations that restrict the use of our site to certain jurisdictions. Because of this, our services are not available in your country.";
const secondParagraph = `For further assistance please contact our `;
const textSupport = "Support.";
</script>

<template>
  <div class="errorpages-wrapper row flex flex-center txt-theme-brand-1">
    <div class="col-4 col-size relative box txt-center bg-unavailable">
      <IconComponent
        :iconClasses="'icon-24 inline-block bg-theme-brand-1 tr-icon mgb-7'"
        :svgImage="'src/assets/icons/ico-block.svg'"
      ></IconComponent>
      <h1 class="spacing-xl txt-theme-brand-1">{{ title }}</h1>
      <p class="txt-bold txt-theme-txt-1 title-smallest">{{ textBold }}</p>
      <p class="txt-theme-txt-1 title-smallest">{{ firstParagraph }}</p>
      <p style="padding: 0 5rem" class="txt-theme-txt-1 title-smallest">
        {{ secondParagraph
        }}<a class="txt-theme-brand-1" style="text-decoration: none" href="/support">{{
          textSupport
        }}</a>
      </p>
    </div>
  </div>
</template>
