<script setup lang="ts">
import { useBrandStore } from "@/stores/brand";
import { useRoute } from "vue-router";
import { ref, watch, computed, onMounted, markRaw } from "vue";
import { useAuthStore } from "@/stores/auth";
import LoaderVue from "@/components/shared/loader/LoaderComponent.vue";
import { useLoaderStore } from "@/stores/loader";
import BoardingWrapperComponent from "@/components/boarding/BoardingWrapperComponent.vue";
import eventRegister from "@/lib/mercury/eventRegister";
import BonusNotification from "@/components/notification/BonusNotificationComponent.vue";
// import { useBonusNotificationStore } from "@/stores/bonusNotification";
import type { PropType } from "vue";
import type { LayoutPropsInterface } from "@/services/interface/structure";
import LoginModal from "@/components/navigation/LoginModal.vue";
import RegistrationModal from "@/components/navigation/RegistrationModal.vue";
import RegisterConfirmOneModal from "@/components/navigation/RegisterConfirmOneModal.vue";
import RegisterConfirmTwoModal from "@/components/navigation/RegisterConfirmTwoModal.vue";
import { useLayoutModalsStore } from "@/stores/layoutModals";
import type { RegisterInitialPayloadInterface } from "@/services/interface/registrationInterface";
import BonusModal from "@/components/bonus/BonusModal.vue";
import ForgotPasswordModal from "@/brands/holahola/views/desktop/auth/ForgotPasswordModal.vue";

import Header from "@/components/navigation/header/HeaderDesktopComponent.vue";
import HeaderComponentBit1111 from "@/brands/bit1111/components/navigation/header/HeaderComponent.vue";
import BottomNav from "@/brands/holahola/components/navigation/BottomNavigationComponent.vue";
import FooterBit111 from "@/brands/bit1111/components/navigation/footer/FooterDesktopComponent.vue";
import Footer from "@/brands/holahola/components/navigation/footer/FooterDesktopComponent.vue";
import Boarding from "@/brands/holahola/components/boarding/HolaBoardingWrapperComponent.vue";
import Navigation from "@/components/navigation/header/DesktopNavigation.vue";
import DepositModal from "@/brands/holahola/components/deposit/DepositModal.vue";
const emit = defineEmits(["finishedLoading"]);

const layoutModalsStore = useLayoutModalsStore();

onMounted(async () => {
  if (player !== null && player.role == "ROLE_PLAYER") {
    eventRegister.authorizationHook();
  }
  eventRegister.nonAuthorizationHook();
});

const loader = useLoaderStore();
const brandStore = useBrandStore();
const authStore = useAuthStore();
const route = useRoute();
const routeAuth = ref(false);

const player = authStore.$state.player;
// const bonusNotificationStore = useBonusNotificationStore();
const navigationToggle = ref(false);

// const notifFlag = computed(() => {
//   return bonusNotificationStore.getFlag();
// });

const finishedLoading = () => {
  emit("finishedLoading");
};

watch(
  route,
  () => {
    routeAuth.value = ["login", "register"].includes(
      typeof route.name == "string" ? route.name : ""
    );
    window.scrollTo(0, 0);
  },
  { deep: true }
);

const boarding = computed(() => {
  return layoutModalsStore.isModalActive("boarding");
});
const depositModal = computed(() => {
  return layoutModalsStore.isModalActive("depositModal");
});

const loginModal = computed(() => {
  return layoutModalsStore.isModalActive("login");
});
const registrationModal = computed(() => {
  return layoutModalsStore.isModalActive("register");
});

const registrationConfirmModalData = computed(() => {
  return layoutModalsStore.getModal("registerConfirm");
});
const registrationConfirmTwoModalData = computed(() => {
  return layoutModalsStore.getModal("registerConfirmLast");
});
const emailPasswordResetModal = computed(() => {
  return layoutModalsStore.isModalActive("emailPasswordReset");
});

const registrationConfirmStepOneUserData = ref<RegisterInitialPayloadInterface>({
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  gender: "",
});
</script>

<template>
  <div id="layout-body" class="main">
    <LoaderVue v-if="loader.getIsLoading"></LoaderVue>

    <DepositModal v-if="depositModal" />

    <!-- <BonusNotification v-if="notifFlag" :amount="bonusNotificationStore.getAmount()" /> -->
    <LoginModal v-if="loginModal" @close="loginModal = false" />
    <RegistrationModal v-if="registrationModal" @close="registrationModal = false" />
    <ForgotPasswordModal
      v-if="emailPasswordResetModal"
      @close="emailPasswordResetModal = false"
    />
    <RegisterConfirmOneModal
      v-if="registrationConfirmModalData.active"
      @close="registrationConfirmModalData.active = false"
      @emitData="registrationConfirmStepOneUserData = $event"
    />
    <RegisterConfirmTwoModal
      v-if="registrationConfirmTwoModalData.active"
      @close="registrationConfirmTwoModalData.active = false"
      :stepOneData="registrationConfirmStepOneUserData"
    />
    <BonusModal />
    <Boarding v-if="boarding" />
    <Header
      @toggleNav="navigationToggle = !navigationToggle"
      :navigationFlag="navigationToggle"
      @finishedLoading="finishedLoading"
      @triggerLogin="loginModal = !loginModal"
    />

    <div class="c-body">
      <Navigation
        v-show="navigationToggle"
        @toggleMenu="navigationToggle = !navigationToggle"
      />
      <RouterView />
    </div>
    <BottomNav />
    <Footer />
  </div>
</template>
