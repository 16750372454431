import { defineStore } from "pinia";

export const useQuickRegistration = defineStore({
  id: "quickRegistration",
  state: () => ({
    data: {
        username: "",
        email: "",
        password:""
    }
  }),
  getters: {
    getData: (state) => state.data,
  },
  actions: {
    setData(data: any) {
      this.data = data;
    },
  },
});
