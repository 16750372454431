<script setup lang="ts">
import IconComponent from "@/components/icons/IconComponent.vue";
import { goToRoute } from "@/router/routingService";
import { ref, watch } from "vue";
import { translate } from "@/services/translations/translation";
import FormWarningComponent from "@/components/shared/forms/FormWarningComponent.vue";

const props = defineProps({
  isTouched: Boolean
})
const emit = defineEmits(["isValid", "update:isTouched"]);
const checked = ref<any>({
  checkedTerms: false,
  checkedConfirmAge: false,
});
const validate = ref(false);
emit("isValid", null);
const onCheckbox = (checkValue: string) => {
  checked[checkValue] = !checked[checkValue];
  emit("update:isTouched", true)
  for (const val in checked.value) if (!checked[val]) return (validate.value = false);

  return (validate.value = true);
};

watch(
  validate,
  (value) => {
    emit("isValid", value);
  },
  { deep: true }
);
</script>

<template>
  <div class="text-small-description">
    <div class="checkbox-wrapper card-form-checkbox relative mgy-4">
      <label class="inline-block">
        <input
          type="checkbox"
          @change="onCheckbox('checkedTerms')"
          class="block"
          v-model="checked.checkedTerms"
          :value="checked.checkedTerms"
        />
        <span class="checkmark flex flex-center bg-1-4 border-2-1 border-width-1" tabindex="0">
          <IconComponent
            v-if="checked.checkedTerms"
            :iconClasses="'icon-12 inline-block icon-checkbox bg-1-1'"
          ></IconComponent>
        </span>
        <span class="w-85"
          >{{ translate("auth.register_init.components.term1_a") }}
          <a
            href="https://holahola.condor-business.com/"
            class="txt-theme-brand-2"
            target="_blank"
            >{{ translate("auth.register_init.components.term1_b") }}</a
          >
          {{ translate("auth.register_init.components.term1_c") }}
          <a id="terms" target="_blank" class="txt-theme-brand-2" href="/terms">{{
            translate("auth.register_init.components.termsAndConditions")
          }}</a>
        </span>
      </label>
    </div>

    <div class="checkbox-wrapper card-form-checkbox relative mgy-4">
      <label class="inline-block">
        <input
          type="checkbox"
          @change="onCheckbox('checkedConfirmAge')"
          class="block"
          v-model="checked.checkedConfirmAge"
          :value="checked.checkedConfirmAge"
        />
        <span class="checkmark flex flex-center bg-1-4 border-2-1 border-width-1" tabindex="0">
          <IconComponent
            v-if="checked.checkedConfirmAge"
            :iconClasses="'icon-12 inline-block icon-checkbox bg-1-1'"
          ></IconComponent>
        </span>
        <span class="w-85">{{ translate("auth.register_init.components.term2") }}</span>
      </label>
    </div>
    <div class="mgl-4">
    <FormWarningComponent
          v-if="!validate && isTouched"
          :text="translate('globalNotificationMessages.required_checkboxes')"
        />
  </div>
  </div>
</template>
