import { RouterSingleton } from "@/router/index";
import { useAuthStore } from "@/stores/auth";
import Unauthorized from "@/views/errors/403.vue";
export function goToRoute(url: string) {
  RouterSingleton.getInstance().getRouter().push(url);
}

const generateLayer = (
  realRoutes: Array<any>,
  themeRoutes: Array<any>,
  realPath: Array<number>,
  themePath: Array<number>
) => {
  const authStore = useAuthStore();
  let isMobile = true;
  if (window.innerWidth > 768) isMobile = false;

  let realRouteLayer = realRoutes;
  let themeRouteLayer = themeRoutes;

  if (realPath.length > 0) {
    realPath.forEach((selector) => {
      realRouteLayer = realRouteLayer[selector].children;
    });
  }

  if (themePath.length > 0) {
    themePath.forEach((selector) => {
      themeRouteLayer = themeRouteLayer[selector].children;
    });
  }

  realRouteLayer.forEach((element, realIndex) => {
    element.component = isMobile
      ? element.mobileComponent
      : element.desktopComponent;

    let themeRoute: any = null;
    let themeIndex = null;
    //find theme route
    themeRouteLayer.forEach((themeElement, i) => {
      if (themeElement.name == element.name) {
        themeRoute = themeElement;
        themeIndex = i;
      }
    });

    if (themeRoute) {
      //swap components
      if (isMobile) {
        element.component = themeRoute.mobileComponent
          ? themeRoute.mobileComponent
          : element.component;
      } else {
        element.component = themeRoute.desktopComponent
          ? themeRoute.desktopComponent
          : themeRoute.mobileComponent
          ? themeRoute.mobileComponent
          : element.component;
      }
      //SWAP META
      if (themeRoute.meta) {
        element.meta = themeRoute.meta;
      }
      //SWAP PROPS
      if (themeRoute.props) {
        element.props = themeRoute.props;
      }
    }

    //go into child layer
    if (element.children) {
      const newRealPath = realPath;
      newRealPath.push(realIndex);
      const newThemePath = themePath;
      if (themeIndex) {
        newThemePath.push(themeIndex);
      }
      generateLayer(realRoutes, themeRoutes, newRealPath, newThemePath);
    }

    if (element.meta?.requiresAuth && !authStore.$state.player) {
      element.component = Unauthorized;
    } else {
      if (isMobile) {
        element.component = element.mobileComponent;
      } else {
        element.component = element.desktopComponent
          ? element.desktopComponent
          : element.mobileComponent;
      }

      if (themeRoute) {
        if (isMobile) {
          element.component = themeRoute.mobileComponent
            ? themeRoute.mobileComponent
            : element.component;
        } else {
          element.component = themeRoute.desktopComponent
            ? themeRoute.desktopComponent
            : themeRoute.mobileComponent
            ? themeRoute.mobileComponent
            : element.component;
        }
      }
    }
  });

  return realRoutes;
};

export const generateRoutes = (
  realRoutes: Array<any>,
  themeRoutes: Array<any>
) => {
  realRoutes = generateLayer(realRoutes, themeRoutes, [], []);
  return realRoutes;
};
