export const exclusionTypes = [
    {
        id: true,
        name: 'I want self-exclusion because I have issues controling my behavior.',
    },
    {
        id: false,
        name: 'I want normal exclusion.',
    }
];

export interface ActiveExclusionElement {
    id: number, 
    editableFrom: string, 
    exclusionType: string, 
    hiddenFromPlayer: boolean, 
    exclusionValue: {
        id: number, 
        name: string
    }
}

export interface DropdownEvent {
    id: number, 
    name: string
}