
export const desktopHeaderLinks = [
    {    
        group: "Casino",
        link: "/casino",
        translation: "navigation.header.desktop.casino",
        icon: "casino",
        iconAlt: "Icon casino",
        linkText: "Casino",
        linkGroup: [],
        requiresAuth: false
    },
//     {    
//       group: "Sports",
//       translation: "navigation.header.desktop.sports",
//       link: "/sportsbook",
//       icon: "slot",
//       iconAlt: "Icon placeholder",
//       linkText: "SportsBook",
//       linkGroup: [] ,
//   },  
  {
    group: "Promotions",
    link: "/promotions",
    icon: "gift",
    iconAlt: "Icon gift",
    translation: "navigation.header.desktop.promotions",
    linkText: "Promotions",
    linkGroup: [],
    requiresAuth: false
  },/* 
  {
    group: "Support",
    link: "/support",
    icon: "gift",
    iconAlt: "Icon gift",
    translation: "navigation.header.desktop.support",
    linkText: "Promotions",
    linkGroup: [],
    requiresAuth: false
  }, */
  {
    group: "Profile",
    link: "/profile",
    icon: "",
    iconAlt: "",
    translation: "navigation.header.desktop.profile",
    linkText: "Promotions",
    linkGroup: [],
    requiresAuth: true
  },/* 
  {
    group: "Blog",
    link: "/guide",
    icon: "",
    iconAlt: "",
    translation: "navigation.header.desktop.blog",
    linkText: "Blog",
    linkGroup: [],
    requiresAuth: false
  }, */
    
  ];