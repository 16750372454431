export const languages = [
  {
    flagIcon: "https://s.b-bets.com/images/flags/icon_lang_gb.png",
    iconAlt: "GB flag",
    language: "English",
    divider: true,
    short: "EN",
    id: "gb",
  },
  {
    flagIcon: "https://s.b-bets.com/images/flags/icon_lang_rs.png",
    iconAlt: "Serbian flag",
    language: "Srpski",
    divider: true,
    short: "SR",
    id: "rs",
  },
  {
    flagIcon: "https://s.b-bets.com/images/flags/icon_lang_no.png",
    iconAlt: "Norvegian flag",
    language: "Norsk bokmål",
    divider: true,
    short: "NB",
    id: "no",
  },
  {
    flagIcon: "https://s.b-bets.com/images/flags/icon_lang_fi.png",
    iconAlt: "Finish flag",
    language: "Suomi",
    divider: true,
    short: "SU",
    id: "fi",
  },
  {
    flagIcon: "https://s.b-bets.com/images/flags/icon_lang_es.png",
    iconAlt: "Spanish flag",
    language: "Español",
    divider: true,
    short: "ES",
    id: "es",
  },
  {
    flagIcon: "https://s.b-bets.com/images/flags/icon_lang_br.png",
    iconAlt: "Portugal flag",
    language: "Português",
    divider: true,
    short: "PO",
    id: "pt",
  },
  {
    flagIcon: "https://s.b-bets.com/images/flags/icon_lang_ru.png",
    iconAlt: "Spanish flag",
    language: "русский",
    divider: true,
    short: "RU",
    id: "ru",
  },
  {
    flagIcon: "https://s.b-bets.com/images/flags/icon_lang_ca.png",
    iconAlt: "Canadian flag",
    language: "Canada",
    divider: true,
    short: "CA",
    id: "ca",
  },
  {
    flagIcon: "https://s.b-bets.com/images/flags/icon_lang_hi.png",
    iconAlt: "Indian flag",
    language: "िंदी",
    divider: true,
    short: "िं",
    id: "in",
  },
  {
    flagIcon: "https://s.b-bets.com/images/flags/icon_lang_nz.png",
    iconAlt: "New Zealand flag",
    language: "New Zealand (English)",
    divider: true,
    short: "NZ",
    id: "nz",
  },
  {
    flagIcon: "https://s.b-bets.com/images/flags/icon_lang_at.png",
    iconAlt: "Austrian flag",
    language: "Österreich (Deutsch)",
    divider: false,
    short: "ÖS",
    id: "at",
  },
];

export interface LanguageCode {
  flagIcon: string;
  code: string;
}

export const languageCodes = [
  // {
  //   flagIcon: "../src/assets/flags/ca.svg",
  //   code: "ca",
  //   countryCode: "+",
  // },
  // {
  //   flagIcon: "../src/assets/flags/ca.svg",
  //   code: "ca",
  //   countryCode: "",
  // },
  {
    flagIcon: "../src/assets/af.svg",
    code: "af",
    countryCode: "+93",
  },
  {
    flagIcon: "../src/assets/flags/ax.svg",
    code: "ax",
    countryCode: "+358",
  },
  {
    flagIcon: "../src/assets/flags/al.svg",
    code: "al",
    countryCode: "+355",
  },
  {
    flagIcon: "../src/assets/flags/dz.svg",
    code: "dz",
    countryCode: "+213",
  },
  {
    flagIcon: "../src/assets/flags/as.svg",
    code: "as",
    countryCode: "+1-684",
  },
  {
    flagIcon: "../src/assets/flags/ad.svg",
    code: "ad",
    countryCode: "+376",
  },
  {
    flagIcon: "../src/assets/flags/ao.svg",
    code: "ao",
    countryCode: "+244",
  },
  {
    flagIcon: "../src/assets/flags/ai.svg",
    code: "ai",
    countryCode: "+1-264",
  },
  {
    flagIcon: "../src/assets/flags/aq.svg",
    code: "aq",
    countryCode: "+672",
  },
  {
    flagIcon: "../src/assets/flags/ag.svg",
    code: "ag",
    countryCode: "+1-268",
  },
  {
    flagIcon: "../src/assets/flags/ar.svg",
    code: "ar",
    countryCode: "+54",
  },
  {
    flagIcon: "../src/assets/flags/am.svg",
    code: "am",
    countryCode: "+374",
  },
  {
    flagIcon: "../src/assets/flags/aw.svg",
    code: "aw",
    countryCode: "+297",
  },
  {
    flagIcon: "../src/assets/flags/au.svg",
    code: "au",
    countryCode: "+61",
  },
  {
    flagIcon: "../src/assets/flags/at.svg",
    code: "at",
    countryCode: "+43",
  },
  {
    flagIcon: "../src/assets/flags/az.svg",
    code: "az",
    countryCode: "+994",
  },
  {
    flagIcon: "../src/assets/flags/bs.svg",
    code: "bs",
    countryCode: "+1-242",
  },
  {
    flagIcon: "../src/assets/flags/bh.svg",
    code: "bh",
    countryCode: "+973",
  },
  {
    flagIcon: "../src/assets/flags/bd.svg",
    code: "bd",
    countryCode: "+880",
  },
  {
    flagIcon: "../src/assets/flags/bb.svg",
    code: "bb",
    countryCode: "+1-246",
  },
  {
    flagIcon: "../src/assets/flags/by.svg",
    code: "by",
    countryCode: "+375",
  },
  {
    flagIcon: "../src/assets/flags/be.svg",
    code: "be",
    countryCode: "+32",
  },
  {
    flagIcon: "../src/assets/flags/bz.svg",
    code: "bz",
    countryCode: "+501",
  },
  {
    flagIcon: "../src/assets/flags/bj.svg",
    code: "bj",
    countryCode: "+229",
  },
  {
    flagIcon: "../src/assets/flags/bm.svg",
    code: "bm",
    countryCode: "+1-441",
  },
  {
    flagIcon: "../src/assets/flags/bt.svg",
    code: "bt",
    countryCode: "+975	",
  },
  {
    flagIcon: "../src/assets/flags/bo.svg",
    code: "bo",
    countryCode: "+591",
  },
  {
    flagIcon: "../src/assets/flags/bq.svg",
    code: "bq",
    countryCode: "+599",
  },
  {
    flagIcon: "../src/assets/flags/ba.svg",
    code: "ba",
    countryCode: "+387",
  },
  {
    flagIcon: "../src/assets/flags/bw.svg",
    code: "bw",
    countryCode: "+267",
  },
  {
    flagIcon: "../src/assets/flags/bv.svg",
    code: "bv",
    countryCode: "+47",
  },
  {
    flagIcon: "../src/assets/flags/br.svg",
    code: "br",
    countryCode: "+55",
  },
  {
    flagIcon: "../src/assets/flags/io.svg",
    code: "io",
    countryCode: "+246",
  },
  {
    flagIcon: "../src/assets/flags/bn.svg",
    code: "bn",
    countryCode: "+673",
  },
  {
    flagIcon: "../src/assets/flags/bg.svg",
    code: "bg",
    countryCode: "+359",
  },
  {
    flagIcon: "../src/assets/flags/bf.svg",
    code: "bf",
    countryCode: "+226",
  },
  {
    flagIcon: "../src/assets/flags/bi.svg",
    code: "bi",
    countryCode: "+257",
  },
  {
    flagIcon: "../src/assets/flags/cv.svg",
    code: "cv",
    countryCode: "+238",
  },
  {
    flagIcon: "../src/assets/flags/kh.svg",
    code: "kh",
    countryCode: "+855",
  },
  {
    flagIcon: "../src/assets/flags/cm.svg",
    code: "cm",
    countryCode: "+237",
  },
  {
    flagIcon: "../src/assets/flags/ca.svg",
    code: "ca",
    countryCode: "+1",
  },
  {
    flagIcon: "../src/assets/flags/ky.svg",
    code: "ky",
    countryCode: "+1-345",
  },
  {
    flagIcon: "../src/assets/flags/cf.svg",
    code: "cf",
    countryCode: "+236",
  },
  {
    flagIcon: "../src/assets/flags/td.svg",
    code: "td",
    countryCode: "+235",
  },
  {
    flagIcon: "../src/assets/flags/cl.svg",
    code: "cl",
    countryCode: "+56",
  },
  {
    flagIcon: "../src/assets/flags/cn.svg",
    code: "cn",
    countryCode: "+86",
  },
  {
    flagIcon: "../src/assets/flags/cx.svg",
    code: "cx",
    countryCode: "+61",
  },
  {
    flagIcon: "../src/assets/flags/cc.svg",
    code: "cc",
    countryCode: "+61",
  },
  {
    flagIcon: "../src/assets/flags/co.svg",
    code: "co",
    countryCode: "+57",
  },
  {
    flagIcon: "../src/assets/flags/km.svg",
    code: "km",
    countryCode: "+269",
  },
  {
    flagIcon: "../src/assets/flags/ck.svg",
    code: "ck",
    countryCode: "+682",
  },
  {
    flagIcon: "../src/assets/flags/cr.svg",
    code: "cr",
    countryCode: "+506",
  },
  {
    flagIcon: "../src/assets/flags/hr.svg",
    code: "hr",
    countryCode: "+385",
  },
  {
    flagIcon: "../src/assets/flags/cu.svg",
    code: "cu",
    countryCode: "+53",
  },
  {
    flagIcon: "../src/assets/flags/cw.svg",
    code: "cw",
    countryCode: "+599",
  },
  {
    flagIcon: "../src/assets/flags/cy.svg",
    code: "cy",
    countryCode: "+357",
  },
  {
    flagIcon: "../src/assets/flags/cz.svg",
    code: "cz",
    countryCode: "+420",
  },
  {
    flagIcon: "../src/assets/flags/ci.svg",
    code: "ci",
    countryCode: "+225",
  },
  {
    flagIcon: "../src/assets/flags/cd.svg",
    code: "cd",
    countryCode: "+243",
  },
  {
    flagIcon: "../src/assets/flags/dk.svg",
    code: "dk",
    countryCode: "+45",
  },
  {
    flagIcon: "../src/assets/flags/dj.svg",
    code: "dj",
    countryCode: "+253",
  },
  {
    flagIcon: "../src/assets/flags/dm.svg",
    code: "dm",
    countryCode: "+1-767",
  },
  {
    flagIcon: "../src/assets/flags/do.svg",
    code: "do",
    countryCode: "+1-809",
  },
  {
    flagIcon: "../src/assets/flags/do.svg",
    code: "do",
    countryCode: "+1-829,",
  },
  {
    flagIcon: "../src/assets/flags/do.svg",
    code: "do",
    countryCode: "+1-849",
  },
  {
    flagIcon: "../src/assets/flags/ec.svg",
    code: "ec",
    countryCode: "+593",
  },
  {
    flagIcon: "../src/assets/flags/eg.svg",
    code: "eg",
    countryCode: "+20",
  },
  {
    flagIcon: "../src/assets/flags/sv.svg",
    code: "sv",
    countryCode: "+503",
  },
  {
    flagIcon: "../src/assets/flags/gq.svg",
    code: "gq",
    countryCode: "+240",
  },
  {
    flagIcon: "../src/assets/flags/er.svg",
    code: "er",
    countryCode: "+291",
  },
  {
    flagIcon: "../src/assets/flags/ee.svg",
    code: "ee",
    countryCode: "+372",
  },
  {
    flagIcon: "../src/assets/flags/sz.svg",
    code: "sz",
    countryCode: "+268",
  },
  {
    flagIcon: "../src/assets/flags/et.svg",
    code: "et",
    countryCode: "+251",
  },
  {
    flagIcon: "../src/assets/flags/fk.svg",
    code: "fk",
    countryCode: "+500",
  },
  {
    flagIcon: "../src/assets/flags/fo.svg",
    code: "fo",
    countryCode: "+298",
  },
  {
    flagIcon: "../src/assets/flags/fm.svg",
    code: "fm",
    countryCode: "+691",
  },
  {
    flagIcon: "../src/assets/flags/fj.svg",
    code: "fj",
    countryCode: "+679",
  },
  {
    flagIcon: "../src/assets/flags/fi.svg",
    code: "fi",
    countryCode: "+358",
  },
  {
    flagIcon: "../src/assets/flags/fr.svg",
    code: "fr",
    countryCode: "+33",
  },
  {
    flagIcon: "../src/assets/flags/gf.svg",
    code: "gf",
    countryCode: "+594",
  },
  {
    flagIcon: "../src/assets/flags/pf.svg",
    code: "pf",
    countryCode: "+689",
  },
  {
    flagIcon: "../src/assets/flags/tf.svg",
    code: "tf",
    countryCode: "+262",
  },
  {
    flagIcon: "../src/assets/flags/ga.svg",
    code: "ga",
    countryCode: "+241",
  },
  {
    flagIcon: "../src/assets/flags/gm.svg",
    code: "gm",
    countryCode: "+220",
  },
  {
    flagIcon: "../src/assets/flags/ge.svg",
    code: "ge",
    countryCode: "+995",
  },
  {
    flagIcon: "../src/assets/flags/de.svg",
    code: "de",
    countryCode: "+49",
  },
  {
    flagIcon: "../src/assets/flags/gh.svg",
    code: "gh",
    countryCode: "+233",
  },
  {
    flagIcon: "../src/assets/flags/gi.svg",
    code: "gi",
    countryCode: "+350",
  },
  {
    flagIcon: "../src/assets/flags/gr.svg",
    code: "gr",
    countryCode: "+30",
  },
  {
    flagIcon: "../src/assets/flags/gl.svg",
    code: "gl",
    countryCode: "+299",
  },
  {
    flagIcon: "../src/assets/flags/gd.svg",
    code: "gd",
    countryCode: "+1-473",
  },
  {
    flagIcon: "../src/assets/flags/gp.svg",
    code: "gp",
    countryCode: "+590",
  },
  {
    flagIcon: "../src/assets/flags/gu.svg",
    code: "gu",
    countryCode: "+1-671",
  },
  {
    flagIcon: "../src/assets/flags/gt.svg",
    code: "gt",
    countryCode: "+502",
  },
  {
    flagIcon: "../src/assets/flags/gg.svg",
    code: "gg",
    countryCode: "+44-1481",
  },
  {
    flagIcon: "../src/assets/flags/gn.svg",
    code: "gn",
    countryCode: "+224",
  },
  {
    flagIcon: "../src/assets/flags/gy.svg",
    code: "gy",
    countryCode: "+592",
  },
  {
    flagIcon: "../src/assets/flags/gw.svg",
    code: "gw",
    countryCode: "+245",
  },
  {
    flagIcon: "../src/assets/flags/ht.svg",
    code: "ht",
    countryCode: "+509",
  },
  {
    flagIcon: "../src/assets/flags/hm.svg",
    code: "hm",
    countryCode: "+672",
  },
  {
    flagIcon: "../src/assets/flags/va.svg",
    code: "va",
    countryCode: "+379",
  },
  {
    flagIcon: "../src/assets/flags/hn.svg",
    code: "hn",
    countryCode: "+504",
  },
  {
    flagIcon: "../src/assets/flags/hk.svg",
    code: "hk",
    countryCode: "+852",
  },
  {
    flagIcon: "../src/assets/flags/hu.svg",
    code: "hu",
    countryCode: "+36",
  },
  {
    flagIcon: "../src/assets/flags/is.svg",
    code: "is",
    countryCode: "+354",
  },
  {
    flagIcon: "../src/assets/flags/in.svg",
    code: "in",
    countryCode: "+91",
  },
  {
    flagIcon: "../src/assets/flags/id.svg",
    code: "id",
    countryCode: "+62",
  },
  {
    flagIcon: "../src/assets/flags/ir.svg",
    code: "ir",
    countryCode: "+98",
  },
  {
    flagIcon: "../src/assets/flags/iq.svg",
    code: "iq",
    countryCode: "+964",
  },
  {
    flagIcon: "../src/assets/flags/ie.svg",
    code: "ie",
    countryCode: "+353",
  },
  {
    flagIcon: "../src/assets/flags/im.svg",
    code: "im",
    countryCode: "+44-1624",
  },
  {
    flagIcon: "../src/assets/flags/il.svg",
    code: "il",
    countryCode: "+972",
  },
  {
    flagIcon: "../src/assets/flags/it.svg",
    code: "it",
    countryCode: "+39",
  },
  {
    flagIcon: "../src/assets/flags/jm.svg",
    code: "jm",
    countryCode: "+1-876",
  },
  {
    flagIcon: "../src/assets/flags/jp.svg",
    code: "jp",
    countryCode: "+81",
  },
  {
    flagIcon: "../src/assets/flags/je.svg",
    code: "je",
    countryCode: "+44-1534",
  },
  {
    flagIcon: "../src/assets/flags/jo.svg",
    code: "jo",
    countryCode: "+962",
  },
  {
    flagIcon: "../src/assets/flags/kz.svg",
    code: "kz",
    countryCode: "+997",
  },
  {
    flagIcon: "../src/assets/flags/ke.svg",
    code: "ke",
    countryCode: "+254",
  },
  {
    flagIcon: "../src/assets/flags/ki.svg",
    code: "ki",
    countryCode: "+686",
  },
  {
    flagIcon: "../src/assets/flags/kw.svg",
    code: "kw",
    countryCode: "+965",
  },
  {
    flagIcon: "../src/assets/flags/kg.svg",
    code: "kg",
    countryCode: "+996",
  },
  {
    flagIcon: "../src/assets/flags/la.svg",
    code: "la",
    countryCode: "+856",
  },
  {
    flagIcon: "../src/assets/flags/lv.svg",
    code: "lv",
    countryCode: "+371",
  },
  {
    flagIcon: "../src/assets/flags/lb.svg",
    code: "lb",
    countryCode: "+961",
  },
  {
    flagIcon: "../src/assets/flags/ls.svg",
    code: "ls",
    countryCode: "+266",
  },
  {
    flagIcon: "../src/assets/flags/lr.svg",
    code: "lr",
    countryCode: "+231",
  },
  {
    flagIcon: "../src/assets/flags/ly.svg",
    code: "ly",
    countryCode: "+218",
  },
  {
    flagIcon: "../src/assets/flags/li.svg",
    code: "li",
    countryCode: "+423",
  },
  {
    flagIcon: "../src/assets/flags/lt.svg",
    code: "lt",
    countryCode: "+370",
  },
  {
    flagIcon: "../src/assets/flags/lu.svg",
    code: "lu",
    countryCode: "+352",
  },
  {
    flagIcon: "../src/assets/flags/mo.svg",
    code: "mo",
    countryCode: "+853",
  },
  {
    flagIcon: "../src/assets/flags/mg.svg",
    code: "mg",
    countryCode: "+261",
  },
  {
    flagIcon: "../src/assets/flags/mw.svg",
    code: "mw",
    countryCode: "+265",
  },
  {
    flagIcon: "../src/assets/flags/my.svg",
    code: "my",
    countryCode: "+60",
  },
  {
    flagIcon: "../src/assets/flags/mv.svg",
    code: "mv",
    countryCode: "+960",
  },
  {
    flagIcon: "../src/assets/flags/ml.svg",
    code: "ml",
    countryCode: "+223",
  },
  {
    flagIcon: "../src/assets/flags/mt.svg",
    code: "mt",
    countryCode: "+356",
  },
  {
    flagIcon: "../src/assets/flags/mh.svg",
    code: "mh",
    countryCode: "+692",
  },
  {
    flagIcon: "../src/assets/flags/mq.svg",
    code: "mq",
    countryCode: "+596",
  },
  {
    flagIcon: "../src/assets/flags/mr.svg",
    code: "mr",
    countryCode: "+222",
  },
  {
    flagIcon: "../src/assets/flags/mu.svg",
    code: "mu",
    countryCode: "+230",
  },
  {
    flagIcon: "../src/assets/flags/yt.svg",
    code: "yt",
    countryCode: "+262",
  },
  {
    flagIcon: "../src/assets/flags/mx.svg",
    code: "mx",
    countryCode: "+52",
  },
  {
    flagIcon: "../src/assets/flags/md.svg",
    code: "md",
    countryCode: "+373",
  },
  {
    flagIcon: "../src/assets/flags/mc.svg",
    code: "mc",
    countryCode: "+377",
  },
  {
    flagIcon: "../src/assets/flags/mn.svg",
    code: "mn",
    countryCode: "+976",
  },
  {
    flagIcon: "../src/assets/flags/me.svg",
    code: "me",
    countryCode: "+382",
  },
  {
    flagIcon: "../src/assets/flags/ms.svg",
    code: "ms",
    countryCode: "+1-664",
  },
  {
    flagIcon: "../src/assets/flags/ma.svg",
    code: "ma",
    countryCode: "+212",
  },
  {
    flagIcon: "../src/assets/flags/mz.svg",
    code: "mz",
    countryCode: "+258",
  },
  {
    flagIcon: "../src/assets/flags/mm.svg",
    code: "mm",
    countryCode: "+95",
  },
  {
    flagIcon: "../src/assets/flags/na.svg",
    code: "na",
    countryCode: "+264",
  },
  {
    flagIcon: "../src/assets/flags/nr.svg",
    code: "nr",
    countryCode: "+674",
  },
  {
    flagIcon: "../src/assets/flags/np.svg",
    code: "np",
    countryCode: "+977",
  },
  {
    flagIcon: "../src/assets/flags/nl.svg",
    code: "nl",
    countryCode: "+31",
  },
  {
    flagIcon: "../src/assets/flags/nc.svg",
    code: "nc",
    countryCode: "+687",
  },
  {
    flagIcon: "../src/assets/flags/nz.svg",
    code: "nz",
    countryCode: "+64",
  },
  {
    flagIcon: "../src/assets/flags/ni.svg",
    code: "ni",
    countryCode: "+505",
  },
  {
    flagIcon: "../src/assets/flags/ne.svg",
    code: "ne",
    countryCode: "+227",
  },
  {
    flagIcon: "../src/assets/flags/ng.svg",
    code: "ng",
    countryCode: "+234",
  },
  {
    flagIcon: "../src/assets/flags/nu.svg",
    code: "nu",
    countryCode: "+683",
  },
  {
    flagIcon: "../src/assets/flags/nf.svg",
    code: "nf",
    countryCode: "+672",
  },
  {
    flagIcon: "../src/assets/flags/kp.svg",
    code: "kp",
    countryCode: "+850",
  },
  {
    flagIcon: "../src/assets/flags/mk.svg",
    code: "mk",
    countryCode: "+389",
  },
  {
    flagIcon: "../src/assets/flags/no.svg",
    code: "no",
    countryCode: "+47",
  },
  {
    flagIcon: "../src/assets/flags/mp.svg",
    code: "mp",
    countryCode: "+1-670",
  },
  {
    flagIcon: "../src/assets/flags/om.svg",
    code: "om",
    countryCode: "+968",
  },
  {
    flagIcon: "../src/assets/flags/pk.svg",
    code: "pk",
    countryCode: "+92",
  },
  {
    flagIcon: "../src/assets/flags/pw.svg",
    code: "pw",
    countryCode: "+680",
  },
  {
    flagIcon: "../src/assets/flags/pa.svg",
    code: "pa",
    countryCode: "+507",
  },
  {
    flagIcon: "../src/assets/flags/pg.svg",
    code: "pg",
    countryCode: "+675",
  },
  {
    flagIcon: "../src/assets/flags/py.svg",
    code: "py",
    countryCode: "+595",
  },
  {
    flagIcon: "../src/assets/flags/pe.svg",
    code: "pe",
    countryCode: "+51",
  },
  {
    flagIcon: "../src/assets/flags/ph.svg",
    code: "ph",
    countryCode: "+63",
  },
  {
    flagIcon: "../src/assets/flags/pn.svg",
    code: "pn",
    countryCode: "+64",
  },
  {
    flagIcon: "../src/assets/flags/pl.svg",
    code: "pl",
    countryCode: "+48",
  },
  {
    flagIcon: "../src/assets/flags/pt.svg",
    code: "pt",
    countryCode: "+351",
  },
  {
    flagIcon: "../src/assets/flags/pr.svg",
    code: "pr",
    countryCode: "+1-787",
  },
  {
    flagIcon: "../src/assets/flags/pr.svg",
    code: "pr",
    countryCode: "+1-939",
  },
  {
    flagIcon: "../src/assets/flags/qa.svg",
    code: "qa",
    countryCode: "+974",
  },
  {
    flagIcon: "../src/assets/flags/cg.svg",
    code: "cg",
    countryCode: "+242",
  },
  {
    flagIcon: "../src/assets/flags/ro.svg",
    code: "ro",
    countryCode: "+40",
  },
  {
    flagIcon: "../src/assets/flags/ru.svg",
    code: "ru",
    countryCode: "+7",
  },
  {
    flagIcon: "../src/assets/flags/rw.svg",
    code: "rw",
    countryCode: "+250",
  },
  {
    flagIcon: "../src/assets/flags/re.svg",
    code: "re",
    countryCode: "+262",
  },
  {
    flagIcon: "../src/assets/flags/bl.svg",
    code: "bl",
    countryCode: "+590",
  },
  {
    flagIcon: "../src/assets/flags/sh.svg",
    code: "sh",
    countryCode: "+290",
  },
  {
    flagIcon: "../src/assets/flags/kn.svg",
    code: "kn",
    countryCode: "+1-869",
  },
  {
    flagIcon: "../src/assets/flags/lc.svg",
    code: "lc",
    countryCode: "+1-758",
  },
  {
    flagIcon: "../src/assets/flags/mf.svg",
    code: "mf",
    countryCode: "+590",
  },
  {
    flagIcon: "../src/assets/flags/pm.svg",
    code: "pm",
    countryCode: "+508",
  },
  {
    flagIcon: "../src/assets/flags/vc.svg",
    code: "vc",
    countryCode: "+1-784",
  },
  {
    flagIcon: "../src/assets/flags/ws.svg",
    code: "ws",
    countryCode: "+685",
  },
  {
    flagIcon: "../src/assets/flags/sm.svg",
    code: "sm",
    countryCode: "+378",
  },
  {
    flagIcon: "../src/assets/flags/st.svg",
    code: "st",
    countryCode: "+239",
  },
  {
    flagIcon: "../src/assets/flags/sa.svg",
    code: "sa",
    countryCode: "+966",
  },
  {
    flagIcon: "../src/assets/flags/sn.svg",
    code: "sn",
    countryCode: "+221",
  },
  {
    flagIcon: "../src/assets/flags/rs.svg",
    code: "rs",
    countryCode: "+381",
  },
  {
    flagIcon: "../src/assets/flags/sc.svg",
    code: "sc",
    countryCode: "+248",
  },
  {
    flagIcon: "../src/assets/flags/sl.svg",
    code: "sl",
    countryCode: "+232",
  },
  {
    flagIcon: "../src/assets/flags/sg.svg",
    code: "sg",
    countryCode: "+65",
  },
  {
    flagIcon: "../src/assets/flags/sx.svg",
    code: "sx",
    countryCode: "+1-721",
  },
  {
    flagIcon: "../src/assets/flags/sk.svg",
    code: "sk",
    countryCode: "+421",
  },
  {
    flagIcon: "../src/assets/flags/si.svg",
    code: "si",
    countryCode: "+386",
  },
  {
    flagIcon: "../src/assets/flags/sb.svg",
    code: "sb",
    countryCode: "+677",
  },
  {
    flagIcon: "../src/assets/flags/so.svg",
    code: "so",
    countryCode: "+252",
  },
  {
    flagIcon: "../src/assets/flags/za.svg",
    code: "za",
    countryCode: "+27",
  },
  {
    flagIcon: "../src/assets/flags/gs.svg",
    code: "gs",
    countryCode: "+672",
  },
  {
    flagIcon: "../src/assets/flags/kr.svg",
    code: "kr",
    countryCode: "+82",
  },
  {
    flagIcon: "../src/assets/flags/ss.svg",
    code: "ss",
    countryCode: "+211",
  },
  {
    flagIcon: "../src/assets/flags/es.svg",
    code: "es",
    countryCode: "+34",
  },
  {
    flagIcon: "../src/assets/flags/lk.svg",
    code: "lk",
    countryCode: "+94",
  },
  {
    flagIcon: "../src/assets/flags/ps.svg",
    code: "ps",
    countryCode: "+970",
  },
  {
    flagIcon: "../src/assets/flags/sd.svg",
    code: "sd",
    countryCode: "+249",
  },
  {
    flagIcon: "../src/assets/flags/sr.svg",
    code: "sr",
    countryCode: "+597",
  },
  {
    flagIcon: "../src/assets/flags/sj.svg",
    code: "sj",
    countryCode: "+47",
  },
  {
    flagIcon: "../src/assets/flags/se.svg",
    code: "se",
    countryCode: "+46",
  },
  {
    flagIcon: "../src/assets/flags/ch.svg",
    code: "ch",
    countryCode: "+41",
  },
  {
    flagIcon: "../src/assets/flags/sy.svg",
    code: "sy",
    countryCode: "+963",
  },
  {
    flagIcon: "../src/assets/flags/tw.svg",
    code: "tw",
    countryCode: "+886",
  },
  {
    flagIcon: "../src/assets/flags/tj.svg",
    code: "tj",
    countryCode: "+992",
  },
  {
    flagIcon: "../src/assets/flags/tz.svg",
    code: "tz",
    countryCode: "+255",
  },
  {
    flagIcon: "../src/assets/flags/th.svg",
    code: "th",
    countryCode: "+66",
  },
  {
    flagIcon: "../src/assets/flags/tl.svg",
    code: "tl",
    countryCode: "+670",
  },
  {
    flagIcon: "../src/assets/flags/tg.svg",
    code: "tg",
    countryCode: "+228",
  },
  {
    flagIcon: "../src/assets/flags/tk.svg",
    code: "tk",
    countryCode: "+690",
  },
  {
    flagIcon: "../src/assets/flags/to.svg",
    code: "to",
    countryCode: "+676",
  },
  {
    flagIcon: "../src/assets/flags/tt.svg",
    code: "tt",
    countryCode: "+1-868",
  },
  {
    flagIcon: "../src/assets/flags/tn.svg",
    code: "tn",
    countryCode: "+216",
  },
  {
    flagIcon: "../src/assets/flags/tr.svg",
    code: "tr",
    countryCode: "+90",
  },
  {
    flagIcon: "../src/assets/flags/tm.svg",
    code: "tm",
    countryCode: "+993",
  },
  {
    flagIcon: "../src/assets/flags/tc.svg",
    code: "tc",
    countryCode: "+1-649",
  },
  {
    flagIcon: "../src/assets/flags/tv.svg",
    code: "tv",
    countryCode: "+688",
  },
  {
    flagIcon: "../src/assets/flags/ug.svg",
    code: "ug",
    countryCode: "+256",
  },
  {
    flagIcon: "../src/assets/flags/ua.svg",
    code: "ua",
    countryCode: "+380",
  },
  {
    flagIcon: "../src/assets/flags/ae.svg",
    code: "ae",
    countryCode: "+971",
  },
  {
    flagIcon: "../src/assets/flags/en.svg",
    code: "en",
    countryCode: "+44",
  },
  {
    flagIcon: "../src/assets/flags/uy.svg",
    code: "uy",
    countryCode: "+598",
  },
  {
    flagIcon: "../src/assets/flags/uz.svg",
    code: "uz",
    countryCode: "+998",
  },
  {
    flagIcon: "../src/assets/flags/vu.svg",
    code: "vu",
    countryCode: "+678",
  },
  {
    flagIcon: "../src/assets/flags/ve.svg",
    code: "ve",
    countryCode: "+58",
  },
  {
    flagIcon: "../src/assets/flags/vn.svg",
    code: "vn",
    countryCode: "+84",
  },
  {
    flagIcon: "../src/assets/flags/vg.svg",
    code: "vg",
    countryCode: "+1-284",
  },
  {
    flagIcon: "../src/assets/flags/vi.svg",
    code: "vi",
    countryCode: "+1-340",
  },
  {
    flagIcon: "../src/assets/flags/wf.svg",
    code: "wf",
    countryCode: "+681",
  },
  {
    flagIcon: "../src/assets/flags/eh.svg",
    code: "eh",
    countryCode: "+212",
  },
  {
    flagIcon: "../src/assets/flags/ye.svg",
    code: "ye",
    countryCode: "+967",
  },
  {
    flagIcon: "../src/assets/flags/zm.svg",
    code: "zm",
    countryCode: "+260",
  },
  {
    flagIcon: "../src/assets/flags/zw.svg",
    code: "zw",
    countryCode: "+263",
  },
  {
    flagIcon: "../src/assets/flags/us.svg",
    code: "us",
    countryCode: "+1",
  },
];
