<script setup lang="ts">
import { validateDropDown } from "../../../../services/validation/dropdown";
import { computed, ref, watch } from "vue";
import { useDropdownsStore } from "../../../../stores/dropdowns.js";
import SelectDropdown from "./SelectDropdownComponent.vue";
// import vSelect from "vue-select";

const props = defineProps({
  selected: Object,
  url: String,
  options: Array<any>,
  label: String,
  multiple: Boolean,
  selector: String,
  preselect: Boolean,
  dropdownId: String,
  testId:String,
  borderClass: String,
  textCutBorder:String,
});
const store = useDropdownsStore();
const items = ref<Array<any>| undefined>([]);
const selectedItem = ref();

if(props.selected){
  selectedItem.value = props.selected
}

const initialize = async () => {
  if (props.url) {
    items.value = await store.getDropdown(props.url);
  } else {
    items.value = props.options;
  }

  inputHandler(props.selected, true);
};

const emit = defineEmits(["isValid", "emitSelected"]);
const initial = ref(false);
const validation = computed(() => validateDropDown(selectedItem.value, props.multiple));

const catchEmit = (event:any) => {
  if (props.multiple) {
      selectedItem.value = event
  } else {
    selectedItem.value = event;
  }

  inputHandler(selectedItem.value);

};
const inputHandler = async (value: any, isFirst = false) => {
  if (!isFirst) {
    touched();
    await emit("emitSelected", value);
  }
  emit("isValid", validation.value.isValid);
};
const touched = () => {
  initial.value = true;
};
watch(
  props,
  (value) => {
    initialize();
  },
  { deep: true }
);

initialize();
</script>

<template>
  <SelectDropdown
    :selected="selected"
    :multiple="multiple"
    :data="items"
    :label="label"
    :optionId="dropdownId"
    :selector="selector"
    @emitSelected="catchEmit"
    @emitMultiple="catchEmit"
    :preselect="preselect"
    :testId="testId"
    :borderClass="borderClass"
    :textCutBorder="textCutBorder"
  />
  <div
    v-if="!validation.isValid && initial"
    class="text-danger"
    :data-testid="testId ? `validation-${testId}` : null"
  >
    {{ validation.message }}
  </div>
</template>
