<script setup lang="ts">
import { ref, watch } from "vue";
import axios from "axios";
import InputValueComponent from "@/brands/holahola/components/shared/forms/input/InputValueComponent.vue";
import IconComponent from "@/components/icons/IconComponent.vue";
import { useLoaderStore } from "@/stores/loader";
import { translate } from "@/services/translations/translation";

const props = defineProps({
  resetCode: Boolean, 
  isTouched: Boolean,
})

const loader = useLoaderStore();
const captchaImage = ref();
const imgCode = ref();
const captchaHash = ref();
const captchaIsValid = ref(false);
const captchaFailed = ref(false);
const failedRequest = ref(false);
const inputIsValid = ref(false);
const inputIsTouched = ref(props.isTouched);

const emit = defineEmits(["isValid", "code", "header", "imgCodeReseted"]);

// VALIDATION START
const newValidationList = ref({
  code: Boolean,
});
type ValidationKey = keyof typeof newValidationList.value;

const formisValid = ref();
const callbackValid = (pointer: ValidationKey, value: BooleanConstructor) => {
  newValidationList.value[pointer] = value;
  computeFormValidation();
};
const computeFormValidation = () => {
  let flag = true;
  let key: ValidationKey;
  for (key in newValidationList.value) {
    if (!newValidationList.value[key]) flag = false;
  }
  formisValid.value = flag;
  emit("isValid", formisValid.value);
};
// VALIDATION END

function download() {
  emit("isValid", formisValid.value);
  loader.toggleLoader(true);
  axios
    .get(`/captcha/get-captcha`, { responseType: "blob" })
    .then((response) => {
      captchaHash.value = response.headers["captcha-hash"];
      const fileURL = URL.createObjectURL(
        new Blob([response.data, { type: "image/png" }])
      );
      captchaImage.value = fileURL;
      loader.toggleLoader(false);
    })
    .catch((err) => {
      loader.toggleLoader(true);
      loader.toggleLoader(false);
    });
  loader.toggleLoader(false);
}
download();

const downloadAgain = () => {
  failedRequest.value = false;
  captchaFailed.value = false;
  download();
};
watch(props, value => {
  if(props.resetCode){
    imgCode.value = "";
    emit("imgCodeReseted")
    download();
  }
  inputIsTouched.value = props.isTouched
})
watch(
  imgCode,
  (value) => {
    emit("code", imgCode.value);
    emit("header", captchaHash.value);
  },
  { deep: true }
);
</script>

<template>
  <div class="flex flex-center mgy-5 radius-10 bg-1-2">
    <div class="pda-5 radius-5">
      <div v-if="!captchaIsValid && !captchaFailed">
        <div class="w-100 flex flex-right-center">
          <img :src="captchaImage" />
          <IconComponent
            class="pdx-5 mgl-1 cursor-pointer"
            :iconClasses="`icon-20 icon-inline align-bottom bg-4-1 icon-refresh-arrow`"
            @click="download"
          ></IconComponent>
        </div>
        <InputValueComponent
          class="mgx-auto mgt-5"
          :placeholder="translate('help.support.enterCode')"
          inputType="number"
          v-model="imgCode"
          :minLength="0"
          :maxLength="255"
          textCutBorder="label-cut bg-1-3 border-top-1-2 border-width-1"
          textCutBorderFocused="label-cut bg-1-3 border-top-1-2 border-width-1"
          bgColor="bg-1-3 radius-26"
          :borderClass="' radius-26 border-1-2 border-width-1'"
          v-model:isTouched="inputIsTouched"
          @isValid="callbackValid('code', $event)"
        ></InputValueComponent>
      </div>
      <div v-if="failedRequest">
        <div class="flex">
          <IconComponent
            class="pdx-5"
            :iconClasses="`icon-20 icon-inline align-bottom bg-error icon-error`"
          ></IconComponent>
          <p>{{ translate("captcha.failed") }}</p>
        </div>
        <button class="mgx-auto mgt-5 txt-bold bg-1-4" @click="downloadAgain">
          {{ translate("captcha.tryAgain") }}
        </button>
      </div>
    </div>
  </div>
</template>
