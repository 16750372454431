<script setup lang="ts">
import axios from "axios";
import { ref } from "vue";
import type { Section } from "@/brands/holahola/interface/faqInterface";
import { translate } from "@/services/translations/translation";

const faqs = ref<Array<Section> | []>([]);
const activeSectionData = ref<Section>();

const getFaqs = async () => {
  axios
    .get("faqs/list")
    .then((response) => {
      faqs.value = response.data.data;

      faqs.value.length > 0 ? findFaq(faqs.value[0].id) : null;
    })
    .catch((err) => {
      console.log("error getting Faqs");
    });
};
getFaqs();

const findFaq = (id: number) => {
  activeSectionData.value = faqs.value.find((element) => element.id == id);
};
</script>

<template>
  <section class="flex flex-center mga-5">
    <div class="col-sm-8">
      <h1 class="txt-center">{{ translate("home.desktop.faq.pageTitle") }}</h1>
      <div v-if="faqs && faqs.length > 0" class="txt-theme-txt-2 mgt-5 flex gap-5">
        <div>
          <div class="pdb-20 sections-wrapper mgt-5">
            <ul class="link faq-sections-list">
              <li
                class="title-medium txt-bold line-article bg-theme-bg-4 radius-6 text-height-xxl link-group cursor-pointer pdx-2 txt-center"
              >
                {{ translate("home.desktop.faq.sideMenuSectionTitle") }}
              </li>
              <li
                class="title-small txt-bold line-article bg-theme-bg-4 radius-6 text-height-xxl link-group cursor-pointer pdx-2"
                :class="{ active: section.id == activeSectionData?.id }"
                v-for="section in faqs"
                :key="section.id"
                :id="`section-${section.id}`"
                @click="findFaq(section.id)"
              >
                <p class="pdx-2">{{ section.name.toUpperCase() }}</p>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="activeSectionData && activeSectionData.faqs.length > 0">
          <ul>
            <li class="mgt-5" v-for="(faq, i) in activeSectionData?.faqs" :key="faq.id">
              <p class="title-medium pda-1 border-brand-1 radius-8">
                <span>{{ i + 1 }}. </span>{{ faq.question }}
              </p>
              <p class="mgt-5 title-small">{{ faq.answer }}</p>
            </li>
          </ul>
        </div>
        <div class="txt-center title-medium" v-else>
          {{ translate("home.desktop.faq.faqEmpty") }}
        </div>
      </div>
      <div v-else class="txt-center title-medium">
        {{ translate("home.desktop.faq.listEmpty") }}
      </div>
    </div>
  </section>
</template>
