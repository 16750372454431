<script setup lang="ts">
import IconComponent from "@/components/icons/IconComponent.vue";
import { ref } from "vue";
import { profileNavigation, sideGroups } from "@/brands/holahola/data/navigation";
import { useAuthStore } from "@/stores/auth";
import { RouterSingleton } from "@/router/index";
import { translate } from "@/services/translations/translation";
import type {
  Item,
  ItemChild,
} from "@/brands/holahola/interface/userAccountSideMenuInterface";

import ButtonComponent from "@/brands/bit1111/components/shared/buttons/ButtonComponent.vue";

const store = useAuthStore();
const expanded = ref(true);
const hoverExpand = ref(false);
const route = RouterSingleton.getInstance().getRouter().currentRoute.value.path;

const items = ref<Array<Item>>(sideGroups);

const logout = () => {
  store.logout();
};
items.value.forEach((item) => {
  item.hover = false;
  profileNavigation.forEach((element) => {
    if (translate(item.name) === element.group) {
      item.children = element.linkGroup;
      item.children.forEach((child) => {
        child.active = false;
      });
    }
  });

  //setting active parent and child when .link == route
  for (let i = 0; i < items.value.length; i++) {
    for (let j = 0; j < items.value[i].children.length; j++) {
      if (items.value[i].children[j].link == route) {
        items.value[i].children[j].active = true;
        items.value[i].active = true;
      }
    }
  }
});

const handleParent = (item: Item) => {
  items.value.forEach((element) => {
    if (element.id == item.id) {
      item.active = !item.active;
    } else {
      element.active = false;
    }
  });
};

const handleChild = (item: Item, child: ItemChild) => {
  item.children.forEach((element) => {
    if (element.link == child.link) {
      element.active = true;
    } else {
      element.active = false;
    }
  });
};
</script>

<template>
  <div
    class="bg-1-4 user-account-side-menu-wrapper w-350px h-100 minh-1000px relative pdx-6 pdy-7"
    v-show="expanded"
  >
    <div class="grid">
      <div class="flex space-between">
        <span class="txt-2-1 txt-size-25 font-Aldrich"
          >{{ store.$state.player?.firstName }} {{ store.$state.player?.lastName }}</span
        >
        <div
          @click="expanded = false"
          class="cursor-pointer"
          @mouseover="hoverExpand = true"
          @mouseleave="hoverExpand = false"
        >
          <IconComponent
            class="pdx-5 mgl-1 mgr-2"
            :iconClasses="`svg-icon icon-30 scale-1-4 inline-block icon-dropdown bg-3-1 rotate-left90 ${
              hoverExpand ? 'minimize-icon-scaled' : 'minimize-icon'
            }`"
          ></IconComponent>
        </div>
      </div>

      <span class="txt-light txt-size-12">{{ store.$state.player?.email }}</span>
    </div>

    <div class="border-2-4 border-style-none border-top-solid border-width-1 mgt-6 pdt-6">
      <div class="" v-for="(item, i) in items" :key="i">
        <div
          class="mgy-7 cursor-pointer"
          @mouseover="item.hover = true"
          @mouseleave="item.hover = false"
          @click="handleParent(item)"
        >
          <span class="pdl-2 w-100 flex flex-left-center">
            <IconComponent
              class="pdx-5 mgl-1 mgr-2"
              :iconClasses="`svg-icon icon-24 scale-1-4 inline-block ${
                item.active || item.hover ? 'bg-3-1' : 'bg-light'
              }   icon-${item.icon}`"
            ></IconComponent>
            <p
              :class="`txt-size-20 font-Poppins txt-weight-600 ${
                item.active || item.hover ? 'txt-3-1' : 'txt-light'
              }`"
            >
              {{ translate(item.name) }}
            </p>
            <span class="w-100">
              <IconComponent
                class="pdx-5 mgl-1 float-right mgr-2"
                :iconClasses="`svg-icon icon-18 ${
                  item.active || item.hover ? 'bg-3-1' : 'bg-light'
                } ${
                  item.active ? 'scale-0-7 ' : 'rotate-right-90'
                }   inline-block  icon-dropdown`"
              ></IconComponent> </span
          ></span>
        </div>

        <span v-if="item.active">
          <div
            v-for="(child, j) in item.children"
            :key="j"
            @click="handleChild(item, child)"
            class="pdl-8"
          >
            <RouterLink :to="child.link">
              <p
                :class="`mgy-5 txt-size-18 font-Poppins  ${
                  child.active ? 'txt-3-1' : 'txt-light'
                }`"
              >
                {{ child.linkText }}
              </p>
            </RouterLink>
          </div>
        </span>
      </div>
    </div>
    <button
      class="mgt-10 w-300px h-50px border-1-2 border-width-1 radius-10 txt-size-16 txt-weight-600 font-Poppins to-upper-case txt-1-2"
      @click="logout()"
    >
      {{ translate('common.logout') }}
    </button>
  </div>
  <div
    v-show="!expanded"
    :class="`w-70px bg-1-4 cursor-pointer h-100 minh-1000px pdt-7 ${
      hoverExpand ? 'selected-shadow' : ''
    }`"
    @click="expanded = true"
    @mouseover="hoverExpand = true"
    @mouseleave="hoverExpand = false"
  >
    <div :class="`fit-content mgx-auto `">
      <IconComponent
        :iconClasses="` svg-icon  scale-1-4 inline-block icon-dropdown bg-3-1 icon-30  ${
          hoverExpand ? 'expand-icon-scaled' : 'expand-icon'
        }`"
      ></IconComponent>
    </div>
  </div>
</template>
<style lang="css" scoped>
.logoutbtn {
  bottom: 0px;
}
.expand-icon {
  transform: rotate(-90deg) translate(0px, -5px);
}
.expand-icon-scaled {
  transform: rotate(-90deg) translate(0px, -5px) scale(2);
}
.minimize-icon-scaled {
  transform: rotate(90deg) scale(2);
}
.minimize-icon {
  transform: rotate(90deg);
}
.selected-shadow {
  box-shadow: 0px 0px 10px 7px #3bbdef;
}
</style>
