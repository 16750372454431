<script setup lang="ts">
import { ref } from "vue";
import HolaBoardingPageComponent from "@/brands/holahola/components/boarding/HolaBoardingPageComponent.vue";
import { goToRoute } from "@/router/routingService";
import { useAuthStore } from "@/stores/auth";
import { useLoaderStore } from "@/stores/loader";
import { boarding } from "@/brands/holahola/data/boarding";
import axios from "axios";
import { isMobileCheck } from "@/services/interface/clientInformation";
import { translate } from "@/services/translations/translation";

const isMobile = ref(isMobileCheck());

const authStore = useAuthStore();
const loader = useLoaderStore();
const currentPage = ref(0);
const maxSlide = ref(1);
const pagesData = ref(boarding);

const emit = defineEmits(["closeBoarding"])

const updateMaxSlide = () => {
  maxSlide.value =
    maxSlide.value < currentPage.value + 1 ? currentPage.value + 1 : maxSlide.value;
};
const nextPage = () => {
  currentPage.value++;
  updateMaxSlide();
};
const previousPage = () => {
  currentPage.value--;
};

const startPlay = async () => {
  loader.toggleLoader(true);
  await axios
    .put("/players/onboard", {})
    .then((response) => {
      //
    })
    .catch((error) => {
      console.log("error loading players/onboard");
    });
  await authStore
    .setPlayerData()
    .then((result) => {
      //
    })
    .catch((error) => {
      console.log("error on 'setPlayerData()'");
    });
  loader.toggleLoader(false);
};

const redirect = async (link: string) => {
  await startPlay();
  goToRoute(link);
};

const moveToPage = (i: number) => {
  currentPage.value = i;
  updateMaxSlide();
};

const close = () =>{
  emit("closeBoarding")
}
</script>

<template>
  <div class="backdrop">
    <div
      class="bg-1-3 radius-12 relative"
      :class="isMobile ? 'hola-onboarding-wrapper-mobile' : 'hola-onboarding-wrapper'"
    >
      <HolaBoardingPageComponent
        v-for="(page, i) in pagesData"
        :i="i"
        :key="i"
        v-show="i === currentPage"
        :page="page"
        :showCloseBtn="i === pagesData.length - 1 ? true : false"
        @redirect="redirect($event)"
      />

      <div
        class="flex flex-center pdx-2"
        :class="isMobile ? 'hola-prev-next-buttons-mobile' : 'hola-prev-next-buttons'"
      >
        <div class="row w-100 mgb-6">
          <div class="col-4">
            <span v-if="currentPage == 0" class="w-100">&nbsp;</span>
            <button
              v-if="currentPage > 0"
              type="button"
              class="btn btn-normal bg-1-1"
              @click="previousPage()"
            >
              {{ translate("common.back") }}
            </button>
          </div>
          <div class="col-4">
            <div class="carousel-nav mgt-1">
              <button
                @click="moveToPage(i)"
                v-for="(btn, i) of pagesData.length"
                v-bind:key="i"
                :disabled="maxSlide < i"
                :class="
                  i === currentPage
                    ? 'hola-carousel-indicator hola-current-slide'
                    : 'hola-carousel-indicator'
                "
              ></button>
            </div>
          </div>
          <div class="col-4">
            <button
              id="submit"
              type="button"
              class="btn btn-normal bg-2-1 float-right"
              @click="
                currentPage === pagesData.length - 1 ? close() : nextPage()
              "
            >
              {{
                currentPage === pagesData.length - 1
                  ? translate("common.start")
                  : translate("common.next")
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
