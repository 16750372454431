<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import LoaderVue from "@/components/shared/loader/LoaderComponent.vue";
import { useLoaderStore } from "@/stores/loader";
import { useLayoutModalsStore } from "@/stores/layoutModals";
import DepositModal from "@/brands/bit1111/components/deposit/DepositModal.vue";
import axios from "axios";
import BoardingSignupBonusModal from "@/brands/bit1111/components/boarding/BoardingSignupBonusModal.vue";
import { useBonusesStore } from "@/stores/bonuses";
import { goToRoute } from "@/router/routingService";

//Boarding for Bit1111 => Check for signupBonus, after it show Deposit.

const bonusStore = useBonusesStore();
const layoutModalsStore = useLayoutModalsStore();
const loader = useLoaderStore();
const hasSignupBonus = ref(false);
const signupBonusData = ref();

// const depositModal = computed(() => {
//   return layoutModalsStore.isModalActive("depositModal");
// });

const openDepositModal = () => {
  /* layoutModalsStore.activateModal({ name: "depositModal" }); */
  goToRoute('/deposit')
};

const onboardingFinished = async () => {
  await axios
    .put("/players/onboard", {})
    .then((response) => {})
    .catch((error) => {
      console.log(error);
    });
};

const getSignupBonus = async () => {
  loader.toggleLoader(true);
  await axios
    .get("/obtained/bonuses?bonusEvent=1&wallet=1&status=1")
    .then((response) => {
      loader.toggleLoader(false);
      response.data.data.length > 0
        ? ((signupBonusData.value = response.data.data[0]),
          (hasSignupBonus.value = true))
        : (onboardingFinished(), openDepositModal());
    })
    .catch((error) => loader.toggleLoader(false));
};

const signupModalClosed = async (event: boolean | string) => {
  hasSignupBonus.value = false;
  await onboardingFinished();
  openDepositModal();

  setTimeout(() => {
    bonusStore.loadAllBonuses();
  }, 2000);
};

onMounted(async () => {
  await getSignupBonus();
});
</script>
<template>
  <LoaderVue v-if="loader.getIsLoading"></LoaderVue>
  <!-- <DepositModal v-if="depositModal" /> -->
  <BoardingSignupBonusModal
    v-if="hasSignupBonus"
    :bonus="signupBonusData"
    @close="signupModalClosed"
  />
</template>

<style></style>
