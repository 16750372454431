<script setup lang="ts">
import { isMobileCheck } from "@/services/interface/clientInformation";
import { onMounted, ref,watch } from "vue";
import type { StringLinkElement } from "@/services/formatting/linkInjector";

interface Content {
  title: string;
  text: Array<StringLinkElement>;
  tag: string;
}

const props = defineProps({
  content: Array<Content>,
  headerStyle: {
    required: false,
    type: String,
  },
  textStyle: {
    required: false,
    type: String,
  },
});

const headercss = ref("pda-1 txt-1-1");
const txtcss = ref("mgy-5 mgx-5");

onMounted(() => {
  if (props.headerStyle) {
    headercss.value = props.headerStyle;
  }
  if (props.textStyle) {
    txtcss.value = props.textStyle;
  }
  if (isMobileCheck()) {
    headercss.value += " txt-size-16";
    txtcss.value += " txt-size-12";
  } else {
    headercss.value += " txt-size-20";
    txtcss.value += " txt-size-14";
  }
  // props.content.forEach(element => {
  //         element.text = stringToStringLinkArray(element.text);
  // });
});

// watch(
//   props,
//   () => {
//    props.content.forEach(element => {
//           element.text = stringToStringLinkArray(element.text);
//   });
//   },
//   { deep: true }
// );
</script>

<template>
  <div class="pdx-5">
    <div v-for="(element, i) in content" :key="i" class="mgb-5">
      <p :class="headercss">
        {{ element.title }}
      </p>
      <p :class="txtcss" style="white-space: pre-wrap">
        <template v-for="(el, k) in element.text" :key="k">
          <a
            v-if="el.isLink"
            :href="el.link"
            target="_blank"
            class="hover-txt-1-1 txt-3-1 txt-bold"
            >{{ el.value }}</a
          >
          <span v-else>{{ el.value }}</span>
        </template>
      </p>
    </div>
  </div>
</template>

<style lang="css"></style>
