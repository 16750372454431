<script setup lang="ts">
import { computed, ref, inject, watch, provide } from "vue";
import LoaderVue from "@/components/shared/loader/LoaderComponent.vue";
import { useLoaderStore } from "@/stores/loader";
import BonusNotification from "@/brands/bit1111/components/bonus/BonusNotificationComponent.vue";
import LoginModal from "@/brands/bit1111/views/desktop/auth/LoginModal.vue";
import RegistrationModal from "@/brands/bit1111/views/desktop/auth/RegistrationModal.vue";
import RegisterConfirmOneModal from "@/brands/bit1111/components/auth/RegisterConfirmOneModal.vue";
import RegisterConfirmTwoModal from "@/brands/bit1111/components/auth/RegisterConfirmTwoModal.vue";
import { useLayoutModalsStore } from "@/stores/layoutModals";
import BonusModal from "@/brands/bit1111/components/bonus/BonusModal.vue";
import ForgotPasswordModal from "@/brands/bit1111/views/desktop/auth/ForgotPasswordModal.vue";
import BoardingStagesWrapper from "@/brands/bit1111/components/boarding/BoardingStagesWrapper.vue";
import DepositModal from "@/brands/bit1111/components/deposit/DepositModal.vue";
import type { RegisterInitialPayloadInterface } from "@/services/interface/registrationInterface";
import PlayerAlertMessasageModal from "@/brands/bit1111/components/shared/modal/PlayerAlertMessageModal.vue";
import PageNotification from "@/brands/bit1111/components/shared/notifications/PageNotificationComponent.vue"

const layoutModalsStore = useLayoutModalsStore();
const loader = useLoaderStore();

/* const notifFlag = computed(() => {
  return layoutModalsStore.isModalActive("bonusNotification");
}); */

const boardingStagesWrapper = computed(() => {
  return layoutModalsStore.isModalActive("boarding");
});
const depositModal = computed(() => {
  return layoutModalsStore.isModalActive("depositModal");
});

const loginModal = computed(() => {
  return layoutModalsStore.isModalActive("login");
});
const registrationModal = computed(() => {
  return layoutModalsStore.isModalActive("register");
});

const registrationConfirmModalData = computed(() => {
  return layoutModalsStore.getModal("registerConfirm");
});
const registrationConfirmTwoModalData = computed(() => {
  return layoutModalsStore.getModal("registerConfirmLast");
});
const emailPasswordResetModal = computed(() => {
  return layoutModalsStore.isModalActive("emailPasswordReset");
});
const playerAlertMessage = computed(() => {
  return layoutModalsStore.isModalActive("playerAlertMessage");
});
const pageNotification = computed(() => {
  return layoutModalsStore.isModalActive("pageNotification");
});
const registrationConfirmStepOneUserData = ref<RegisterInitialPayloadInterface>({
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  gender: "",
});
</script>
<template>
  <LoaderVue v-if="loader.getIsLoading"></LoaderVue>
  <DepositModal v-if="depositModal" />
<!--   <BonusNotification v-if="notifFlag" /> -->
  <LoginModal v-if="loginModal" @close="loginModal = false" />
  <RegistrationModal v-if="registrationModal" @close="registrationModal = false" />
  <ForgotPasswordModal
    v-if="emailPasswordResetModal"
    @close="emailPasswordResetModal = false"
  />
  <RegisterConfirmOneModal
    v-if="registrationConfirmModalData.active"
    @close="registrationConfirmModalData.active = false"
    @emitData="registrationConfirmStepOneUserData = $event"
  />
  <RegisterConfirmTwoModal
    v-if="registrationConfirmTwoModalData.active"
    @close="registrationConfirmTwoModalData.active = false"
    :stepOneData="registrationConfirmStepOneUserData"
  />
  <BonusModal />
  <PageNotification v-if="pageNotification" />
  <BoardingStagesWrapper v-if="boardingStagesWrapper" />
  <PlayerAlertMessasageModal v-if="playerAlertMessage" />
</template>

<style></style>
