export const headerLinks = [
  {
    group: "play",
    icon: "casino",
    translation: "navigation.casino",
    link: "/casino",
    linkGroup: [],
  },
  {
    group: "Promotions",
    icon: "gift",
    translation: "navigation.promotions",
    link: "/promotions",
    linkGroup: [],
  },
 

  {
    group: "Account",
    icon: "profile",
    translation: "navigation.account",
    linkGroup: [
      {
        group: "Account",
        link: "/profile",
        icon: "gift",
        iconAlt: "Icon gift",
        linkText: "Profile",
        translation: "navigation.profile",
        linkGroup: [],
      },
      {
        group: "Account",
        link: "/personal-information",
        icon: "gift",
        iconAlt: "Icon gift",
        linkText: "Personal Information",
        translation: "navigation.personalInfo",
        linkGroup: [],
      },
      {
        group: "Account",
        link: "/change-password",
        icon: "gift",
        iconAlt: "Icon gift",
        linkText: "Change Password",
        translation: "navigation.changePass",
        linkGroup: [],
      },
      // {
      //   group: "Account",
      //   link: "/limits",
      //   icon: "gift",
      //   iconAlt: "Icon gift",
      //   linkText: "Protection Settings",
      //   translation: "navigation.protectionSettings",
      //   linkGroup: [],
      // },

      {
        group: "Account",
        link: "/kyc",
        icon: "gift",
        iconAlt: "Icon gift",
        linkText: "KYC",
        translation: "navigation.kyc",
        linkGroup: [],
      },
      {
        group: "Account",
        icon: "gift",
        link: "/bets-history",
        iconAlt: "Icon gift",

        linkText: "History",
        translation: "navigation.history",
        linkGroup: [
          {
            group: "history",
            link: "/bets-history",
            icon: "gift",
            iconAlt: "Icon gift",
            linkText: "Bets History",
            translation: "navigation.betsHistory",
          },
          {
            group: "history",
            link: "/bonus-history",
            icon: "gift",
            iconAlt: "Icon gift",
            linkText: "Bonus History",
            translation: "navigation.bonusHistory",
          },
          {
            group: "history",
            link: "/transaction-history",
            icon: "gift",
            iconAlt: "Icon gift",
            linkText: "Transaction History",
            translation: "navigation.transactionHistory",
          },
        ],
      },
    ],
  },
  {
    group: "Transactions",
    icon: "transfer",
    translation: "navigation.transactions",
    linkGroup: [
      {
        group: "transactions",
        link: "/deposit",
        icon: "gift",
        iconAlt: "Icon gift",
        translation: "navigation.deposit",
        linkText: "Deposit",
        linkGroup: [],
      },
      {
        group: "transactions",
        link: "/withdraw",
        icon: "gift",
        iconAlt: "Icon gift",
        translation: "navigation.withdrawal",
        linkText: "Withdrawal",
        linkGroup: [],
      },
     /*  {
        group: "transactions",
        link: "/transfer",
        icon: "gift",
        iconAlt: "Icon gift",
        translation: "navigation.transfer",
        linkText: "Transfer",
        linkGroup: [],
      }, */
    ],
  },
  {
    group: "Help",
    icon: "help",
    translation: "navigation.help",
    linkGroup: [
      {
        group: "support",
        link: "/support",
        icon: "gift",
        iconAlt: "Icon gift",
        translation: "navigation.support",
        linkText: "Support",
        linkGroup: [],
      },
      {
        group: "guide",
        link: "/guide",
        icon: "gift",
        iconAlt: "Icon gift",
        translation: "navigation.guide",
        linkText: "Blog",
        linkGroup: [],
      },
      {
        group: "faq",
        link: "/faq",
        icon: "gift",
        iconAlt: "Icon gift",
        translation: "navigation.faq",
        linkText: "FAQ",
        linkGroup: [],
      },
      {
        group: "terms",
        link: "/terms",
        icon: "gift",
        iconAlt: "Icon gift",
        translation: "navigation.terms",
        linkText: "Terms & Conditions",
        linkGroup: [],
      },
      {
        group: "security",
        link: "/security",
        icon: "gift",
        iconAlt: "Icon gift",
        translation: "navigation.privacy",
        linkText: "Privacy Policy",
        linkGroup: [],
      },
      {
        group: "gameExplanations",
        link: "/game-explanations",
        icon: "gift",
        iconAlt: "Icon gift",
        translation: "navigation.gameExplanations",
        linkText: "Game Explanations",
        linkGroup: [],
      },
      {
        group: "bonusRules",
        link: "/bonus-rules",
        icon: "gift",
        iconAlt: "Icon gift",
        translation: "navigation.bonusRules",
        linkText: "Bonus Rules",
        linkGroup: [],
      }
    ],
  },
];
