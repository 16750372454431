<script setup lang="ts">
import IconComponent from "../../icons/IconComponent.vue";
import InfiniteSlide from "./InfiniteSlideComponent.vue";

const props = defineProps({
  sliderElements: Array<string|any>,
  isInfoContent: Boolean,
  backgroundColor: String,
});

const scrollToLeft = () => {
  // console.log("Scroll left");
};

const scrollToRight = () => {
  // console.log("Scroll right");
};
</script>

<template>
  <div class="infinite-slider">
    <div class="slider-wrapper relative">
      <button
        :class="backgroundColor ? props.backgroundColor : 'bg-theme-bg-3'"
        type="button"
        class="arrow-icon prev"
        @click="scrollToLeft()"
      >
        <IconComponent
          :iconClasses="'icon-angle icon-32 inline-block bg-theme-txt-2'"
        ></IconComponent>
      </button>
      <button
        type="button"
        :class="backgroundColor ? props.backgroundColor : 'bg-theme-bg-3'"
        class="arrow-icon next"
        @click="scrollToRight()"
      >
        <IconComponent
          :iconClasses="'icon-angle icon-32 inline-block bg-theme-txt-2'"
        ></IconComponent>
      </button>
      <div
        :class="backgroundColor ? props.backgroundColor : 'bg-theme-bg-3'"
        class="slider"
      >
        <div class="slide-track">
          <slot v-if="!isInfoContent && sliderElements">
            <div v-for="(slide, i) in sliderElements" v-bind:key="i">
              <InfiniteSlide :singleSlide="slide"></InfiniteSlide>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
