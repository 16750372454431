import { useAuthStore } from "@/stores/auth";
import { useBalanceStore } from "@/stores/balance";
import type { MercureEventListener } from "./EventListenerInterface";

class CasinoReservedFundsEvent implements MercureEventListener {
  listener(event: MessageEvent) {
    const balanceStore = useBalanceStore();
    const eventData = JSON.parse(event.data);
    
    balanceStore.setReservedFunds(
      parseFloat(eventData.SB.reservedFunds).toFixed(2),
      "casino"
    );
    balanceStore.setReservedFunds(
      parseFloat(eventData.C.reservedFunds).toFixed(2),
      "sportbook"
    );
  }
  topic() {
    const authStore = useAuthStore();
    const player = authStore.$state.player;
    if (player == null) {
      throw new Error("Player not logged In");
    }
    
    const topic = "reserved-funds/player/" + player?.id;
    return topic;
  }

  on() {
    return "message";
  }
}

export default CasinoReservedFundsEvent;
