<script setup lang="ts">
import { ref, onMounted } from "vue";
import InputValueComponent from "@/brands/holahola/components/shared/forms/input/InputValueComponent.vue";
import { useLoaderStore } from "@/stores/loader";
import { translate } from "@/services/translations/translation";
import CheckBoxComponent from "@/components/shared/forms/checkbox/CheckBoxComponent.vue";
import type { Deposit } from "@/services/payment/depositAbstract/deposit";
import type { PropType } from "vue";
import type { InitiatorResponse } from "@/services/payment/depositAbstract/handleDeposit";
import ButtonComponent from "@/brands/bit1111/components/shared/buttons/ButtonComponent.vue";
import { computeFormValidation } from "@/services/validation/formValidation";

const props = defineProps({
  deposit: {
    required: true,
    type: Object as PropType<Deposit>,
  },
});

const emit = defineEmits(["back"]);
const back = () => {
  emit("back");
};

const loader = useLoaderStore();
const cardHolder = ref("Player Active");
const encCreditcardNumber = ref("4012888888881881");
const encCvv = ref("123");
const expiryYear = ref("2025");
const expiryDate = ref("10/25");
const paymentSuccess = ref();
const paymentSolution = ref("Visa/Mastercard");
const amount = ref(0);
const currency = ref("");
const initiatorResponse = ref<InitiatorResponse>();

const submit = async () => {
  loader.toggleLoader(true);
  let key: ValidationKey;
  for (key in validationList.value) {
    validationList.value[key].isTouched = true;
  }
  if (formisValid.value) {
  const additionalData = {
    cardHolder: cardHolder.value,
    encCreditcardNumber: encCreditcardNumber.value,
    encCvv: encCvv.value,
    expiryMonth: expiryDate.value.substring(0, 2),
    expiryYear: "20" + expiryDate.value.substring(3, 5),
  };

  await props.deposit.injectAdditionalData(additionalData);
  initiatorResponse.value = await props.deposit.initiateDeposit();
}
  loader.toggleLoader(false);
};

onMounted(() => {
  amount.value = props.deposit.getAmount();
});

const provider = ref([
  {
    backLinkTitle: "deposit",
    logo:
      "https://s.b-bets.com/images/payment/solutions/piq/creditcard/creditcard_select.svg",
    generals: [
      {
        method: "transactions.deposit.desktop.generals.paymentMethod",
        amount: "transactions.deposit.desktop.generals.amount",
      },
    ],
    form: [
      {
        name: {
          placeholder: "transactions.deposit.desktop.generals.form.name.placeholder",
          tip: "transactions.deposit.desktop.generals.form.name.tip",
        },
        number: {
          placeholder: "transactions.deposit.desktop.generals.form.number.placeholder",
          tip: "transactions.deposit.desktop.generals.form.number.tip",
        },
        date: {
          placeholder: "transactions.deposit.desktop.generals.form.date.placeholder",
        },
        digits: {
          placeholder: "transactions.deposit.desktop.generals.form.digits.placeholder",
          tip: "transactions.deposit.desktop.generals.form.digits.tip",
        },
        postalCode: {
          placeholder:
            "transactions.deposit.desktop.generals.form.postalCode.placeholder",
        },
      },
    ],
    checkbox: "transactions.deposit.desktop.generals.form.checkbox",
    submit: "transactions.deposit.desktop.generals.form.submit",
  },
]);
// VALIDATION START
const validationList = ref({
  name: { isTouched: false, valid: false },
  cardNum: { isTouched: false, valid: false },
  expDate: { isTouched: false, valid: false },
  cvv: { isTouched: false, valid: false },
});
type ValidationKey = keyof typeof validationList.value;

const formisValid = ref<boolean>(false);
const callbackValid = (pointer: ValidationKey, value: boolean) => {
  validationList.value[pointer].valid = value;
  computeFormValidation(validationList.value, formisValid);
};
// VALIDATION END
</script>

<template>
  <div class="payment-provider-wrapper">
    <div class="payment-provider-content pda-4">
      <div class="txt-center">
        <img
          src="https://i.ibb.co/DWTFNfj/visa.jpg"
          alt="provider name"
          class="provider-logo radius-6 w-100"
          loading="lazy"
        />
      </div>
      <div class="selected mgt-5">
        <div class="method mgx-3 flex">
          <p class="payment-method txt-theme-txt-2 text-small">
            {{ translate(provider[0].generals[0].method) }}
          </p>
          <p id="payment" class="label txt-theme-txt-2 text-small txt-bold">
            {{ paymentSolution }}
          </p>
        </div>
        <div class="amount mgx-3 flex">
          <p class="payment-amount txt-theme-txt-2 text-small">
            {{ translate(provider[0].generals[0].amount) }}
          </p>
          <p id="amount" class="label txt-theme-txt-2 text-small txt-bold">
            {{
              amount?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            }}
          </p>
        </div>
      </div>
      <form @submit.prevent>
        <div class="mgx-3 mgt-5">
          <InputValueComponent
            inputId="name"
            :placeholder="translate(provider[0].form[0].name.placeholder)"
            :disableValidation="true"
            v-model="cardHolder"
            textCutBorder="label-cut bg-1-3 border-top-1-2 border-width-1"
            textCutBorderFocused="label-cut bg-1-3 border-top-1-2 border-width-1"
            bgColor="bg-1-3 radius-26"
            :borderClass="' radius-26 border-1-2 border-width-1'"
            @isValid="callbackValid('name', $event)"
            v-model:isTouched="validationList.name.isTouched"
          >
          </InputValueComponent>
          <p class="txt-theme-txt-2 mgl-5">
            {{ translate(provider[0].form[0].name.tip) }}
          </p>
        </div>
        <div class="mgx-3 mgt-5">
          <InputValueComponent
            inputId="cardNum"
            :placeholder="translate(provider[0].form[0].number.placeholder)"
            :maxLength="16"
            :disableValidation="true"
            v-model="encCreditcardNumber"
            textCutBorder="label-cut bg-1-3 border-top-1-2 border-width-1"
            textCutBorderFocused="label-cut bg-1-3 border-top-1-2 border-width-1"
            bgColor="bg-1-3 radius-26"
            :borderClass="' radius-26 border-1-2 border-width-1'"
            @isValid="callbackValid('cardNum', $event)"
            v-model:isTouched="validationList.cardNum.isTouched"
          >
          </InputValueComponent>
          <p class="txt-theme-txt-2 mgl-5">
            {{ translate(provider[0].form[0].number.tip) }}
          </p>
        </div>

        <div class="card-data flex">
          <div class="w-50 mgx-3 mgt-5">
            <InputValueComponent
              inputId="expDate"
              :placeholder="translate(provider[0].form[0].date.placeholder)"
              :maxLength="5"
              :disableValidation="true"
              v-model="expiryDate"
              textCutBorder="label-cut bg-1-3 border-top-1-2 border-width-1"
              textCutBorderFocused="label-cut bg-1-3 border-top-1-2 border-width-1"
              bgColor="bg-1-3 radius-26"
              :borderClass="' radius-26 border-1-2 border-width-1'"
              @isValid="callbackValid('expDate', $event)"
            v-model:isTouched="validationList.expDate.isTouched"
            ></InputValueComponent>
          </div>
          <div class="digits w-50 mgt-5 mgx-3">
            <InputValueComponent
              inputId="cvv"
              :placeholder="translate(provider[0].form[0].digits.placeholder)"
              :maxLength="3"
              :disableValidation="true"
              v-model="encCvv"
              textCutBorder="label-cut bg-1-3 border-top-1-2 border-width-1"
              textCutBorderFocused="label-cut bg-1-3 border-top-1-2 border-width-1"
              bgColor="bg-1-3 radius-26"
              :borderClass="' radius-26 border-1-2 border-width-1'"
              @isValid="callbackValid('cvv', $event)"
            v-model:isTouched="validationList.cvv.isTouched"
            ></InputValueComponent>
          </div>
        </div>
        <p class="txt-error-light mgx-3 mgt-5" v-if="paymentSuccess == false">
          {{ translate("transactions.deposit.desktop.generals.errVisaCard") }}
        </p>
        <div class="mgt-5 mgx-3">
          <CheckBoxComponent
            labelClass="txt-theme-txt-2 title-large"
            :isChecked="false"
            :checkboxTitle="
              translate('transactions.deposit.desktop.generals.saveThisCardForFuture')
            "
          />
        </div>

        <p v-if="initiatorResponse?.isError" class="mgt-3 txt-error-1 mgx-5 txt-bold">
          {{ initiatorResponse?.responseMessage }}
        </p>

        <div class="flex flex-center mgt-7 mgb-3">
          <ButtonComponent
            class="mgx-5"
            id="back"
            type="secondaryFilled"
            :text="translate('common.back')"
            @click="back"
          />
          <ButtonComponent
            class="mgx-5"
            id="back"
            :text="translate('common.submit')"
            @click="submit"
          />
        </div>
      </form>
    </div>
  </div>
</template>
