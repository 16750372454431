import axios from "axios";
import { defineStore } from "pinia";
import { stringToStringLinkArray } from "@/services/formatting/linkInjector";
import type { StringLinkElement } from "@/services/formatting/linkInjector";

export interface ContentPage {
  title: string;
  text: Array<StringLinkElement> | string;
  type: string;
  urlSlug: string;
}
export const useContentPagesStore = defineStore({
  id: "contentpages",
  state: () => ({
    pages: {
      "terms-and-conditions": <Array<ContentPage>>[],
      "bonus-rules": <Array<ContentPage>>[],
      "games-explanations": <Array<ContentPage>>[],
      "privacy-policy": <Array<ContentPage>>[],
      "about": <Array<ContentPage>>[],
      "bonus-info": <Array<ContentPage>>[],
    },
    loaded: <boolean>false,
  }),
  actions: {
    async loadPages() {
      await axios
        .get("/content-pages")
        .then((result) => {
          result.data.data.forEach((element: ContentPage) => {
            if (this.pages[element.urlSlug.substring(1) as keyof typeof this.pages]) {
              this.pages[element.urlSlug.substring(1) as keyof typeof this.pages].push(element);
            }
          });
          this.loaded = true;

          // Object.keys(this.pages).forEach((key) => {
          //   this.pages[key as keyof typeof this.pages].forEach((element) => {
          //     element.text = stringToStringLinkArray(element.text as string);
          //   });
          // });
        })
        .catch((err) => {
          //catch
        });
    },
    async getContent(tag: string) {
      if (!this.loaded) {
        await this.loadPages();
      }
      return this.pages[tag as keyof typeof this.pages];
    },
    async formatContent() {
      //FORMAT FOR HTML ??
    },
  },
});
