import { ref } from 'vue';

const txtPOI = `To make sure your verification process goes as quick and smooth as possible the first step is the most important one. Part of this is to understand who you are and to make sure the account is registered in your name and you are the only one having access to it.
Therefore we ask you for preferably a copy of your passport. When this is not possible you can upload another form of ID like drivers license or ID card.`
const txtPOA = `The second check includes the upload of a document proving your address. It insures you and us that your account data is accurate and up to date and we will have no issues with sending your winnings for example. This can be a copy of a utility bill (such as a water/ electricity bill or a tax bill) or any sort of invoice letter (such as phone bill, insurance bill, payslip document etc.). The address verification document must be issued within the last 2 months and has to visibly show your name, address, and issue date.`
const txtBank = `The third document we ask you to upload is your bank statement. This is where you would like to receive your (future) pay outs. We are obliged to make sure the bank account is on your name and on your address provided in step 2. This way, we can make sure your winnings will come to you!
We also use your bank statement to do other checks we are obliged to do by law: such as source of income for example.`
const txtDeposit = `Why do we need proof of deposit? It is quite simple. You want to play in a safe environment, and we want to make sure that you are the person playing on your account. By providing this document we verify your proof of deposit and make sure that the money is actually being wired to you and nobody else.`

export const structureItems = [
    {
    id: 'poi',
    name: 'Proof Of Identity (POI)',
    icon: 'fingerprint',
    txt: txtPOI, 
    post: '/kyc/upload/1',
    type: "proof-identity-poi"
    },
    {
    id: 'poa',
    name: 'Proof Of Address (POA)',
    icon: 'home',
    txt: txtPOA,  
    post: '/kyc/upload/3',
    type: "proof-identity-poa"
    },
    {
    id: 'bank',
    name: 'Bank Statement',
    subtext: 'Not older than 2 months', 
    icon: 'bank', 
    txt: txtBank,  
    post: '/kyc/upload/2',
    type: "proof-identity-bank"
    },
    {
    id: 'pod',
    name: 'Proof Of Deposit (POD)',
    icon: 'deposit',
    txt: txtDeposit,  
    post: '/kyc/upload/4',
    type: "proof-identity-pod"
    }
]

export const reactItems = ref({
    "proof-identity-poi":{
                    dropdown: false, 
                    subtext: null,    
                    success: false,
                    submittedNotif: false, 
                    submitted: false,
                    verified: false,
                    files: [{}]
                    },
"proof-identity-poa":{
                    dropdown: false, 
                    subtext: 'Not older than 2 months',    
                    success: false,
                    submittedNotif: false,  
                    submitted: false,
                    verified: false,
                    files: [{}]
                    },
"proof-identity-bank":{
                    dropdown: false, 
                    subtext: 'Not older than 2 months',    
                    success: false,
                    submittedNotif: false,  
                    submitted: false,
                    verified: false,
                    files: [{}]
                    },
"proof-identity-pod":{
                    dropdown: false, 
                    subtext: null,
                    submittedNotif: false,     
                    success: false, 
                    submitted: false,
                    verified: false,
                    files: [{}]
                    }
}
)