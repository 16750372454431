export const getContentParams = () => {
  const {
    page = "overview",
    categoryIds,
    championshipIds,
    sportId,
    periodType,
    couponType,
    eventId,
  } = getSearchParams();
  return {
    page: page,
    ...(eventId && {
      eventId: Number(eventId),
    }),
    ...(categoryIds && {
      categoryIds: categoryIds.split(",").map((n) => Number(n)),
    }),
    ...(championshipIds && {
      championshipIds: championshipIds.split(",").map((n) => Number(n)),
    }),
    ...(sportId && {
      sportId: Number(sportId),
    }),
    ...(periodType && {
      periodType: Number(periodType),
    }),
    ...(couponType && {
      couponType: Number(couponType),
    }),
  };
};

export const getSearchParams = () => {
  const searchParams = Object.fromEntries(
    new URLSearchParams(document.location.search.substring(1)).entries()
  );

  return {
    integration: searchParams.integration || "condordev",
    ...searchParams, 
  };
};
