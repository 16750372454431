<script setup>
import { ref, watch } from "vue";
import IconComponent from "@/components/icons/IconComponent.vue";
import { translate } from "@/services/translations/translation";

const props = defineProps({
  label: String,
  isInlineSelect: Boolean,
  modalHeight: String,
  data: Array,
  selector: String,
  preselect: Boolean,
  isImage: Boolean,
  optionId: String,
  multiple: Boolean,
  testId: String,
  selected: [Object, String, Number],
  borderClass: String,
  textCutBorder: String,
  arrowIsVisible: {
    default: true,
    type: Boolean,
    required: false,
  },
  resetIsVisible: {
    default: false,
    type: Boolean,
    required: false,
  },
});

const filteredData = ref(props.data);
const emit = defineEmits(["emitSelected", "emitMultiple", "reset"]);
const isListOpen = ref(false);
const searchValue = ref("");
const selectedItem = ref("");
const selectedMultipleItem = ref([]);
const preselectComplete = ref(false);
const ignoreToggle = ref(false);
const isResetClicked = ref(false);

const toggleList = () => {
  if (!ignoreToggle.value && !isResetClicked.value) {
    isListOpen.value = !isListOpen.value;
    searchValue.value = "";
  } else {
    ignoreToggle.value = false;
    isResetClicked.value = false;
  }
};

const removeItem = (i) => {
  selectedMultipleItem.value.splice(i, 1);
  ignoreToggle.value = true;
  emit("emitSelected", selectedItem.value);
};

const selectOption = (event) => {
  if (props.multiple) {
    if (!selectedMultipleItem.value.includes(event)) {
      selectedMultipleItem.value.push(event);
    }
    emit("emitMultiple", selectedMultipleItem.value);
    toggleList();
  } else {
    selectedItem.value = event;
    emit("emitSelected", selectedItem.value);
    toggleList();
  }
};

const filterItems = () => {
  filteredData.value = [];
  for (let index = 0; index < props.data.length; index++) {
    if (
      props.selector
        ? props.data[index][props.selector]
            .toString()
            .toLowerCase()
            .includes(searchValue.value.toLowerCase())
        : props.data[index]
            .toString()
            .toLowerCase()
            .includes(searchValue.value.toLowerCase())
    ) {
      filteredData.value.push(props.data[index]);
    }
  }
};

const resetDropdown = () => {
  isResetClicked.value = true;
  selectedItem.value = "";
  emit("reset", true);
};

watch(
  props,
  (value) => {
    filteredData.value = props.data;
    if (props.selected) {
      selectedItem.value = props.selected;
    }
    if (props.preselect && !preselectComplete.value) {
      selectedItem.value = filteredData.value[0];
      preselectComplete.value = true;
      emit("emitSelected", selectedItem.value);
    }
  },
  { deep: true }
);
watch(
  searchValue,
  (value) => {
    filterItems();
  },
  { deep: true }
);

const topLevelClass = ref("");

const init = () => {
  if (props.isInlineSelect.value) {
    topLevelClass.value += "inline-select";
  } else if (!props.borderClass) {
    topLevelClass.value += "w-100 border-input mgy-4 ";
  }
  if (props.multiple) {
    topLevelClass.value += "multiple-select-height";
  }
  if (props.borderClass) {
    topLevelClass.value += props.borderClass;
  }
};
init();
</script>

<template>
  <div :class="topLevelClass" class="bg-5-1 relative w-100 mgt-4 select-wrapper">
    <div
      class="input-wrapper relative h-100"
      :class="props.multiple ? 'zindex-menu' : ''"
      @click="toggleList()"
      role="button"
      :id="optionId"
      aria-haspopup="true"
      tabindex="0"
      v-on:keyup.enter="isListOpen = true"
      v-on:keyup.esc="isListOpen = false"
      :data-testid="testId ? `toggle-${testId}` : null"
    >
      <span
        class="absolute"
        :class="[
          !props.multiple
            ? !selectedItem
              ? 'floating-label'
              : 'floating-label-touched'
            : !selectedMultipleItem[0]
            ? 'floating-label'
            : 'floating-label-touched',
          selectedItem ? [props.textCutBorder, 'txt-1-1'] : '',
        ]"
        v-if="label"
        :data-testid="testId ? `label-${testId}` : null"
        >{{ label }}</span
      >
      <input type="hidden" class="h-100" readonly tabindex="1" v-model="selectedItem" />

      <template v-if="!isImage">
        <div
          v-if="!props.multiple"
          class="shared-input w-100 pdt-4 pdl-2rem text-smallest"
          :data-testid="testId ? `selected-${testId}` : null"
        >
          {{ selector ? selectedItem[selector] : selectedItem }}
        </div>
        <div
          v-else
          class="flex flex-wrap multiple-select-height"
          :class="props.multiple ? 'zindex-menu' : ''"
        >
          <div class="flex flex-wrap mgt-5 w-90">
            <div
              class="border-input mga-1 flex flex-center radius-6 pda-1"
              v-for="(item, i) in selectedMultipleItem"
              :key="i"
            >
              <span
                class="mgl-2"
                :data-testid="testId ? `selected-multiple-${i}-${testId}` : null"
                >{{ selector ? item[selector] : item }}</span
              >
              <IconComponent
                @click="removeItem(i)"
                :data-testid="testId ? `remove-multiple-${i}-${testId}` : null"
                iconClasses="svg-icon icon-16 icon-form-clear bg-1-1 block mgx-2 zindex-tooltip"
              ></IconComponent>
            </div>
          </div>
        </div>
      </template>
      <span v-else>
        <img class="flag" :src="selectedItem[selector]" />
      </span>
      <div
        class="flex flex-center h-100"
        :class="!isInlineSelect ? 'input-right-btn' : 'inline-btn'"
      >
        <span class="flex flex-center">
          <span
            v-if="resetIsVisible && !multiple"
            @click="resetDropdown()"
            class="pdy-2"
          >
            <IconComponent
              iconClasses="svg-icon pointer-disable icon-10 icon-form-clear bg-400 block mgx-2 zindex-tooltips"
            ></IconComponent>
          </span>
          <img
            v-if="arrowIsVisible"
            src="@/assets/icons/ico-dropdown.svg"
            alt="Arrow icon"
            class="slow pdb-2 mgl-4 mgr-5 txt-light"
            :class="{
              'rotate-up': isListOpen,
              'icon-9': isInlineSelect,
              'icon-20': !isInlineSelect,
              absolute: props.multiple,
            }"
        /></span>
      </div>
    </div>

    <div
      v-if="isListOpen"
      class="zindex-modal relative absolute scrollbar-style border-4-5 radius-10 mgt-3"
      :class="{ 'w-100': !isInlineSelect }"
    >
      <ul class="bg-5-1 radius-10" :data-testid="testId ? `ul-${testId}` : null">
        <li>
          <input
            type="text"
            v-model="searchValue"
            class="search-input w-100 h-100 txt-light"
            :placeholder="translate('common.searchKey') + '...'"
          />
        </li>
        <li
          v-for="(item, i) in filteredData"
          v-bind:key="item.name"
          :id="optionId + (i + 1)"
          class="bg-5-1 hover-bg-5-2 hover-txt-1-1"
          @click="selectOption(item)"
          role="option"
          :data-testid="testId ? `li-${i}-${testId}` : null"
        >
          <span v-if="isImage">
            <img class="flag" :src="item[selector]" />
          </span>
          <span v-else>{{ selector ? item[selector] : item }}</span>
        </li>
      </ul>
    </div>
  </div>
  <div
    class="zindex-header w-100 h-100 fixed top-0 left-0 bg-transparent"
    @click.self="isListOpen = false"
    v-show="isListOpen"
  ></div>
</template>
