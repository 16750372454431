<script setup lang="ts">
import { ref, onMounted } from "vue";
import InputValueComponent from "@/components/shared/forms/input/InputValueComponent.vue";
import { useLoaderStore } from "@/stores/loader";
import { translate } from "@/services/translations/translation";
import CheckBoxComponent from "@/components/shared/forms/checkbox/CheckBoxComponent.vue";
import type { Deposit } from "@/services/payment/depositAbstract/deposit";
import type { PropType } from "vue";
import type { InitiatorResponse } from "@/services/payment/depositAbstract/handleDeposit";

const props = defineProps({
  deposit: {
    required: true,
    type: Object as PropType<Deposit>,
  },
});

const loader = useLoaderStore();
const cardHolder = ref("Player Active");
const encCreditcardNumber = ref("4012888888881881");
const encCvv = ref("123");
const expiryYear = ref("2025");
const expityDate = ref("10/25");
const paymentSuccess = ref();
const paymentSolution = ref("Visa/Mastercard");
const amount = ref(0);
const initiatorResponse = ref<InitiatorResponse>();

const submit = async () => {
  loader.toggleLoader(true);
  const additionalData = {
    cardHolder: cardHolder.value,
    encCreditcardNumber: encCreditcardNumber.value,
    encCvv: encCvv.value,
    expiryMonth: expityDate.value.substring(0, 2),
    expiryYear: "20" + expiryYear.value.substring(2, 4),
  };

  await props.deposit.injectAdditionalData(additionalData);
  initiatorResponse.value = await props.deposit.initiateDeposit();
  loader.toggleLoader(false);
};

onMounted(() => {
  amount.value = props.deposit.getAmount();
});

const provider = ref([
  {
    backLinkTitle: "deposit",
    logo:
      "https://s.b-bets.com/images/payment/solutions/piq/creditcard/creditcard_select.svg",
    generals: [
      {
        method: "transactions.deposit.desktop.generals.paymentMethod",
        amount: "transactions.deposit.desktop.generals.amount",
      },
    ],
    form: [
      {
        name: {
          placeholder: "transactions.deposit.desktop.generals.form.name.placeholder",
          tip: "transactions.deposit.desktop.generals.form.name.tip",
        },
        number: {
          placeholder: "transactions.deposit.desktop.generals.form.number.placeholder",
          tip: "transactions.deposit.desktop.generals.form.number.tip",
        },
        date: {
          placeholder: "transactions.deposit.desktop.generals.form.date.placeholder",
        },
        digits: {
          placeholder: "transactions.deposit.desktop.generals.form.digits.placeholder",
          tip: "transactions.deposit.desktop.generals.form.digits.tip",
        },
        postalCode: {
          placeholder:
            "transactions.deposit.desktop.generals.form.postalCode.placeholder",
        },
      },
    ],
    checkbox: "transactions.deposit.desktop.generals.form.checkbox",
    submit: "transactions.deposit.desktop.generals.form.submit",
  },
]);
</script>

<template>
  <div class="payment-provider-wrapper form-size">
    <h1 class="txt-center">
      {{ translate("transactions.deposit.desktop.generals.creditCard") }}
    </h1>
    <div class="payment-provider-content pda-4">
      <div class="txt-center">
        <img
          src="https://i.ibb.co/NSF3y9w/c-card.png"
          alt="provider name"
          class="provider-logo radius-6"
          loading="lazy"
        />
      </div>
      <div class="selected mgt-5">
        <div class="method mgx-3 flex">
          <p class="payment-method txt-theme-txt-2 text-small">
            {{ translate(provider[0].generals[0].method) }}
          </p>
          <p id="payment" class="label txt-theme-txt-2 text-small txt-bold">
            {{ paymentSolution }}
          </p>
        </div>
        <div class="amount mgx-3 flex">
          <p class="payment-amount txt-theme-txt-2 text-small">
            {{ translate(provider[0].generals[0].amount) }}
          </p>
          <p id="amount" class="label txt-theme-txt-2 text-small txt-bold">
            {{ amount }}
          </p>
        </div>
      </div>
      <form @submit.prevent>
        <div class="mgx-3 mgt-5">
          <InputValueComponent
            inputId="name"
            :placeholder="translate(provider[0].form[0].name.placeholder)"
            :disableValidation="true"
            v-model="cardHolder"
            textCutBorder="text-cut-border bg-theme-bg-1"
            borderClass="border-color-theme-brand-1 radius-5 border-input"
          >
          </InputValueComponent>
          <p class="txt-theme-txt-2 mgl-5">
            {{ translate(provider[0].form[0].name.tip) }}
          </p>
        </div>
        <div class="mgx-3 mgt-5">
          <InputValueComponent
            inputId="cardNum"
            :placeholder="translate(provider[0].form[0].number.placeholder)"
            :maxLength="16"
            :disableValidation="true"
            v-model="encCreditcardNumber"
            textCutBorder="text-cut-border bg-theme-bg-1"
            borderClass="border-color-theme-brand-1 radius-5 border-input"
          >
          </InputValueComponent>
          <p class="txt-theme-txt-2 mgl-5">
            {{ translate(provider[0].form[0].number.tip) }}
          </p>
        </div>

        <div class="card-data flex">
          <div class="w-50 mgx-3 mgt-5">
            <InputValueComponent
              inputId="expDate"
              :placeholder="translate(provider[0].form[0].date.placeholder)"
              :maxLength="5"
              :disableValidation="true"
              v-model="expityDate"
              textCutBorder="text-cut-border bg-theme-bg-1"
              borderClass="border-color-theme-brand-1 radius-5 border-input"
            ></InputValueComponent>
          </div>
          <div class="digits w-50 mgt-5 mgx-3">
            <InputValueComponent
              inputId="cvv"
              :placeholder="translate(provider[0].form[0].digits.placeholder)"
              :maxLength="3"
              :disableValidation="true"
              v-model="encCvv"
              textCutBorder="text-cut-border bg-theme-bg-1"
              borderClass="border-color-theme-brand-1 radius-5 border-input"
            ></InputValueComponent>
          </div>
        </div>
        <p class="txt-error-light mgx-3 mgt-5" v-if="paymentSuccess == false">
          {{ translate("transactions.deposit.desktop.generals.errVisaCard") }}
        </p>
        <div class="mgt-5 mgx-3">
          <CheckBoxComponent
            labelClass="txt-theme-txt-2 title-large"
            :isChecked="false"
            :checkboxTitle="
              translate('transactions.deposit.desktop.generals.saveThisCardForFuture')
            "
          />
        </div>

        <p v-if="initiatorResponse?.isError">{{ initiatorResponse?.responseMessage }}</p>

        <div class="ccard-cta mgt-6">
          <button
            id="submit"
            class="btn btn-success mgy-4 mgx-auto btn-large-100"
            @click="submit"
          >
            {{ translate("transactions.deposit.desktop.generals.form.submitVisa") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
