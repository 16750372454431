<script setup lang="ts">
import { ref } from "vue";
import { useRoute } from "vue-router";
import { useLoaderStore } from "@/stores/loader";
import axios from "axios";
import { axiosGet } from "@/services/axios/crudOperations";
import RealityCheckComponent from "@/components/notification/RealityCheckComponent.vue";
import { useAuthStore } from "@/stores/auth";
import NotificationComponent from "@/components/shared/notification/NotificationComponent.vue";
import { getOS } from "@/services/helper";

const authStore = useAuthStore();
const loader = useLoaderStore();
const route = useRoute();
const gameName = ref(route.query.gamename);
const gameLink = ref("");
const realityLimit = ref<number>(0);
const totalLimit = ref<number>(0);
const intervalId = ref();
const realityCheckFlag = ref(false);
const notificationError = ref(false);
const notificationErrorMsg = ref();
const axiosBodyRunGame = ref({
  gameUri: gameName.value,
  gameRedirectionUrls: {
    cashierUrl: window.location.origin + "/deposit",
    lobbyUrl: window.location.origin + "/casino",
  },
  language: "en",
  platform: 2,
  channel: getOS(),
});

const getGameLink = async () => {
  loader.toggleLoader(true);
  await axios
    .post("/games/play", axiosBodyRunGame.value)
    .then((response) => {
      gameLink.value = response.data.data.url;
      loader.toggleLoader(false);
    })
    .catch((err) => {
      notificationError.value = true;
      const error = err.response.data;
      if (error.message) {
        notificationErrorMsg.value = error.message;
      } else {
        notificationErrorMsg.value = error.detail;
      }
      console.log("getGameLink error", err.response.data);
      loader.toggleLoader(false);
    });
};

const closeRealityCheck = () => {
  realityCheckFlag.value = false;
  realityLimit.value = totalLimit.value;
};
const logoutFromRC = () => {
  realityCheckFlag.value = false;
  authStore.logout();
};

const init = async () => {
  await getGameLink();
  const response = await axiosGet("/player-limits/time");
  if (response) {
    if (response.minutes) {
      realityLimit.value = response.minutes * 1; //should be *60 for seconds, I left it as *1 for easier testing
      totalLimit.value = realityLimit.value;
    }
  }

  if (realityLimit.value) {
    intervalId.value = setInterval(() => {
      realityLimit.value = realityLimit.value - 1;
      if (realityLimit.value <= 0) {
        realityCheckFlag.value = true;
      }
    }, 1000 * 1);
  }
};
init();
</script>

<template>
  <RealityCheckComponent
    v-if="realityCheckFlag"
    @closeRC="closeRealityCheck"
    @logout="logoutFromRC"
  />
  <div class="form-size">
    <NotificationComponent
      v-show="notificationError"
      class="mgy-3"
      title="Game failed to start!"
      :messageBody="notificationErrorMsg"
      bgClass="bg-error"
      @emitDismiss="false"
    ></NotificationComponent>
  </div>
  <div class="casino-wrapper">
    <iframe id="game-iframe" :src="gameLink"></iframe>
  </div>
</template>
