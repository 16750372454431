import type { ValidationError, DefaultError, ResponseError } from "./ErrorInterface";
import type { AxiosError } from "axios";
export class ErrorHandler {

  handle(error: AxiosError): DefaultError | ValidationError {
    if (error.response?.status == 422) {
      return this.validationHandle(error);
    } else {
      return this.defaultHandle(error);
    }
  }
  validationHandle(error: AxiosError): ValidationError {
    const validationErrorObj: ValidationError = {
      message: "",
      code: 0,
      status: 0,
      errors: [],
    };
    const res = anyType(error.response?.data);

    const getResponseErrors = () => {
      const allErrors = <Array<ResponseError>>[];
      res.errors.forEach((element: ResponseError) => {
        allErrors.push(element);
      });
      return allErrors;
    };

    if ("message" in res && "errors" in res) {
      validationErrorObj.message = res.message;
      validationErrorObj.errors = getResponseErrors();
      validationErrorObj.status = error.response?.status as number;
      validationErrorObj.code = error.response?.status as number;
    }
    return validationErrorObj;
  }
  defaultHandle(error: AxiosError): DefaultError {
    const errorObj: DefaultError = {
      message: "Interval Server Error! Please contact administrators!",
      code: 0,
      status: 0,
    };
    const res = anyType(error.response?.data);
    if ("message" in res) {
      errorObj.message = res.message;
    }
    if ("detail" in res) {
      errorObj.message = res.detail;
    }
    errorObj.status = error.response?.status as number;
    errorObj.code = error.response?.status as number;

    if (errorObj.status === 503) {
      return this.systemError(errorObj);
    }
    return errorObj;
  }
  systemError(errorObj: DefaultError): DefaultError {
    errorObj.message =
      "Service is unavailable. Please contact the administrator";
    return errorObj;
  }
}

function anyType(object: any): any {
  return object;
}
