<script setup lang="ts">
import { computed } from "vue";
import BonusNotification from "@/brands/bit1111/components/bonus/BonusNotificationComponent.vue";
import BonusModal from "@/components/bonus/BonusModal.vue";
import { useAuthStore } from "@/stores/auth";
import PlayerAlertMessasageModal from "@/brands/bit1111/components/shared/modal/PlayerAlertMessageModal.vue";
import { useLayoutModalsStore } from "@/stores/layoutModals";
import BoardingStagesMobileWrapper from "../components/boarding/BoardingStagesMobileWrapper.vue";
import PageNotification from "@/brands/bit1111/components/shared/notifications/PageNotificationComponent.vue"

const authStore = useAuthStore();
const layoutModalsStore = useLayoutModalsStore();
const notifFlag = computed(() => {
  return layoutModalsStore.isModalActive("bonusNotification");
});
const playerAlertMessage = computed(() => {
  return layoutModalsStore.isModalActive("playerAlertMessage");
});
const pageNotification = computed(() => {
  return layoutModalsStore.isModalActive("pageNotification");
});
const boardingStagesWrapper = computed(() => authStore.getBoardingFlag);
</script>

<template>
  <BonusNotification v-if="notifFlag" />
  <BonusModal />
  <PageNotification v-if="pageNotification" />
 <BoardingStagesMobileWrapper v-if="!boardingStagesWrapper"/>
  <PlayerAlertMessasageModal v-if="playerAlertMessage" />
</template>
