import { formatCarouselGames } from "@/services/formatting/gameFormatter";
export const moveToPage = async (i: number, data: any) => {
  if (i >= 0 && i < data.value.carouselLength) {
    if (i > data.value.currentPage) {
      data.value.transitionByEnter = `translateX(${data.value.transitionValue}px)`;
      data.value.transitionByLeave = `translateX(-${data.value.transitionValue}px)`;
    } else {
      data.value.transitionByEnter = `translateX(-${data.value.transitionValue}px)`;
      data.value.transitionByLeave = `translateX(${data.value.transitionValue}px)`;
    }
    data.value.currentPage = i;
  }
};

export const touchEvent = (event: any, i: number, carouselData: any) => {
  if (carouselData.value.touchXValue < event.changedTouches[0].screenX && i <= 6 ) {
    moveToPage(i - 1, carouselData);
  } else if (
    carouselData.value.touchXValue > event.changedTouches[0].screenX && i < 6
  ) {
    moveToPage(i + 1, carouselData);
  }
};

export const calculateCarouselLength = (carouselData: any, gamesLength: number, splitGamesBy: number, rows: number) => {
  carouselData.value.carouselLength = Math.ceil(
    gamesLength / splitGamesBy / rows
  );
};

export const splitCarouselGames = (allSplitedGames: any, carouselLength: number, rows: number, games: any, formatData: any,) => {
  let carouselGamesSplit = <Array<any>>[];
  allSplitedGames.value = [];
  for (let i = 0; i < carouselLength; i++) {
    for (let j = 0; j < rows; j++) {
      let slicedGames = games.slice(
        i *
          rows *
          formatCarouselGames(formatData).visibleGames +
          j * formatCarouselGames(formatData).visibleGames,
        i *
          rows *
          formatCarouselGames(formatData).visibleGames +
          j * formatCarouselGames(formatData).visibleGames +
          formatCarouselGames(formatData).visibleGames
      );
      carouselGamesSplit.push(slicedGames);
    }
    allSplitedGames.value.push(carouselGamesSplit);
    carouselGamesSplit = [];
  }
};