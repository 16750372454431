<script setup lang="ts">
import IconComponent from "../../icons/IconComponent.vue";
import { ref, onMounted, watch } from "vue";
import { headerLinks } from "@/services/data/navList";
import { goToRoute } from "@/router/routingService";

const props = defineProps({
  navigationFlag: Boolean,
});

const mobileMenu = ref(true);
const displayMenu = ref(false);

const emit = defineEmits(["toggleMenu"]);

const onMenuClick = () => {
  if (mobileMenu.value) {
    displayMenu.value = !displayMenu.value;
  }
  emit("toggleMenu");
};
const goToLink = (link: string) => {
  // onMenuClick();
  goToRoute(link);
};

const isMounted = ref(false);
onMounted(() => {
  isMounted.value = true;
});

watch(
  props,
  () => {
    displayMenu.value = props.navigationFlag;
  },
  { deep: true }
);
</script>

<template>
  <div class="menu-wrapper zindex-menu mgl-3">
    <div>
      <button
        type="button"
        class="menu block bg-transparent pda-2 mobile-menu-toggle"
        :class="displayMenu ? 'menu-active' : ''"
        @click="onMenuClick()"
      >
        <div
          :class="
            !displayMenu
              ? 'line  bg-theme-brand-2'
              : 'line  bg-theme-brand-2 transformMenu'
          "
          v-for="line in [1, 2, 3]"
          v-bind:key="line"
        ></div>
      </button>
    </div>
  </div>
</template>
