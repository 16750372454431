import { defineStore } from "pinia";
import type { Notification } from "@/services/interface/notifications";
import axios from "axios";

export const useNotificationStore = defineStore({
  id: "notification",
  state: () => ({
    notifications: <Array<Notification>>[],
    notSeen: <number>0,
  }),  
  actions: {
    async getNotifications() {
      if(this.notifications.length === 0) {
         await this.fetchNotifications()
      }
     
      this.getUnseenNotifications();
      return this.notifications    
    },
    async fetchNotifications() {
      await axios
        .get("/notification")
        .then((response: any) => (this.notifications = response.data.data))
        .catch((error) => console.log(error));  
    },
    async getUnseenNotifications() {
      this.notSeen = 0;
        this.notifications.forEach((element) => {         
          if (!element.seen && this.notSeen < 99) {
            this.notSeen += 1
          }
        });
    },
    async setAllNotificationsSeen() {
      await axios.post("/notification/seen", {data: null})
      .then(response =>  this.notSeen = 0)
      .catch(error => console.log(error))
     
    }, 
  },
});
