export const initChat = (chatId: string) => {
    const chatDiv = document.createElement("div");
    chatDiv.id = `comm100-button-${chatId}`;
    document.body.appendChild(chatDiv);

    const scriptFunction = `
    var Comm100API = Comm100API || {};
      (function (t) {
        function e(e) {
          var a = document.createElement("script"),
            c = document.getElementsByTagName("script")[0];
          (a.type = "text/javascript"),
            (a.async = !0),
            (a.src = e + t.site_id),
            c.parentNode.insertBefore(a, c);
        }
        (t.chat_buttons = t.chat_buttons || []),
          t.chat_buttons.push({
            code_plan: "${chatId}",
            div_id: "comm100-button-${chatId}",
          }),
          (t.site_id = 90005226),
          (t.main_code_plan = "${chatId}"),
          e("https://vue.comm100.com/livechat.ashx?siteId="),
          setTimeout(function () {
            t.loaded ||
              e("https://standby.comm100vue.com/livechat.ashx?siteId=");
          }, 5e3);
      })(Comm100API || {});
    `

    const scriptElement = document.createElement("script");
    scriptElement.innerHTML = scriptFunction;
    scriptElement.type = "text/javascript";
    document.body.appendChild(scriptElement);
    
  }