import { Deposit } from "@/services/payment/depositAbstract/deposit";
import type { HandleDepositData } from "@/services/payment/depositAbstract/deposit";
import {
  NoAdditionalDataHandler,
  AdditionalDataHandler,
  NotImplemented,
} from "@/services/payment/depositImplementations/additionalDataHandle";
import {
  DirectInitiator,
  SkrillMidStepInitiator,
  NotSupported,
} from "@/services/payment/depositImplementations/initiators";

export class PIQVisa extends Deposit {
  constructor(handleData: HandleDepositData) {
    super(
      new AdditionalDataHandler("creditCard"),
      new DirectInitiator(),
      handleData
    );
  }
}

export class MandatoCC extends Deposit {
  constructor(handleData: HandleDepositData) {
    super(
      new AdditionalDataHandler("creditCard"),
      new DirectInitiator(),
      handleData
    );
  }
}

export class BitPaceFixedDeposit extends Deposit {
  constructor(handleData: HandleDepositData) {
    super(new NoAdditionalDataHandler(), new DirectInitiator(), handleData);
  }
}

export class PIQSkrill extends Deposit {
  constructor(handleData: HandleDepositData) {
    super(
      new NoAdditionalDataHandler(),
      new SkrillMidStepInitiator(),
      handleData
    );
  }
}

export class PIQNeteller extends Deposit {
  constructor(handleData: HandleDepositData) {
    super(
      new AdditionalDataHandler("neteller"),
      new DirectInitiator(),
      handleData
    );
  }
}

export class INTERNALDirect extends Deposit {
  constructor(handleData: HandleDepositData) {
    super(new NoAdditionalDataHandler(), new DirectInitiator(), handleData);
  }
}
export class MANDATODirect extends Deposit {
  constructor(handleData: HandleDepositData) {
    super(new NoAdditionalDataHandler(), new DirectInitiator(), handleData);
  }
}

export class NotSuported extends Deposit {
  constructor(handleData: HandleDepositData) {
    super(new NotImplemented(), new NotSupported(), handleData);
  }
}
