<script setup lang="ts">
import { ref, onMounted } from "vue";
import { items } from "@/brands/bit1111/data/footer";
import { translate } from "@/services/translations/translation";
import { useAuthStore } from "@/stores/auth";
import axios from "axios";
import FooterCarouselWrapperComponent from "./FooterCarouselWrapperComponent.vue";

const authStore = useAuthStore();

const localItems = ref<Array<any>>([]);

onMounted(async () => {
  items.forEach((element) => {
    localItems.value.push(element);
  });
  let categories: Array<any> = [];
  const children: Array<any> = [];
  await axios
    .get("/dropdown/game-category")
    .then((res) => {
      categories = res.data.data;
    })
    .catch((err) => {
      //
    });
  categories.forEach((element) => {
    children.push({
      txt: element.name,
      path: `/casino?category=${element.id}`,
    });
  });
  localItems.value.push({
    txt: "Casino",
    children: children,
  });
});
</script>

<template>
  <div>
    <div class="bg-5-1">
      <div class="pdy-7">
        <span v-for="(item, i) in localItems" :key="i"
          ><h1 class="txt-light txt-size-24 pdb-3 pdt-5 txt-center">
            {{ item.txt }}
          </h1>
          <div class="mgx-10">
            <RouterLink v-for="(child, j) in item.children" :key="j" :to="child.path">
              <p class="footer-link txt-4-7 hover-txt-1-1 txt-size-16 mgy-2 txt-center">
                {{ child.txt }}
              </p></RouterLink
            >
          </div>
        </span>
        <FooterCarouselWrapperComponent :isMobile="true" />
      </div>

      <!-- this need to be removed - only for presentation -->
      <div class="mgb-5 fit-content mgx-auto">
        <a
          target="_blank"
          href="https://anjouanoffshorefinanceauthority.org/gaming-licenses/orange-dawn-technology/"
          ><img
            src="https://i.ibb.co/q1jKFzX/Screenshot-from-2023-08-11-10-42-16-removebg-preview.png"
            alt="Screenshot-from-2023-08-11-10-42-16-removebg-preview"
            border="0"
        /></a>
      </div>
      <div class="mga-5 flex flex-center flex-wrap mgx-10">
        <div class="w-25">
          <div class="play-responsibly w-45px mgy-2"><div>18+</div></div>
        </div>

        <div class="w-75">
          <RouterLink to="responsible-gaming"
            ><p class="footer-link txt-4-7 title-small pre-wrap">
              Gaming can be addictive! Please play responsibly!
            </p></RouterLink
          >
        </div>
      </div>
      <!-- this need to be removed - only for presentation -->
      <div
        class="minh-81px bg-dark w-100 flex flex-center txt-center pdy-5 txt-size-10 pdx-5"
      >
        <div class="flex flex-wrap">
          <p class="txt-light pdy-5 txt-500">
            {{ translate("navigation.footer.copyright") }}
          </p>
          <p class="txt-light txt-500">
            {{ translate("navigation.footer.worldwide") }}
          </p>
        </div>
      </div>
      <div class="h-64px" v-if="!authStore?.$state?.player"></div>
    </div>
  </div>
</template>
