export const homeSliderData = [
  {
    // image: "https://i.ibb.co/yyrz5fW/banner-register.jpg",
    image: "https://i.ibb.co/3RWCrfX/hero-img.png",
    imageMobile:
      "https://i.ibb.co/30CWYKV/banner-landing.jpg",
    imageAlt: "banner image alt 1",
    title: "",
    offer: "",
  },
  // {
  //   image:
  //     "https://i.ibb.co/w6kjKz7/banner-landing-promotion1.jpg",
  //   imageMobile:
  //     "https://i.ibb.co/Sx8WfZB/banner-landing-sport.jpg",
  //   imageAlt: "banner image alt 2",
  //   title: "",
  //   offer: "",
  // },
  // {
  //   image:
  //     "https://i.ibb.co/znThjCJ/banner-landing-promotion2.jpg",
  //   imageMobile:
  //     "https://i.ibb.co/znThjCJ/banner-landing-promotion2.jpg",
  //   imageAlt: "banner image alt 2",
  //   title: "",
  //   offer: "",
  // },


];

export const homeSliderDesktopData = [
  {
    image:
      "/src/assets/images/HolaHola/hero-img.png",
    imageAlt: "banner image alt 1",
    title: "",
    offer: "",
  }
]

export const homeSliderData1 = [
  {
    image:
      "https://s.24bettle.com/images/amkt/24B-welcome-bonus-autumn-index-desktop-1920x554-EN.png",
    imageMobile:
      "https://s.24bettle.com/images/amkt/24B-welcome-bonus-autumn-mobile-375x280-EN.png",
    imageAlt: "banner image alt 2",
    title: "",
    offer: "",
  },
  {
    image:
      "https://s.24bettle.com/images/amkt/24B-carlos-weekly-adventures-index-desktop-1920x554NO_CARLOS-EN.png",
    imageMobile:
      "https://s.24bettle.com/images/amkt/24B-carlos-weekly-adventures-index-mobile-375x280-NO_CARLOS-ENEN.png",
    imageAlt: "banner image alt 2",
    title: "",
    offer: "",
  },
];
export const homeSliderData2 = [
  {
    image:
      "https://s.24bettle.com/images/amkt/24B-index-sportsbook-EN-1--DESKTOPspring2022-spring2022.png",
    imageMobile:
      "https://s.24bettle.com/images/amkt/24B-index-sportsbook-EN-MOBILEspring2022-spring2022.png",
    imageAlt: "banner image alt 2",
    title: "",
    offer: "",
  },
  {
    image:
      "https://s.b-bets.com/images/amkt/bb-index-telegram-boosted-1900x462.jpg",
    imageMobile:
      "https://s.b-bets.com/images/amkt/bb-index-telegram-boosted-500x600.jpg",
    imageAlt: "banner image alt 2",
    title: "",
    offer: "",
  },
];

export const sectionsData = [
  // {
  //   group: "sports",
  //   sections: [
  //     {
  //       icon: "soccer",
  //       iconAlt: "sports icon",
  //       title: "Sports",
  //       image: "https://s.b-bets.com/images/amkt/BB-IndexBanner-Hellish.jpg",
  //       imageAlt: "sports",
  //       link: "#",
  //     },
  //     {
  //       icon: "live-sports",
  //       iconAlt: "live sports icon",
  //       title: "live sports",
  //       image: "",
  //       imageAlt: "live sports",
  //       link: "#",
  //     },
  //     {
  //       icon: "placeholder",
  //       iconAlt: "virtual sports icon",
  //       title: "virtual sports",
  //       image: "",
  //       imageAlt: "virtual sports",
  //       link: "#",
  //     },
  //   ],
  // },

  {
    group: "casino",
    sections: [
      {
        icon: "casino",
        iconAlt: "casino games icon",
        title: "casino games",
        image: "https://s.b-bets.com/images/amkt/BB-IndexBanner-Hellish.jpg",
        imageAlt: "casino games",
        link: "#",
      },
      {
        icon: "live-casino",
        iconAlt: "live casino icon",
        title: "live casino",
        image: "https://s.big5casino.com/images/amkt/b5-index-bacobo-1440x430-en.png",
        imageAlt: "live casino",
        link: "#",
      },
      {
        icon: "slot",
        iconAlt: "slots icon",
        title: "slots",
        image: "https://s.big5casino.com/images/amkt/b5-index-oryx-trick-or-spin-1440x430-en.gif",
        imageAlt: "slots",
        link: "#",
      },
    ],
  },

  // {
  //   group: "promotions",
  //   sections: [
  //     {
  //       icon: "gift",
  //       iconAlt: "gift icon",
  //       title: "welcome bonuses",
  //       image: "",
  //       imageAlt: "welcome bonuses",
  //       link: "#",
  //     },
  //     {
  //       icon: "auction",
  //       iconAlt: "auctions icon",
  //       title: "auctions",
  //       image: "",
  //       imageAlt: "auctions",
  //       link: "#",
  //     },
  //     {
  //       icon: "percent",
  //       iconAlt: "promo icon",
  //       title: "all promos",
  //       image: "",
  //       imageAlt: "promos",
  //       link: "#",
  //     },
  //   ],
  // },
];
