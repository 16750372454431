<script setup lang="ts">
import IconComponent from "../../components/icons/IconComponent.vue";
import { goToRoute } from "@/router/routingService";
import { onMounted, ref, onUnmounted } from "vue";
import { useLayoutModalsStore } from "@/stores/layoutModals";

const title = "Error 403";
const textBold = "You are unauthorized to see this page";
const firstParagraph = ` Try to login on to our casino. If you are logged in, please contact our Support so we can fix the problem.`;
const returnToHomePage = "RETURN TO HOME PAGE";

const timeoutHomeRedirect = ref();
onMounted(() => {
  goToRoute("/login");
  timeoutHomeRedirect.value = setTimeout(() => {
    goToRoute("/");
  }, 500);
});
onUnmounted(() => {
  clearTimeout(timeoutHomeRedirect.value);
});
</script>

<template>
  <div class="errorpages-wrapper row flex flex-center txt-theme-brand-1">
    <div class="col-4 col-size relative box txt-center bg-404">
      <IconComponent
        :iconClasses="'icon-24 inline-block bg-theme-brand-1 tr-icon mgb-7'"
        :svgImage="'src/assets/icons/ico-warning.svg'"
      ></IconComponent>
      <h1 class="spacing-xl txt-theme-brand-1">{{ title }}</h1>
      <p class="txt-bold txt-theme-txt-1 title-smallest">{{ textBold }}</p>
      <p class="txt-theme-txt-1 title-smallest mgb-5">{{ firstParagraph }}</p>
      <RouterLink
        class="btn-large txt-bold bg-theme-brand-1 txt-theme-bg-1 pda-4"
        :to="{ name: 'home' }"
      >
        {{ returnToHomePage }}
      </RouterLink>
    </div>
  </div>
</template>
