import axios from "axios";

export async function registerStepOne(data: any) {
  let response = null;
  let error = null;
  // Save data to state (for further use in resending the confirmation email)
  await axios
    .post("api/auth/registration/init", data)
    .then((res) => {
      response = res;
    })
    .catch((err) => {
      error = err;
    });
  if (error) {
    throw error;
  } else {
    return response;
  }
}

export async function registerStepTwo(data: any) {
  // Save data to state (for further use in resending the confirmation email)
  let response = null;
  let error = null;
  await axios
    .post("api/registration/confirm", data)
    .then((res) => {
      response = res;
    })
    .catch((err) => {
      error = err;
    });

  if (error) {
    throw error;
  } else {
    return response;
  }
}

