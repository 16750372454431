<script setup lang="ts">
import { useLayoutModalsStore } from "@/stores/layoutModals";
import { isMobileCheck } from "@/services/interface/clientInformation";
import ButtonComponent from "@/brands/bit1111/components/shared/buttons/ButtonComponent.vue";
import { translate } from "@/services/translations/translation";

const isMobile = isMobileCheck();
const layoutModals = useLayoutModalsStore();
const modal = layoutModals.getModal("bonusNotification");
const closeNotification = () => {
  layoutModals.closeModal("bonusNotification");
};
</script>

<template>
  <div class="backdrop">
    <div
      class="bg-4-6 radius-12 relative"
      :class="isMobile ? 'sign-up-wrapper-mobile' : 'sign-up-wrapper'"
    >
      <h3 class="txt-center mgb-5">{{ translate("promotions.desktop.bonusApplied") }}</h3>

      <div class="space-between flex mgb-3">
        <p>{{ translate("common.bonus") }}:</p>
        <p id="balance" class="txt-1-1 txt-bold">{{ translate("common.name") }}:</p>
      </div>
      <div class="space-between flex mgb-5">
        <p>{{ translate("common.amount") }}::</p>
        <p id="balance" class="txt-1-1 txt-bold">{{ modal.data }}</p>
      </div>
      <span class="w-100 flex flex-center">
        <ButtonComponent
          type="primaryFilled"
          :heightPx="38"
          :width="50"
          text="Ok"
          @click="closeNotification"
      /></span>
    </div>
  </div>
</template>
