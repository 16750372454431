<script setup lang="ts">
import { ref } from "vue";
import SlideComponent from "./SlideComponent.vue";
import type { PropType } from "vue";
import type { SliderPropsInterface } from "@/services/interface/widgets";

const props = defineProps({
  props: {
    required: false,
    type: Object as PropType<SliderPropsInterface>,
  },
});

const currentSlide = ref(0);
const timerFunction = ref();

const nextSlide = () => {
  currentSlide.value++;
  if (
    currentSlide.value ==
    (props.props ? (props.props.sliderData ? props.props.sliderData.length : 0) : 0)
  ) {
    currentSlide.value = 0;
  }
};
const previousSlide = () => {
  currentSlide.value--;
  if (currentSlide.value < 0) {
    currentSlide.value = props.props
      ? props.props.sliderData
        ? props.props.sliderData.length - 1
        : 0
      : 0;
  }
};

const moveToSlide = (dotIndex: number) => {
  currentSlide.value = dotIndex;
};

const initTimer = () => {
  timerFunction.value = setInterval(function () {
    nextSlide();
  }, 5000);
};
const stopSlider = () => {
  clearInterval(timerFunction.value);
};
const init = () => {
  initTimer();
};

init();
</script>

<template>
  <div class="slider-selector" v-if="props.props">
    <div
      class="slider-wrapper flex w-100"
      v-on:mouseover="stopSlider()"
      v-on:mouseleave="initTimer()"
    >
      <div class="carousel-container relative w-100">
        <button
          class="carousel-btn btn-left"
          @click="previousSlide()"
          aria-label="previous slide"
        >
          <img src="../../../assets/icons/ico-angle.svg" alt="Previous" class="icon-36" />
        </button>
        <button
          class="carousel-btn btn-right"
          @click="nextSlide()"
          aria-label="next slide"
        >
          <img
            src="../../../assets/icons/ico-angle.svg"
            alt="Next"
            class="icon-36 next"
          />
        </button>

        <SlideComponent :slide="props.props.sliderData[currentSlide] ?? null" />

        <div class="carousel-nav mgt-1 zindex-tooltip">
          <button
            v-for="i in props.props.sliderData?.length"
            v-bind:key="i"
            @click="moveToSlide(i - 1)"
            :class="
              i - 1 === currentSlide
                ? 'carousel-indicator current-slide'
                : 'carousel-indicator'
            "
            :aria-label="'move to slide ' + i"
          ></button>
        </div>
      </div>
    </div>
  </div>
</template>
