<script setup lang="ts">
import IconComponent from "../../components/icons/IconComponent.vue";
import DividerComponent from "../../components/shared/divider/DividerComponent.vue";
import InputValueComponent from "../../components/shared/forms/input/InputValueComponent.vue";
import { ref, computed } from "vue";
import { useBalanceStore } from "../../stores/balance";
import axios from "axios";
import { translate } from "@/services/translations/translation";

const balanceStore = useBalanceStore();
const currentWallet = ref("Casino");
const receivingWallet = ref("Sportbook");
const transferAmount = ref();

const wallets = computed(() => [
  {
    title: "Casino",
    wallet: balanceStore.getWallet("casino"),
  },
  {
    title: "Sports",
    wallet: balanceStore.getWallet("sportbook"),
  },
]);

const remainingWagering = ref();

const swapWallet = () => {
  if (currentWallet.value == "Casino") {
    currentWallet.value = "Sportbook";
    receivingWallet.value = "Casino";
    remainingWagering.value = wallets.value[1].wallet.remainingWageringAmount;
  } else {
    currentWallet.value = "Casino";
    receivingWallet.value = "Sportbook";
    remainingWagering.value = wallets.value[0].wallet.remainingWageringAmount;
  }
};

const transfer = async () => {
  const payload = {
    from: currentWallet.value == "Casino" ? 1 : 2,
    to: currentWallet.value == "Casino" ? 2 : 1,
    amount: parseFloat(transferAmount.value),
  };
  await axios
    .post("wallet/transfer", payload)
    .then((result) => {
      // console.log("walet transfer success: ", result);
    })
    .catch((err) => {
      console.log("walet transfer error: ", err);
    });
};

const init = () => {
  remainingWagering.value = wallets.value[0].wallet.remainingWageringAmount;
};

init();
</script>

<template>
  <div class="payment-provider-wrapper form-size">
    <p class="txt-error-light mga-5 text-smallest" v-if="remainingWagering > 0">
      {{ translate("transactions.transfer_wallet.mobile.unable") }} ${{
        remainingWagering
      }}
    </p>

    <!-- <app-header-focus [title]="title | titlecase"></app-header-focus> -->
    <div class="pda-4">
      <div class="flexTr flex mga-2" v-for="(wallet, i) in wallets" v-bind:key="i">
        <div class="flexTr flex">
          <IconComponent
            :iconClasses="'icon-24 svg-cover block bg-theme-brand-1 icon-wallet'"
          ></IconComponent>
          <span class="block mgl-4 txt-theme-txt-2 txt-bold">{{ wallet.title }}</span>
        </div>
        <div :id="`${wallet.title.toLowerCase()}Amount`" class="txt-theme-brand-1">
          ${{ wallet.wallet.total }}
        </div>
      </div>
    </div>

    <div class="row mgx-5">
      <div class="col-4 txt-left">
        <div class="flex flex-left-center">
          <p class="txt-theme-txt-2">{{ translate("common.from") }}</p>
          <IconComponent
            :iconClasses="'icon-16 svg-cover block bg-brand icon-back rotate-up '"
          ></IconComponent>
        </div>

        <p :class="'txt-theme-brand-1'">{{ currentWallet }}</p>
      </div>
      <div class="col-4 grid-center">
        <button
          class="flexTr flex pda-4 btn-brand-light-secondary radius-5 pdx-5"
          :class="currentWallet == 'Sportsbook' ? 'row-reverse' : ''"
          @click="swapWallet()"
        >
          <span id="switch" class="">{{ translate("common.switch").toUpperCase() }}</span>
        </button>
      </div>
      <div class="col-4 txt-right">
        <div class="flex flex-right-center">
          <IconComponent
            :iconClasses="'icon-16 svg-cover block bg-brand icon-back rotate-up mgr-2'"
          ></IconComponent>
          <p class="txt-theme-txt-2">{{ translate("common.to") }}</p>
        </div>

        <p :class="'txt-theme-brand-1'">
          {{ receivingWallet }}
        </p>
      </div>
    </div>
    <div class="form-size pda-4">
      <InputValueComponent
        inputId="transferAmount"
        :placeholder="`${translate(
          'transactions.transfer_wallet.mobile.amount'
        )} $ (EUR)`"
        v-model="transferAmount"
        :regexValidator="'decimal'"
      >
      </InputValueComponent>
      <div class="ccard-cta mgt-6">
        <button
          id="submit"
          class="btn-large btn-brand-light mg-center"
          :disabled="!transferAmount || transferAmount == 0 || remainingWagering > 0"
          @click="transfer"
        >
          <span class="txt-bold">{{ translate("common.transfer").toUpperCase() }}</span>
        </button>
      </div>
    </div>

    <DividerComponent :dividerClass="'w-95 mgx-auto mgt-6 bg-dark400'"></DividerComponent>
    <div class="ccard-cta mgt-6 pda-4">
      <button id="changeWallet" class="btn btn-large bg-theme-brand-2 mg-center">
        <span class="txt-bold">{{
          translate("transactions.transfer_wallet.mobile.change")
        }}</span>
      </button>
    </div>
  </div>
</template>
