<script setup lang="ts">
import { ref } from "vue";
import InputValueComponent from "@/brands/bit1111/components/shared/forms/input/InputValueComponent.vue";
import { useLoaderStore } from "@/stores/loader";
import type { Deposit } from "@/services/payment/depositAbstract/deposit";
import type { PropType } from "vue";
import type { InitiatorResponse } from "@/services/payment/depositAbstract/handleDeposit";
import { translate } from "@/services/translations/translation";
import ButtonComponent from "@/brands/bit1111/components/shared/buttons/ButtonComponent.vue";
const props = defineProps({
  deposit: {
    required: true,
    type: Object as PropType<Deposit>,
  },
});

const loader = useLoaderStore();
const account = ref("netellertest1@bambora.com");
const initiatorResponse = ref<InitiatorResponse>();

const emit = defineEmits(["back"]);
const back = () => {
  emit("back");
};

const submit = async () => {
  loader.toggleLoader(true);
  const additionalData = {
    account: account.value,
  };

  await props.deposit.injectAdditionalData(additionalData);
  initiatorResponse.value = await props.deposit.initiateDeposit();
  loader.toggleLoader(false);
};
</script>

<template>
  <div class="form-size mgb-4">
    <div class="mgb-4 flex">
      <div :class="` txt-center card-deposit`">
        <img
          src="https://i.ibb.co/bRYJPP6/neteller-select.png"
          class="border-piq radius-6 cursor-pointer w-100"
        />
      </div>
    </div>
    <div class="pdx-3 mgy-4">
      <InputValueComponent
        inputId="account"
        :placeholder="translate('common.account')"
        v-model="account"
        :inputType="'text'"
      ></InputValueComponent>
    </div>
    <p v-if="initiatorResponse?.isError" class="mgt-3 txt-error-1 mgx-5 txt-bold">
      {{ initiatorResponse?.responseMessage }}
    </p>
    <div class="flex flex-center mgt-7 mgb-3">
      <ButtonComponent
        class="mgx-5"
        id="back"
        type="secondaryFilled"
        :text="translate('common.back')"
        @click="back"
      />
      <ButtonComponent
        class="mgx-5"
        id="back"
        :text="translate('common.submit')"
        @click="submit"
      />
    </div>
  </div>
</template>
