export const links = [
  {
    id: 1,
    name: "account.user_account_menu.desktop.account",
    icon: "profile",
    link: "",
    active: false,
    children: [],
  },
  {
    id: 2,
    name: "account.user_account_menu.desktop.transactions",
    icon: "transfer",
    link: "",
    active: false,
    children: [],
  },
  {
    id: 3,
    name: "account.user_account_menu.desktop.history",
    icon: "time",
    link: "",
    active: false,
    children: [],
  },
  {
    id: 4,
    name: "account.user_account_menu.desktop.help",
    icon: "help",
    link: "",
    active: false,
    children: [],
  },
];
