<script setup lang="ts">
import { ref } from "vue";
const props = defineProps({
  step: Number,
});

const divClassSelected =
  "border-input border-2-1 radius-20 square-25 bg-1-3 mgx-5 flex flex-center pdt-1";

const pClassSelected = "txt-2-1";
const divClassEmpty =
  "border-input border-1-3 radius-20 square-25 txt-center mgx-5 flex flex-center pdt-1";

const pClassEmpty = "txt-1-3";
</script>
<template>
  <div class="flex flex-center mgy-5">
    <div :class="step == 1 ? divClassSelected : divClassEmpty">
      <p :class="step == 1 ? pClassSelected : pClassEmpty">1</p>
    </div>
    <div :class="step == 2 ? divClassSelected : divClassEmpty">
      <p :class="step == 2 ? pClassSelected : pClassEmpty">2</p>
    </div>
    <div :class="step == 3 ? divClassSelected : divClassEmpty">
      <p :class="step == 3 ? pClassSelected : pClassEmpty">3</p>
    </div>
  </div>
</template>
