<script setup lang="ts">
import { useLayoutModalsStore } from "@/stores/layoutModals";
import InputValueComponent from "@/components/shared/forms/input/InputValueComponent.vue";
import InputPasswordComponent from "@/components/shared/forms/input/InputPasswordComponent.vue";
import DropDownComponent from "@/components/shared/forms/dropdown/DropDownComponent.vue";
import { ref, onMounted } from "vue";
import { registerStepOne } from "@/services/auth/registration";
import { useLoaderStore } from "@/stores/loader";
import { useAuthStore } from "@/stores/auth";
import DividerComponent from "@/components/shared/divider/DividerComponent.vue";
import SocialsComponent from "@/brands/holahola/components/auth/SocialsTwoComponent.vue";
import RegistrationStepCompoment from "@/brands/holahola/components/auth/RegistrationStepComponent.vue";
import { goToRoute } from "@/router/routingService";
import type { FormResponseValidation } from "@/services/interface/registrationInterface";
import type { LooseObject } from "@/services/interface/utils";
import { ErrorHandler } from "@/lib/exceptions/ErrorHandler";
import { translate } from "@/services/translations/translation";
import IconComponent from "@/components/icons/IconComponent.vue";

interface RegisterInitialPayloadInterface {
  currency: string;
  email: string;
  password: string;
  username: string;
}

const registerData = ref<RegisterInitialPayloadInterface>({
  currency: "",
  email: "",
  password: "",
  username: "",
});

const formResponseValidation = ref<LooseObject>({
  username: [],
  password: [],
  email: [],
});

const loader = useLoaderStore();
const authStore = useAuthStore();
const layoutModalsStore = useLayoutModalsStore();
const selectedCurrency = ref<any>();
const hideResponseErrors = ref(false);

// VALIDATION START
const newValidationList = ref({
  // gender: Boolean,
  username: Boolean,
  email: Boolean,
  password: Boolean,
  currency: Boolean,
  // phoneCodeMock:Boolean
});
type ValidationKey = keyof typeof newValidationList.value;

const formisValid = ref();
const callbackValid = (pointer: ValidationKey, value: BooleanConstructor) => {
  newValidationList.value[pointer] = value;
  computeFormValidation();
};
const computeFormValidation = () => {
  let flag = true;
  let key: ValidationKey;
  for (key in newValidationList.value) {
    if (!newValidationList.value[key]) flag = false;
  }
  formisValid.value = flag;
};
// VALIDATION END

const close = () => {
  layoutModalsStore.closeModal("register");
};

const reRoute = (url: string) => {
  goToRoute("/login");
  close();
};

const submit = () => {
  const data = {
    username: registerData.value.username.toLowerCase(),
    email: registerData.value.email,
    currency: selectedCurrency.value.code.toString(),
    password: registerData.value.password,
  };
  loader.toggleLoader(true);
  registerStepOne(data)
    .then(async (response) => {
      hideResponseErrors.value = true;

      await authStore.login(data.username, data.password);
      // await authStore.setPlayerData();
      goToRoute(`/register/mailsent?email=${data.email}`);
      close();
      loader.toggleLoader(false);
    })
    .catch((error) => {
      hideResponseErrors.value = false;

      for (const key of Object.keys(formResponseValidation.value)) {
        formResponseValidation.value[key] = [];
      }
      const handler = new ErrorHandler();
      const err = handler.handle(error);
      err.errors?.forEach((element) => {
        formResponseValidation.value[
          element.property as keyof FormResponseValidation
        ].push(element.message);
      });
      loader.toggleLoader(false);
    });
};
</script>
<template>
  <div>
    <div class="backdrop"></div>
    <form @submit.prevent>
      <div class="auth-modal bg-theme-bg-1 pda-5">
        <div type="" class="flex flex-right">
          <IconComponent
            :iconClasses="'svg-icon block icon-16  icon-form-clear bg-theme-bg-7 cursor-pointer'"
            @click="close"
          ></IconComponent>
        </div>

        <h1 class="title-large mgt-5 txt-theme-txt-1 txt-bold mgx-auto fit-content">
          {{ translate("auth.register_init.desktop.header") }}
        </h1>
        <p class="txt-theme-txt-2 mgx-auto fit-content text-smallest txt-bold">
          {{ translate("auth.register_init.desktop.header_description") }}
        </p>
        <RegistrationStepCompoment :step="1" />
        <InputValueComponent
          :responseErrors="formResponseValidation.username"
          :hideResponseErrors="hideResponseErrors"
          @hideErrors="hideResponseErrors = $event"
          inputId="name"
          :placeholder="translate('auth.register_init.desktop.username')"
          regexValidator="username"
          v-model="registerData.username"
          :minLength="5"
          :maxLength="25"
          :borderClass="'border-input radius-5 border-color-theme-brand-1'"
          @isValid="callbackValid('username', $event)"
        />
        <DropDownComponent
          dropdownId="currency"
          :label="translate('auth.register_init.desktop.currency')"
          url="/currencies"
          :selector="'code'"
          :preselect="true"
          :selected="selectedCurrency"
          @emitSelected="selectedCurrency = $event"
          :borderClass="'border-input radius-5 border-color-theme-brand-1 '"
          @isValid="callbackValid('currency', $event)"
        />
        <InputValueComponent
          inputId="email"
          :placeholder="translate('auth.register_init.desktop.email')"
          regexValidator="email"
          v-model="registerData.email"
          :minLength="5"
          :maxLength="64"
          :borderClass="'border-input radius-5 border-color-theme-brand-1 w-50'"
          :responseErrors="formResponseValidation.email"
          :hideResponseErrors="hideResponseErrors"
          @hideErrors="hideResponseErrors = $event"
          @isValid="callbackValid('email', $event)"
        />
        <InputPasswordComponent
          passId="password"
          :label="translate('auth.register_init.desktop.password')"
          v-model="registerData.password"
          :minLength="8"
          :maxLength="25"
          :borderClass="'border-input radius-5 border-color-theme-brand-1'"
          :responseErrors="formResponseValidation.password"
          :hideResponseErrors="hideResponseErrors"
          @hideErrors="hideResponseErrors = $event"
          @isValid="callbackValid('password', $event)"
        />
        <!-- <CheckBoxTermsAndConditions /> -->
        <button
          id="submit"
          class="bg-theme-brand-2 mgb-6 mgt-4 txt-bold pdx-5 pdy-3 radius-5 w-100"
          @click="submit()"
          :disabled="!formisValid"
        >
          {{ translate("auth.register_init.desktop.submit") }}
        </button>
        <div>
          <p class="txt-theme-txt-2 text-smallest mgx-auto fit-content">
            {{ translate("auth.register_init.desktop.already_have_account") }}
            <span class="txt-theme-brand-2 cursor-pointer" @click="reRoute('/login')">{{
              translate("auth.register_init.desktop.login")
            }}</span>
          </p>
        </div>
        <DividerComponent
          :dividerClass="'w-90 mgx-auto mgb-6 mgt-6 bg-dark400'"
        ></DividerComponent>
        <SocialsComponent />
      </div>
    </form>
  </div>
</template>
