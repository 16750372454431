<script setup>
import { onMounted, onUnmounted, ref, computed, watch } from "vue";
import { useLoaderStore } from "@/stores/loader";
import axios from "axios";
import { useTranslationStore } from "@/stores/translation";
import { translate } from "@/services/translations/translation";
import {
  getContentParams,
  getSearchParams,
} from "@/services/sportsbook/sportsbook";
import { getLanguage } from "@/services/sportsbook/sportsbookLanguages";
const translationStore = useTranslationStore();
const userToken = ref("");
const fatalErrorLoading = ref("");
const loader = useLoaderStore();
const srcSportsBook =
  "https://sb2widgetsstatic-altenar2-stage.biahosted.com/altenarWSDK.js";
const language = computed(() => {
  return translationStore.language;
});
const getUserSportsbookToken = async () => {
  await loadSportBook();/*
  loader.toggleLoader(true);
  axios
    .get(`games/altenar`)
    .then(async (response) => {
      userToken.value = response.data.data.token;
      loader.toggleLoader(false);
      await loadSportBook();
    })
    .catch((error) => {
      loader.toggleLoader(false);
      fatalErrorLoading.value = translate("sportsbook.failedLoading");
    });*/
};

const { integration, culture, token, ...rest } = getSearchParams();

const finishedLoadingSport = () => {
  window.altenarWSDK.init({
    integration,
    culture: language.value.code,
    token: userToken.value,
  });
  window.addEventListener("popstate", () => {
    window.altenarWSDK.set({
      ...getContentParams(),
    });
  });
  const root = document.getElementById("container");

  window.ASB = window.altenarWSDK.addSportsBook({
    props: {
      onRouteChange: (data) => {
        const url = new URL(window.location.href);
        const keysToSave = ["integration", "culture", "token"];
        const urlSearchParams = new URLSearchParams(data);
        url.searchParams.forEach((value, key) => {
          if (keysToSave.includes(key)) urlSearchParams.set(key, value);
        });
        url.search = urlSearchParams.toString();
        window.history.pushState(null, "", url);
      },
      onSignInButtonClick: () => {
        const token = window.prompt("Token");
        if (token) window.altenarWSDK.set({ token });
      },
      routerType: "memory",
      ...getContentParams(),
    },
    container: root.appendChild(document.createElement("div")),
  });
};
const script = document.createElement("script");
const loadSportBook = async () => {
  script.id = "sportsbook-script";
  const getScript = document.getElementById("sportsbook-script");
  try {
    if (!getScript) {
      loader.toggleLoader(true);
      script.src = srcSportsBook;

      script.async = true;

      document.head.appendChild(script);

      script.addEventListener("load", () => {
        finishedLoadingSport();
        loader.toggleLoader(false);
      });
    }
  } catch (err) {
    loader.toggleLoader(false);
    fatalErrorLoading.value = translate("sportsbook.failedLoading");
  }
};

onMounted(async () => {
  await getUserSportsbookToken();
});

onUnmounted(() => {
  if (document.head.contains(script)) {
    script.removeEventListener("load", finishedLoadingSport);
    document.head.removeChild(script);
    window.ASB.remove();
  }
});

watch(language, (value) => {
  console.log(value);
  console.log(getLanguage(value.code));
  window.altenarWSDK.set({
    culture: getLanguage(value.code),
  });
});
</script>
<template>
  <div>
    <div v-if="!fatalErrorLoading" id="container"></div>
    <div v-if="fatalErrorLoading" class="txt-center mgt-6 title-medium">
      {{ fatalErrorLoading }}
    </div>
  </div>
</template>
