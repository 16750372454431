interface FormatCarouselGames {
    visibleGames: number,
    transitionValue: number,
    breakpoints: Array<number>,
    removeGamesOnBreakpoint: number, 
    gameWidth: number

}

export const formatCarouselGames = (data: FormatCarouselGames) => {
  let windowWidth = window.innerWidth;
  let games = data.visibleGames;  
  let calculatedTransitionValue = data.transitionValue;
  if (windowWidth > 768) {
    for (let i = 0; i < data.breakpoints.length; i++) {
      if (data.breakpoints[i] > windowWidth) {
        calculatedTransitionValue -= data.removeGamesOnBreakpoint * data.gameWidth;
        games -= data.removeGamesOnBreakpoint;
      }
    }
  }
  return {visibleGames: games, transitionValue: calculatedTransitionValue}
};
