import Unavailable from "@/views/errors/Unavailable.vue";
import Maintenance from "@/views/errors/Maintenance.vue";
import { RolePlayer, RolePlayerInactive } from "@/services/auth/roles";
import HomePage from "@/views/landing/HomePageView.vue";
import CasinoPage from "@/views/casino/CasinoView.vue";
import PromotionsDesktopView from "@/brands/holahola/views/desktop/bonus/PromotionsDesktopView.vue";
import ProfileView from "@/views/account/ProfileView.vue";
import RegisterView from "@/views/auth/RegisterInitialView.vue";
import RegisterConfirm from "@/views/auth/CompleteRegistrationView.vue";
import RegisterConfirmEmailView from "@/views/auth/RegisterConfirmEmailView.vue";
import BetsHistoryView from "@/views/history/BetsHistoryView.vue";
import BetsHistoryMobileView from "@/views/history/mobile/BetsHistoryMobile.vue";
import TransactionHistoryView from "@/views/history/TransactionHistoryView.vue";
import TransactionHistoryMobile from "@/views/history/mobile/TransactionHistoryMobile.vue";
import BonusHistoryView from "@/views/history/BonusHistoryView.vue";
import BonusHistoryMobile from "@/views/history/mobile/BonusHistoryMobile.vue";
import DepositView from "@/views/transactions/DepositView.vue";
import WithdrawalView from "@/views/transactions/WithdrawalView.vue";
import TransferWalletView from "@/views/transactions/TransferWalletView.vue";
import SupportView from "@/views/support/SupportView.vue";
import ResponsibleGamingView from "@/views/other/ResponsibleGamingView.vue";
import TermsAndConditions from "@/views/termsAndConditions/TermsAndConditions.vue";
import Faq from "@/views/faq/FaqViews.vue";
import LimitsView from "@/views/account/limits/LimitsView.vue";
import DepositLimitView from "@/views/account/limits/DepositLimitView.vue";
import WageringLimitView from "@/views/account/limits/WageringLimitView.vue";
import LossLimitView from "@/views/account/limits/LossLimitView.vue";
import TimeLimitView from "@/views/account/limits/TimeLimitView.vue";
import PersonalInformationView from "@/views/account/PersonalInformationView.vue";
import LoginView from "@/views/auth/LoginView.vue";
import KYCView from "@/views/account/kyc/KYCView.vue";
import QuestionnaireView from "@/views/account/kyc/QuestionnaireView.vue";
import GameView from "@/views/casino/GameView.vue";
import ForgotPasswordView from "@/views/auth/ForgotPasswordView.vue";
import ResetPasswordView from "@/views/auth/ResetPasswordView.vue";
import PaymentRedirectView from "@/views/transactions/PaymentRedirectView.vue";
import ErrorPage from "@/views/errors/404.vue";
import UnauthorizedView from "@/views/errors/403.vue";
import type { RouterInterface } from "@/services/interface/router";
import AboutView from "@/views/other/AboutView.vue";
import SportsBookView from "@/views/sportsBook/SportsBookView.vue";
import ChangePasswordView from "@/views/account/changePassword/ChangePasswordView.vue";
import SecurityAndPrivacy from "@/views/other/SecurityAndPrivacy.vue";
import DepositModal from "@/brands/holahola/components/deposit/DepositModal.vue";
import DepositRedirect from "@/brands/bit1111/views/desktop/transactions/DepositRedirect.vue";
import WithdrawalRedirect from "@/brands/bit1111/views/desktop/transactions/WithdrawalRedirect.vue";


import AboutContent from "@/brands/bit1111/views/desktop/support/About.vue";
import BonusesInfo from "@/brands/bit1111/views/desktop/support/BonusesInfo.vue"; 
import AboutContentMobile from "@/brands/bit1111/views/mobile/support/About.vue";
import BonusesInfoMobile from "@/brands/bit1111/views/mobile/support/BonusesInfo.vue"; 

import GuideView from "@/views/support/Guide.vue"

export const realRoutes: Array<RouterInterface> = [
  {
    path: "/",
    name: "home",
    mobileComponent: HomePage,
    desktopComponent: HomePage,
    meta: { isFocusedHeader: false },
  },
  {
    path: "/casino",
    name: "casino",
    mobileComponent: CasinoPage,
    desktopComponent: CasinoPage,
    meta: { requiresAuth: false, role: RolePlayer, isFocusedHeader: false },
  },
  {
    path: "/promotions",
    name: "promotions",
    mobileComponent: PromotionsDesktopView,
    desktopComponent: PromotionsDesktopView,
    meta: { requiresAuth: false, isFocusedHeader: false },
  },
  {
    path: "/promotions/:id",
    name: "promotions-single",
    mobileComponent: PromotionsDesktopView,
    desktopComponent: PromotionsDesktopView,
    meta: { requiresAuth: false, isFocusedHeader: false },
  },
  {
    path: "/profile",
    name: "profile",
    mobileComponent: ProfileView,
    desktopComponent: ProfileView,
    meta: { requiresAuth: true, role: RolePlayer, isFocusedHeader: false },
  },
  {
    on: true,
    path: "/register",
    name: "register",
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    mobileComponent: RegisterView,
    desktopComponent: RegisterView,
    meta: {
      requiresNotAuth: true,
      isFocusedHeader: false,
      pageToModal: true,
    },
  },
  {
    path: "/registration/confirm",
    name: "registerConfirm",
    mobileComponent: RegisterConfirm,
    desktopComponent: RegisterConfirm,
    meta: {
      requiresAuth: true,
      role: RolePlayerInactive,
      checkRegistrationToken: true,
      isFocusedHeader: false,
      pageToModal: true,
    },
  },
  {
    path: "/register/mailsent",
    name: "registerMailsent",
    mobileComponent: RegisterConfirmEmailView,
    desktopComponent: RegisterConfirmEmailView,
    meta: {
      requiresAuth: true,
      role: RolePlayerInactive,
      isFocusedHeader: false,
    },
  },
  {
    path: "/bets-history",
    name: "betsHistory",
    mobileComponent: BetsHistoryMobileView,
    desktopComponent: BetsHistoryView,
    meta: { requiresAuth: true, role: RolePlayer, isFocusedHeader: false },
  },
  {
    path: "/transaction-history",
    name: "transactionHistory",
    mobileComponent: TransactionHistoryMobile,
    desktopComponent: TransactionHistoryView,
    meta: { requiresAuth: true, role: RolePlayer, isFocusedHeader: false },
  },
  {
    path: "/bonus-history",
    name: "bonusHistory",
    mobileComponent: BonusHistoryMobile,
    desktopComponent: BonusHistoryView,
    meta: { requiresAuth: true, role: RolePlayer, isFocusedHeader: false },
  },
  {
    path: "/deposit",
    name: "depositModal",
    mobileComponent: DepositView,
    desktopComponent: DepositModal,
    meta: {
      pageToModal: true,
      requiresAuth: true,
      role: RolePlayer,
      isFocusedHeader: false,
    },
  },
  {
    path: "/withdraw",
    name: "Withdraw",
    mobileComponent: WithdrawalView,
    desktopComponent: WithdrawalView,
    meta: { requiresAuth: true, role: RolePlayer, isFocusedHeader: false },
  },
  {
    path: "/transfer",
    name: "Transfer",
    mobileComponent: TransferWalletView,
    desktopComponent: TransferWalletView,
    meta: { requiresAuth: true, role: RolePlayer, isFocusedHeader: false },
  },

  {
    path: "/support",
    name: "Support",
    mobileComponent: SupportView,
    desktopComponent: SupportView,
    meta: { requiresAuth: false, isFocusedHeader: false },
  },
  {
    path: "/guide/:url?",
    name: "Guide",
    mobileComponent: GuideView,
    desktopComponent: GuideView,
    meta: { requiresAuth: false },
  },
  {
    path: "/responsibility",
    name: "Responsible Gaming",
    mobileComponent: ResponsibleGamingView,
    desktopComponent: ResponsibleGamingView,
    meta: { requiresAuth: false, role: RolePlayer, isFocusedHeader: false },
  },
  {
    path: "/terms",
    name: "TermsAndConditions",
    mobileComponent: TermsAndConditions,
    desktopComponent: TermsAndConditions,
    meta: { role: RolePlayer, isFocusedHeader: false },
  },
  {
    path: "/faq",
    name: "Faq",
    mobileComponent: Faq,
    desktopComponent: Faq,
    meta: { role: RolePlayer, isFocusedHeader: false },
  },

  {
    path: "/limits",
    name: "Protection Settings",
    mobileComponent: LimitsView,
    desktopComponent: LimitsView,
    meta: { requiresAuth: true, role: RolePlayer, isFocusedHeader: false },
    children: [
      {
        path: "",
        name: "Deposit limit",
        mobileComponent: DepositLimitView,
        desktopComponent: DepositLimitView,
        meta: {
          requiresAuth: true,
          role: RolePlayer,
          isFocusedHeader: false,
        },
      },
      {
        path: "wagering",
        name: "Wagering limit",
        mobileComponent: WageringLimitView,
        desktopComponent: WageringLimitView,
        meta: {
          requiresAuth: true,
          role: RolePlayer,
          isFocusedHeader: false,
        },
      },
      {
        path: "loss",
        name: "Loss limit",
        mobileComponent: LossLimitView,
        desktopComponent: LossLimitView,
        meta: {
          requiresAuth: true,
          role: RolePlayer,
          isFocusedHeader: false,
        },
      },
      {
        path: "time",
        name: "Time limit",
        mobileComponent: TimeLimitView,
        desktopComponent: TimeLimitView,
        meta: {
          requiresAuth: true,
          role: RolePlayer,
          isFocusedHeader: false,
        },
      },
    ],
  },
  {
    path: "/personal-information",
    name: "Personal Information",
    mobileComponent: PersonalInformationView,
    desktopComponent: PersonalInformationView,
    meta: { requiresAuth: true, role: RolePlayer, isFocusedHeader: false },
  },
  {
    path: "/change-password",
    name: "Change Password",
    mobileComponent: ChangePasswordView,
    desktopComponent: ChangePasswordView,
    meta: { requiresAuth: true, role: RolePlayer, isFocusedHeader: false },
  },
  {
    path: "/login",
    name: "login",
    mobileComponent: LoginView,
    desktopComponent: LoginView,
    meta: {
      requiresNotAuth: true,
      isFocusedHeader: false,
      pageToModal: true,
    },
  },
  {
    path: "/kyc",
    name: "kyc",
    mobileComponent: KYCView,
    desktopComponent: KYCView,
    meta: { requiresAuth: true, role: RolePlayer, isFocusedHeader: false },
  },
  {
    path: "/questionnaire",
    name: "questionnaire",
    mobileComponent: QuestionnaireView,
    desktopComponent: QuestionnaireView,
    meta: { requiresAuth: true, role: RolePlayer, isFocusedHeader: false },
  },
  {
    path: "/game",
    name: "game",
    mobileComponent: GameView,
    desktopComponent: GameView,
    meta: { isFocusedHeader: false, requiresAuth: false },
  },
  {
    path: "/unavailable",
    name: "unavailable",
    mobileComponent: Unavailable,
    desktopComponent: Unavailable,
    meta: { isFocusedHeader: false },
  },
  {
    path: "/maintenance",
    name: "maintenance",
    mobileComponent: Maintenance,
    desktopComponent: Maintenance,
    meta: { isFocusedHeader: false },
  },
  {
    path: "/email-password-reset",
    name: "emailPasswordReset",
    mobileComponent: ForgotPasswordView,
    desktopComponent: ForgotPasswordView,
  },
  {
    path: "/password-reset",
    name: "passwordReset",
    mobileComponent: ResetPasswordView,
    desktopComponent: ResetPasswordView,
    // meta: { requiresAuth: true },
    meta: { isFocusedHeader: false },
  },
  {
    path: "/about",
    name: "about",
    mobileComponent: AboutContentMobile,
    desktopComponent: AboutContent,
  },
  {
    path: "/bonus-info",
    name: "bonusInfo",
    mobileComponent: BonusesInfoMobile,
    desktopComponent: BonusesInfo,
  },
  {
    path: "/security",
    name: "security",
    mobileComponent: SecurityAndPrivacy,
    desktopComponent: SecurityAndPrivacy,
  },
  {
    path: "/sportsbook",
    name: "sportsbook",
    mobileComponent: SportsBookView,
    desktopComponent: SportsBookView,
  },
  {
    path: "/deposit-redirect",
    name: "depositRedirect",
    mobileComponent: DepositRedirect,
    desktopComponent: DepositRedirect,
    meta: {
      requiresAuth: false,
      isFocusedHeader: false,
    },
  },
  {
    path: "/withdrawal-redirect",
    name: "withdrawalRedirect",
    mobileComponent: WithdrawalRedirect,
    desktopComponent: WithdrawalRedirect,
    meta: {
      requiresAuth: false,
      isFocusedHeader: false,
    },
  },
  {
    path: "/game-explanations",
    name: "gameExplanations",
    mobileComponent: ErrorPage,
    desktopComponent: ErrorPage,
  },
  {
    path: "/bonus-rules",
    name: "bonusRules",
    mobileComponent: ErrorPage,
    desktopComponent: ErrorPage,
  },
  {
    path: "/navigation",
    name: "navigation",
    mobileComponent: UnauthorizedView,
    desktopComponent: UnauthorizedView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    on: true,
    path: "/403",
    name: "403",
    mobileComponent: UnauthorizedView,
    desktopComponent: UnauthorizedView,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    mobileComponent: ErrorPage,
    desktopComponent: ErrorPage,
    meta: { isFocusedHeader: false },
  },
];
