import LayoutMobileViewDefault from "@/views/LayoutView.vue";
import LayoutDesktopViewDefault from "@/views/LayoutDesktopView.vue";

import LayoutMobileViewHolahola from "@/brands/holahola/layout/LayoutMobileView.vue";
import LayoutDesktopViewHolahola from "@/brands/holahola/layout/LayoutDesktopView.vue";

import LayoutDesktopViewBit1111 from "@/brands/bit1111/layout/LayoutDesktopView.vue";
import LayoutMobileViewBit1111 from "@/brands/bit1111/layout/LayoutMobileView.vue";

export function loadLayout(brandName: string) {
  let mobileLayout = LayoutMobileViewDefault;
  let desktopLayout = LayoutDesktopViewDefault;
  switch (brandName) {
    case "Holahola":
      mobileLayout = LayoutMobileViewHolahola;
      desktopLayout = LayoutDesktopViewHolahola;
      break;
    case "Bit1111":
      mobileLayout = LayoutMobileViewBit1111;
      desktopLayout = LayoutDesktopViewBit1111;
      break;
    default:
      console.log("Default layout loaded");
      break;
  }
  return { desktop: desktopLayout, mobile: mobileLayout };
}
