<script setup lang="ts">
import { useBrandStore } from "@/stores/brand";
import { useRoute } from "vue-router";
import { ref, watch, computed, onMounted, markRaw } from "vue";
import { useAuthStore } from "@/stores/auth";
import LoaderVue from "@/components/shared/loader/LoaderComponent.vue";
import { useLoaderStore } from "@/stores/loader";
import BoardingWrapperComponent from "@/components/boarding/BoardingWrapperComponent.vue";
import eventRegister from "@/lib/mercury/eventRegister";
import BonusNotification from "@/components/notification/BonusNotificationComponent.vue";
// import { useBonusNotificationStore } from "@/stores/bonusNotification";
import type { PropType } from "vue";
import type { LayoutPropsInterface } from "@/services/interface/structure";
import BonusModal from "@/components/bonus/BonusModal.vue";
import { useLayoutModalsStore } from "@/stores/layoutModals";
import Header from "@/brands/holahola/components/navigation/header/HeaderComponentMobile.vue";
import Footer from "@/brands/holahola/components/navigation/footer/FooterMobileComponent.vue";
import Boarding from "@/brands/holahola/components/boarding/HolaBoardingWrapperComponent.vue";
import MobileNav from "@/brands/holahola/components/navigation/header/MobileNavigation.vue";
import ModalsMobile from "@/brands/holahola/layout/ModalsMobile.vue";
import BottomMobileNavigationComponent from "@/brands/holahola/components/navigation/BottomMobileNavigationComponent.vue";

const emit = defineEmits(["finishedLoading"]);

onMounted(async () => {
  if (player !== null && player.role == "ROLE_PLAYER") {
    eventRegister.authorizationHook();
  }
  eventRegister.nonAuthorizationHook();
});

const layoutModalsStore = useLayoutModalsStore();
const loader = useLoaderStore();
const brandStore = useBrandStore();
const authStore = useAuthStore();
const route = useRoute();
const routeAuth = ref(false);
const boarding = computed(() => {
  return layoutModalsStore.isModalActive("boarding");
});
const player = authStore.$state.player;
// const bonusNotificationStore = useBonusNotificationStore();
const navigationToggle = ref(false);
// const notifFlag = computed(() => {
//   return bonusNotificationStore.getFlag();
// });

const finishedLoading = () => {
  emit("finishedLoading");
};

watch(
  route,
  () => {
    routeAuth.value = ["login", "register"].includes(
      typeof route.name == "string" ? route.name : ""
    );
    window.scrollTo(0, 0);
  },
  { deep: true }
);
</script>

<template>
  <div id="layout-body" class="main">
    <ModalsMobile />
    <!-- <BoardingWrapperComponent v-if="!boarding" /> -->
    <LoaderVue v-if="loader.getIsLoading"></LoaderVue>
    <!-- <BonusNotification v-if="notifFlag" :amount="bonusNotificationStore.getAmount()" /> -->
    <BonusModal />
    <Header
      @toggleNav="navigationToggle = !navigationToggle"
      :navigationFlag="navigationToggle"
      @finishedLoading="finishedLoading"
    />
    <!--  <Boarding v-if="boarding" /> -->
    <div class="c-body">      
      <transition name="slide" mode="out-in">
        <MobileNav
          v-if="navigationToggle"
          @toggleMenu="navigationToggle = !navigationToggle"
        />
      </transition>
      <RouterView />
    </div>
    <Footer />
    <BottomMobileNavigationComponent
      :navigationFlag="navigationToggle"
      @toggleNav="navigationToggle = !navigationToggle"
    />
  </div>
</template>
<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s;
}
.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
