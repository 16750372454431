<script setup lang="ts">
import InputValueComponent from "@/brands/holahola/components/shared/forms/input/InputValueComponent.vue";
import InputPasswordComponent from "@/brands/holahola/components/shared/forms/input/InputPasswordComponent.vue";
import { useLayoutModalsStore } from "@/stores/layoutModals";
import AuthSelectModal from "@/brands/holahola/components/auth/AuthSelectModal.vue";
import { useAuthStore } from "@/stores/auth";
import { ref } from "vue";
import { useLoaderStore } from "@/stores/loader";
import { goToRoute } from "@/router/routingService";
import { computeFormValidation } from "@/services/validation/formValidation";
import { translate } from "@/services/translations/translation";

const login = ref({
  username: "",
  password: "",
});

const layoutModalsStore = useLayoutModalsStore();
const loader = useLoaderStore();
const authStore = useAuthStore();
const responseErr = ref();
const hideResponseErrors = ref(false);

const closeModal = () => {
  layoutModalsStore.closeModal("loginMobile");
};
const submit = async () => {
  loader.toggleLoader(true);
  let key: ValidationKey;
  for (key in validationList.value) {
    validationList.value[key].isTouched = true;
  }
  if (formisValid.value) {
  responseErr.value = await authStore.login(
    login.value.username.toLowerCase(),
    login.value.password
  );
  !responseErr.value ? close() : null;
  }
  loader.toggleLoader(false);
};
const reRoute = (url: string) => {
  goToRoute(url);
  close();
};
// VALIDATION START
const validationList = ref({
  username: { isTouched: false, valid: false },
  password: { isTouched: false, valid: false },
});
type ValidationKey = keyof typeof validationList.value;

const formisValid = ref<boolean>(false);
const callbackValid = (pointer: ValidationKey, value: boolean) => {
  validationList.value[pointer].valid = value;
  responseErr.value = false;
  computeFormValidation(validationList.value, formisValid);
};
// VALIDATION END
</script>
<template>
  <div class="backdrop">
    <div
      class="absolute top-8 left-3 right-3 bg-light  zindex-modal radius-8 pdx-5 pdt-5 login-modal"
    >
      <div class="w-100 flex flex-justify-end">
        <div
          @click="closeModal()"
          class="w-29px h-29px border-dark radius-circle txt-dark txt-bold flex flex-center txt-size-20 cursor-pointer"
        >
          <p class="pdt-1">X</p>
        </div>
      </div>
      <AuthSelectModal selected="login" />
      <div class="w-100 flex flex-center flex-column">
      <div class="mgt-5 w-80">
        <InputValueComponent
          :hideResponseErrors="hideResponseErrors"
          @hideErrors="hideResponseErrors = $event"
          :inputType="'text'"
          v-model="login.username"
          :placeholder="translate('auth.login.desktop.username')"
          :minLength="0"
          :maxLength="255"
          textCutBorder="label-cut bg-1-3 border-top-1-2 border-width-1"
          textCutBorderFocused="label-cut bg-1-3 border-top-1-2 border-width-1"
          bgColor="bg-1-3 radius-26"
          :borderClass="' radius-26 border-1-2 border-width-1'"
          v-model:isTouched="validationList.username.isTouched"
          @isValid="callbackValid('username', $event)"
        ></InputValueComponent>
      </div>
      <div class="mgt-3 w-80">
        <InputPasswordComponent
          :hideResponseErrors="hideResponseErrors"
          @hideErrors="hideResponseErrors = $event"
          :autocomplete="true"
          :minLength="1"
          :label="translate('auth.login.desktop.password')"
          v-model="login.password"
          textCutBorder="label-cut bg-1-3 border-top-1-2 border-width-1"
          textCutBorderFocused="label-cut bg-1-3 border-top-1-2 border-width-1"
          bgColor="bg-1-3 radius-26"
          :borderClass="' radius-26 border-1-2 border-width-1'"
          :isTouched="validationList.password.isTouched"
          @isValid="callbackValid('password', $event)"
        ></InputPasswordComponent>
      </div></div>
      <p
        v-if="responseErr"
        class="txt-bold txt-center txt-error-1 cursor-pointer text-smallest mgy-5"
      >
        {{ responseErr }}
      </p>

      <p
        class="txt-3-1 cursor-pointer txt-size-16 txt-center mgy-5"
        @click="reRoute('/email-password-reset')"
      >
      {{ translate('auth.login.desktop.forgot_password') }}
      </p>
      <button
        class="mgx-auto bg-2-1 radius-10 w-150px h-50px font-Poppins txt-size-18 txt-weight-600 txt-light"
        @click="submit"
      >
      {{ translate('auth.login.desktop.submit') }}
      </button>
      <p class="txt-center txt-size-14 font-Poppins txt-light mgt-6">
        {{ translate('auth.login.desktop.not_registered') }}
      </p>
      <p class="txt-center txt-size-14 font-Poppins txt-light mgb-7">
        <span class="cursor-pointer txt-2-1 underlined" @click="reRoute('/register')"
          >{{ translate('auth.login.desktop.create_free_account') }}
        </span>
        {{ translate('auth.login.desktop.start_winning') }}
      </p>
      <div class="bg-1-2 h-1px  mgx-auto"></div>
      <div class="flex fit-content mgx-auto mgy-6">
        <p class="font-Poppins txt-size-18 txt-5-2">{{ translate('auth.login.components.socialsLogin') }}</p>
        <img
          class="block cursor-pointer mgl-7"
          src="https://i.ibb.co/5RTkMw8/Icon-awesome-twitter.png"
          alt=""
        />
        <img
          class="block cursor-pointer mgl-5"
          src="https://i.ibb.co/m88PW9b/Icon-awesome-google.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<style scoped>
.login-modal {
  background: transparent linear-gradient(180deg, #23008a, #000635) 0% 0%
    no-repeat padding-box;
}
</style>
