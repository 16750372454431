<script setup lang="ts">
import type { GameInterface } from "../../services/data/casino";
import IconComponent from "../icons/IconComponent.vue";
import imgUrl from "@/assets/icons/img-placeholder.svg";
import { isMobileCheck } from "@/services/interface/clientInformation";
const props = defineProps({
  gameList: Array<GameInterface>,
  testId: String,
});
const price = "$0.20 - $5,000.00";
const fallbackLogo = imgUrl;
const gameLogo = (game: GameInterface) => {
  let imgSrc = isMobileCheck()
    ? game.images.mobile
      ? game.images.mobile
      : game.images.desktop
    : game.images.desktop;
  if (imgSrc == null) {
    imgSrc = fallbackLogo;
  } else {
    imgSrc.includes("http") ? null : (imgSrc = "https://" + imgSrc);
  }
  return imgSrc;
  };
</script>

<template>
  <div class="inline-block" v-bind:key="game.id" v-for="game in gameList">
    <RouterLink :id="game.id" :to="`game?gamename=${game.uri}`">
      <div class="inline-block cursor-pointer">
        <div class="mga-2">
          <img
            :data-testId="testId ? `img-${testId}` : null"
            class="img-game radius-6"
            :src="gameLogo(game)"
          />
          <span class="span-overlay bg-theme-bg-1 flex flex-center hpx-28 pda-0 mga-0">
            <IconComponent
              :iconClasses="'icon-16 inline-block bg-theme-brand-1 ico-w mgt-1'"
              :svgImage="'src/assets/icons/ico-stake.svg'"
            ></IconComponent>
            <p class="title-smallest mgy-0 pdl-3 txt-theme-txt-1">
              {{ price }}
            </p>
          </span>
          <span :data-testid="testId ? `name-${testId}` : null" class="txt-theme-txt-1">{{
            game.name
          }}</span>
        </div>
      </div>
    </RouterLink>
  </div>
</template>
