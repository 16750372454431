import axios from "axios";


export async function getPaymentMethods(type: string, walletType: number) {
  const body = {
    paymentType: type,
    walletType: walletType,
  };
  let response 
  try {
    response = await axios.post("/payment/methods", body);
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function findUrlInString(meta: string) {
  let link = "";
  for (let index = 0; index < meta.length; index++) {
    if (
      meta[index] == "u" &&
      meta[index + 1] == "r" &&
      meta[index + 2] == "l" &&
      meta[index + 3] == "="
    ) {
      index = index + 4;
      while (meta[index] != '"') {
        link += meta[index];
        index++;
      }
      break;
    }
  }
  return link;
}
