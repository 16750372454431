<script setup lang="ts">
import { ref } from "vue";
import { typeValidation } from "../../../../services/validation/fileTypeValidation";
import IconComponent from "../../../icons/IconComponent.vue";

const props = defineProps({
  fileId: String,
  testId: String,
});

const emit = defineEmits(["onFileChange", "isValid"]);
const imgName = ref("Click here to upload your file");
const invalidFileMsg = ref();
const successfulUpload = ref(false);

const inputHandler = (event: any) => {
  const file = event.target.files[0];
  if (typeValidation(file)) {
    invalidFileMsg.value = "";
    successfulUpload.value = true;
    imgName.value = file.name;
    emit("onFileChange", file);
  } else {
    successfulUpload.value = false;
    invalidFileMsg.value = "Invalid file type.";
  }
  emit("isValid", successfulUpload.value);
};
emit("isValid", successfulUpload.value);
</script>

<template>
  <label for="poiFile" class="shared-input upload-btn-wrapper radius-6 txt-left">
    <input type="file" :id="fileId" @change="inputHandler" />
    <span class="label-text pdl-2 txt-dark200 align-middle">{{ imgName }}</span>
    <span class="svg-icon icon-42 svg-cover block bg-dark500 icon-upload setSize"></span>
  </label>
  <div class="txt-center">
    <small class="text-description txt-dark400 mgy-3">
      <span class="txt-error">{{ invalidFileMsg }}</span
      >JPG, JPEG, PNG, PDF (max 2MB)
    </small>
    <span v-if="successfulUpload">
      <IconComponent
        :iconClasses="'icon-20 inline-block icon-success-small bg-success  mgl-2 translateY-5px'"
      ></IconComponent>
    </span>
  </div>
</template>
