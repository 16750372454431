<script setup lang="ts">
import InputValueComponent from "@/brands/bit1111/components/shared/forms/input/InputValueComponent.vue";
import InputPasswordComponent from "@/brands/bit1111/components/shared/forms/input/InputPasswordComponent.vue";
import { useAuthStore } from "@/stores/auth";
import { ref, type Ref } from "vue";
import { useLoaderStore } from "@/stores/loader";
import SocialsTwoComponent from "@/brands/bit1111/components/auth/SocialsTwoComponent.vue";
import { useLayoutModalsStore } from "@/stores/layoutModals";
import { goToRoute } from "@/router/routingService";
import { translate } from "@/services/translations/translation";
import IconComponent from "@/components/icons/IconComponent.vue";
import ButtonComponent from "@/brands/bit1111/components/shared/buttons/ButtonComponent.vue";
import { computeFormValidation } from "@/services/validation/formValidation";

const login = ref({
  username: "",
  password: "",
});

const layoutModalsStore = useLayoutModalsStore();
const loader = useLoaderStore();
const authStore = useAuthStore();
const responseErr = ref();
const hideResponseErrors = ref(false);

const close = () => {
  layoutModalsStore.closeModal("login");
};
const submit = async () => {
  loader.toggleLoader(true);
  let key: ValidationKey;
  for (key in validationList.value) {
    validationList.value[key].isTouched = true;
  }
  if (formisValid.value) {
    responseErr.value = await authStore.login(
      login.value.username.toLowerCase(),
      login.value.password
    );
    !responseErr.value ? close() : null;
  }
  loader.toggleLoader(false);
};

const reRoute = (url: string) => {
  goToRoute(url);
  close();
};

// VALIDATION START
const validationList = ref({
  username: { isTouched: false, valid: false },
  password: { isTouched: false, valid: false },
});
type ValidationKey = keyof typeof validationList.value;

const formisValid = ref<boolean>(false);
const callbackValid = (pointer: ValidationKey, value: boolean) => {
  validationList.value[pointer].valid = value;
  responseErr.value = false;
  computeFormValidation(validationList.value, formisValid);
};
// VALIDATION END
</script>
<template>
  <div>
    <div class="backdrop"></div>
    <form @submit.prevent>
      <div class="auth-modal-bit1111 bg-4-6 pdy-5 pdx-7 radius-15">
        <div type="" class="flex flex-right">
          <IconComponent
            :iconClasses="'svg-icon block icon-16  icon-form-clear bg-1-1 cursor-pointer'"
            @click="close"
          ></IconComponent>
        </div>
        <h1
          class="title-xxl to-upper-case mgt-5 txt-1-1 txt-bold mgx-auto fit-content"
        >
          {{ translate("auth.login.desktop.header") }}
        </h1>
        <p
          class="title-large txt-light mgx-auto fit-content text-smallest txt-bold"
        >
          {{ translate("auth.login.desktop.header_description") }}
        </p>
        <div class="mgt-5">
          <InputValueComponent
            :hideResponseErrors="hideResponseErrors"
            @hideErrors="hideResponseErrors = $event"
            inputId="name"
            :placeholder="translate('auth.login.desktop.username')"
            :inputType="'text'"
            v-model="login.username"
            :disableValidation="false"
            :borderClass="'border-input radius-10'"
            regexValidator="username"
            textCutBorder="label-cut-border-light"
            v-model:isTouched="validationList.username.isTouched"
            @isValid="callbackValid('username', $event)"
          ></InputValueComponent>
        </div>
        <div class="mgt-6">
          <InputPasswordComponent
            :hideResponseErrors="hideResponseErrors"
            @hideErrors="hideResponseErrors = $event"
            :autocomplete="true"
            passId="password"
            :minLength="1"
            :label="translate('auth.login.desktop.password')"
            v-model="login.password"
            :isTouched="validationList.password.isTouched"
            :borderClass="'border-input radius-10 '"
            textCutBorder="label-cut-border-light"
            @isValid="callbackValid('password', $event)"
          ></InputPasswordComponent>
        </div>
        <p
          v-if="responseErr"
          class="txt-bold txt-error-1 cursor-pointer text-smallest mgy-5 pdl-4"
        >
          {{ responseErr }}
        </p>
        <p
          class="txt-weight-500 txt-1-3 cursor-pointer text-smallest mgb-6 pdl-4"
          @click="reRoute('/email-password-reset')"
        >
          {{ translate("auth.login.desktop.forgot_password") }}
        </p>
        <ButtonComponent
          :text="translate('auth.login.desktop.submit')"
          @click="submit"
          :width="100"
          :heightPx="40"
          class="mgb-6"
        />
        <!-- <SocialsTwoComponent /> -->
        <span class="w-100 txt-center">
          <p class="txt-light text-smallest mgb-5">
            {{ translate("auth.login.desktop.dont_have_account") }}
            <span
              class="txt-2-3 txt-weight-500 underlined cursor-pointer"
              @click="reRoute('/register')"
              >{{ translate("auth.login.desktop.register") }}</span
            >
          </p></span
        >
      </div>
    </form>
  </div>
</template>
