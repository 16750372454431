<script setup lang="ts">
import IconComponent from "@/components/icons/IconComponent.vue";
import { useLayoutModalsStore } from "@/stores/layoutModals";
import { onMounted, ref } from "vue";
import { translate } from "@/services/translations/translation";

/* 
TO ACTIVATE SUCCESS NOTIFICATION:
layoutModalsStore.activateModal({ name: 'pageNotification', data: {type: 'success', title?: 'Some long ass title', text?:'A very very very long text', mobile?: true} });

TO ACTIVATE ERROR NOTIFICATION:
layoutModalsStore.activateModal({ name: 'pageNotification', data: {type: 'error', title?: 'Some long ass title', text?:'A very very very long text', mobile?: true} });

*/
const mobile = ref(false);

const data = ref({
  bgColor: "",
  title: "",
  text: "",
  icon: "",
});

const layoutModalsStore = useLayoutModalsStore();

onMounted(() => {
  setTimeout(() => {
    layoutModalsStore.closeModal("pageNotification");
  }, 5000);

  layoutModalsStore.modals.pageNotification.data.mobile
    ? (mobile.value = true)
    : (mobile.value = false);

  layoutModalsStore.modals.pageNotification.data.type === "success"
    ? (data.value = {
        bgColor: "bg-success-5",
        title: layoutModalsStore.modals.pageNotification.data.title
          ? layoutModalsStore.modals.pageNotification.data.title
          : translate("globalNotificationMessages.success"),
        text: layoutModalsStore.modals.pageNotification.data.text
          ? layoutModalsStore.modals.pageNotification.data.text
          : translate("globalNotificationMessages.success_message"),
        icon: "icon-success-small",
      })
    : (data.value = {
        bgColor: "bg-error-1",
        title: layoutModalsStore.modals.pageNotification.data.title
          ? layoutModalsStore.modals.pageNotification.data.title
          : translate("globalNotificationMessages.error"),
        text: layoutModalsStore.modals.pageNotification.data.text
          ? layoutModalsStore.modals.pageNotification.data.text
          : translate("globalNotificationMessages.error_message"),
        icon: "icon-warning",
      });
});
</script>
<template>
  <Transition name="fade" appear>
    <div
      :class="
        [data.bgColor,
        mobile
          ? 'top-8 left-0 w-80'
          : 'top-11 left-50 page-notification maxw-1184px w-100']
      "
      class="form-size-desktop flex radius-6 fixed zindex-tooltip"
    >
      <div class="pdx-5 flex flex-center">
        <IconComponent
          :iconClasses="`svg-icon icon-30 ${data.icon} bg-light block mgx-2 `"
        ></IconComponent>
      </div>
      <div class="flex-grow-1 pdy-4">
        <p class="txt-light txt-bold mga-0 pda-0">
          {{ data.title }}
        </p>
        <p class="txt-light mga-0 pda-0">
          {{ data.text }}
        </p>
      </div>
      <div
        @click="layoutModalsStore.closeModal('pageNotification')"
        class="mgx-5 flex flex-center cursor-pointer"
      >
        X
      </div>
    </div>
  </Transition>
</template>
<style scoped>
.page-notification {
  transform: translateX(-50%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
