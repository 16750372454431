<script setup lang="ts">
import { useLayoutModalsStore } from "@/stores/layoutModals";
import InputValueComponent from "@/components/shared/forms/input/InputValueComponent.vue";
import DropDownComponent from "@/components/shared/forms/dropdown/DropDownComponent.vue";
import { ref, onMounted } from "vue";
import { useLoaderStore } from "@/stores/loader";
import { useAuthStore } from "@/stores/auth";
import { registerStepTwo } from "@/services/auth/registration";
import type {
  RegisterInitialPayloadInterface,
  RegisterConfirmPayLoadInterface,
} from "@/services/interface/registrationInterface";
import type { PropType } from "vue";
import { RouterSingleton } from "@/router/index";
import InputPhoneComponent from "@/components/shared/forms/input/InputPhoneComponent.vue";
import CheckBoxTermsAndConditions from "@/brands/holahola/components/auth/TermsAndConditionsHola.vue";
import RegistrationStepCompoment from "@/brands/holahola/components/auth/RegistrationStepComponent.vue";
import IconComponent from "@/components/icons/IconComponent.vue";

const props = defineProps({
  stepOneData: {
    required: true,
    type: Object as PropType<RegisterInitialPayloadInterface>,
  },
});

const borderClass = ref("border-input radius-5 border-color-theme-brand-1");
const loader = useLoaderStore();
const authStore = useAuthStore();
const layoutModalsStore = useLayoutModalsStore();

const registerData = ref<RegisterConfirmPayLoadInterface>({
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  gender: "",
});

const close = () => {
  layoutModalsStore.closeModal("registerConfirmLast");
};

const selectedCountry = ref();
const city = ref("");
const address = ref("");
const zipCode = ref("");
const phoneCode = ref("+1");
const phoneNumber = ref("");

const submit = async () => {
  const data = {
    address: address.value,
    city: city.value,
    country: selectedCountry.value.id,
    dateOfBirth: registerData.value.dateOfBirth,
    firstName: registerData.value.firstName,
    lastName: registerData.value.lastName,
    gender: registerData.value.gender,
    zipCode: zipCode.value,
    phone: phoneNumber.value,
    phoneCode: phoneCode.value,
  };
  loader.toggleLoader(true);
  registerStepTwo(data)
    .then(async () => {
      await authStore.setPlayerData();
      RouterSingleton.getInstance().getRouter().push("/");
      // layoutModalsStore.activateModal("boarding");
      loader.toggleLoader(false);
    })
    .catch((error) => {
      loader.toggleLoader(false);
    });

  close();
};

onMounted(() => {
  registerData.value = JSON.parse(JSON.stringify(props.stepOneData));
});

// VALIDATION START
const newValidationList = ref({
  country: Boolean,
  city: Boolean,
  address: Boolean,
  zipCode: Boolean,
  phone: Boolean,
  checkboxComponent: Boolean,
});
type ValidationKey = keyof typeof newValidationList.value;

const formisValid = ref(false);
const callbackValid = (pointer: ValidationKey, value: BooleanConstructor) => {
  newValidationList.value[pointer] = value;
  computeFormValidation();
};
const computeFormValidation = () => {
  let flag = true;
  let key: ValidationKey;
  for (key in newValidationList.value) {
    if (!newValidationList.value[key]) flag = false;
  }
  formisValid.value = flag;
};
// VALIDATION END
</script>
<template>
  <div>
    <div class="backdrop"></div>
    <form @submit.prevent>
      <div class="auth-modal bg-theme-bg-1 pda-5">
        <div type="" class="flex flex-right">
          <IconComponent
            :iconClasses="'svg-icon block icon-16  icon-form-clear bg-theme-bg-7 cursor-pointer'"
            @click="close"
          ></IconComponent>
        </div>
        <h1 class="title-large mgt-5 txt-theme-txt-1 txt-bold mgx-auto fit-content">
          REGISTER
        </h1>
        <p class="txt-theme-txt-2 mgx-auto fit-content text-smallest txt-bold">
          We are almost there!
        </p>
        <RegistrationStepCompoment :step="3" />
        <DropDownComponent
          dropdownId="country"
          :borderClass="borderClass"
          :label="'Country'"
          :url="'/geo-resources/countries'"
          :selected="selectedCountry"
          :selector="'name'"
          @emitSelected="selectedCountry = $event"
          @isValid="callbackValid('country', $event)"
        >
        </DropDownComponent>

        <InputValueComponent
          :borderClass="borderClass"
          inputId="city"
          placeholder="City"
          regexValidator="city"
          v-model="city"
          :minLength="1"
          :maxLength="200"
          @isValid="callbackValid('city', $event)"
        ></InputValueComponent>
        <InputValueComponent
          :borderClass="borderClass"
          inputId="adress"
          placeholder="Address"
          regexValidator="address"
          v-model="address"
          :minLength="2"
          :maxLength="40"
          @isValid="callbackValid('address', $event)"
        ></InputValueComponent>
        <InputValueComponent
          :borderClass="borderClass"
          inputId="zip"
          placeholder="Zip Code"
          regexValidator="zipCode"
          v-model="zipCode"
          :minLength="2"
          :maxLength="10"
          @isValid="callbackValid('zipCode', $event)"
        ></InputValueComponent>
        <div class="mgt-4">
          <InputPhoneComponent
            :borderClass="borderClass"
            :selectedCode="phoneCode"
            :selectedPhoneNumber="Number(phoneNumber)"
            codeRegexValidator="phoneCode"
            phoneRegexValidator="phone"
            :codeMinLength="2"
            :codeMaxLength="10"
            :phoneMinLength="5"
            :phoneMaxLength="15"
            @changePhoneCode="phoneCode = $event"
            @changePhoneNumber="phoneNumber = $event"
            @isValid="callbackValid('phone', $event)"
          />
        </div>
        <CheckBoxTermsAndConditions
          @isValid="callbackValid('checkboxComponent', $event)"
        />
        <button
          id="submit"
          class="bg-theme-brand-2 mgb-6 mgt-4 txt-bold pdx-5 pdy-3 radius-5 w-100"
          @click="submit()"
          :disabled="!formisValid"
        >
          LET THE FUN BEGIN!
        </button>
      </div>
    </form>
  </div>
</template>
