<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { icons } from "../../../services/data/developementData";
import LimitsLink from "../../../components/limits/LimitsLinkComponent.vue";
import { isMobileCheck } from "../../../services/interface/clientInformation";
import IconComponent from "../../../components/icons/IconComponent.vue";
import DesktopLimitHeader from "../../../components/limits/DesktopLimitsHeaderComponent.vue";
import { useRoute } from "vue-router";
import { goToRoute } from "@/router/routingService";

const route = useRoute();
const isMobile = ref();

const isOnMainPage = computed(() => (route.path.includes("/limits/") ? false : true));

onMounted(async () => {
  isMobile.value = isMobileCheck();
  if (!isMobile.value) {
    await goToRoute("/limits/deposit");
  }
});
</script>

<template>
  <div>
    <template v-if="isMobile">
      <div class="mgy-10 mgx-3" v-if="isOnMainPage">
        <LimitsLink icon="deposit" link="limits/deposit" label="Deposit Limits" />
        <LimitsLink icon="placeholder" link="limits/wagering" label="Wagering Limits" />
        <LimitsLink icon="placeholder" link="limits/loss" label="Loss Limits" />
        <LimitsLink icon="placeholder" link="limits/time" label="Time Limits" />
      </div>
    </template>

    <template v-else>
      <div class="container">
        <DesktopLimitHeader />
      </div>
    </template>
    <RouterView />
  </div>
</template>
