<script setup lang="ts">
import { useLayoutModalsStore } from "@/stores/layoutModals";
import InputValueComponent from "@/brands/holahola/components/shared/forms/input/InputValueComponent.vue";
import { ref } from "vue";
import { genders } from "@/services/data/genders";
import type { GenderInterface } from "@/services/data/genders";
import type { RegisterInitialPayloadInterface } from "@/services/interface/registrationInterface";
import DividerComponent from "@/components/shared/divider/DividerComponent.vue";
import SocialsComponent from "@/brands/holahola/components/auth/SocialsTwoComponent.vue";
import InputPhoneComponent from "@/brands/holahola/components/shared/forms/input/InputPhoneComponent.vue";
import IconComponent from "@/components/icons/IconComponent.vue";
import { translate } from "@/services/translations/translation";
import { computeFormValidation } from "@/services/validation/formValidation";

const emit = defineEmits(["emitData"]);
const layoutModalsStore = useLayoutModalsStore();

const borderClass = ref("border-input radius-20 ");

const genderSelected = ref<GenderInterface>(genders[0]);
const registerData = ref<RegisterInitialPayloadInterface>({
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  gender: "",
});

const close = () => {
  layoutModalsStore.closeModal("registerConfirm");
};

const submit = () => {
  let key: ValidationKey;
  for (key in validationList.value) {
    validationList.value[key].isTouched = true;
  }
  if (formisValid.value) {
  registerData.value.gender = genderSelected.value.value;
  emit("emitData", registerData.value);
  layoutModalsStore.activateModal({ name: "registerConfirmLast" });
  close();
  }
};

// VALIDATION START
const validationList = ref({
  firstName: { isTouched: false, valid: false },
  lastName: { isTouched: false, valid: false },
  dateOfBirth: { isTouched: false, valid: false },
});
type ValidationKey = keyof typeof validationList.value;

const formisValid = ref<boolean>(false);
const callbackValid = (pointer: ValidationKey, value: boolean) => {
  validationList.value[pointer].valid = value;
  computeFormValidation(validationList.value, formisValid);
};
// VALIDATION END
</script>
<template>
  <div>
    <div class="backdrop" @click="close"></div>
    <form @submit.prevent>
      <div
        class="modal-Centered bg-linear-1-1-1-5 zindex-modal radius-15 pdy-3 pdx-15"
      >
        <div type="" class="flex flex-right mgb-10 pdy-5 mgt-7">
          <div
            class="fit-content cursor-pointer flex-center"
            style="
              position: absolute;
              margin-left: auto;
              margin-right: auto;
              margin-top: -10px;
              left: 0;
              right: 0;
            "
          >
            <h1 class="txt-30 font-Aldrich txt-2-1 txt-center">
              {{ translate('auth.confirm.desktop.title') }}
            </h1>
          </div>

          <IconComponent
            :iconClasses="'svg-icon block icon-16  icon-form-clear bg-4-1 cursor-pointer '"
            @click="close"
          ></IconComponent>
        </div>

        <RegistrationStepCompoment :step="2" />
        <div class="mgt-5 w-520px">
          <InputValueComponent
            inputId="firstName"
            :placeholder="translate('auth.register_init.mobile.registerComplete.firstName')"
            regexValidator="firstName"
            v-model="registerData.firstName"
            :minLength="2"
            :maxLength="25"
            textCutBorder="label-cut bg-1-3 border-top-1-2 border-width-1"
            textCutBorderFocused="label-cut bg-1-3 border-top-1-2 border-width-1"
            bgColor="bg-1-3 radius-26"
            :borderClass="' radius-26 border-1-2 border-width-1'"
            v-model:isTouched="validationList.firstName.isTouched"
            @isValid="callbackValid('firstName', $event)"
          />
        </div>
        <div class="mgt-5 w-520px">
          <InputValueComponent
            inputId="lastName"
            :placeholder="translate('auth.register_init.mobile.registerComplete.lastName')"
            regexValidator="lastName"
            v-model="registerData.lastName"
            :minLength="2"
            :maxLength="25"
            textCutBorder="label-cut bg-1-3 border-top-1-2 border-width-1"
            textCutBorderFocused="label-cut bg-1-3 border-top-1-2 border-width-1"
            bgColor="bg-1-3 radius-26"
            :borderClass="' radius-26 border-1-2 border-width-1'"
            v-model:isTouched="validationList.lastName.isTouched"
            @isValid="callbackValid('lastName', $event)"
          />
        </div>
        <div class="mgt-5 w-520px">
          <InputValueComponent
            textCutBorder="label-cut bg-1-3 border-top-1-2 border-width-1"
            textCutBorderFocused="label-cut bg-1-3 border-top-1-2 border-width-1"
            bgColor="bg-1-3 radius-26"
            :borderClass="' radius-26 border-1-2 border-width-1'"
            inputId="date"
            :placeholder="translate('auth.register_init.mobile.registerComplete.date')"
            :inputType="'date'"
            regexValidator="date"
            :maxLength="10"
            v-model="registerData.dateOfBirth"
            v-model:isTouched="validationList.dateOfBirth.isTouched"
            @isValid="callbackValid('dateOfBirth', $event)"
          ></InputValueComponent>
        </div>
        <div class="mgt-4 flex">
          <div class="flex flex-center">
            <p class="txt-2-1 text-smallest mgy-5">{{ translate('common.gender') }}:</p>
          </div>
          <div class="flex flex-center w-100">
            <label
              class="text-smallest mgy-5 w-50 txt-center cursor-pointer pdy-3 radius-8 border-width-1 mgx-4"
              v-for="(gender, i) in genders"
              :class="gender.labelText == genderSelected.labelText ? 'bg-1-3 border-2-1 txt-2-1' : 'border-dark txt-dark'"
              :key="i"
              @click="genderSelected = gender"
              >{{ translate(gender.labelText).toUpperCase() }}

              <span class="checkmark mgl-2">
                <span
                  class="checkmark-inside"
                  v-if="gender.value == genderSelected.value"
                ></span>
              </span>
            </label>
          </div>
        </div>
        <button
          id="submit"
          class="mgx-auto mgy-7 bg-2-1 radius-10 w-150px h-50px font-Poppins txt-size-18 txt-weight-600 txt-light"
          @click="submit()"
        >
        {{ translate('common.continue').toUpperCase() }}
        </button>
        <div>
          <p class="txt-theme-txt-2 text-smallest mgx-auto fit-content">
          {{ translate('auth.login.components.alreadyHaveAccount') }}
            <span class="txt-2-1">{{ translate('common.login') }}</span>
          </p>
        </div>
        <DividerComponent
          :dividerClass="'w-90 mgx-auto mgb-6 mgt-6 bg-dark400'"
        ></DividerComponent>
        <SocialsComponent />
      </div>
    </form>
  </div>
</template>
