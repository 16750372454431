<script setup lang="ts">
import FlagWrapperComponent from "@/components/icons/FlagWrapperComponent.vue";
import IconComponent from "@/components/icons/IconComponent.vue";
import { languagesFlags } from "@/services/data/languagesFlags";
import axios from "axios";
import { ref } from "vue";
import type { LanguageElement } from "@/services/interface/language";
import { useTranslationStore } from "@/stores/translation";
import AllFlagsComponent from "@/components/icons/flags/AllFlagsComponent.vue";

const props = defineProps({
  backdrop: Boolean,
  personalMenuWrapper: Boolean,
  divider: Boolean,
  bgTheme: String,
  bgHover: String,
  toggleDropdown: Boolean,
  absolutePosition: Boolean,
  isMobile: Boolean,
  topMargin: {
    required: false,
    type: String,
    default: "0px",
  },
  isInsideModal: {
    required: false, 
    type: Boolean, 
    default: false,
  }
});

const languagesList = ref();
const openLanguages = ref(false);
const selectedFlag = ref();
const LANGUAGE_API = "bootstrap/available-languages";
const translationStore = useTranslationStore();

const handleFlag = async (language: LanguageElement) => {
  openLanguages;
  selectedFlag.value = language.code;
  openLanguages.value = false;

  const translationPayload = JSON.parse(JSON.stringify(language));
  await translationStore.setTranslation(translationPayload);
};

const findDefaultLanguage = (languages: Array<LanguageElement>) => {
  return languages.find((item) => item.default == true);
};

const getWhitelistedLanguages = async () => {
  axios
    .get(LANGUAGE_API)
    .then((response) => {
      const { data } = response.data;
      languagesList.value = data;
      const defaultLang = findDefaultLanguage(data);
      selectedFlag.value = defaultLang?.code;
    })
    .catch((error) => {
      // console.log("error at LANGUAGE_API", error);
    });
};
getWhitelistedLanguages();

const handleClose = () => {
  openLanguages.value = false;
};
</script>
<template>
  <div
    id="lang"
    class="flex flex-center cursor-pointer flag-area"
    :class="{ 'btn-default-lang': absolutePosition }"
    @click="openLanguages = !openLanguages"
  >
    <div class="lang-head-btn mgl-auto mgr-3 border-1-1 border-width-2">
      <FlagWrapperComponent :selectedFlag="selectedFlag" />
    </div>
    <p class="txt-18 txt-light txt-bold to-upper-case">{{ selectedFlag }}</p>
    <div class="">
      <IconComponent
        v-if="toggleDropdown"
        :class="openLanguages ? ' rotate-up mgt-4' : 'ico-size-16'"
        :iconClasses="'icon-16 scale-0-7 inline-block bg-1-1 icon-dropdown'"
      ></IconComponent>
    </div>
  </div>
  <div :class="{ backdrop: backdrop }" @click.self="handleClose" v-if="openLanguages">
    <div class="absolute top-0 zindex-dropdown" :class="isInsideModal ? '' : 'w-100 maxw-1184px '">
      <div
        :style="`margin-top: ${topMargin}`"
        :class="{
          'border-1-1': personalMenuWrapper,
          'language-menu-wrapper-bit1111-mobile': isMobile,
          'language-menu-wrapper-bit1111': !isMobile,
        }"
      >
        <div :class="bgTheme">
          <div
            class="cursor-pointer language-element pdt-2"
            v-for="language in languagesList"
            :key="language.id"
          >
            <div
              :id="language.id"
              @click="handleFlag(language)"
              class="flex flex-left-center pdx-5 pdy-1"
            >
              <div><AllFlagsComponent :flagCode="language.code" /></div>
              <p class="mgl-3 txt-1-1 text-small">{{ language.language }}</p>
            </div>
            <div class="pdx-12px">
              <div v-show="divider" class="w-100 separator-divider bg-5-1 mgb-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
