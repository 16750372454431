<template>
  <section class="flex flex-center mga-5">
    <div class="col-sm-6">
      <h1 class="txt-center txt-theme-brand-1">Terms and Conditions</h1>
      <div class="txt-theme-txt-2 text-smallest">
        <p class="mgt-5">Dear User,</p>
        <p class="mgt-5">
          Kindly be advised that our terms and conditions have been updated and will be in
          effect as of the 30th of September 2020.
        </p>

        <p class="mgt-5">Below is a summary of the main updates.</p>

        <p class="mgt-5">
          Under the Eligibility section, term 37., we have lifted the restriction for
          these countries: Armenia, Belarus, Georgia, Moldova, Serbia, Ukraine, Uruguay
          and Uzbekistan. Players coming from these countries are now allowed to play on
          our website.
        </p>

        <p class="mgt-5">
          Under the Withdrawal requests section, term 90, we have raised the maximum
          monthly limit from previous $ 2,500 to new $ 5,000. Weekly limits have been
          removed.
        </p>

        <p class="mgt-5">
          Under the Abuse of promotions section, term 137.g, is updated regarding maximum
          single bet restriction and when playing with the bonuses. The new terms state
          that the example of the irregular play is placing single bets equal to or in
          excess of 30% or more of the value of the User’s starting balance (deposit plus
          bonus) until the wagering requirements for that bonus have been met.
        </p>
      </div>
    </div>
  </section>
</template>
