<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import LoaderVue from "@/components/shared/loader/LoaderComponent.vue";
import { useLoaderStore } from "@/stores/loader";
import { useLayoutModalsStore } from "@/stores/layoutModals";
import axios from "axios";
import BoardingSignupBonusModal from "@/brands/bit1111/components/boarding/BoardingSignupBonusModal.vue"
import Boarding from "@/brands/holahola/components/boarding/HolaBoardingWrapperComponent.vue";
import { goToRoute } from "@/router/routingService";

//Boarding for Bit1111 => Check for signupBonus, after it show Deposit.

const layoutModalsStore = useLayoutModalsStore();
const loader = useLoaderStore();
const hasSignupBonus = ref(false);
const signupBonusData = ref();
const boarding = ref(false);

const onboardingFinished = () => {
  axios
    .put("/players/onboard", {})
    .then((response) => {})
    .catch((error) => {
      console.log(error);
    });
}

const getSignupBonus = async() => {
  loader.toggleLoader(true)
  await axios
    .get("/obtained/bonuses?bonusEvent=1&wallet=1&status=1")
    .then((response) => {
      loader.toggleLoader(false)
      response.data.data.length > 0
        ? ((signupBonusData.value = response.data.data[0]),
          (hasSignupBonus.value = true))
        : (onboardingFinished(), goToRoute("/deposit"));
    })
    .catch((error) => loader.toggleLoader(false));  
}

const signupModalClosed = async (event: boolean | string) => {
  loader.toggleLoader(true);
  hasSignupBonus.value = false;
  onboardingFinished();
  loader.toggleLoader(false);
  goToRoute("/deposit");
};

const closeBoardingModal = async() => {
  boarding.value = false
  await getSignupBonus()
}

onMounted(async () => {
  boarding.value = true;
})
</script>
<template>
  <LoaderVue v-if="loader.getIsLoading"></LoaderVue>
  <Boarding v-if="boarding" @closeBoarding="closeBoardingModal()" />
  <BoardingSignupBonusModal v-if="hasSignupBonus" :bonus="signupBonusData" @close="signupModalClosed" />
</template>

<style></style>
