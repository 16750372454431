<script setup>
import { ref } from "vue";
import IconComponent from "../../../components/icons/IconComponent.vue";
import { RouterLink } from "vue-router";
import InputFileComponent from "../../../components/shared/forms/input/InputFileComponent.vue";
import axios from "axios";
import BackButton from "../../../components/shared/backButton/BackButtonComponent.vue";
import { useLoaderStore } from "../../../stores/loader";
import { structureItems, reactItems } from "../../../services/data/kyc";

const props = defineProps({
  testId: String,
});

const loader = useLoaderStore();
const contentTxt =
  "We are required by law to verify the identity of our customers. In order to prevent any interruptions while using your gaming account and continue enjoying our products, please complete the requirements below.";
const pendingSubtext = "Pending verification";
const verifiedSubtext = "Verified";
const successfullSubtext = "Uploaded successfully";
const submittedTxt =
  "Your documents were successfully submitted and are currently pending review. We'll notify you via email as soon as possible.";

const completed = ref(false);
const submitted = ref(false);
const submitFailure = ref(false);
const verified = ref(false);

const submitNotification = () => {
  submitted.value = true;
  setTimeout(() => {
    submitted.value = false;
  }, 5000);
};
const submitFailureNotification = () => {
  submitFailure.value = true;
  setTimeout(() => {
    submitFailure.value = false;
  }, 5000);
};

const pendingSubtextSwitch = (item) => {
  const reactItem = reactItems.value[item.type];
  if (reactItem.submittedNotif) {
    return submittedTxt;
  } else if (reactItem.submitted) {
    return pendingSubtext;
  } else if (reactItem.verified) {
    return verifiedSubtext;
  } else if (reactItem.success) {
    return successfullSubtext;
  }
  return reactItem.subtext;
};

const addInputField = (item) => {
  let fileList = reactItems.value[item.type].files;

  if (Object.keys(fileList).length <= 4) {
    fileList.push({});
  }
};

const removeInputField = (item) => {
  let fileList = reactItems.value[item.type].files;

  if (Object.keys(fileList).length <= 5) {
    fileList.pop({});
  }
};

const checkIsValid = (item, $event) => {
  if ($event) {
    reactItems.value[item.type].success = true;
  } else {
    reactItems.value[item.type].success = false;
  }
};

const saveFile = (item, $event, j) => {
  reactItems.value[item.type].files[j] = $event;
};

const uploadFile = async (item, i) => {
  loader.toggleLoader(true);
  const reactItem = reactItems.value[item.type];
  const routeLink = structureItems[i].post;
  let formData = new FormData();
  for (let i = 0; reactItem.files.length > i; i++) {
    formData.append("documents[]", reactItem.files[i]);
  }
  await axios
    .post(routeLink, formData)
    .then((response) => {
      if ((response.status = 201)) {
        reactItem.submittedNotif = true;
        setTimeout(() => {
          reactItem.submittedNotif = false;
          reactItem.submitted = true;
        }, 7000);
        submitNotification();
      }
    })
    .catch((e) => {
      submitFailureNotification();
    });
  loader.toggleLoader(false);
};

const loadFromServer = async () => {
  loader.toggleLoader(true);
  let responseData = "";

  await axios
    .get("/kyc/status")
    .then((response) => (responseData = response.data.data))
    .catch((e) => console.log(e));

  const personalId = responseData.PersonalId;
  const POI = reactItems.value["proof-identity-poi"];
  const proofOfAdress = responseData.AddressVerification;
  const POA = reactItems.value["proof-identity-poa"];
  const bankStatement = responseData.BankStatement;
  const bank = reactItems.value["proof-identity-bank"];
  const proofOfDeposit = responseData.DepositProof;
  const POD = reactItems.value["proof-identity-pod"];

  if (personalId == "pending") {
    POI.submitted = true;
  } else if (personalId == "approved") {
    POI.verified = true;
  }

  if (proofOfAdress == "pending") {
    POA.submitted = true;
  } else if (proofOfAdress == "approved") {
    POA.verified = true;
  }

  if (bankStatement == "pending") {
    bank.submitted = true;
  } else if (bankStatement == "approved") {
    bank.verified = true;
  }

  if (proofOfDeposit == "pending") {
    POD.submitted = true;
  } else if (proofOfDeposit == "approved") {
    POD.verified = true;
  }

  if (POI.verified && POA.verified && bank.verified && POD.verified) {
    verified.value = true;
  } else {
    verified.value = false;
  }
  loader.toggleLoader(false);
};
loadFromServer();

const iconDropdownRotation = (item) => {
  if (
    !reactItems.value[item.type].success &&
    !reactItems.value[item.type].submitted &&
    !reactItems.value[item.type].submittedNotif &&
    !reactItems.value[item.type].verified
  ) {
    return true;
  } else {
    return false;
  }
};

const showGreenChecker = (item) => {
  if (
    reactItems.value[item.type].success &&
    !reactItems.value[item.type].submitted &&
    !reactItems.value[item.type].verified
  ) {
    return true;
  } else {
    return false;
  }
};

const showDropdown = (item) => {
  if (
    reactItems.value[item.type].dropdown &&
    !reactItems.value[item.type].submitted &&
    !reactItems.value[item.type].submittedNotif &&
    !reactItems.value[item.type].verified
  ) {
    return true;
  } else {
    return false;
  }
};
</script>

<template>
  <div class="kyc-wrapper card">
    <div class="row card-page flex flex-center">
      <div class="col-6">
        <!-- Title -->
        <div class="txt-center">
          <p v-show="!verified" class="txt-bold title-small mgt-5">
            Verify your account in
            <span class="txt-theme-brand-1">&nbsp5&nbsp </span> easy steps
          </p>

          <!-- Verified checker green -->
          <div v-show="verified" class="mgt-6">
            <IconComponent
              :iconClasses="`icon-90 pdr-6 inline-block bg-success icon-success`"
            ></IconComponent>
            <p class="txt-success title-large">Account Verified</p>
          </div>
        </div>

        <!-- txt -->
        <div>
          <p class="text-smallest txt-theme-txt-2 mgy-5">{{ contentTxt }}</p>
        </div>

        <!-- Submit failure info card = red  -->
        <div v-show="submitFailure" class="bg-error radius-6 mgb-4 pdy-6 pdr-6 flex">
          <div class="flex flex-center pdx-6">
            <IconComponent
              :iconClasses="`icon-20 scale-13 inline-block bg-light icon-form-clear`"
            ></IconComponent>
          </div>
          <div>
            <p class="text-smallest txt-bold pda-0">Documents failed to submit!</p>
            <p class="text-smallest pda-0">Please try again.</p>
          </div>
        </div>

        <!-- Main card -->
        <div v-for="(item, i) in structureItems">
          <div
            :data-testid="testId ? `mainCard-${i}` : null"
            :class="reactItems[item.type].submittedNotif ? 'bg-success' : 'bg-theme-bg-2'"
            class="radius-6 padding-lr mgb-4"
          >
            <div class="cursor-pointer container-dropdown w-100 flex">
              <span
                :id="item.id"
                @click="reactItems[item.type].dropdown = !reactItems[item.type].dropdown"
                class="flex flex-center w-100"
              >
                <div class="flex">
                  <IconComponent
                    v-show="
                      !reactItems[item.type].submitted &&
                      !reactItems[item.type].submittedNotif
                    "
                    :iconClasses="`icon-24 scale-13 pdr-6 inline-block bg-theme-brand-1 icon-${item.icon}`"
                    :data-testid="testId ? `icon-${i}` : null"
                  ></IconComponent>
                  <IconComponent
                    v-if="reactItems[item.type].submittedNotif"
                    :iconClasses="`icon-24 scale-13 pdr-6 inline-block bg-theme-brand-1 icon-check check-icon`"
                  ></IconComponent>
                  <span class="flex check-span">
                    <IconComponent
                      v-if="verified || reactItems[item.type].verified"
                      :iconClasses="`icon-24 scale-13 pdr-6 inline-block bg-theme-brand-1 icon-check-circle check-icon`"
                    ></IconComponent>
                  </span>
                </div>
                <IconComponent
                  v-show="reactItems[item.type].submitted"
                  :iconClasses="`icon-24 scale-13 pdr-6 inline-block bg-theme-brand-1 icon-pending`"
                ></IconComponent>

                <div class="w-100 pdl-4">
                  <p
                    :data-testid="testId ? `title-${i}` : null"
                    class="pda-0 txt-bold title-small"
                    :class="
                      reactItems[item.type].verified ? 'txt-success' : 'txt-theme-txt-2'
                    "
                  >
                    {{ item.name }}
                  </p>
                  <p
                    :data-testid="testId ? `subtext-${i}` : null"
                    class="pda-0 txt-12"
                    :class="
                      reactItems[item.type].verified ? 'txt-success' : 'txt-theme-txt-2'
                    "
                  >
                    {{ pendingSubtextSwitch(item) }}
                  </p>
                </div>

                <!-- Icon dropdown rotation-->
                <div v-show="iconDropdownRotation(item)">
                  <IconComponent
                    :data-testid="testId ? `arrow-${i}` : null"
                    :class="reactItems[item.type].dropdown ? 'rotate-up' : 'scale-0-7'"
                    :iconClasses="`icon-18 scale-0-7 inline-block bg-theme-txt-1 icon-dropdown`"
                  ></IconComponent>
                </div>

                <!-- Icon checked green -->
                <div v-show="showGreenChecker(item)">
                  <IconComponent
                    :iconClasses="`icon-24 scale-13 scale-0-7 inline-block bg-success icon-check`"
                  ></IconComponent>
                </div>
              </span>
            </div>

            <!--DROPDOWN  -->
            <div v-show="showDropdown(item)" class="mgb-4">
              <p class="text-smallest pdt-0 txt-theme-txt-2 mgy-3">{{ item.txt }}</p>
              <div v-for="(file, j) in reactItems[item.type].files" class="pdb-2 flex">
                <div class="w-100">
                  <InputFileComponent
                    :fileId="item.id + 'file' + (j + 1)"
                    @isValid="checkIsValid(item, $event)"
                    @onFileChange="saveFile(item, $event, j)"
                  ></InputFileComponent>
                </div>
                <div class="pdl-2 pdt-3 flex">
                  <IconComponent
                    @click="addInputField(item)"
                    v-if="
                      j == reactItems[item.type].files.length - 1 &&
                      reactItems[item.type].files.length <= 4
                    "
                    :iconClasses="`icon-24 scale-0-7 inline-block bg-theme-brand-2 icon-plus cursor-pointer`"
                  ></IconComponent>
                  <IconComponent
                    @click="removeInputField(item)"
                    v-if="
                      j == reactItems[item.type].files.length - 1 &&
                      reactItems[item.type].files.length <= 5 &&
                      reactItems[item.type].files.length > 1
                    "
                    :iconClasses="`icon-24 scale-09 inline-block bg-error icon-form-clear cursor-pointer`"
                  ></IconComponent>
                </div>
              </div>
              <div class="pdb-4">
                <button
                  :id="'submit' + item.id"
                  @click="uploadFile(item, i)"
                  class="bg-theme-brand-2 txt-bold txt-dark title-smallest radius-6 btn-submit w-100"
                >
                  SUBMIT DOCUMENTS
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- QUESTONNAIRE -->
        <RouterLink
          id="questionnaire"
          :to="completed || verified ? '' : '/questionnaire'"
        >
          <div
            class="padding-lr cursor-pointer container-dropdown w-100 radius-6 bg-theme-bg-2 mgb-5 flex"
          >
            <div class="flex">
              <IconComponent
                :iconClasses="`icon-24 scale-13 pdr-6 inline-block bg-theme-brand-1 icon-edit-doc`"
              ></IconComponent>
              <span class="flex check-span">
                <IconComponent
                  v-if="verified"
                  :iconClasses="`icon-24 scale-13 pdr-6 inline-block bg-success icon-check-circle check-icon`"
                ></IconComponent>
              </span>
            </div>
            <div class="w-100 pdl-4">
              <p
                class="pda-0 txt-bold title-small txt-theme-txt-1"
                :class="verified ? 'txt-success' : 'txt-theme-txt-2'"
              >
                Questionnaire
              </p>
              <p
                v-show="verified"
                class="pda-0 txt-12"
                :class="verified ? 'txt-success' : 'txt-theme-txt-2'"
              >
                {{ verifiedSubtext }}
              </p>
            </div>
            <div v-show="!completed && !verified" class="flex flex-center">
              <IconComponent
                :iconClasses="`rotate-right-90 icon-18 inline-block bg-theme-txt-1 icon-dropdown`"
              ></IconComponent>
            </div>
          </div>
        </RouterLink>
        <!--  <button v-show="!verified && !submitted" class="bg-theme-brand-1 txt-bold txt-dark title-smallest radius-6 btn-submit w-100 mgb-7">SUBMIT DOCUMENTS</button> -->
      </div>
    </div>
  </div>
</template>
