<script setup lang="ts">
import { computed } from "vue";
import BonusNotification from "@/brands/bit1111/components/bonus/BonusNotificationComponent.vue";
import BonusModal from "@/components/bonus/BonusModal.vue";
import { useAuthStore } from "@/stores/auth";
import PlayerAlertMessasageModal from "@/brands/bit1111/components/shared/modal/PlayerAlertMessageModal.vue";
import { useLayoutModalsStore } from "@/stores/layoutModals";
import LoginModal from "@/brands/holahola/views/mobile/auth/LoginModal.vue";
import { useLoaderStore } from "@/stores/loader";
import LoaderVue from "@/components/shared/loader/LoaderComponent.vue";
import RegisterModal from "../views/mobile/auth/RegisterModal.vue";

import BoardingStagesMobileWrapper from "@/brands/holahola/components/boarding/BoardingStagesMobileWrapper.vue";
import PageNotification from "@/brands/holahola/components/shared/notifications/PageNotificationComponent.vue"

const authStore = useAuthStore();
const layoutModalsStore = useLayoutModalsStore();
const loader = useLoaderStore();
const boardingStagesWrapper = computed(() => authStore.getBoardingFlag);
/* const notifFlag = computed(() => {
  return layoutModalsStore.isModalActive("bonusNotification");
});
const playerAlertMessage = computed(() => {
  return layoutModalsStore.isModalActive("playerAlertMessage");
}); */
const loginMobile = computed(() => {
  return layoutModalsStore.isModalActive("loginMobile");
});
const registerMobile = computed(() => {
  return layoutModalsStore.isModalActive("registerMobile");
});
const pageNotification = computed(() => {
  return layoutModalsStore.isModalActive("pageNotification");
});
</script>

<template>
  <LoaderVue v-if="loader.getIsLoading"></LoaderVue>
  <!--  <BonusNotification v-if="notifFlag" />
  <BonusModal /> -->
  <!--   <PlayerAlertMessasageModal v-if="playerAlertMessage" /> -->

  <LoginModal v-if="loginMobile" />
  <PageNotification v-if="pageNotification" />
  <BoardingStagesMobileWrapper v-if="!boardingStagesWrapper" />
  <RegisterModal v-if="registerMobile" />
  
</template>
