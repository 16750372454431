import type { Month } from "@/services/datepicker/datepicker";

export const defaultDateFormatter = (
  day: number,
  month: Month,
  year: number
) => {
  let formattedDate = "";
  formattedDate += day < 10 ? "0" + day : day;
  formattedDate += "-";
  formattedDate += month.numeric < 10 ? "0" + month.numeric : month.numeric;
  formattedDate += "-";
  formattedDate += year;

  return formattedDate;
};
