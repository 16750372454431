<script setup lang="ts">
import { useLayoutModalsStore } from "@/stores/layoutModals";
import InputValueComponent from "@/brands/holahola/components/shared/forms/input/InputValueComponent.vue";
import DropDownComponent from "@/brands/holahola/components/shared/forms/dropdown/DropDownComponent.vue";
import { ref, onMounted } from "vue";
import { useLoaderStore } from "@/stores/loader";
import { useAuthStore } from "@/stores/auth";
import { registerStepTwo } from "@/services/auth/registration";
import type {
  RegisterInitialPayloadInterface,
  RegisterConfirmPayLoadInterface,
} from "@/services/interface/registrationInterface";
import type { PropType } from "vue";
import { RouterSingleton } from "@/router/index";
import InputPhoneComponent from "@/brands/holahola/components/shared/forms/input/InputPhoneComponent.vue";
import CheckBoxTermsAndConditions from "@/brands/holahola/components/auth/TermsAndConditionsHola.vue";
import RegistrationStepCompoment from "@/brands/holahola/components/auth/RegistrationStepComponent.vue";
import IconComponent from "@/components/icons/IconComponent.vue";
import { computeFormValidation } from "@/services/validation/formValidation";
import { translate } from "@/services/translations/translation";

const props = defineProps({
  stepOneData: {
    required: true,
    type: Object as PropType<RegisterInitialPayloadInterface>,
  },
});

const borderClass = ref("border-input radius-5 border-color-theme-brand-1");
const loader = useLoaderStore();
const authStore = useAuthStore();
const layoutModalsStore = useLayoutModalsStore();

const registerData = ref<RegisterConfirmPayLoadInterface>({
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  gender: "",
});

const close = () => {
  layoutModalsStore.closeModal("registerConfirmLast");
};

const selectedCountry = ref();
const city = ref("");
const address = ref("");
const zipCode = ref("");
const phoneCode = ref("+1");
const phoneNumber = ref("");

const submit = async () => {
  const data = {
    address: address.value,
    city: city.value,
    country: selectedCountry.value?.id,
    dateOfBirth: registerData.value.dateOfBirth,
    firstName: registerData.value.firstName,
    lastName: registerData.value.lastName,
    gender: registerData.value.gender,
    zipCode: zipCode.value,
    phone: phoneNumber.value,
    phoneCode: phoneCode.value,
  };
  loader.toggleLoader(true);
  let key: ValidationKey;
  for (key in validationList.value) {
    validationList.value[key].isTouched = true;
  }
  if (formisValid.value) {
  registerStepTwo(data)
    .then(async () => {
      await authStore.setPlayerData();
      RouterSingleton.getInstance().getRouter().push("/");
      // layoutModalsStore.activateModal("boarding");      
    })
    .catch((error) => {
    });

  close();
  }loader.toggleLoader(false);
};

onMounted(() => {
  registerData.value = JSON.parse(JSON.stringify(props.stepOneData));
});

// VALIDATION START
const validationList = ref({
  country: { isTouched: false, valid: false },
  city: { isTouched: false, valid: false },
  address: { isTouched: false, valid: false },
  zipCode: { isTouched: false, valid: false },
  phone: { isTouched: false, valid: false },
  checkboxComponent: { isTouched: false, valid: false },
});
type ValidationKey = keyof typeof validationList.value;

const formisValid = ref<boolean>(false);
const callbackValid = (pointer: ValidationKey, value: boolean) => {
  validationList.value[pointer].valid = value;
  computeFormValidation(validationList.value, formisValid);
};
// VALIDATION END
</script>
<template>
  <div>
    <div class="backdrop"></div>
    <form @submit.prevent>
      <div
        class="modal-Centered bg-linear-1-1-1-5 zindex-modal radius-15 pdy-3 pdx-15"
      >
        <div type="" class="flex flex-right">
          <IconComponent
            :iconClasses="'svg-icon block icon-16  icon-form-clear bg-theme-bg-7 cursor-pointer'"
            @click="close"
          ></IconComponent>
        </div>
        <h1
          class="title-large mgt-5 txt-theme-txt-1 txt-bold mgx-auto fit-content"
        >
        {{ translate('common.register').toUpperCase() }}
        </h1>
        <p class="txt-theme-txt-2 mgx-auto fit-content text-smallest txt-bold">
        {{ translate('auth.register_init.mobile.registerComplete.almostThere') }}
        </p>
        <RegistrationStepCompoment :step="3" />
        <DropDownComponent
          dropdownId="country"
          :borderClass="'  border-1-2 border-width-1'"
          :textCutBorder="'label-cut bg-1-3 border-top-1-2 border-width-1 txt-light'"
          :bgClass="'bg-1-3 '"
          :radiusClass="'radius-26'"
          :label="translate('common.country')"
          :url="'/geo-resources/countries'"
          :selected="selectedCountry"
          :selector="'name'"
          @emitSelected="selectedCountry = $event"
          v-model:isTouched="validationList.country.isTouched"
          @isValid="callbackValid('country', $event)"
        >
        </DropDownComponent>

        <InputValueComponent
          textCutBorder="label-cut bg-1-3 border-top-1-2 border-width-1"
          textCutBorderFocused="label-cut bg-1-3 border-top-1-2 border-width-1"
          bgColor="bg-1-3 radius-26"
          :borderClass="' radius-26 border-1-2 border-width-1'"
          inputId="city"
          :placeholder="translate('common.city')"
          regexValidator="city"
          v-model="city"
          :minLength="1"
          :maxLength="200"
          v-model:isTouched="validationList.city.isTouched"
          @isValid="callbackValid('city', $event)"
        ></InputValueComponent>
        <InputValueComponent
          textCutBorder="label-cut bg-1-3 border-top-1-2 border-width-1"
          textCutBorderFocused="label-cut bg-1-3 border-top-1-2 border-width-1"
          bgColor="bg-1-3 radius-26"
          :borderClass="' radius-26 border-1-2 border-width-1'"
          inputId="adress"
          :placeholder="translate('common.address')"
          regexValidator="address"
          v-model="address"
          :minLength="2"
          :maxLength="40"
          v-model:isTouched="validationList.address.isTouched"
          @isValid="callbackValid('address', $event)"
        ></InputValueComponent>
        <InputValueComponent
          textCutBorder="label-cut bg-1-3 border-top-1-2 border-width-1"
          textCutBorderFocused="label-cut bg-1-3 border-top-1-2 border-width-1"
          bgColor="bg-1-3 radius-26"
          :borderClass="' radius-26 border-1-2 border-width-1'"
          inputId="zip"
          :placeholder="translate('common.zipCode')"
          regexValidator="zipCode"
          v-model="zipCode"
          :minLength="2"
          :maxLength="10"
          v-model:isTouched="validationList.zipCode.isTouched"
          @isValid="callbackValid('zipCode', $event)"
        ></InputValueComponent>
        <div class="mgt-4">
          <InputPhoneComponent
            :borderClass="borderClass"
            :selectedCode="phoneCode"
            :selectedPhoneNumber="Number(phoneNumber)"
            codeRegexValidator="phoneCode"
            phoneRegexValidator="phone"
            :codeMinLength="2"
            :codeMaxLength="10"
            :phoneMinLength="5"
            :phoneMaxLength="15"
            @changePhoneCode="phoneCode = $event"
            @changePhoneNumber="phoneNumber = $event"
            @isValid="callbackValid('phone', $event)"
            v-model:isTouched="validationList.phone.isTouched"
          />
        </div>
        <CheckBoxTermsAndConditions
          @isValid="callbackValid('checkboxComponent', $event)"
          v-model:isTouched="validationList.checkboxComponent.isTouched"
        />
        <button
          id="submit"
          class="mgx-auto mgy-7 bg-2-1 radius-10 w-250px h-50px font-Poppins txt-size-18 txt-weight-600 txt-light"
          @click="submit()"
        >
        {{ translate('auth.register_init.mobile.registerComplete.funBegin') }}
        </button>
      </div>
    </form>
  </div>
</template>
