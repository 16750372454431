<script setup lang="ts">
import type {
  BannerInterface,
  BannerElementInterface,
} from "@/services/interface/banner";
import type { PropType } from "vue";
import { onMounted, ref } from "vue";
import ButtonComponent from "@/brands/bit1111/components/shared/buttons/ButtonComponent.vue";
const props = defineProps({
  banner: {
    required: true,
    type: Object as PropType<BannerInterface>,
  },
  height: { required: false, type: Number },
  width: { required: false, type: Number },
  index: { required: true, type: Number },
});

// const parentheight = ref(0);
const initializeDiv = () => {
  //   try {
  //     parentheight.value = document
  //       .getElementById(`bannerImageId${props.index}`)
  //       .getBoundingClientRect().height;
  //   } catch (error) {
  //     parentheight.value = 500;
  //   }
};

onMounted(() => {
  setTimeout(initializeDiv, 1000);
});
</script>
<template>
  <div class="bannerWrapper w-100 h-100 ">
    <img
      class="imageElement w-100 h-100 radius-top-8"
      :id="`bannerImageId${index}`"
      :src="banner.data.imageBannerUrl"
    />
    <div class="imageOverlay h-100">
      <div class="w-100 h-100" :style="banner.data.properties">
        <template v-for="(element, i) in banner.data.elements" :key="i">
          <template v-if="element.isButton">
            <ButtonComponent
              v-if="element.url != ''"
              :style="element.style + 'width: fit-content;'"
              class="pdx-3 mgy-3"
              :text="element.text"
              type="primaryFilled"
              :link="element.url"
            />
            <ButtonComponent
              v-else
              :style="element.style + 'width: fit-content;'"
              class="pdx-3 mgy-3"
              :text="element.text"
              type="primaryFilled"
            />
          </template>

          <span v-else :style="element.style">{{ element.text }}</span>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
.bannerWrapper {
  position: relative;
  top: 0;
  left: 0;
}

.imageElement {
  position: relative;
  top: 0;
  left: 0;
  aspect-ratio: 1184/500;
}
.imageOverlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  aspect-ratio: 1184/500;
  /* background-color: rgba(255, 50, 50, 0.2); */
}
</style>
