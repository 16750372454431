import CasinoBalanceEvent from "@/eventListeners/casinoBalance";
import CasinoWageringEvent from "@/eventListeners/casinoWagering";
import CasinoPlayerAlert from "@/eventListeners/casinoPlayerAlert";
import CasinoNotificationEvent from "@/eventListeners/casinoPlayerNotifications";
import CasinoReservedFundsEvent from "@/eventListeners/reservedFundsListener";
import { SSEManager } from "./sseManager";

const eventRegister = new SSEManager([
  {
    name: "playerBalance",
    auth: true,
    event: new CasinoBalanceEvent(),
  },
  {
    name: "playerWagering",
    auth: true,
    event: new CasinoWageringEvent(),
  },
  {
    name: "playerAlert",
    auth: true,
    event: new CasinoPlayerAlert(),
  },
  {
    name: "playerNotification",
    auth: true,
    event: new CasinoNotificationEvent(),
  },
  {
    name: "playerReservedFunds",
    auth: true,
    event: new CasinoReservedFundsEvent(),
  },
  // {
  //   name: "test",
  //   auth: false,
  //   topic: "test",
  //   on: "message",
  //   listener: function (event) {
  //     return;
  //   },
  // },
]);

export default eventRegister;
