import { defineStore } from "pinia";
import axios from "axios";

export const usePaymentStore = defineStore({
  id: "payments",
  state: () => ({
    depositMethods: [null, null],
    withdrawalMethods: [null, null],
  }),
  actions: {
    getMethod(walletId, method) {
      let methods;
      if (method == "withdrawal") {
        methods = this.withdrawalMethods[walletId - 1];
      } else {
        methods = this.depositMethods[walletId - 1];
      }
      return methods;
    },
    async loadMethods() {
      Promise.allSettled([
        axios.post("/payment/methods", {
          paymentType: "deposit",
          walletType: 1,
        }),
        axios.post("/payment/methods", {
          paymentType: "deposit",
          walletType: 2,
        }),
        axios.post("/payment/methods", {
          paymentType: "withdrawal",
          walletType: 1,
        }),
        axios.post("/payment/methods", {
          paymentType: "withdrawal",
          walletType: 2,
        }),
      ])
        .then((results) => {
          this.depositMethods[0] = results[0].value.data.data;
          this.depositMethods[1] = results[1].value.data.data;
          this.withdrawalMethods[0] = results[2].value.data.data;
          this.withdrawalMethods[1] = results[3].value.data.data;
        })
        .catch((error) => {
          // console.log("error loading methods: ", error);
        });
    },
    async loadMethodsWait() {
      await Promise.allSettled([
        axios.post("/payment/methods", {
          paymentType: "deposit",
          walletType: 1,
        }),
        axios.post("/payment/methods", {
          paymentType: "deposit",
          walletType: 2,
        }),
        axios.post("/payment/methods", {
          paymentType: "withdrawal",
          walletType: 1,
        }),
        axios.post("/payment/methods", {
          paymentType: "withdrawal",
          walletType: 2,
        }),
      ])
        .then((results) => {
          this.depositMethods[0] = results[0].value.data.data;
          this.depositMethods[1] = results[1].value.data.data;
          this.withdrawalMethods[0] = results[2].value.data.data;
          this.withdrawalMethods[1] = results[3].value.data.data;
        })
        .catch((error) => {
          // console.log("error loading methods: ", error);
        });
    },
    async getMethodLoad(method, walletId) {
      let response = null;
      try {
        response = await axios.post("/payment/methods", {
          paymentType: method,
          walletType: walletId,
        });
        response = response.data.data;
      } catch (error) {
        //
      }

      return response;
    },
  },
});
