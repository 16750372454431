import { defineStore } from "pinia";
import { axiosGet } from "../services/axios/crudOperations";
export const useDropdownsStore = defineStore("dropdowns", {
  state: () => ({
    allDropdowns: Object,
  }),
  actions: {
    getDropdown(url) {
      if (url in this.allDropdowns) {
        return this.allDropdowns[url];
      }
      return this.putDropdown(url);
    },
    async putDropdown (url) {
      let response;
      if (url.startsWith("/")) {
        response = await axiosGet(url);
      } else {
        response = await axiosGet(`/dropdown/${url}`);
      }

      this.allDropdowns[url] = response;
      return response;
    },
  },
});
