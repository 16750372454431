import { ref } from "vue";

export const links = ref([
    {
      name: "My Account",
      url: null,
      open: false,
      id: "myAccount",
      children: [
        {
          name: "Personal Information",
          url: "/personal-information",
          id: "personalInformation",
          divider: true,
        },
        {
          name: "Change Password",
          url: "/change-password",
          id: "changePassword",
          divider: true,
        },
        {
          name: "Protection Settings",
          url: "/limits",
          id: "protectionSettings",
          divider: true,
        },
        // {
        //   name: "Subscription Settings",
        //   url: "/subscription-settings",
        //   id: "subscriptionSettings",
        //   divider: true,
        // },
        {
          name: "KYC",
          url: "/kyc",
          id: "kyc",
          divider: false,
        },
      ],
    },
    {
      name: "Transactions",
      url: null,
      open: false,
      id: "transactions",
      children: [
        {
          name: "Deposit",
          url: "/deposit",
          id: "deposit",
          divider: true,
        },
        {
          name: "Withdraw",
          url: "/withdraw",
          id: "withdraw",
          divider: true,
        },
        {
          name: "Transfer",
          url: "/transfer",
          id: "transfer",
          divider: false,
        },
      ],
    },
    {
      name: "History",
      url: null,
      open: false,
      id: "history",
      children: [
        {
          name: "Transaction History",
          url: "/transaction-history",
          id: "transactionHistory",
          divider: true,
        },
        // {
        //   name: "Wagering History",
        //   url: "/wagering-history",
        //   id: "wageringHistory",
        //   divider: true,
        // },
        {
          name: "Bets History",
          url: "/bets-history",
          id: "betsHistory",
          divider: true,
        },
        {
          name: "Bonus History",
          url: "/bonus-history",
          id: "bonusHistory",
          divider: false,
        },
      ],
    },
    {
      name: "Help",
      url: null,
      open: false,
      id: "help",
      children: [
        // {
        //   name: "FAQ",
        //   url: "/faq",
        //   id: "faq",
        //   divider: true,
        // },
        {
          name: "Contact Us",
          url: "/support",
          id: "contact",
          divider: true,
        },
        // {
        //   name: "Chat",
        //   url: "/chat",
        //   id: "chat",
        //   divider: false,
        // },
      ],
    },
  ]);
  
  export const balances = ref([
    {
      name: "Equilibrio de juegos de mesa",
      value: "$0.00",
      id: "sportbook"
    },
    {
      name: "Casino Balance",
      value: "$0.00",
      id: "casino"
    },
  ]);