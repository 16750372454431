<script setup lang="ts">
import DividerComponent from "@/components/shared/divider/DividerComponent.vue";
import { useAuthStore } from "@/stores/auth";
import { ref } from "vue";
import { goToRoute } from "@/router/routingService";
import InputValueComponent from "../../components/shared/forms/input/InputValueComponent.vue";
import InputPasswordComponent from "../../components/shared/forms/input/InputPasswordComponent.vue";
import { useLoaderStore } from "../../stores/loader";

const loader = useLoaderStore();
const login = ref({
  username: "",
  password: "",
});
const authStore = useAuthStore();
const responseErr = ref();
async function onSubmit() {
  loader.toggleLoader(true);
  responseErr.value = await authStore.login(
    login.value.username.toLowerCase(),
    login.value.password
  );
  loader.toggleLoader(false);
}
function clearResponseErr() {
  responseErr.value = null;
}
clearResponseErr();
</script>
<template>
  <div class="card">
    <div class="card-body">
      <div class="form-size">
        <h1 class="txt-center title-large mgt-5 txt-theme-txt-1">Login</h1>
        <form class="" @submit.prevent="onSubmit">
          <input
            type="hidden"
            id="_token"
            name="_token"
            value="YpwXOiwNaPdKp1ESVu7SeuNYYcx0-_JEzY2_jXO-9X8"
          />
          <InputValueComponent
            inputId="name"
            placeholder="Username or email"
            :inputType="'text'"
            v-model="login.username"
            :disableValidation="true"
          ></InputValueComponent>
          <InputPasswordComponent
            :autocomplete="true"
            passId="password"
            label="Password 8+ characters"
            v-model="login.password"
            :disableValidation="true"
          ></InputPasswordComponent>
          <p v-show="responseErr !== null" class="txt-center txt-error">
            Login failed: {{ responseErr }}
          </p>
          <button
            id="submit"
            class="block btn-brand-light btn-large mgb-6 mgt-4 mgx-auto"
          >
            Login
          </button>
        </form>
        <div class="form-size">
          <RouterLink
            id="reset"
            class="block txt-center txt-theme-brand-2"
            to="/email-password-reset"
          >
            Forgot password?
          </RouterLink>
          <DividerComponent
            :dividerClass="'w-95 mgx-auto mgt-6 bg-theme-bg-3'"
            :dividerText="'or'"
          ></DividerComponent>
          <h2 class="txt-center mgy-5 title-large txt-theme-txt-1">
            Login with
          </h2>
          <!-- social buttons -->
          <div class="social-wrapper flex mgb-6">
            <a href="" class="btn btn-normal btn-info-dark"
              ><img
                src="../../assets/icons/ico-sm-fb.svg"
                alt="Facebook"
                class="icon-inline"
                loading="lazy"
                id="facebook"
              />
              Facebook</a
            >
            <a href="" class="btn btn-normal btn-info"
              ><img
                src="../../assets/icons/ico-sm-tw.svg"
                alt="Twitter"
                class="icon-20 icon-inline"
                loading="lazy"
                id="twitter"
              />
              Twitter</a
            ><a href="" class="btn btn-normal btn-light"
              ><img
                src="../../assets/icons/ico-sm-google.svg"
                alt="Google"
                class="icon-20 icon-inline"
                loading="lazy"
                id="google"
              />
              Google</a
            >
          </div>
          <DividerComponent
            :dividerClass="'w-95 mgx-auto mgy-6 bg-theme-bg-3'"
          ></DividerComponent>
          <p class="txt-center mgy-6 txt-theme-txt-1">Don't have an account?</p>
          <button
            id="register"
            class="btn btn-large btn-brand-light-secondary mgx-auto mgy-6 txt-bold"
            @click="goToRoute('register')"
          >
            Register
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
