<script setup lang="ts">
import IconComponent from "../../../icons/IconComponent.vue";
import { ref, computed, watch } from "vue";
import { validateInput } from "../../../../services/validation/inputValidation";

const props = defineProps({
  //style
  placeholder: String,
  isInlineInput: Boolean,
  inputType: String,
  inputId: String,
  lines: Number,
  columns: Number,
  //model
  modelValue: [String, Number],
  //validation params
  maxLength: Number,
  minLength: Number,
  regexValidator: String,

  disableValidation: Boolean,
});

const isTouched = ref(false);

//DOUBLE BINDING + VALIDATION
//const initial = ref(false);
const emit = defineEmits(["isValid", "update:modelValue"]);
const validation = computed(() =>
  validateInput(
    props.modelValue ? props.modelValue.toString() : "",
    props.minLength ? props.minLength : 0,
    props.maxLength ? props.maxLength : 255,
    props.regexValidator ? props.regexValidator : ""
  )
);
const inputHandler = async (value: string | number | undefined) => {
  await emit("update:modelValue", value);
  if (value) {
    if (
      props.inputType == "number" &&
      !value.toString().match("^[0-9]*$") &&
      value.toString() != ""
    ) {
      const tempStr = value.toString();
      await emit("update:modelValue", tempStr.slice(0, tempStr.length - 1));
    }
    if (
      props.inputType == "date" &&
      (value.toString().length == 3 || value.toString().length == 6)
    ) {
      let tempStr = value.toString().substring(0, value.toString().length - 1);
      tempStr += "-";
      await emit("update:modelValue", tempStr);
    }
    isTouched.value = true;
  }
  emit("isValid", validation.value.isValid);
};

const inputEvent = async (event: any) => {
  if (event) {
    await inputHandler(event.target.value);
  }
};

inputHandler(props.modelValue);
watch(
  props,
  (value) => {
    if (props.modelValue !== null) {
      isTouched.value = true;
    } else {
      isTouched.value = false;
    }

    emit("isValid", validation.value.isValid);
  },
  { deep: true }
);
//DOUBLE BINDING + VALIDATION
</script>

<template>
  <label class="w-100 block relative bg-theme-bg-1 mgt-4 '">
    <textarea
      :rows="lines"
      :cols="columns"
      :id="inputId"
      @click="isTouched = true"
      :type="'text'"
      class="w-100 pdl-4 pdt-6"
      :class="{ 'border-input radius-5 ': !isInlineInput }"
      :value="modelValue"
      @input="inputEvent($event)"
    ></textarea>
    <span
      :class="
        isTouched
          ? 'floating-label-touched absolute'
          : 'floating-label-textarea txt-theme-txt-2 absolute'
      "
      >{{ placeholder }}</span
    >
  </label>
  <div v-if="!validation.isValid && isTouched && !disableValidation" class="txt-error">
    {{ validation.message }}
  </div>
</template>
