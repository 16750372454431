<script setup lang="ts">
import { ref, shallowRef, watch, onMounted, onUnmounted } from "vue";
import type { Ref, PropType } from "vue";
import axios from "axios";
import GameLinkComponent from "@/brands/bit1111/components/casino/GameLinkComponent.vue";
import type { NameIdInterface } from "@/brands/bit1111/interface/casinoInterface";
import { translate } from "@/services/translations/translation";
import { useLoaderStore } from "@/stores/loader";
import NotificationComponent from "@/components/shared/notification/NotificationComponent.vue";
import { formatCarouselGames } from "@/services/formatting/gameFormatter";
import SliderButtons from "@/brands/bit1111/components/shared/slider/SliderButtons.vue";
import {
  moveToPage,
  touchEvent,
  calculateCarouselLength,
  splitCarouselGames,
} from "@/brands/bit1111/services/sliderFunctions";
import ButtonComponentVue from "../shared/buttons/ButtonComponent.vue";

const props = defineProps({
  category: {
    required: false,
    type: Object as PropType<NameIdInterface>,
  },
  supplier: {
    required: false,
    type: Object as PropType<NameIdInterface>,
  },
  searchedName: {
    required: false,
    type: String,
    default: "",
  },
  searchActive: {
    required: true,
    type: Boolean,
  },
  isMobile: {
    required: false,
    type: Boolean,
    default: false,
  },
  isCarousel: {
    required: false,
    type: Boolean,
    default: false,
  },
  showAllGames: {
    required: false,
    type: Boolean,
    default: false,
  },
  visibleGames: {
    required: true,
    type: Number,
  },
  gameWidth: {
    required: true,
    type: Number,
  },
  gameHeight: {
    required: true,
    type: Number,
  },
  rows: {
    required: false,
    default: 1,
    type: Number,
  },
  carouselTransitionValue: {
    required: false,
    type: Number,
    default: 1200,
  },
  buttonWidth: {
    required: false,
    type: Number,
    default: 0,
  },
  breakpoints: {
    requred: false,
    type: Array<number>,
    default: [1230, 935],
  },
  removeGamesOnBreakpoint: {
    required: false,
    type: Number,
    default: 1,
  },
  resetLoadMoreValue: {
    required: false,
    default: false,
    type: Boolean,
  },
  seeMoreGamesLink: {
    required: false,
    default: true,
    type: Boolean,
  },
});
const axiosBody = ref();
const finishedLoading = ref(false);
const loader = useLoaderStore();
const games = ref();
const gamesSplit = ref();
const allCarouselSplitedGames = ref();
const scrollableContainer: Ref<HTMLElement | undefined> = shallowRef();
const gamesLength = ref();
const formatCarouselGamesData = ref({
  breakpoints: props.breakpoints,
  visibleGames: props.visibleGames,
  removeGamesOnBreakpoint: props.removeGamesOnBreakpoint,
  transitionValue: props.carouselTransitionValue,
  gameWidth: props.gameWidth,
});

// splitGamesByIndex is for how much Load more button will show
const splitGamesByIndex = ref(
  formatCarouselGames(formatCarouselGamesData.value).visibleGames * 2
);
const splitGamesBy = ref();
const isLoaded = ref(false);
const notificationError = ref(false);
const selectedGame = ref();
const gameCounter = ref(false);
const localLoader = ref(false);
const carouselData = ref({
  carouselLength: 0,
  currentPage: 0,
  transitionValue: formatCarouselGames(formatCarouselGamesData.value)
    .transitionValue,
  transitionByEnter: 0,
  transitionByLeave: 0,
  touchXValue: 0,
});
const axiosBodyDefault = ref({
  start: 0,
  limit: 1000,
  filters: ref<Array<{ name: string; value: string | number | undefined }>>([
    {
      name: "device",
      value: "1",
    },
    { name: "category", value: 0 },
  ]),
});
const emit = defineEmits(["loadMoreValueReseted"]);

// Spliting games if not carousel
const splitGames = () => {
  gamesSplit.value = [];
  if (!props.isCarousel) {
    for (let i = 0; i < games.value?.length; i++) {
      if (i < splitGamesBy.value) {
        gamesSplit.value.push(games.value[i]);
      }
    }
  }
};

const loadMore = () => {
  if (
    splitGamesBy.value <=
    gamesLength.value -
      formatCarouselGames(formatCarouselGamesData.value).visibleGames
  ) {
    splitGamesBy.value += formatCarouselGames(
      formatCarouselGamesData.value
    ).visibleGames;
    splitGames();
  } else {
    splitGamesByIndex.value = gamesLength.value - splitGamesBy.value;
    splitGamesBy.value += splitGamesByIndex.value;
    splitGames();
  }
};

const init = async () => {
  localLoader.value = true;
  calculateLoadMoreStartValue();
  splitGamesBy.value = formatCarouselGames(
    formatCarouselGamesData.value
  ).visibleGames;
  
  axiosBody.value = JSON.parse(JSON.stringify(axiosBodyDefault.value));
  if (props.searchedName != "") {
    axiosBody.value.filters.push({
      name: "name",
      value: props.searchedName,
    });
  }
  props.showAllGames
    ? (axiosBody.value.filters[1].value = "")
    : (axiosBody.value.filters[1].value = props.category?.id);
  if (props.supplier) {
    const SupplierFilter = {
      name: "provider",
      value: props.supplier.id,
    };
    if (props.supplier.name != "All")
      axiosBody.value.filters.push(SupplierFilter);
  }
  await axios
    .post("/games/list", axiosBody.value)
    .then(function (response) {
      games.value = response.data.data.results;
      splitGamesBy.value = formatCarouselGames(
          formatCarouselGamesData.value
        ).visibleGames;
      if (props.isCarousel) {
        calculateCarouselLength(
          carouselData,
          games.value?.length,
          splitGamesBy.value,
          props.rows
        );
        splitCarouselGames(
          allCarouselSplitedGames,
          carouselData.value.carouselLength,
          props.rows,
          games.value,
          formatCarouselGamesData.value
        );
      } else {
        if (splitGamesBy.value > games.value?.length) {
          splitGamesBy.value = games.value?.length;
        }
        splitGames();

      }
    })
    .catch((error) => {
      notificationError.value = true;
    });
  finishedLoading.value = true;
  axiosBodyDefault.value.filters = [
    {
      name: "device",
      value: "1",
    },
    { name: "category", value: 0 },
  ];

  gamesLength.value = games.value?.length;
  localLoader.value = false;
};
const moveToPageFromButtons = (i: number) => {
  moveToPage(i, carouselData);
};
const touchSlide = (event: any, i: number) => {
  touchEvent(event, i, carouselData);
};
// EVENT LISTENER FOR LOAD MORE
const throttleEventListener = (fn: any, delay: number) => {
  let time = Date.now();
  return () => {
    if (time + delay - Date.now() <= 0) {
      fn();
      time = Date.now();
    }
  };
};

const defaultDesktopStartValue = ref<number>(-150);
const defaultMobileStartValue = ref<number>(-100);
const lastPass = ref<number>(0);
const minSkipValue = ref<number>(0);
const maxSkipValue = ref<number>(0);
const calculateLoadMoreStartValue = () => {
  props.isMobile
    ? ((lastPass.value = defaultMobileStartValue.value),
      (minSkipValue.value = 290),
      (maxSkipValue.value = 800))
    : ((lastPass.value = defaultDesktopStartValue.value),
      (minSkipValue.value = 207),
      (maxSkipValue.value = 800));
  emit("loadMoreValueReseted", true);
};

const calculateRowsToLoad = (currentValue: number) => {
  let count = Math.floor(
      (currentValue - lastPass.value) / minSkipValue.value
    );
    for (let i = 0; i < count; i++) {
      lastPass.value += minSkipValue.value;
      loadMore();
    }
}

const loadMoreOnScroll = () => {
  let currentValue = Math.abs(document.body.getBoundingClientRect().top);
  if (
    maxSkipValue.value > currentValue - lastPass.value &&
    currentValue - lastPass.value >= minSkipValue.value
  ) {
    calculateRowsToLoad(currentValue);
    gamesSplit.value?.length == games.value?.length
      ? (gameCounter.value = true)
      : (gameCounter.value = false);
  } else if (maxSkipValue.value < currentValue - lastPass.value) {
    gameCounter.value = true;
  }
};

// EVENT LISTENER FOR LOAD MORE END

const previousCategory = ref(props.category);
watch(
  props,
  (value) => {
    init();
    if (previousCategory.value !== value.category) {
      lastPass.value = 0;
      previousCategory.value = value.category;
    }
    props.resetLoadMoreValue ? calculateLoadMoreStartValue() : null;
    if (!props.isCarousel) {
      loadMoreOnScroll();
    }
  },
  { deep: true }
);

const throttle = ref();
const format = ref();
onMounted(async () => {
  await init();
  isLoaded.value = true;
  format.value = formatCarouselGames(formatCarouselGamesData.value);
  window.addEventListener("resize",
    format.value
  );
  if (!props.isCarousel) {
    throttle.value = throttleEventListener(loadMoreOnScroll, 100)
    window.addEventListener(
      "scroll",
      throttle.value
    );
    loadMoreOnScroll();
  }
});
onUnmounted(() => {
  window.removeEventListener("resize",
    format.value
  );
  window.removeEventListener(
    "scroll",
    throttle.value
  );
});

const closeNotification = (event: boolean) => {
  notificationError.value = event;
  lastPass.value = 0;
};

const continueLoading = () => {
  let currentValue = Math.abs(document.body.getBoundingClientRect().top);
  calculateRowsToLoad(currentValue);
}
</script>
<template>
  <div class="mgy-7 mgx-auto bit-slider-wrapper" v-if="isLoaded">
    <div class="col-6 w-100">
      <NotificationComponent
        v-if="notificationError"
        title="Error"
        :messageBody="translate('casino.desktop.failedLoadCasinoGames')"
        bgClass="bg-error-1"
        iconClass="bg-3-1 icon-40  mga-5 icon-warning"
        @emitDismiss="closeNotification($event)"
      ></NotificationComponent>
    </div>
    <div class="row w-100">
      <div :class="isMobile ? 'txt-center w-100' : ''" class="col-6 pdb-4">
        <h2
          class="txt-light mgl-5 txt-bold mgb-3"
          :class="isMobile ? 'txt-size-28' : 'title-xxl'"
        >
          <span v-if="props.showAllGames">{{ translate("common.all") }}</span>
          <span v-else>{{ props.category?.name }}</span>
          <span class="txt-1-1 mgl-3">{{ translate("common.games") }}</span>
        </h2>
      </div>
    </div>

    <div class="mgy-7" v-if="localLoader">
      <div class="lds-spinner mgx-auto">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <div
      v-if="!props.isCarousel && !localLoader"
      :class="props.isMobile ? 'games-grid-mobile' : 'games-grid'"
      ref="scrollableContainer"
    >
      <div
        v-for="(item, i) in gamesSplit"
        :key="i"
        class="w-100 h-100 mgr-3 flex flex-center"
      >
        <Transition name="fade" appear>
          <GameLinkComponent
            @click="selectedGame = i"
            :gameIsSelected="selectedGame == i ? true : false"
            :width="props.gameWidth"
            :height="props.gameHeight"
            :key="i"
            :game="item"
            :isMobile="isMobile"
            :buttonWidth="props.buttonWidth"
        /></Transition>
      </div>
    </div>
    <!-- CAROUSEL -->
    <TransitionGroup
      v-if="props.isCarousel"
      class="w-100 relative maxw 1184px flex flex-column hidden-overflow"
      name="slide-fade"
      tag="div"
      mode="out-in"
    >
      <div
        @touchstart.passive="carouselData.touchXValue = $event.changedTouches[0].screenX"
        @touchend="touchSlide($event, i)"
        v-for="(item, i) in allCarouselSplitedGames"
        v-show="carouselData.currentPage == i"
        :key="i"
        class="flex flex-center flex-column"
      >
        <div v-for="(splitedGames, j) in item" class="flex flex-center pdy-3" :key="j">
          <div
            v-for="(game, k) in splitedGames"
            :key="k"
            class="w-100 h-100 mgx-3 flex flex-center"
          >
            <GameLinkComponent
              @click="selectedGame = k"
              :gameIsSelected="selectedGame == k ? true : false"
              :width="props.gameWidth"
              :height="props.gameHeight"
              :game="game"
              :isMobile="isMobile"
              :buttonWidth="props.buttonWidth"
            />
          </div>
        </div>
      </div>
    </TransitionGroup>
    <SliderButtons
      @moveToPage="moveToPageFromButtons($event)"
      :transitionValue="carouselData.transitionValue"
      :carouselLength="carouselData.carouselLength"
      :currentPage="carouselData.currentPage"
      :seeMoreGames="seeMoreGamesLink"
      :isMobile="isMobile"
    />

    <div v-if="!props.isCarousel" class="grid flex-center mgt-6">
      <!--<ButtonComponent
        v-if="gamesLength > splitGamesBy"
        type="primaryText"
        :text="translate('casino.components.load')"
        :widthPx="180"
        :heightPx="40"
        @click="loadMore()"
      /> -->
      <div class="fit-content mgx-auto">
        <ButtonComponentVue
          :widthPx="150"
          v-if="gameCounter && splitGamesBy < gamesLength"
          @click="continueLoading()"
          :heightPx="40"
          text="Load more"
        />
      </div>

      <p v-if="gameCounter" class="mgy-3 txt-light">
        <template v-if="category?.name"
          >{{ translate("casino.components.displaying_top") }}
          {{ " " + category?.name + " " }}
          {{ translate("common.games") }}</template
        >
      </p>
    </div>
  </div>
</template>
<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 1s ease;
}
.slide-fade-enter-from {
  transform: v-bind("carouselData.transitionByEnter");
  opacity: 0;
}
.slide-fade-leave-to {
  transform: v-bind("carouselData.transitionByLeave");
  opacity: 0;
}
.slide-fade-enter-to,
.slide-fade-leave-from {
  transform: translateX(0);
  opacity: 1;
}
.slide-fade-leave-active {
  position: absolute;
  width: 100%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
