import { defineStore } from "pinia";

export const useWithdrawStore = defineStore({
  id: "withdraw",
  state: () => ({
    withdrawStage: 0,
    data: <any>{},
  }),
  getters: {
    getWithdrawStage: (state) => state.withdrawStage,
    getWithdrawData: (state) => state.data,
  },
  actions: {
    setWithdrawStage(stage: number) {
      this.withdrawStage = stage;
    },
    setWithdrawData(data: any) {
      this.data = data;
    },
  },
});
