<script setup lang="ts">
import { ref, onMounted } from "vue";
import LogoComponent from "@/components/icons/LogoComponent.vue";
import FooterCarouselWrapperComponent from "@/brands/holahola/components/navigation/footer/FooterCarouselWrapperComponent.vue";
import TextareaValueComponent from "@/brands/holahola/components/shared/forms/input/TextareaValueComponent.vue";
import InputValueComponent from "@/brands/holahola/components/shared/forms/input/InputValueComponent.vue";
import Captcha from "@/brands/holahola/components/captcha/CaptchaComponent.vue";
import { translate } from "@/services/translations/translation";
import axios from "axios";
import { computeFormValidation } from "@/services/validation/formValidation";
import { useLayoutModalsStore } from "@/stores/layoutModals";

const isTablet = ref(false);
const layoutModalsStore = useLayoutModalsStore();
const name = ref("");
const email = ref("");
const textArea = ref("");
const captchaCode = ref();
const captchaHash = ref();
const resetCaptchaCode = ref(false);
const subject = ref();

// VALIDATION START
const validationList = ref({
  name: { isTouched: false, valid: false },
  email: { isTouched: false, valid: false },
  username: { isTouched: false, valid: true },
  textArea: { isTouched: false, valid: false },
  subject: { isTouched: false, valid: true },
  captcha: { isTouched: false, valid: false },
});
type ValidationKey = keyof typeof validationList.value;

const formisValid = ref<boolean>(false);
const callbackValid = (pointer: ValidationKey, value: boolean) => {
  validationList.value[pointer].valid = value;
  computeFormValidation(validationList.value, formisValid);
};
// VALIDATION END

//TODO: Waiting on BE endpoint
const submit = async () => {
  // submited.value = false;
  // submitFailed.value = false;
  // loader.toggleLoader(true);
  let key: ValidationKey;
  for (key in validationList.value) {
    validationList.value[key].isTouched = true;
  }
  if (formisValid.value) {
    await axios
      .post(
        "/contact/us",
        {
          name: name.value,
          email: email.value,
          username: "No username",
          subject: subject.value,
          message: textArea.value,
          captcha: captchaCode.value,
        },
        {
          headers: {
            "Captcha-Hash": captchaHash.value,
          },
        }
      )
      .then((response) => {
        layoutModalsStore.activateModalNoClose({
          name: "pageNotification",
          data: {
            type: "success",
          },
        });
      })
      .catch((err) => {
        layoutModalsStore.activateModalNoClose({
          name: "pageNotification",
          data: {
            type: "error",
          },
        });
      });
  }
};

const platformHandler = () => {
  if (window.innerWidth > 1248) {
    isTablet.value = false;
  } else {
    isTablet.value = true;
  }
};

onMounted(() => {
  platformHandler();
  window.addEventListener("resize", platformHandler);
});
</script>

<template>
  <div class="">
    <div class="flex flex-center bg-1-4 pdy-6">
      <img
        src="https://i.ibb.co/cgDsNzV/Icon-awesome-instagram.png"
        alt=""
        class="mgx-6 cursor-pointer"
      />
      <img
        src="https://i.ibb.co/SrJBc71/Icon-awesome-facebook.png"
        alt=""
        class="mgx-6 cursor-pointer"
      />
      <img
        src="https://i.ibb.co/VJywwY3/Icon-awesome-twitter.png"
        alt=""
        class="mgx-6 cursor-pointer"
      />
    </div>
    <div class="contact-form pdy-8">
      <div class="flex flex-justify-center">
        <div class="txt-center mgx-7 pdt-10">
          <LogoComponent></LogoComponent>
          <p class="mgb-6 mgt-10">{{ translate("navigation.footer.about") }}</p>
          <p class="mgb-6">
            {{ translate("navigation.footer.gameExplanations") }}
          </p>
          <p class="mgb-6">{{ translate("navigation.footer.affiliates") }}</p>
          <p class="mgb-6">{{ translate("navigation.footer.affiliates") }}</p>
          <RouterLink to="/guide" class="no-underlined txt-light"
            ><p class="mgb-6">{{ translate("navigation.blog") }}</p></RouterLink
          >
          <RouterLink to="/support" class="no-underlined txt-light"
            ><p class="mgb-6">{{ translate("common.support") }}</p></RouterLink
          >
        </div>
        <div class="mgx-7 pdt-10">
          <p class="mgb-6">
            {{ translate("navigation.footer.casinoBonusTerms") }}
          </p>
          <p class="mgb-6">
            {{ translate("navigation.footer.sportbookBonusTerms") }}
          </p>
          <p class="mgb-6">{{ translate("navigation.footer.terms") }}</p>
          <p class="mgb-6">{{ translate("navigation.footer.security") }}</p>
          <p class="mgb-6">{{ translate("navigation.footer.payments") }}</p>
          <p class="mgb-6">{{ translate("navigation.footer.responsible") }}</p>
        </div>
        <div>
          <p class="txt-1-2 txt-size-32 txt-weight-600 mgb-6">
            {{ translate("navigation.footer.contact_form_title") }}
          </p>
          <p class="txt-size-18 txt-weight-600">
            {{ translate("navigation.footer.contact_form_subtitle") }}
          </p>
          <p class="txt-size-18 txt-weight-600">
            {{ translate("navigation.footer.contact_form_subtext") }}
          </p>

          <div class="flex mgt-7">
            <div class="w-300px">
              <InputValueComponent
                :placeholder="translate('common.name')"
                inputType="text"
                v-model="name"
                :minLength="0"
                :maxLength="255"
                textCutBorder="label-cut bg-1-1"
                textCutBorderFocused="label-cut bg-1-1 border-top-light border-width-1"
                bgColor="bg-1-1 "
                :borderClass="' radius-10 '"
                @isValid="callbackValid('name', $event)"
                v-model:isTouched="validationList.name.isTouched"
              />
            </div>
            <div class="w-300px">
              <InputValueComponent
                class="mgx-3"
                :placeholder="translate('common.email')"
                inputType="text"
                v-model="email"
                :minLength="0"
                :maxLength="255"
                textCutBorder="label-cut bg-1-1"
                textCutBorderFocused="label-cut bg-1-1 border-top-light border-width-1"
                bgColor="bg-1-1 "
                :borderClass="' radius-10 '"
                @isValid="callbackValid('email', $event)"
                v-model:isTouched="validationList.email.isTouched"
              />
            </div>
          </div>
          <div class="mgb-5 mgt-5">
            <InputValueComponent
              inputId="subject"
              :placeholder="translate('common.subject')"
              inputType="text"
              v-model="subject"
              :minLength="0"
              :maxLength="255"
              textCutBorder="label-cut bg-1-1"
              textCutBorderFocused="label-cut bg-1-1 border-top-light border-width-1"
              bgColor="bg-1-1 "
              :borderClass="' radius-10 '"
              @isValid="callbackValid('subject', $event)"
              v-model:isTouched="validationList.subject.isTouched"
            ></InputValueComponent>
          </div>
          <div>
            <TextareaValueComponent
              :lines="10"
              inputId="textarea"
              :placeholder="translate('navigation.footer.contact_form_message')"
              inputType="text"
              v-model="textArea"
              :minLength="3"
              :maxLength="255"
              textCutBorder="label-cut bg-1-1"
              textCutBorderFocused="label-cut bg-1-1 border-top-light border-width-1"
              bgColor="bg-1-1 "
              :borderClass="' radius-10 '"
              :disableValidation="true"
              @isValid="callbackValid('textArea', $event)"
              v-model:isTouched="validationList.textArea.isTouched"
            />

            <p
              class="txt-error-1"
              v-if="
                validationList.textArea.isTouched &&
                !validationList.textArea.valid
              "
            >
              {{ "Input field is required." }}
            </p>
          </div>
          <div class="flex">
            <div class="w-50">
              <Captcha
                :resetCode="resetCaptchaCode"
                @header="captchaHash = $event"
                @code="captchaCode = $event"
                @imgCodeReseted="resetCaptchaCode = false"
                @isValid="callbackValid('captcha', $event)"
                v-model:isTouched="validationList.captcha.isTouched"
              ></Captcha>
            </div>
            <div class="w-50 flex flex-right-center">
              <button
                class="w-200px h-64px border-3-1 border-width-1 radius-10 txt-3-1 txt-size-20 txt-weight-600 hover-border"
                @click="submit"
              >
                {{ translate("common.send").toUpperCase() }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-dark pdy-6">
      <div class="flex flex-column w-80 mgx-auto mgt-5">
        <FooterCarouselWrapperComponent />
        <div class="flex mgt-10">
          <img src="https://i.ibb.co/2WzbJb7/Group-796.png" alt="" />
          <div class="mgl-10">
            <p class="font-Poppins txt-size-14 txt-5-2 txt-right">
              {{ translate("navigation.footer.description") }}
            </p>
            <br />
            <p class="font-Poppins txt-size-14 txt-5-2 txt-right">
              &copy; {{ translate("navigation.footer.address") }} ®
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="css" scoped>
.contact-form {
  background: transparent linear-gradient(0deg, #23008a 0%, #000635 100%) 0% 0%
    no-repeat padding-box;
}
</style>
