import axios, { type AxiosRequestConfig } from "axios";
import {
  getBrandName,
  sendingRefreshToken,
  setingActivity,
  getAccessToken,
  logout,
  gettingActivity,
  snoozeRequest,
} from "./services/auth/httpInterceptor";

axios.defaults.baseURL = import.meta.env.VITE_API_CASINO;

// Request interceptor for API calls
axios.interceptors.request.use(
  async function (config) {
    if (!isExternalRequest(config)) {
      const accessToken = getAccessToken();
      const defautHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "C-Forwarded-Brand": getBrandName(),
      };
      config.headers = { ...config.headers, ...defautHeaders };
      if (accessToken !== null) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
axios.interceptors.response.use(
  (response) => {
    setingActivity();
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    const isRefreshTokenRequest =
      originalRequest.url.includes("api/auth/token");
    const signInActivity = gettingActivity();
    if (error.response.status === 401) {
      if (!originalRequest.retry && !isRefreshTokenRequest && signInActivity) {
        if (localStorage.getItem("refreshTokenWait") !== null) {
          await snoozeRequest();
        } else {
          originalRequest.retry = true;
          localStorage.setItem("refreshTokenWait", "true");
          await sendingRefreshToken();
          localStorage.removeItem("refreshTokenWait");
        }
        return axios(originalRequest);
      }
      logout();
    }
    return Promise.reject(error);
  }
);

const isExternalRequest = (config: AxiosRequestConfig) => {
  return (
    config.url?.includes("http") &&
    !config.url?.includes(import.meta.env.VITE_API_CASINO)
  );
};
