<script setup lang="ts">
import { goToRoute } from "@/router/routingService";

const props = defineProps({
  text: {
    type: String,
    required: false,
  },
  toUpperCase: {
    type: Boolean,
    default: false,
  },
  heightPx: {
    type: Number,
    required: false,
    default: 50,
  },
  widthPx: {
    type: Number,
    required: false,
  },
  width: {
    type: Number,
    required: false,
    default: 100,
  },
  link: {
    type: String,
    required: false,
  },
  type: {
    type: String,
    required: false,
    /* Possible: primaryFilled (is default), primaryVariantFilled, prima secondaryFilled, tertiaryFilled, primaryText, successFilled, errorFilled */
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  radius: {
    type: Number,
    required: false,
    default: 10,
  },
  txtBold: {
    type: Boolean,
    required: false,
    default: false,
  },
  txtWeight: {
    type: Number,
    required: false,
    default: 500,
  },
  txtSize: {
    type: String,
    required: false,
    default: "txt-small",
  },
  txtpdx: {
    type: Number,
    required: false,
  },
});

const goTo = () => {
  props.link ? goToRoute(props.link) : "";
};
/* height: ${props.height}px; width:${props.width}px; border-radius:${props.radius}px */
const buttonType = () => {
  switch (props.type) {
    case "primaryVariantFilled":
      return "bg-1-3 hover-bg-1-4 active-bg-1-2 txt-1-5 hover-txt-1-2 active-txt-1-4";
    case "primaryOutline":
      return "bg-transparent hover-bg-2-2 border-2-5 border-width-1 hover-bg-2-6 active-bg-2-6 txt-2-5 active-txt-1-4";
    case "secondaryFilled":
      return "bg-2-1 hover-bg-2-2 active-bg-2-3 txt-2-4 hover-txt-2-4 active-txt-2-2";
    case "tertiaryFilled":
      return "bg-3-1 hover-bg-3-2  ctive-bg-3-3 txt-3-4 hover-txt-3-3 active-txt-3-2";
    case "primaryText":
      return " hover-bg-1-3 hover-opacity-03 active-opacity-05  active-bg-1-3 txt-1-3 hover-txt-1-2 active-txt-1-2";
    case "successFilled":
      return "bg-success-1 hover-bg-success-2  active-bg-success-3 txt-success-4 hover-txt-success-3 active-txt-success-2";
    case "errorFilled":
      return "bg-error-1 hover-bg-error-2  active-bg-error-3 txt-light hover-txt-error-3 active-txt-error-2";
    case "categoryNav":
      return "bg-1-2 hover-bg-1-2  active-bg-1-3 txt-light hover-txt-1-3 active-txt-1-2 ";
    //primaryFilled is default
    default:
      return "bg-1-1 hover-bg-1-2  active-bg-1-3 txt-light hover-txt-1-3 active-txt-1-2";
  }
};
</script>
<template>
  <button
    @click="goTo()"
    :disabled="props.disabled"
    :style="
      props.widthPx
        ? `width: ${props.widthPx}px; height: ${props.heightPx}px; border-radius:${props.radius}px`
        : `width: ${props.width}%; height: ${props.heightPx}px; border-radius:${props.radius}px`
    "
    :class="buttonType()"
  >
    <span
      :class="[
        props.toUpperCase ? 'to-upper-case' : '',
        props.txtBold ? 'txt-bold' : `txt-weight-${props.txtWeight}`,
        props.txtSize ? `${props.txtSize}` : '',
        props.txtpdx ? `pdx-${props.txtpdx}` : '',
      ]"
      >{{ props.text }}</span
    >
    <slot></slot>
  </button>
</template>
