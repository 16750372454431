<script setup lang="ts">
import type { PropType } from "vue";
import { ref } from "vue";
import type { GameInterface } from "@/services/data/casino";
import type { GameDataInterface } from "@/brands/bit1111/interface/casinoInterface";
import { translate } from "@/services/translations/translation";
import ButtonComponent from "@/brands/bit1111/components/shared/buttons/ButtonComponent.vue";
import imgUrl from "@/assets/icons/img-placeholder.svg";
import { isMobileCheck } from "@/services/interface/clientInformation";
const props = defineProps({
  game: {
    required: true,
    type: Object as PropType<GameInterface>,
  },
  isMobile: {
    required: false,
    type: Boolean,
    default: false,
  },
  width: {
    required: true,
    type: Number,
  },
  height: {
    required: true,
    type: Number,
  },
  buttonWidth: {
    required: false,
    type: Number,
    default: 0,
  },
  gameIsSelected: {
    required: false,
    type: Boolean,
    default: false,
  },
});
/* 'w-175px h-140px' : 'w-284px h-197px' */
const fallbackLogo = imgUrl;
const gameLogo = (game: GameInterface) => {
  let imgSrc = isMobileCheck()
    ? game.images.mobile
      ? game.images.mobile
      : game.images.desktop
    : game.images.desktop;
  if (imgSrc == null) {
    imgSrc = fallbackLogo;
  } else {
    imgSrc.includes("http") ? null : (imgSrc = "https://" + imgSrc);
  }
  return imgSrc;
};
</script>
<template>
  <span
    class="flex flex-center game-backdrop single-game"
    :class="`w-${props.width}px h-${props.height}px`"
  >
    <img
      style="background-repeat: no-repeat"
      :class="`w-${props.width}px h-${props.height}px`"
      :src="gameLogo(props.game)"
      class="absolute radius-10"
    />
    <span v-if="!isMobile" class="zindex-overlay">
      <RouterLink
        :to="{ path: 'game', query: { gamename: props.game.uri, mode: 'fun' } }"
      >
        <ButtonComponent
          type="primaryVariantFilled"
          class="game-button mgy-2"
          :text="translate('casino.components.tryGame')"
          :widthPx="props.buttonWidth"
        />
      </RouterLink>
      <RouterLink
        :to="{ path: 'game', query: { gamename: props.game.uri, mode: 'real' } }"
      >
        <ButtonComponent
          class="game-button mgy-2"
          :text="translate('casino.components.playGame')"
          :widthPx="props.buttonWidth"
        />
      </RouterLink>
    </span>
    <span v-if="isMobile && props.gameIsSelected" class="zindex-overlay ">
      <RouterLink
        :to="{ path: 'game', query: { gamename: props.game.uri, mode: 'fun' } }"
      >
        <ButtonComponent
          type="primaryVariantFilled"
          class="game-button mgy-2"
          :text="translate('casino.components.tryGame')"
          :widthPx="props.buttonWidth"
        />
      </RouterLink>
      <RouterLink
        :to="{ path: 'game', query: { gamename: props.game.uri, mode: 'real' } }"
      >
        <ButtonComponent
          class="game-button mgy-2"
          :text="translate('casino.components.playGame')"
          :widthPx="props.buttonWidth"
        />
      </RouterLink>
    </span>
  </span>
</template>
