<script setup lang="ts">
import { computed } from "vue";
import { useBonusesStore } from "@/stores/bonuses";
import { useAuthStore } from "@/stores/auth";
import { isMobileCheck } from "@/services/interface/clientInformation";

const isMobile = isMobileCheck();
const bonusesStore = useBonusesStore();
const authStore = useAuthStore();
const bonuses = computed(() =>
  bonusesStore.$state.appliedBonuses? bonusesStore.$state.appliedBonuses: null
);
const boarding = computed(() =>
  authStore.$state.player ? authStore.$state.player.onboarding : null
);

const bonusAnswer = (bonusId: number, value: number) => {
  bonusesStore.acceptOrDeclineOrClaimLaterBonus(bonusId, value);
};
</script>

<template>
  <div class="backdrop" v-if="false">
    <div
      class="bg-4-1 border-piq radius-12"
      :class="isMobile ? 'sign-up-wrapper-mobile' : 'sign-up-wrapper'"
    >
      <div class="mgt-7" v-for="bonus in bonuses" :key="bonus.id">
        <h3 class="txt-center txt-1-1">Bonus: {{ bonus?.bonusAward.bonus.name }}</h3>
        <div class="space-between flex mgb-5">
          <p>Amount:</p>
          <p id="balance" class="txt-theme-txt-1 txt-bold">
            {{ bonus?.amount }} {{ bonus?.currencyCode }}
          </p>
        </div>
        <div v-if="bonus?.obtainableUntil" class="space-between flex mgb-5">
          <p>Obtainable Until:</p>
          <p id="balance" class="txt-theme-txt-1 txt-bold">
            {{ bonus?.obtainableUntil }}
          </p>
        </div>
        <div class="flex flex-center txt-bold">
          <button
            @click="bonusAnswer(bonus.id, 2)"
            class="radius-right-8 radius-left-8 tile-button-size txt-size-15 no-wrap txt-light bg-4-6 hover-bg-4-7 pda-3"
          >
            Claim
          </button>
          <button
            @click="bonusAnswer(bonus.id, 4)"
            class="radius-right-8 radius-left-8 tile-button-size txt-size-15 no-wrap txt-light bg-warning-1 hover-bg-warning-2 pda-3"
          >
            Decline
          </button>
          <button
            @click="bonusAnswer(bonus.id, 5)"
            class="radius-right-8 radius-left-8 tile-button-size txt-size-15 txt-bold no-wrap txt-dark bg-1-1 hover-bg-1-3 pda-3"
          >
            Claim Later
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
