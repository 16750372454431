export const validators = {
  email: {
    regex: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    message: "regexEmail",
  },
  date: {
    regex: /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/,
    message: "regexDate",
  },
  decimal:{
    regex: /^\d*\.?\d*$/,
    message: "regexDecimal"
  },
  firstName: {
    regex: /^[A-Za-z\s]+$/,
    message: "regexFirstName",
  },
  lastName: {
    regex: /^[A-Za-z\s]+$/, 
    message: "regexLastName",
  },
  username: {
    regex: /^[A-Za-z0-9_]+$/,
    message: "regexUsername"
  },
  city: {
    regex: /^[A-Za-z0-9\s\+\-\*\!]+$/,
    message: "regexCity"
  },
  address: {
    regex: /^[A-Za-z0-9\s\+\-\*\!]+$/,
    message: "regexAddress"
  },
  zipCode: {
    regex: /^[0-9a-zA-Z\-]+$/,
    message: "regexZipcode"
  },  
  phoneCode: {
    regex: /^[0-9+-]+$/,
    message: "regexZipcode"
  },  
  phone: {
    regex: /^[0-9]+$/,
    message: "regexPhone"
  }, 
  password: {
    regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/,
    message: "regexPassword"
  }, 
  
};
