export const items = [
  {
    txt: "General Info",
    children: [
      {
        txt: "About Bit1111",
        path: "/about",
      },
      {
        txt: "FAQ",
        path: "/faq",
      },
      {
        txt: "Contact Us",
        path: "/support",
      },
      {
        txt: "Payments",
        path: "/",
      },
    ],
  },
  {
    txt: "Terms & Conditions",
    children: [
      {
        txt: "Terms & Conditions",
        path: "/terms",
      },
      {
        txt: "Casino Promotions",
        path: "/promotions",
      },
      {
        txt: "Bonus Terms",
        path: "/bonus-rules",
      },
    ],
  },
  {
    txt: "Security & Privacy",
    children: [
      {
        txt: "Privacy Policy",
        path: "/security",
      },
      // {
      //   txt:"Cookie Policy",
      //   path:"/promotions"
      // },
      // {
      //   txt:"Responsible Gaming",
      //   path:"/bonus-rules"
      // }
    ],
  },
  // {
  //   txt: "Company",
  //   children: [
  //     {
  //       txt: "About",
  //       path: "/about",
  //     },
  //     // {
  //     //   txt: "Bit1111 Bonuses",
  //     //   path: "/bonus-info",
  //     // },
  //   ]
  // },
  // {
  //   txt: "Games",
  //   children: [
  //     {
  //       txt: "Casino",
  //       path: "/casino",
  //     },
  //   ]
  // },
  // {
  //   txt: "Resources",
  //   children: [
  //     {
  //       txt: "Terms & Conditions",
  //       path: "/terms",
  //     },
  //     {
  //       txt: "Privacy Policy",
  //       path: "/security",
  //     },
  //     {
  //       txt: "Bonus Terms and Conditions",
  //       path: "/bonus-rules",
  //     },
  //     {
  //       txt: "FAQ",
  //       path: "/faq",
  //     },

  //   ]
  // },
];
