<script setup lang="ts">
import { ref, onMounted } from "vue";
import { items } from "@/brands/bit1111/data/footer";
import axios from "axios";
import { translate } from "@/services/translations/translation";
import FooterCarouselWrapperComponent from "./FooterCarouselWrapperComponent.vue";

const isTablet = ref(false);

const platformHandler = () => {
  if (window.innerWidth > 1248) {
    isTablet.value = false;
  } else {
    isTablet.value = true;
  }
};

const localItems = ref<Array<any>>([]);

onMounted(async () => {
  platformHandler();
  window.addEventListener("resize", platformHandler);
  items.forEach((element) => {
    localItems.value.push(element);
  });
  let categories: Array<any> = [];
  const children: Array<any> = [];
  await axios
    .get("/dropdown/game-category")
    .then((res) => {
      categories = res.data.data;
    })
    .catch((err) => {
      //
    });
  categories.forEach((element) => {
    children.push({
      txt: element.name,
      path: `/casino?category=${element.id}`,
    });
  });
  localItems.value.push({
    txt: "Casino",
    children: children,
  });
});
</script>

<template>
  <div>
    <div class="bg-5-1 bit-footer-wrapper  flex flex-center flex-column">
      <div :class="isTablet ? 'w-85' : 'main-div'">
        <div class="containerGrid pdy-7">
          <span v-for="(item, i) in localItems" :key="i"
            ><h1 class="txt-light title-large pdb-4">
              {{ item.txt }}
            </h1>
            <RouterLink
              v-for="(child, j) in item.children"
              :key="j"
              :to="child.path"
            >
              <p class="footer-link txt-4-7 title-small pdb-4">
                {{ child.txt }}
              </p></RouterLink
            >
          </span>
        </div>
        <FooterCarouselWrapperComponent />
      </div>

    
      <div :class="isTablet ? 'w-85' : 'main-div'" class="flex space-between w-100">
        <div class="mga-5">
          <a
            target="_blank"
            href="https://anjouanoffshorefinanceauthority.org/gaming-licenses/orange-dawn-technology/"
            ><img
              class="w-70px"
              src="https://i.ibb.co/q1jKFzX/Screenshot-from-2023-08-11-10-42-16-removebg-preview.png"
              alt="Screenshot-from-2023-08-11-10-42-16-removebg-preview"
              border="0"
          /></a>
        </div>
        <div class="mga-5 flex flex-center">
          <div>
            <RouterLink to="responsible-gaming"
              ><p class="footer-link txt-4-7 title-small">
                Gaming can be addictive! Please play responsibly!
              </p></RouterLink
            >
          </div>
          <div class="play-responsibly mgx-5"><div>18+</div></div>
        </div>
      </div>
      <div class="minh-81px bg-dark w-100 flex flex-center txt-center pdt-5">
        <div :class="isTablet ? 'w-85' : 'main-div'" class="flex flex-wrap flex-center">
          <span class="flex flex-center"
            ><p class="txt-light txt-size-10 txt-500">
              {{ translate("navigation.footer.copyright") }}
            </p></span
          >
          <span class="flex flex-center txt-center maxw-500px"
            ><p class="txt-light txt-size-10 pdy-5 txt-500">
              {{ translate("navigation.footer.worldwide") }}
            </p>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="css" scoped>
.containerGrid {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". . . .";
}
</style>
