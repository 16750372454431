<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { desktopHeaderLinks } from "@/brands/holahola/data/navigation/desktopNavigation";
import { goToRoute } from "@/router/routingService";
import { useAuthStore } from "@/stores/auth";
import { useRoute } from "vue-router";
import { translate } from "@/services/translations/translation";

const props = defineProps({
  navigationFlag: Boolean,
});
const route = useRoute();
const authStore = useAuthStore();
const mobileMenu = ref(true);
const displayMenu = ref(false);

const emit = defineEmits(["toggleMenu"]);

const onMenuClick = () => {
  if (mobileMenu.value) {
    displayMenu.value = !displayMenu.value;
    window.scrollTo(0, 0);
  }
  emit("toggleMenu");
};
const goToLink = (link: string) => {
  // onMenuClick();
  goToRoute(link);
};

const menuDisplay = () => {
  if (window.innerWidth < 768) {
    mobileMenu.value = true;
    displayMenu.value = false;
  } else {
    mobileMenu.value = false;
    displayMenu.value = true;
  }
};

const isMounted = ref(false);
onMounted(() => {
  window.addEventListener("resize", menuDisplay);
  menuDisplay();
  isMounted.value = true;
});

watch(
  props,
  () => {
    displayMenu.value = props.navigationFlag;
  },
  { deep: true }
);
</script>

<template>
  <div  v-if="mobileMenu" class="menu-wrapper flex flex-center zindex-menu h-20px pda-0">
    
      <button
        type="button"
        class="menu block bg-transparent mobile-menu-toggle"
        @click="onMenuClick()"
      >
        <div
          :class="!displayMenu ? 'line  bg-light' : 'line  bg-light transformMenu'"
          v-for="line in [1, 2, 3]"
          v-bind:key="line"
        ></div>
      </button>
  </div>
  <div v-else class="menu-wrapper  zindex-menu h-20px pda-0">
    <nav class="h-20px" v-if="!mobileMenu && displayMenu">
      <ul class="pdr-10 pdl-5 flex h-20px">
        <li v-for="(link, i) in desktopHeaderLinks" class="" v-bind:key="i">
          <div
            v-if="!link.requiresAuth || (link.requiresAuth && authStore.$state.player)"
          >
            <RouterLink :to="link.link">
              <p
                :class="route.fullPath == link.link ? 'txt-3-1' : 'txt-light'"
                class="mgx-5 txt-size-18 hover-txt-3-1 txt-weight-600 font-Poppins"
                :id="`${link.group}-group`"
              >
                {{ translate(link.translation) }}
              </p>
            </RouterLink>
          </div>
          <ul></ul>
        </li>
      </ul>
    </nav>
  </div>
</template>
