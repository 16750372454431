import { useLoaderStore } from "@/stores/loader";
import { createApp, ref, provide, inject } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import { RouterSingleton } from "./router/index";
import "./axios";
import { bootstrap, init } from "./bootstrapBrand";
// import * as Sentry from "@sentry/vue";
import VueDragscroll from "vue-dragscroll";
import { realRoutes } from "@/router/defaultRoutes";


init()
bootstrap().then((response) => {
  const app = createApp(App, response.props);
  // Sentry.init({
  //   app,
  //   dsn: import.meta.env.VITE_SENTRY,
  // });
  app.use(createPinia());
  const router = RouterSingleton.getInstance(response.routes).getRouter();
  app.use(router);
  // app.use(VueDragscroll);

  // Sentry.init({
  //  /*  Vue, */
  //   dsn: "https://24dbc2980a830c7aef78181a584d47fb@o4505709450035200.ingest.sentry.io/4505709454622720",
  //   integrations: [
  //     new Sentry.BrowserTracing({
  //       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //       tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
  //       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  //     }),
  //     new Sentry.Replay(),
  //   ],
  //   // Performance Monitoring
  //   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  //   // Session Replay
  //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // });


  app.mount("#app");
});
