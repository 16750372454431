export function typeValidation(file: any): boolean {
  const ext = file.type.split("/");
  if (!ext[1].match(/jpg|jpeg|png|pdf$/i)) {
    return false;
  } else if (file.size > 1024 * 1024 * 2) {
    return false;
  } else {
    return true;
  }
}
