<script setup lang="ts">
import IconComponent from "@/components/icons/IconComponent.vue";
import { ref, computed, watch, onMounted } from "vue";
import { validateInput } from "@/services/validation/inputValidation";
const props = defineProps({
  modelValue: String,
  maxLength: Number,
  minLength: Number,
  regexValidator: String,
  disableValidation: Boolean,
  label: String,
  passId: String,
  testId: String,
  bgColor: String,
  autocomplete: Boolean,
  borderClass: String,
  responseErrors: Array,
  hideResponseErrors: Boolean,
});

const isTouched = ref(false);
const isPasswordVisible = ref(false);
const responseErrorsList = ref(props.responseErrors);
const hideErrors = ref(props.hideResponseErrors);

//DOUBLE BINDING + VALIDATION
const emit = defineEmits(["isValid", "update:modelValue", "hideErrors"]);
const validation = computed(() =>
  validateInput(
    props.modelValue ? props.modelValue : "",
    props.minLength ? props.minLength : 8,
    props.maxLength ? props.maxLength : 255,
    props.regexValidator ? props.regexValidator : ""
  )
);
const inputHandler = async (value: string | undefined) => {
  if (value) {
    await emit("update:modelValue", value);
    isTouched.value = true;
  }
  emit("isValid", validation.value.isValid);
};

const inputEvent = async (event: any) => {
  await emit("hideErrors", true);
  if (event) {
    await inputHandler(event.target.value);
  }
};

inputHandler(props.modelValue);

const propListener = () => {
  hideErrors.value = props.hideResponseErrors;

  inputHandler(props.modelValue);
  if (props.modelValue != "") {
    isTouched.value = true;
  } else {
    isTouched.value = false;
  }
  if (props.responseErrors != null) {
    responseErrorsList.value = props.responseErrors;
  }
};
watch(
  props,
  (value) => {
    propListener();
  },
  { deep: true }
);
//DOUBLE BINDING + VALIDATION
</script>

<template>
  <div class="password-input relative mgy-4">
    <label
      class="block relative"
      :class="bgColor ? bgColor : 'bg-theme-bg-1'"
      @click="isTouched = true"
    >
      <input
        :autocomplete="!autocomplete ? 'new-password' : 'on'"
        :data-testid="testId ? `input-${testId}` : null"
        :id="passId"
        :type="isPasswordVisible ? 'text' : 'password'"
        class="radius-5 w-100 pdl-2rem border-input txt-theme-txt-1 block bg-dark shared-input input-value"
        :class="borderClass ?? ''"
        :value="modelValue"
        @input="inputEvent($event)"
      />
      <span
        :class="isTouched ? 'floating-label-touched absolute' : 'floating-label absolute'"
        :data-testid="testId ? `label-${testId}` : null"
        >{{ props.label }}</span
      >
      <button
        tabindex="-1"
        type="button"
        class="input-right-btn h-100"
        @click="isPasswordVisible = !isPasswordVisible"
        :data-testid="testId ? `eye-${testId}` : null"
      >
        <IconComponent
          :iconClasses="'icon-22 inline-block align-middle bg-dark300'"
          :class="isPasswordVisible ? 'icon-pass-visible' : 'icon-pass-hidden'"
        ></IconComponent>
      </button>
    </label>
    <div
      v-if="!validation.isValid && isTouched && !disableValidation"
      class="txt-error"
      :data-testid="testId ? `validation-${testId}` : null"
    >
      {{ validation.message }}
    </div>
    <div v-if="responseErrorsList && !hideErrors">
      <div class="txt-error" v-for="(error, i) in responseErrorsList" :key="i">
        {{ error }}
      </div>
    </div>
  </div>
</template>
