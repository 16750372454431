<script setup lang="ts">
import DropDownComponent from "@/brands/bit1111/components/shared/forms/dropdown/DropDownComponent.vue";
import { ref, computed } from "vue";
import { DatePicker } from "@/services/datepicker/datepicker";
import { defaultDateFormatter } from "@/services/datepicker/actions";
import { computeFormValidation } from "@/services/validation/formValidation";

const props = defineProps({
  wrapperDivClass: {
    required: false,
    default: "flex w-100 ",
    type: String,
  },
  dropdownDivClass: {
    required: false,
    default: "w-33 mgx-2",
    type: String,
  },
  isTouched: {
    required: true, 
    default: false, 
    type: Boolean
  }
});

const emit = defineEmits(["emitDate", "isValid", "update:isTouched"]);
const datePicker = ref(new DatePicker());

const selectedMonth = computed(() => datePicker.value.getSelectedMonth());
const selectedDay = computed(() => datePicker.value.getSelectedDay());
const selectedYear = computed(() => datePicker.value.getSelectedYear());

const emitter = () => {
  if (selectedMonth.value && selectedDay.value && selectedYear.value) {
    emit(
      "emitDate",
      defaultDateFormatter(selectedDay.value, selectedMonth.value, selectedYear.value)
    );
  }
};

const selectDay = (event: any) => {
  datePicker.value.setDay(event);
  emitter();
  emit("update:isTouched", true)
};

const selectMonth = (event: any) => {
  datePicker.value.setMonth(event);
  emitter();
  emit("update:isTouched", true)
};

const selectYear = (event: any) => {
  datePicker.value.setYear(event);
  emitter();
  emit("update:isTouched", true)
};
// VALIDATION START
const validationList = ref({
  day: { valid: false },
  month: { valid: false },
  year: { valid: false },
});
type ValidationKey = keyof typeof validationList.value;

const formisValid = ref<boolean>(false);
const callbackValid = (pointer: ValidationKey, value: boolean) => {
  validationList.value[pointer].valid = value;
  computeFormValidation(validationList.value, formisValid);
  emit("isValid", formisValid.value)
};
// VALIDATION END
</script>
<template>
  <div :class="props.wrapperDivClass">
    <div :class="props.dropdownDivClass">
      <DropDownComponent
        :borderClass="'border-input radius-10'"
        :label="'DD'"
        :options="datePicker.getDays()"
        textCutBorder="label-cut-border-light"
        @emitSelected="selectDay($event)"
        @isValid="callbackValid('day', $event)"
      >
      </DropDownComponent>
    </div>
    <div :class="props.dropdownDivClass">
      <DropDownComponent
        :borderClass="'border-input radius-10'"
        :label="'MM'"
        :options="datePicker.getMonths()"
        :selector="'short'"
        textCutBorder="label-cut-border-light"
        @emitSelected="selectMonth($event)"
        @isValid="callbackValid('month', $event)"
      >
      </DropDownComponent>
    </div>
    <div :class="props.dropdownDivClass">
      <DropDownComponent
        :borderClass="'border-input radius-10'"
        :label="'YYYY'"
        :options="datePicker.getYears()"
        textCutBorder="label-cut-border-light"
        @emitSelected="selectYear($event)"
        @isValid="callbackValid('year', $event)"
      >
      </DropDownComponent>
    </div>
  </div>
</template>
