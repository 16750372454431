<script setup lang="ts">
import MainNavComponent from "@/brands/bit1111/components/navigation/header/MainNavComponent.vue";
import LogoComponent from "@/components/icons/LogoComponent.vue";
import LanguageComponent from "@/brands/bit1111/components/shared/language/LanguageComponent.vue";
import PersonalMenu from "@/brands/bit1111/components/navigation/header/PersonalMenuComponent.vue";
import PersonalMenuDesktop from "@/brands/bit1111/components/navigation/header/PersonalMenuDesktopComponent.vue";
// import AuctionsComponent from "./AuctionsComponent.vue";
import { ref, computed, onMounted, watch } from "vue";
import { useAuthStore } from "@/stores/auth";
import { goToRoute } from "@/router/routingService";
import { useRoute } from "vue-router";
import BackButton from "@/components/shared/backButton/BackButtonComponent.vue";
import FlagWrapper from "@/components/icons/FlagWrapperComponent.vue";
import { useLoaderStore } from "@/stores/loader";
import { translate } from "@/services/translations/translation";
import ButtonComponent from "@/brands/bit1111/components/shared/buttons/ButtonComponent.vue";
import HeaderNotificationComponent from "@/brands/bit1111/components/navigation/header/HeaderNotificationComponent.vue";
import { useNotificationStore } from "@/stores/notifications";
import IconComponentVue from "@/components/icons/IconComponent.vue";

const loader = useLoaderStore();
const notifStore = useNotificationStore();

const props = defineProps({
  navigationFlag: Boolean,
});

const emit = defineEmits(["toggleNav", "finishedLoading"]);
const route = useRoute();
const notifications = ref();
const notSeenNotifications = ref(notifStore.notSeen);
const languagesShow = ref(true);
const authStore = useAuthStore();
const playerRole = computed(() =>
  authStore.$state.player ? authStore.$state.player.role : ""
);

const isFocused = computed(() => {
  return route ? route.meta.isFocusedHeader : false;
});
const mobileMenu = ref(true);
const langIconSrc = ref();
const personalMenu = ref(false);
const notificationMenu = ref(false);

const menuDisplay = () => {
  if (window.innerWidth < 768) {
    mobileMenu.value = true;
  } else {
    mobileMenu.value = false;
  }
};

const toggleCallback = () => {
  emit("toggleNav");
};

const finishedLoadingEmit = () => {
  emit("finishedLoading");
};

const handleNotifications = () => {
  notificationMenu.value = !notificationMenu.value;
  notifStore.setAllNotificationsSeen();
};
watch(notifStore, () => {
  notifications.value = notifStore.notifications;
  notSeenNotifications.value = notifStore.notSeen;
});
onMounted(async () => {
  window.addEventListener("resize", menuDisplay);
  menuDisplay();
  setTimeout(finishedLoadingEmit, 5);
  notifications.value = notifStore.notifications;
  notSeenNotifications.value = notifStore.notSeen;
});
</script>

<template>
  <header
    class="mobile-header w-100 bg-dark h-64px zindex-header c-header sticky-top"
    v-if="mobileMenu && !isFocused"
  >
    <template v-if="playerRole && playerRole === 'ROLE_PLAYER_INACTIVE'">
      <LogoComponent></LogoComponent>
      <div class="flex flex-center lang-account">
        <button
          id="user"
          class="w-40px h-40px radius-circle mgr-5 bg-1-6 flex flex-center border-width-2 border-1-6"
          @click="personalMenu = !personalMenu"
        >
          <IconComponentVue
            iconClasses="svg-icon icon-22 icon-user-fill bg-1-1 block mgx-2 "
          />
        </button>
        <PersonalMenu
          v-if="personalMenu"
          @closeMenu="personalMenu = false"
        ></PersonalMenu>
      </div>
    </template>
    <template v-else>
      <MainNavComponent @toggleMenu="toggleCallback" :navigationFlag="navigationFlag" />
      <LogoComponent></LogoComponent>
      <template v-if="!authStore.$state.player">
        <div class="lang-account mgr-5">
          <LanguageComponent
            :backdrop="true"
            :personalMenuWrapper="true"
            bgTheme="bg-4-6"
            :toggleDropdown="false"
            :absolutePosition="false"
            :isMobile="mobileMenu"
            topMargin="25px"
          ></LanguageComponent>
        </div>
      </template>
      <template v-else>
        <div class="flex flex-center">
          <button
            @click="goToRoute('/deposit')"
            id="notification"
            class="w-40px h-40px flex flex-center radius-circle relative bg-1-6 mgr-3"
          >
            <IconComponentVue
              iconClasses="svg-icon icon-22 icon-deposit2 bg-1-1 block mgx-2 "
            />
          </button>

          <button
            @click="handleNotifications()"
            id="notification"
            class="w-40px h-40px flex flex-center radius-circle relative bg-1-6 border-width-2 border-1-6 mgr-3"
          >
            <IconComponentVue
              iconClasses="svg-icon icon-22 icon-notification-fill bg-1-1 block mgx-2 "
            />
            <div
              v-if="notSeenNotifications > 0"
              class="bg-error-1 radius-circle absolute top-0 right-0 msg-counter flex flex-center border-1-6 border-width-2"
            >
              <p class="txt-light w-18px h-18px flex flex-center pda-0 mga-0 txt-size-11">
                {{ notSeenNotifications }}
              </p>
            </div>
          </button>

          <button
            id="user"
            class="w-40px h-40px radius-circle mgr-5 bg-1-6 flex flex-center border-width-2 border-1-6"
            @click="personalMenu = !personalMenu"
          >
            <IconComponentVue
              iconClasses="svg-icon icon-22 icon-user-fill bg-1-1 block mgx-2 "
            />
          </button>
          <PersonalMenu
            v-if="personalMenu"
            @closeMenu="personalMenu = false"
          ></PersonalMenu>
          <HeaderNotificationComponent
            v-if="notificationMenu"
            @closeMenu="notificationMenu = false"
            :data="notifications"
          />
        </div>
      </template>
    </template>
  </header>

  <header
    class="w-100 bg-4-1 zindex-header relative c-header txt-light sticky-top"
    v-if="isFocused && mobileMenu"
  >
    <BackButton class="w-100" />
  </header>

  <header
    class="w-100 bg-dark zindex-header flex flex-center sticky-top"
    v-if="!mobileMenu"
  >
    <div class="flex flex-center h-95px w-100 maxw-1184px">
      <LogoComponent></LogoComponent>

      <MainNavComponent @toggleMenu="toggleCallback" :navigationFlag="navigationFlag" />
      <div class="w-100 maxw-360px flex flex-right-center pdr-5 relative">
        <div class="pdx-2" v-if="!authStore.$state.player">
          <ButtonComponent
            id="register"
            :text="translate('common.register').toUpperCase()"
            :widthPx="100"
            :heightPx="40"
            @click="goToRoute('register')"
          />
        </div>
        <div class="pdx-2" v-if="!authStore.$state.player">
          <ButtonComponent
            type="primaryText"
            id="login"
            :text="translate('common.login').toUpperCase()"
            :widthPx="100"
            :heightPx="40"
            @click="goToRoute('/login')"
          />
        </div>
        <div class="lang-account" v-if="!authStore.$state.player">
          <LanguageComponent
            :backdrop="true"
            :personalMenuWrapper="true"
            :toggleDropdown="true"
            v-if="languagesShow"
            bgTheme="bg-4-6"
            :isMobile="mobileMenu"
            topMargin="95px"
            bgHover="bg-3-2"
          ></LanguageComponent>
        </div>

        <div v-else class="flex flex-center">
          <div class="mgr-6">
            <ButtonComponent
              id="register"
              :text="translate('common.deposit').toUpperCase()"
              :widthPx="100"
              :heightPx="40"
              @click="goToRoute('deposit')"
            />
          </div>

          <!-- <button
            @click="goToRoute('/deposit')"
            id="notification"
            class="w-50px h-50px flex flex-center radius-circle mgr-3 relative bg-1-6 border-width-2 border-1-6 mgr-5"
          >
            <IconComponentVue
              iconClasses="svg-icon icon-22 icon-deposit2 bg-1-1 block mgx-2 "
            />
          </button> -->

          <button
            @click="handleNotifications()"
            id="notification"
            class="w-50px h-50px flex flex-center radius-circle mgr-3 relative bg-1-6 border-width-2 border-1-6 mgr-5"
          >
            <IconComponentVue
              iconClasses="svg-icon icon-24 icon-notification-fill bg-1-1 block mgx-2 "
            />

            <div
              v-if="notSeenNotifications > 0"
              class="bg-error-1 radius-circle absolute top-8 right-5 msg-counter flex flex-center border-1-6 border-width-2"
            >
              <p class="txt-light w-18px h-18px flex flex-center pda-0 mga-0 txt-size-11">
                {{ notSeenNotifications }}
              </p>
            </div>
          </button>

          <button
            id="user"
            class="w-50px h-50px radius-circle mgr-3 bg-1-6 flex flex-center border-width-2 border-1-6"
            @click="personalMenu = !personalMenu"
          >
            <IconComponentVue
              iconClasses="svg-icon icon-24 icon-user-fill bg-1-1 block mgx-2 "
            />
          </button>
          <PersonalMenuDesktop
            v-if="personalMenu"
            @closeMenu="personalMenu = false"
          ></PersonalMenuDesktop>
          <HeaderNotificationComponent
            v-if="notificationMenu"
            @closeMenu="notificationMenu = false"
            :data="notifications"
          />
        </div>
      </div>
    </div>
  </header>
</template>
