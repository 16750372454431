<script setup lang="ts">
import axios from "axios";
import { ref } from "vue";
import IconComponent from "../../components/icons/IconComponent.vue";
import { useLoaderStore } from "@/stores/loader";
import DropDownComponent from "@/components/shared/forms/dropdown/DropDownComponent.vue";
import NotificationComponent from "@/components/shared/notification/NotificationComponent.vue";
import GameListComponent from "@/components/casino/GameListComponent.vue";
import type { GameInterface, CategoryInterface } from "@/services/data/casino";
import CategoriesComponent from "@/components/casino/CategoriesComponent.vue";
import { isMobileCheck } from "@/services/interface/clientInformation";

const props = defineProps({
  testid: String,
});

const loader = useLoaderStore();
const games = ref<Array<GameInterface>>();
const categories = ref([{ id: null, name: "All" }]);
const categoriesId = ref<number | null>(null);
const inputSearch = ref("");
const gameProviderId = ref("");
const notificationError = ref(false);
const price = "$0.20 - $5,000.00";

//axios body
const axiosBody = ref({
  start: 0,
  limit: 200,
  filters: [
    {
      name: "name",
      value: inputSearch,
    },
    {
      name: "device",
      value: "1",
    },
    {
      name: "category",
      value: categoriesId,
    },
    {
      name: "supplier",
      value: gameProviderId,
    },
  ],
});

const checkDevice = () => {
  if (isMobileCheck()) {
    axiosBody.value.filters[1].value = "2";
  } else {
    axiosBody.value.filters[1].value = "1";
  }
};

const filterGames = (category: CategoryInterface) => {
  categoriesId.value = category.id;
  getGameList();
};

const getGameCategories = async () => {
  loader.toggleLoader(true);
  try {
    await axios.get("/dropdown/game-category").then(function (response) {
      const defaultArr = categories.value;
      categories.value = defaultArr.concat(response.data.data);
    });
  } catch (error) {
    console.log(error);
  }
  loader.toggleLoader(false);
};

const filterGameProviders = ($event: any) => {
  gameProviderId.value = $event.id;
  getGameList();
};

const getGameList = async () => {
  loader.toggleLoader(true);
  checkDevice();

  await axios
    .post("/games/list", axiosBody.value)
    .then(function (response) {
      games.value = response.data.data.results;
    })
    .catch((error) => {
      notificationError.value = true;
    });

  loader.toggleLoader(false);
};

const closeNotification = (event: boolean) => {
  notificationError.value = event;
};

getGameCategories();
getGameList();
</script>

<template>
  <div class="casino-wrapper row flex flex-center">
    <div class="col-6 center pda-6 block txt-center flex-center">
      <div class="inline-flex">
        <CategoriesComponent
          :categories="categories"
          @selectedCategory="filterGames($event)"
        ></CategoriesComponent>
        <div class="w-100 flex flex-center">
          <div class="w-70 inline-flex">
            <input
              @input="getGameList()"
              v-model="inputSearch"
              class="w-100 txt-theme-txt-1 mgt-4 bg-theme-bg-1 border-input radius-6 hpx-42 title-smallest pdl-4 mgt-6"
              type="text"
              placeholder="Search Games"
            />
            <div id="providers" class="txt-left">
              <DropDownComponent
                :dropdownId="'provider'"
                :label="'Provider'"
                url="/dropdown/game-supplier"
                :selector="'name'"
                @emitSelected="filterGameProviders($event)"
              ></DropDownComponent>
            </div>
          </div>
        </div>
      </div>

      <div class="mgt-6">
        <NotificationComponent
          v-if="notificationError"
          title="Error"
          messageBody="No games found, please try again"
          bgClass="bg-error"
          iconClass="bg-theme-txt-1 icon-40  mga-5 icon-warning"
          @emitDismiss="closeNotification($event)"
        ></NotificationComponent>
        <GameListComponent :gameList="games"></GameListComponent>
      </div>
    </div>
  </div>
</template>
