<script setup lang="ts">
import type { CategoryInterface } from "../../services/data/casino"
import IconComponent from "../icons/IconComponent.vue";
import { ref } from "vue";

const props = defineProps({
  categories: Array<CategoryInterface>,
    testId: String,
});

const emit = defineEmits(["selectedCategory"]);
const categoriesId = ref<number | null>(null);

const filterGames = (category: CategoryInterface) => {
    categoriesId.value = category.id;
    emit("selectedCategory", category)
}
</script>
<template>
 <div class="flex flex-center">
          <div class="w-100 h-100"
            @click="filterGames(category)"
            v-for="(category, i) in categories"
            :key="category.name"
          >
            <div class="cursor-pointer center title-smallest pdx-4 mgt-6">
              <IconComponent
              
                :iconClasses="
                  categoriesId == category.id
                    ? 'bg-theme-brand-1 icon-20 inline-block transform-ico '
                    : 'bg-theme-txt-1 icon-20 inline-block transform-ico '
                "
                :svgImage="'../src/assets/icons/ico-placeholder-ico.svg'"
              ></IconComponent>
              <p
                :data-testid="testId ? `categoryTitle-${i}-${category.id}` : null"
                :class="{ 'txt-theme-brand-1': category.id == categoriesId }"
                class="mga-0 pda-0"
              >
                {{ category.name }}
              </p>
            </div>
          </div>
        </div>
</template>
