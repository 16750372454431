<script setup lang="ts">
import CheckBoxComponent from "./CheckBoxComponent.vue";
import { ref, computed } from "vue";
import { validateMultipleCheckbox } from "@/services/validation/multipleCheckBox";
const props = defineProps({
  options: Array<any>,
  minChecked: Number,
  testId: String
});
const initial = ref(false);
const localOptions = ref();
const emit = defineEmits(["onCheckboxGroupChange", "isValid"]);
const validation = computed(() =>
  validateMultipleCheckbox(props.options, props.minChecked ? props.minChecked : 1)
);
const handleChange = (event: any, i: number) => {
  localOptions.value = props.options;
  localOptions.value[i].isChecked = event;
  emit("onCheckboxGroupChange", localOptions.value);
  emit("isValid", validation.value.isValid);
  initial.value = true;
};
emit("isValid", validation.value.isValid);
</script>
<template>
  <CheckBoxComponent
    v-for="(item, i) in options"
    :checkboxId="item.id"
    v-bind:key="i"
    :testId="item.testId ? item.testId : null"
    :isChecked="item.isChecked"
    :checkboxTitle="item.title"
    @onCheckboxChange="handleChange($event, i)"
  />
  <div
    v-if="!validation.isValid && initial"
    class="txt-error"
    :data-testid="testId ? `validation-group-${testId}` : null"
  >
    {{ validation.message }}
  </div>
</template>
