<script setup lang="ts">
// import router from "../../router";
import { RouterSingleton } from "@/router/index";
import IconComponent from "../../components/icons/IconComponent.vue";
import { goToRoute } from "@/router/routingService";
import { onMounted } from "vue";
const returnToHome = () => {
  RouterSingleton.getInstance().getRouter().push("/");
};

const title = "Error 404";
const textBold =
  "The content you are looking for does not exist or has been moved elsewhere";
const firstParagraph = `Make sure that you've correctly entered the URL of the page you are looking for. If you reached this page by an error on the website, please contact our Support so we can fix the problem.`;
const returnToHomePage = "RETURN TO HOME PAGE";

onMounted(() => {
  goToRoute("/");
});
</script>

<template>
  <div class="errorpages-wrapper row flex flex-center txt-theme-brand-1">
    <div class="col-4 col-size relative box txt-center bg-404">
      <IconComponent
        :iconClasses="'icon-24 inline-block bg-theme-brand-1 tr-icon mgb-7'"
        :svgImage="'src/assets/icons/ico-warning.svg'"
      ></IconComponent>
      <h1 id="title" class="spacing-xl txt-theme-brand-1">{{ title }}</h1>
      <p class="txt-bold txt-theme-txt-1 title-smallest">{{ textBold }}</p>
      <p class="txt-theme-txt-1 title-smallest">{{ firstParagraph }}</p>
      <button
        id="submit"
        class="btn-large txt-bold bg-theme-brand-1 txt-theme-bg-1"
        @click="returnToHome"
      >
        {{ returnToHomePage }}
      </button>
    </div>
  </div>
</template>
