<script setup lang="ts">
import LayoutViewVue from "@/views/LayoutView.vue";
import LayoutDesktopView from "@/views/LayoutDesktopView.vue";

import { useBrandStore } from "@/stores/brand";
import type { BrandData } from "@/stores/contracts/BrandData";
import { useAuthStore } from "@/stores/auth";
import { ref, onMounted, shallowRef } from "vue";
import type { PropType } from "vue";
import { useLoaderStore } from "@/stores/loader";
import LoaderView from "@/views/LoaderView.vue";
import { RouterSingleton } from "@/router/index";
import { useTranslationStore } from "@/stores/translation";
import { gettingActivity } from "@/services/auth/httpInterceptor";
import { loadDefaultTranslation } from "@/services/translations/loadDefaultTranslation";
import { loadLayout } from "@/services/structure/layoutLoader";
import { useBannerStore } from "@/stores/banners";
import { removeInitElement } from "@/bootstrapBrand";
import { useBonusesStore } from "./stores/bonuses";

interface AppProps {
  data: BrandData;
  layoutData: any;
  banners: Array<any>;
}

const desktopLayout = shallowRef();
const mobileLayout = shallowRef();
const bonusStore = useBonusesStore();
const loading = ref(false);
const translationStore = useTranslationStore();
const store = useBrandStore();
const loaderStore = useLoaderStore();
const authStore = useAuthStore();
const props = defineProps({
  props: {
    required: true,
    type: Object as PropType<AppProps>,
  },
});

const baseUrl = import.meta.env.VITE_API_CASINO;
const isMobile = ref(true);
const loadedTranslations = ref(false);
const bannersStore = useBannerStore();

const reloadPlayer = async () => {
  loaderStore.toggleLoader(true);
  const playerActivity = gettingActivity();
  const refreshToken = authStore.getRefreshToken();
  if (refreshToken && playerActivity) {
    await authStore.setPlayerData();
  }
  loaderStore.toggleLoader(false);
};

const finishedLoading = () => {
  loading.value = false;
};

const platformHandler = () => {
  if (window.innerWidth > 768) {
    if (isMobile.value) RouterSingleton.getInstance().recreateRouter();
    isMobile.value = false;
  } else {
    if (!isMobile.value) RouterSingleton.getInstance().recreateRouter();
    isMobile.value = true;
  }
};

onMounted(async () => {
  store.dataInitialization(props.props.data);
  reloadPlayer();
  document.title = store.getName;

  bonusStore.getBonusesWhileLoggedOut();
  bannersStore.setBanners(props.props.banners);

  await platformHandler();
  await window.addEventListener("resize", platformHandler);
  await translationStore.setTranslation(loadDefaultTranslation());
  const layouts = loadLayout(props.props.data.name);
  desktopLayout.value = layouts.desktop;
  mobileLayout.value = layouts.mobile;
  document.body.classList.add(store.getTemplateClass);
  loadedTranslations.value = true;
  removeInitElement();
});
</script>

<template>
  <!-- <LoaderView v-show="loading" /> -->

  <Transition>
    <component
      :is="mobileLayout"
      v-if="isMobile"
      @finishedLoading="finishedLoading"
    ></component>
    <component :is="desktopLayout" v-else @finishedLoading="finishedLoading"></component>
  </Transition>
</template>

<style lang="scss">
@import "@/assets/scss/main.scss";
.v-enter-active {
  transition: opacity 1s ease;
}
.v-enter-from {
  opacity: 0;
}
</style>
