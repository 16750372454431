<script setup lang="ts">
import InputValueComponent from "@/brands/holahola/components/shared/forms/input/InputValueComponent.vue";
import InputPasswordComponent from "@/brands/holahola/components/shared/forms/input/InputPasswordComponent.vue";
import { useAuthStore } from "@/stores/auth";
import { ref } from "vue";
import { useLoaderStore } from "@/stores/loader";
import SocialsTwoComponent from "@/brands/bit1111/components/auth/SocialsTwoComponent.vue";
import { useLayoutModalsStore } from "@/stores/layoutModals";
import { goToRoute } from "@/router/routingService";
import IconComponent from "@/components/icons/IconComponent.vue";
import { translate } from "@/services/translations/translation";
import { computeFormValidation } from "@/services/validation/formValidation";

const login = ref({
  username: "",
  password: "",
});

const layoutModalsStore = useLayoutModalsStore();
const loader = useLoaderStore();
const authStore = useAuthStore();
const responseErr = ref();
const hideResponseErrors = ref(false);

const close = () => {
  layoutModalsStore.closeModal("login");
};
const submit = async () => {
  loader.toggleLoader(true);
  let key: ValidationKey;
  for (key in validationList.value) {
    validationList.value[key].isTouched = true;
  }
  if (formisValid.value) {
  responseErr.value = await authStore.login(
    login.value.username.toLowerCase(),
    login.value.password
  );
  !responseErr.value ? close() : null;
  }
  loader.toggleLoader(false);
};

const reRoute = (url: string) => {
  goToRoute(url);
  close();
};

// VALIDATION START
const validationList = ref({
  username: { isTouched: false, valid: false },
  password: { isTouched: false, valid: false },
});
type ValidationKey = keyof typeof validationList.value;

const formisValid = ref<boolean>(false);
const callbackValid = (pointer: ValidationKey, value: boolean) => {
  validationList.value[pointer].valid = value;
  responseErr.value = false;
  computeFormValidation(validationList.value, formisValid);
};
// VALIDATION END
</script>
<template>
  <div>
    <div class="backdrop" @click="close"></div>
    <div class="modal-Centered bg-linear-1-1-1-5 zindex-modal radius-15 pdy-3 pdx-15">
      <div type="" class="flex flex-right mgb-10 pdy-5 mgt-7">
        <div
          class="fit-content cursor-pointer flex-center"
          style="
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            margin-top: -10px;
            left: 0;
            right: 0;
          "
        >
          <h1 class="txt-30 font-Aldrich txt-2-1 txt-center">{{ translate('auth.login.desktop.header') }}</h1>
        </div>

        <IconComponent
          :iconClasses="'svg-icon block icon-16  icon-form-clear bg-4-1 cursor-pointer '"
          @click="close"
        ></IconComponent>
      </div>

      <div class="mgt-5 w-520px">
        <InputValueComponent
          :hideResponseErrors="hideResponseErrors"
          @hideErrors="hideResponseErrors = $event"
          :inputType="'text'"
          v-model="login.username"
          :placeholder="translate('auth.login.desktop.username')"
          :minLength="0"
          :maxLength="255"
          textCutBorder="label-cut bg-1-3 border-top-1-2 border-width-1"
          textCutBorderFocused="label-cut bg-1-3 border-top-1-2 border-width-1"
          bgColor="bg-1-3 radius-26"
          :borderClass="' radius-26 border-1-2 border-width-1'"
          v-model:isTouched="validationList.username.isTouched"
          @isValid="callbackValid('username', $event)"
        ></InputValueComponent>
      </div>
      <div class="mgt-6">
        <InputPasswordComponent
          :hideResponseErrors="hideResponseErrors"
          @hideErrors="hideResponseErrors = $event"
          :autocomplete="true"
          :minLength="1"
          :label="translate('auth.login.desktop.password')"
          v-model="login.password"
          textCutBorder="label-cut bg-1-3 border-top-1-2 border-width-1"
          textCutBorderFocused="label-cut bg-1-3 border-top-1-2 border-width-1"
          bgColor="bg-1-3 radius-26"
          :borderClass="' radius-26 border-1-2 border-width-1'"
          :isTouched="validationList.password.isTouched"
          @isValid="callbackValid('password', $event)"
        ></InputPasswordComponent>
      </div>
      <p
        v-if="responseErr"
        class="txt-bold txt-error-1 cursor-pointer text-smallest mgy-5"
      >
        {{ responseErr }}
      </p>

      <p
        class="txt-3-1 cursor-pointer txt-size-16 txt-center mgy-5"
        @click="reRoute('/email-password-reset')"
      >
        {{ translate('auth.login.desktop.forgot_password') }}
      </p>
      <button
        class="mgx-auto bg-2-1 radius-10 w-150px h-50px font-Poppins txt-size-18 txt-weight-600 txt-light hover-border"
        @click="submit"
      >
      {{ translate('auth.login.desktop.submit') }}
      </button>
      <p class="txt-center txt-size-14 font-Poppins txt-light mgt-6">
        {{ translate('auth.login.desktop.not_registered') }}
      </p>
      <p class="txt-center txt-size-14 font-Poppins txt-light mgb-7">
        <span class="cursor-pointer txt-2-1 underlined" @click="reRoute('/register')"
          >{{ translate('auth.login.desktop.create_free_account') }}
        </span>
        {{ translate('auth.login.desktop.start_winning') }}
      </p>
      <div class="bg-1-2 h-1px w-440px mgx-auto"></div>
      <div class="flex fit-content mgx-auto mgy-6">
        <p class="font-Poppins txt-size-18 txt-5-2">{{ translate('auth.login.components.socialsLogin') }}</p>
        <img
          class="block cursor-pointer mgl-7"
          src="https://i.ibb.co/5RTkMw8/Icon-awesome-twitter.png"
          alt=""
        />
        <img
          class="block cursor-pointer mgl-5"
          src="https://i.ibb.co/m88PW9b/Icon-awesome-google.png"
          alt=""
        />
      </div>
      <!-- <SocialsTwoComponent /> -->
    </div>
  </div>
</template>
