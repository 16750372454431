export const bannersFallback = [
  {
    data: {
      properties:
        "display: flex; flex-direction: column;align-items: left; padding-top: 50px; ",
      elements: [
      
      ],
      imageBannerUrl: "https://i.ibb.co/8xvvt8n/hero-widget-casino.jpg",
    },
    tag: "casino",
  },
  {
    data: {
      properties:
        "display: flex; flex-direction: column;align-items: left; padding-top: 50px; ",
      elements: [],
      imageBannerUrl: "https://i.ibb.co/C8Wj06V/main-sports-banner.png",
    },
    tag: "home",
  },
  {
    data: {
      properties:
        "display: flex; flex-direction: column;align-items: left; padding-top: 50px; ",
      elements: [],
      imageBannerUrl: "https://i.ibb.co/8xvvt8n/hero-widget-casino.jpg",
    },
    tag: "home",
  },
  {
    data: {
      properties:
        "display: flex; flex-direction: column;align-items: left; padding-top: 50px; ",
      elements: [],
      imageBannerUrl: "https://i.ibb.co/xq95vCg/sports-mid-banner-promo.png",
    },
    tag: "home",
  },
  {
    data: {
      properties:
        "display: flex; flex-direction: column;align-items: left; padding-top: 50px; ",
      elements: [],
      imageBannerUrl: "https://i.ibb.co/3hYF0CB/hero-widget-crypto.jpg",
    },
    tag: "home",
  },
  {
    data: {
      properties:
        "display: flex; flex-direction: column;align-items: left; padding-top: 50px; ",
      elements: [],
      imageBannerUrl: "https://i.ibb.co/xq95vCg/sports-mid-banner-promo.png",
    },
    tag: "home2",
  },
 
  {
    data: {
      properties:
        "display: flex; flex-direction: column;align-items: center; padding-top: 50px; ",
      imageBannerUrl: "https://i.ibb.co/3hYF0CB/hero-widget-crypto.jpg",
      elements: [],
    },
    tag: "casino",
  },
  {
    data: {
      properties:
        "display: flex; flex-direction: column;align-items: left; padding-top: 50px; ",
      elements: [],
      imageBannerUrl: "https://i.ibb.co/8xvvt8n/hero-widget-casino.jpg",
    },
    tag: "mobile-casino",
  },
  {
    data: {
      properties:
        "display: flex; flex-direction: column;align-items: center; padding-top: 50px; ",
      imageBannerUrl: "https://i.ibb.co/3hYF0CB/hero-widget-crypto.jpg",
      elements: [],
    },
    tag: "mobile-casino",
  },
  {
    data: {
      imageBannerUrl: "https://i.ibb.co/YNq3s9F/bit1111-promotion-banner.jpg",
      properties:
        "display: flex; flex-direction: column;align-items: center; padding-top: 50px; ",
      elements: [],
    },
    tag: "promotions",
  },
  {
    data: {
      imageBannerUrl: "https://i.ibb.co/YNq3s9F/bit1111-promotion-banner.jpg",
      properties:
        "display: flex; flex-direction: column;align-items: center; padding-top: 50px; ",
      elements: [],
    },
    tag: "mobile-promotions",
  },
];
