<script setup lang="ts">
import { useBrandStore } from "@/stores/brand";
import { useRoute } from "vue-router";
import { ref, watch, computed, onMounted, markRaw } from "vue";
import { useAuthStore } from "@/stores/auth";
import LoaderVue from "../components/shared/loader/LoaderComponent.vue";
import { useLoaderStore } from "../stores/loader";
import BoardingWrapperComponent from "../components/boarding/BoardingWrapperComponent.vue";
import eventRegister from "@/lib/mercury/eventRegister";
import BonusNotification from "../components/notification/BonusNotificationComponent.vue";
// import { useBonusNotificationStore } from "../stores/bonusNotification";
import type { PropType } from "vue";
import type { LayoutPropsInterface } from "@/services/interface/structure";
import BonusModal from "@/components/bonus/BonusModal.vue";

import Header from "@/brands/holahola/components/navigation/header/HeaderComponent.vue";
import BottomNav from "@/brands/holahola/components/navigation/BottomNavigationComponent.vue";
import Footer from "@/components/footer/FooterComponent.vue";
import Boarding from "@/brands/holahola/components/boarding/HolaBoardingWrapperComponent.vue";
import MobileNav from "@/components/navigation/header/MobileNavigation.vue";

const emit = defineEmits(["finishedLoading"]);

onMounted(async () => {
  if (player !== null && player.role == "ROLE_PLAYER") {
    eventRegister.authorizationHook();
  }
  eventRegister.nonAuthorizationHook();
});

const loader = useLoaderStore();
const brandStore = useBrandStore();
const authStore = useAuthStore();
const route = useRoute();
const routeAuth = ref(false);
const boarding = computed(() => authStore.getBoardingFlag);
const player = authStore.$state.player;
// const bonusNotificationStore = useBonusNotificationStore();
const navigationToggle = ref(false);
// const notifFlag = computed(() => {
//   return bonusNotificationStore.getFlag();
// });

const finishedLoading = () => {
  emit("finishedLoading");
};

watch(
  route,
  () => {
    routeAuth.value = ["login", "register"].includes(
      typeof route.name == "string" ? route.name : ""
    );
    window.scrollTo(0, 0);
  },
  { deep: true }
);
</script>

<template>
  <div id="layout-body" class="main">
    <!-- <BoardingWrapperComponent v-if="!boarding" /> -->
    <LoaderVue v-if="loader.getIsLoading"></LoaderVue>
    <!-- <BonusNotification v-if="notifFlag" :amount="bonusNotificationStore.getAmount()" /> -->
    <BonusModal />
    <Header
      @toggleNav="navigationToggle = !navigationToggle"
      :navigationFlag="navigationToggle"
      @finishedLoading="finishedLoading"
    />
    <Boarding v-if="!boarding" />
    <div class="c-body">
      <MobileNav
        v-show="navigationToggle"
        @toggleMenu="navigationToggle = !navigationToggle"
      />
      <RouterView v-show="!navigationToggle" />
    </div>
    <BottomNav />
    <Footer />
  </div>
</template>
