<script setup>
import { ref } from "vue";
import { axiosGet } from "../../services/axios/crudOperations";
import ClientPaginationTableCompoment from "../../components/shared/table/ClientPaginationTableComponent.vue";

const tableHeaders = ref(["amount", "currency", "date", "time", "type"]);

const tableData = ref([]);

const init = async () => {
  const res = await axiosGet("/history/bonuses");
  const tempData = res[0];
  tempData.forEach((element) => {
    let tempDate = element.date.date;
    element.date = tempDate.slice(0, -16);
    element.time = tempDate.slice(10, -7);
  });
  tableData.value = tempData;
};

init();
</script>

<template>
  <div class="form-size pdb-10">
    <h1 class="txt-center txt-theme-txt-1 mgb-5 mgt-2">Bonus History</h1>
    <ClientPaginationTableCompoment :tableData="tableData" :headers="tableHeaders" />
  </div>
</template>
