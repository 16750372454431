<script setup lang="ts">
import { desktopHeaderLinks } from "@/services/data/navList";
import IconComponent from "@/components/icons/IconComponent.vue";
import { goToRoute } from "@/router/routingService";
import DividerComponent from "@/components/shared/divider/DividerComponent.vue";
import { translate } from "@/services/translations/translation";
const emit = defineEmits(["toggleMenu"]);

const goToLink = (link: string) => {
  emit("toggleMenu");
  goToRoute(link);
};
</script>

<template>
  <div class="header-desktop-menu bg-theme-bg-4 pdt-5 pdx-5 pdb-20">
    <ul class="links pdt-5">
      <li v-for="(link, i) in desktopHeaderLinks" class="link-group" v-bind:key="i">
        <a
          href="#"
          :class="{ 'first-group-title': i === 0 }"
          class="block txt-theme-txt-1 txt-bold mgy-7"
          :id="`${link.group}-group`"
        >
          <IconComponent
            :iconClasses="`icon-20 icon-inline align-bottom bg-theme-txt-1 icon-${link.icon}`"
          ></IconComponent>
          {{ translate(link.translation) }}</a
        >
        <ul>
          <li v-for="(singleLink, j) in link.linkGroup" v-bind:key="j" class="link mgy-4">
            <a
              class="flex w-100 txt-theme-txt-2 mgl-5"
              @click="goToLink(singleLink.link)"
              :id="`${singleLink.icon}`"
            >
              <!-- <IconComponent
                :iconClasses="`icon-20 icon-inline align-bottom bg-theme-txt-2 icon-${singleLink.icon}`"
              ></IconComponent> -->
              {{ translate(singleLink.translation) }}</a
            >
          </li>
        </ul>
      </li>
    </ul>
    <DividerComponent
      :dividerClass="'w-95 mgx-auto mgt-6 bg-theme700'"
    ></DividerComponent>

    <DividerComponent
      :dividerClass="'w-95 mgx-auto mgt-6 bg-theme700'"
    ></DividerComponent>

    <ul class="links pdt-5">
      <li class="txt-theme700">Promociones</li>
      <li class="txt-theme700">Contáctanos</li>
      <li class="txt-theme700">Soporte</li>
      <li class="txt-theme700">App</li>
    </ul>
  </div>
</template>
