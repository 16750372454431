export function formatDate(date: Date) {
  let formatted = "";
  if (date.getDate() < 10) {
    formatted += "0" + date.getDate().toString();
  } else {
    formatted += date.getDate().toString();
  }
  formatted += ".";
  if (date.getMonth() < 9) {
    formatted += "0" + (date.getMonth() + 1).toString();
  } else {
    formatted += (date.getMonth() + 1).toString();
  }
  formatted += ".";
  formatted += date.getFullYear().toString();
  formatted += ".";
  return formatted;
}

export function formatTime(date: Date) {
  let formatted = "";
  
  if (date.getHours() == 0) {
    formatted += "00";
  } else {
    if (date.getHours() < 10) {
      formatted += "0" + date.getHours().toString();
    } else {
      formatted += date.getHours().toString();
    }
  }
  formatted += ":";
  if (date.getMinutes() == 0) {
    formatted += "00";
  } else {
    if (date.getMinutes() < 10) {
      formatted += "0" + date.getMinutes().toString();
    } else {
      formatted += date.getMinutes().toString();
    }
  }
  formatted += ":";
  if (date.getSeconds() == 0) {
    formatted += "00";
  } else {
    if (date.getSeconds() < 10) {
      formatted += "0" + date.getSeconds().toString();
    } else {
      formatted += date.getSeconds().toString();
    }
  }
  formatted += " UTC";
  const offset = date.getTimezoneOffset();

  if (offset != 0) {
    if (offset > 0) {
      formatted = formatted + "-" + (-offset / 60).toString();
    } else {
      formatted += "+" + (-offset / 60).toString();
    }
  }
  return formatted;
}

export function milisToTime(start:Date, end:Date){
  const secondsTime =
  Math.abs(start.getTime() - end.getTime()) / 1000;
const hours = Math.floor(secondsTime / 3600);
const minutes = Math.floor((secondsTime - 3600 * hours) / 60);
const seconds = Math.floor(secondsTime - 3600 * hours - 60 * minutes);
  return "" + hours + "h " + minutes + "m " + seconds + "s";
}