<script setup lang="ts">
import { languageCodes } from "@/services/data/languages";
import { ref, watch, computed } from "vue";
import InputValueComponent from "@/brands/holahola/components/shared/forms/input/InputValueComponent.vue";
import { validatePhoneInput } from "@/services/validation/inputValidation";
import AllFlagsComponent from "@/components/icons/flags/AllFlagsComponent.vue";
import { translate } from "@/services/translations/translation";
import FormWarningComponent from "@/components/shared/forms/FormWarningComponent.vue";

const props = defineProps({
  selectedCode: String,
  selectedPhoneNumber: Number,
  disableValidation: Boolean,
  responseErrors: Array,
  codeMaxLength: Number,
  codeMinLength: Number,
  phoneMaxLength: Number,
  phoneMinLength: Number,
  codeRegexValidator: String,
  phoneRegexValidator: String,
  borderClass: String,
  labelClass: String,
  isTouched: Boolean,
});

const responseErrorsList = ref(props.responseErrors);
const phoneCode = ref();
const phoneNumber = ref();
const flag = ref();
const codeExists = ref();

const matchFlagAndCode = (value: string) => {
  codeExists.value = false;
  for (let i = 0; languageCodes.length > i; i++) {
    if (languageCodes[i].countryCode == value) {
      flag.value = languageCodes[i].code;
      codeExists.value = true;
      break;
    }
  }
  if (!codeExists.value) flag.value = value;
};

if (props.selectedPhoneNumber) {
  phoneNumber.value = props.selectedPhoneNumber;
}

if (props.selectedCode) {
  phoneCode.value = props.selectedCode;
  codeExists.value = true;
  matchFlagAndCode(phoneCode.value);
}

const validation = computed(() =>
  validatePhoneInput(
    props.selectedCode ? props.selectedCode : "",
    props.selectedPhoneNumber ? props.selectedPhoneNumber : 0,
    props.codeMinLength ? props.codeMinLength : 0,
    props.codeMaxLength ? props.codeMaxLength : 255,
    props.phoneMinLength ? props.phoneMinLength : 0,
    props.phoneMaxLength ? props.phoneMaxLength : 255,
    props.codeRegexValidator ? props.codeRegexValidator : "",
    props.phoneRegexValidator ? props.phoneRegexValidator : ""
  )
);

const emit = defineEmits(["changePhoneNumber", "changePhoneCode", "isValid", "update:isTouched"]);

watch(phoneCode, (value: string) => {
  matchFlagAndCode(value);
  emit("changePhoneCode", value);
 emit("update:isTouched", true)
});

watch(phoneNumber, (value: number) => {
  emit("changePhoneNumber", value);
 emit("update:isTouched", true)
});
emit("isValid", false);

watch([validation, codeExists, phoneCode, phoneNumber], ([valueA]) => {
  checkCodeAndPhone();
});

const checkCodeAndPhone = () => {
  if (codeExists.value && props.selectedPhoneNumber) {
    emit("isValid", validation.value?.isValid);
  } else {
    emit("isValid", false);
  }
};
checkCodeAndPhone();
watch(
  props,
  () => {
    if (props.responseErrors != null) {
      responseErrorsList.value = props.responseErrors;
    }
  },
  { deep: true }
);
</script>

<template>
  <div>
    <div class="relative flex align-items-center space-between radius-5 pdy-1 pdr-1">
      <div class="w-45 hidden-overflow flex border-input radius-10 bg-1-3">
        <div class="flex flex-align-center">
          <AllFlagsComponent :flagCode="flag" />
        </div>
        <InputValueComponent
          :disableTopMargin="true"
          inputId="code"
          :disableValidation="true"
          :placeholder="translate('common.code')"
          :isInlineInput="true"
          v-model="phoneCode"
          :isTouched="isTouched"
          :borderClass="'bg-1-3'"
        ></InputValueComponent>
      </div>
      <div class="w-50">
        <InputValueComponent
          inputType="number"
          :borderClass="'border-input radius-10 bg-1-3'"
          :disableTopMargin="true"
          inputId="phone"
          :disableValidation="true"
          :placeholder="translate('common.phoneNumber')"
          :textCutBorder="labelClass"
          :isInlineInput="true"
          v-model="phoneNumber"
          :isTouched="isTouched"
        ></InputValueComponent>
      </div>
    </div>
    <div class="pdl-4">
      <div v-if="responseErrorsList">
        <div class="txt-error-1 my-1" v-for="(error, i) in responseErrorsList" :key="i">
          {{ error }}
        </div>
      </div>
      <div
        v-if="!validation?.isValid && isTouched && !disableValidation"
        class="txt-error-1"
      >
        {{ validation?.message }}
      </div>
      <div v-if="!codeExists && isTouched">
        <div class="txt-error-1 mt-1">
          {{ translate("auth.register_init.desktop.no_phonecode") }}
        </div>
      </div>
      <FormWarningComponent
        v-if="!validation?.isValid && isTouched"
        :text="translate('globalNotificationMessages.required_input')"
      />
    </div>
  </div>
</template>
