<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import type { BonusInterface } from "@/services/interface/bonuses";
import type { PropType } from "vue";
import { translate } from "@/services/translations/translation";
import BannerElement from "@/brands/holahola/components/promotions/BannerElement.vue";
const props = defineProps({
  bonus: {
    required: true,
    type: Object as PropType<BonusInterface>,
  },
  imageWidth: String,
  imageHeight: String,
  isMobile: Boolean,
});


const emit = defineEmits(["reloadBonuses", "readMore"]);
const defaultImage = ref(
  "https://bshots.egcvi.com/thumbnail/hippo2_imr_med_M.jpg"
);
const translateStatus = ref();
const translateStatusColor = ref();


const getProperStatusText = () => {
  switch (props.bonus.status) {
    case 2:
      translateStatus.value = "accepted";
      translateStatusColor.value = "txt-success-1";
      break;
    case 3:
      translateStatus.value = "applied";
      translateStatusColor.value = "txt-success-1";
      break;
    case 4:
      translateStatus.value = "expired";
      translateStatusColor.value = "txt-error-1";
      break;
    case 5:
      translateStatus.value = "declined";
      translateStatusColor.value = "txt-error-1";
      break;
  }
};
const readMore =() => {
  emit("readMore", props.bonus)
}
onMounted(() => {
  getProperStatusText();
});
watch(props, () => {
  getProperStatusText();
});
</script>

<template>
  <div>


    <div class="relative mga-5">
      <template v-if="isMobile">
        <div class="flex flex-wrap flex-justify-center">
          <div class="relative mga-3 w-100">
            <div class="flex flex-column">
              <div v-if="props.bonus.bannerMobile" class=" radius-top-10">
                <BannerElement
                  :banner="props.bonus.bannerMobile"
                  :index="$props.bonus.id"
                />
              </div>
              <div
                v-if="props.bonus.status != 1"
                class="absolute w-100 bonus-status txt-center txt-size-30 radius-top-10"
                :class="translateStatusColor"
              >
                {{
                  translate(
                    `promotions.desktop.${translateStatus}`
                  ).toUpperCase()
                }}
              </div>
              <img
                class="h-260px w-360px radius-top-10"
                v-if="!props.bonus.bannerMobile"
                :src="
                  props.bonus.bonusAward.bonus.image
                    ? props.bonus.bonusAward.bonus.image
                    : defaultImage
                "
              />
              <div class="promo-details pdy-7">
                <p
                  v-if="props.bonus.bonusAward?.bonus.name"
                  class="pdb-3 txt-center to-upper-case txt-size-18 txt-weight-600 font-Poppins"
                >
                  {{ props.bonus.bonusAward?.bonus.name }}
                </p>
                <button
                @click="readMore()"
                  class="mgt-5 mgx-auto txt-center w-180px h-50px border-light border-width-1 radius-10 txt-light txt-size-16 font-Poppins txt-weight-600"
                >
                  {{ translate("common.readMore") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          v-if="props.bonus.status != 1"
          class="absolute w-100 bonus-status txt-center txt-size-30"
          :class="translateStatusColor"
        >
          {{ translate(`promotions.desktop.${translateStatus}`).toUpperCase() }}
        </div>
        <div class="flex flex-wrap flex-justify-center mgt-7 mgx-auto">
          <div class="relative w-645px">
            <div>
              <div
                v-if="props.bonus.banner"
                class="block w-100 h-320px radius-top-10"
              >
                <BannerElement
                  :banner="props.bonus.banner"
                  :index="$props.bonus.id"
                />
              </div>
              <img
                v-if="!props.bonus.banner"
                :src="
                  props.bonus.bonusAward.bonus.image
                    ? props.bonus.bonusAward.bonus.image
                    : defaultImage
                "
                class="block w-100 h-320px radius-top-10"
              />
              <div class="promo-details pdy-7">
                <p
                  v-if="props.bonus.bonusAward?.bonus.name"
                  class="pdb-3 txt-center to-upper-case txt-size-18 txt-weight-600 font-Poppins"
                >
                  {{ props.bonus.bonusAward?.bonus.name }}
                </p>

                <button
                @click="readMore()"
                  class="mgt-5 mgx-auto txt-center w-180px h-50px border-light border-width-1 radius-10 txt-light txt-size-16 font-Poppins txt-weight-600"
                >
                  {{ translate("common.readMore") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<style lang="css" scoped>
.bonus-status {
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
}
</style>
