<script setup lang="ts">
import { headerLinks } from "@/brands/bit1111/data/navigation/mobileNavigation";
import IconComponent from "@/components/icons/IconComponent.vue";
import { goToRoute } from "@/router/routingService";
import { ref, onMounted, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { translate } from "@/services/translations/translation";

const emit = defineEmits(["toggleMenu"]);
const route = useRoute();
const localHeaders = ref(JSON.parse(JSON.stringify(headerLinks)));

const goToLink = (link: string) => {
  emit("toggleMenu");
  goToRoute(link);
};
const closeAll = () => {
  localHeaders.value.forEach((group: any) => {
    group.open = false;
    group.linkGroup.forEach((link: any, j: number) => {
      if (link.linkGroup.length > 0) {
        link.linkGroup.forEach((child: any, x: number) => {
          child.open = false;
          link.open = false;
          group.open = false;
        });
      } else {
        link.open = false;
        group.open = false;
      }
    });
  });
};
const init = () => {
  closeAll();
  localHeaders.value.forEach((group: any, i: number) => {
    if (group.linkGroup.length == 0) {
      if (group.link == route?.path) {
        group.open = true;
      }
    } else {
      group.linkGroup.forEach((link: any, j: number) => {
        if (link.linkGroup.length > 0) {
          link.linkGroup.forEach((child: any, x: number) => {
            if (child.link == route?.path) {
              child.open = true;
              link.open = true;
              group.open = true;
            }
          });
        } else {
          if (link.link == route?.path) {
            link.open = true;
            group.open = true;
          }
        }
      });
    }
  });
};

onMounted(() => {
  init();
});

watch(route, (value) => {
  init();
});

const openGroup = (i: number, j?: number) => {
  if (j) {
    const tempChild = !localHeaders.value[i].linkGroup[j].open;
    closeAll();
    localHeaders.value[i].open = true;
    localHeaders.value[i].linkGroup[j].open = tempChild;
  } else {
    if (localHeaders.value[i].linkGroup.length == 0) {
      goToLink(localHeaders.value[i].link);
    }
    const tempParent = !localHeaders.value[i].open;
    closeAll();
    localHeaders.value[i].open = tempParent;
  }
};
</script>

<template>
  <div class="header-mobile-menu bg-4-1">
    <ul class="links pdt-5 pdl-8">
      <li v-for="(link, i) in localHeaders" class="link-group" v-bind:key="i">
        <a
          class="block group-title-link mgy-9 txt-weight-600"
          :class="link.open ? ' txt-1-3' : ' txt-1-1'"
          :id="`${link.group}-group`"
          @click="openGroup(i)"
        >
          <IconComponent
            :iconClasses="`icon-20 icon-inline align-bottom icon-${link.icon} ${
              link.open ? ' bg-1-3 ' : ' bg-1-1 '
            }`"
          ></IconComponent>
          {{ translate(link.translation).toUpperCase() }}
          <!-- <IconComponent
            v-if="link.linkGroup.length > 0"
            :iconClasses="`icon-16 icon-inline mgb-2 icon-dropdown slow ${
              link.open ? ' bg-1-3 rotate-up' : ' bg-1-1 '
            }`"
          ></IconComponent> -->
        </a>
        <ul v-if="link.open" class="text-small">
          <li
            v-for="(singleLink, j) in link.linkGroup"
            v-bind:key="j"
            class="link mgb-5 mgl-7"
          >
            <template v-if="singleLink.linkGroup.length > 0">
              <a
                class="block group-title-link mgb-6"
                :class="singleLink.open ? ' txt-1-3 ' : ' txt-1-1 '"
                :id="`${singleLink.linkText}-group`"
                @click="openGroup(i, j)"
              >
                {{ translate(singleLink.translation) }}</a
              >

              <ul v-if="singleLink.open">
                <li
                  v-for="(childLink, x) in singleLink.linkGroup"
                  v-bind:key="x"
                  class="link mgy-4 mgl-7"
                >
                  <a
                    class="flex w-100"
                    :class="childLink.open ? 'txt-1-3' : 'txt-1-1'"
                    @click="goToLink(childLink.link)"
                    :id="`${childLink.icon}`"
                  >
                    {{ translate(childLink.translation) }}</a
                  >
                </li>
              </ul>
            </template>
            <template v-else>
              <a
                class="flex w-100"
                @click="goToLink(singleLink.link)"
                :class="singleLink.open ? 'txt-1-3' : 'txt-1-1'"
                :id="`${singleLink.icon}`"
              >
                {{ translate(singleLink.translation) }}</a
              >
            </template>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
