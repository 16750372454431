<script setup lang="ts">
import { languageCodes } from "@/services/data/languages";
import { ref, watch, computed } from "vue";
import InputValueComponent from "./InputValueComponent.vue";
import { validatePhoneInput } from "@/services/validation/inputValidation";
import AllFlagsComponent from "@/components/icons/flags/AllFlagsComponent.vue";

const props = defineProps({
  selectedCode: String,
  selectedPhoneNumber: Number,
  disableValidation: Boolean,
  responseErrors: Array,
  codeMaxLength: Number,
  codeMinLength: Number,
  phoneMaxLength: Number,
  phoneMinLength: Number,
  codeRegexValidator: String,
  phoneRegexValidator: String,
  borderClass: String,
});

const responseErrorsList = ref(props.responseErrors);
const phoneCode = ref(languageCodes[0].code);
const phoneNumber = ref();
const flag = ref(languageCodes[0].flagIcon);
const codeExists = ref();
const isTouched = ref(false);

const matchFlagAndCode = (value: string) => {
  codeExists.value = false;
  for (let i = 0; languageCodes.length > i; i++) {
    if (languageCodes[i].countryCode == value) {
      flag.value = languageCodes[i].code;
      codeExists.value = true;
      break;
    }
  }
};

if (props.selectedPhoneNumber) {
  phoneNumber.value = props.selectedPhoneNumber;
}

if (props.selectedCode) {
  phoneCode.value = props.selectedCode;
  codeExists.value = true;
  matchFlagAndCode(phoneCode.value);
}

const validation = computed(() =>
  validatePhoneInput(
    props.selectedCode ? props.selectedCode : "",
    props.selectedPhoneNumber ? props.selectedPhoneNumber : 0,
    props.codeMinLength ? props.codeMinLength : 0,
    props.codeMaxLength ? props.codeMaxLength : 255,
    props.phoneMinLength ? props.phoneMinLength : 0,
    props.phoneMaxLength ? props.phoneMaxLength : 255,
    props.codeRegexValidator ? props.codeRegexValidator : "",
    props.phoneRegexValidator ? props.phoneRegexValidator : ""
  )
);

const emit = defineEmits(["changePhoneNumber", "changePhoneCode", "isValid"]);

watch(phoneCode, (value: string) => {
  matchFlagAndCode(value);
  emit("changePhoneCode", value);
  isTouched.value = true;
});

watch(phoneNumber, (value: number) => {
  emit("changePhoneNumber", value);
  isTouched.value = true;
});
emit("isValid", false);

watch([validation, codeExists, phoneCode, phoneNumber], ([valueA]) => {
  checkCodeAndPhone();
});

const checkCodeAndPhone = () => {
  if (codeExists.value && props.selectedPhoneNumber) {
    emit("isValid", validation.value?.isValid);
  } else {
    emit("isValid", false);
  }
};
checkCodeAndPhone();
watch(
  props,
  () => {
    if (props.responseErrors != null) {
      responseErrorsList.value = props.responseErrors;
    }
  },
  { deep: true }
);
</script>

<template>
  <div
    class="relative flex align-items-center space-between radius-5 pdy-1 pdr-1"
  >
    <div
      class="w-40 hidden-overflow flex border-input radius-8 border-color-theme-brand-1"
    >
      <div class="flex flex-align-center">
        <AllFlagsComponent :flagCode="flag" />
      </div>
      <InputValueComponent
        :disableTopMargin="true"
        inputId="code"
        :disableValidation="true"
        placeholder="Code"
        :isInlineInput="true"
        v-model="phoneCode"
      ></InputValueComponent>
    </div>
    <div class="w-50">
      <InputValueComponent
        :borderClass="'border-input radius-8 border-color-theme-brand-1 '"
        :disableTopMargin="true"
        inputId="phone"
        :disableValidation="true"
        placeholder="Phone number"
        :isInlineInput="true"
        v-model="phoneNumber"
      ></InputValueComponent>
    </div>
  </div>
  <div v-if="responseErrorsList">
    <div
      class="txt-error my-1"
      v-for="(error, i) in responseErrorsList"
      :key="i"
    >
      {{ error }}
    </div>
  </div>
  <div
    v-if="!validation?.isValid && isTouched && !disableValidation"
    class="txt-error"
  >
    {{ validation?.message }}
  </div>
  <div v-if="!codeExists">
    <div class="txt-error my-1">Phone code does not exists.</div>
  </div>
</template>
