<script setup lang="ts">
import {
  sectionsData,
  homeSliderData,
  homeSliderData1,
  homeSliderData2,
} from "../../services/data/homeData";
import GroupSection from "../../components/shared/sectionGroup/GroupSectionComponent.vue";
import SliderComponent from "../../components/shared/slider/SliderComponent.vue";
import { useBrandStore } from "@/stores/brand";

const brandStore = useBrandStore();
// TODO: this needs to come from API
const slider = {
  Holahola: homeSliderData,
  "B-iGaming": homeSliderData1,
  "Betting-High": homeSliderData2,
};

const sliderData = slider[brandStore.name as keyof typeof slider];
</script>

<template>
  <div>
    <SliderComponent :props="{ sliderData: sliderData }" />
    <GroupSection
      v-for="section in sectionsData"
      v-bind:key="section.group"
      :section="section"
    />
  </div>
</template>
