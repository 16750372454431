<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue";
import IconComponent from "@/components/icons/IconComponent.vue";

const props = defineProps({
  title: String,
  iconList: Array as () => string[],
  isMobile: Boolean,
});

const icons = ref<string[]>([...(props.iconList || [])]);

const iconWidth = !props.isMobile ? 145 : 100;
const iconHeight = 40;
const translateWidth = !props.isMobile ? 300 : 250;
const translateX = ref(0);
const threshold = ref(0);
const calculateTranslationPosition = ref(Math.floor(translateX.value / 1000));

const nextIcons = () => {
  translateX.value -= translateWidth;
  calculateTranslationPosition.value = Math.floor(translateX.value / 1000);
  if (threshold.value > calculateTranslationPosition.value) {
    icons.value.push(
      ...(props.iconList || []),
      ...(props.iconList || []),
      ...(props.iconList || [])
    );
    threshold.value -= 1;
  }
};

const prevIcons = () => {
  calculateTranslationPosition.value = Math.floor(translateX.value / 1000);
  if (calculateTranslationPosition.value < 0) {
    if (translateX.value + translateWidth > 0) {
      translateX.value = 0;
    } else {
      translateX.value += translateWidth;
    }
  }
};

let startX = 0;
let isDragging = false;

const onTouchStart = (event: any) => {
  startX = event.touches[0].clientX;
  isDragging = true;
};

const onTouchMove = (event: any) => {
  if (isDragging && props.isMobile) {
    const touch = event.touches[0];
    const deltaX = touch.clientX - startX;
    if (translateX.value + deltaX <= 0) {
      translateX.value += deltaX;
    }
    startX = touch.clientX;
  }
};

const onTouchEnd = (event: any) => {
  isDragging = false;
  icons.value.push(...(props.iconList || []), ...(props.iconList || []));
};

onMounted(() => {
  if (props.iconList?.length! > 7 || props.isMobile) {
    icons.value.push(...(props.iconList || []), ...(props.iconList || []));
  }
});
</script>

<template>
  <div class="w-100 flex flex-center pdb-5">
    <p class="txt-bold txt-size-16">{{ props.title }}</p>
  </div>
  <div class="flex">
    <div
      v-if="icons?.length > 7 || isMobile"
      class="arrow left flex flex-center"
      @click="prevIcons"
    >
      <IconComponent
        iconClasses="svg-icon icon-20 icon-angle bg-1-1 block mgx-2 cursor-pointer"
      ></IconComponent>
    </div>
    <div
      @touchstart="onTouchStart"
      @touchmove="onTouchMove"
      @touchend="onTouchEnd"
      class="carousel"
    >
      <div class="icons-wrapper">
        <div
          id="iconContainer"
          class="icons-container w-100"
          :class="icons?.length < 7 && !isMobile ? 'flex-center w-100' : ''"
          :style="{ transform: `translateX(${translateX}px)` }"
        >
          <div
            :style="`width: ${iconWidth}px; height: ${iconHeight}px`"
            class="icon-container mgx-5"
            v-for="(icon, index) in icons"
            :key="index"
          >
            <img
              class="icon"
              :style="`max-width: ${iconWidth}px; min-width: 50px; max-height: ${iconHeight}px`"
              :src="icon"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="icons?.length > 7 || isMobile"
      class="arrow right flex flex-center"
      @click="nextIcons"
    >
      <IconComponent
        iconClasses="svg-icon rotate-180 icon-20 icon-angle bg-1-1 block mgx-2 cursor-pointer"
      ></IconComponent>
    </div>
  </div>
</template>

<style scoped>
.arrow:hover,
.svg-icon:hover {
  .icon-20 {
    scale: 1.5;
  }
}
.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.arrow {
  cursor: pointer;
  font-size: 24px;
  margin: 0 10px;
}

.icons-wrapper {
  display: flex;
}

.icons-container {
  display: flex;
  transition: transform 0.4s ease;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0px;
}
.icon {
  cursor: pointer;
  filter: brightness(0) invert(1) grayscale(1) brightness(1);
}
.icon:hover {
  filter: none;
}
</style>
