import { useAuthStore } from "@/stores/auth";
import { useBalanceStore } from "@/stores/balance";
import { useLayoutModalsStore } from "@/stores/layoutModals";
import type { MercureEventListener } from "./EventListenerInterface";

class CasinoBalanceEvent implements MercureEventListener {
  listener(event: MessageEvent) {
    const balanceStore = useBalanceStore();
    const layoutStore = useLayoutModalsStore();
    const eventData = JSON.parse(event.data);

    console.log("BALANCE EVENT: ", eventData);

    // if (parseInt(eventData.CASINO.bonusAmountAdded) > 0) {
    //   layoutStore.activateModalNoClose({
    //     name: "bonusNotification",
    //     data: parseInt(eventData.CASINO.bonusAmountAdded),
    //   });
    // }
    balanceStore.setBalance(
      parseFloat(eventData.CASINO.balance).toFixed(2),
      parseFloat(eventData.CASINO.bonusBalance).toFixed(2),
      "casino"
    );
    balanceStore.setBalance(
      parseFloat(eventData.SPORT_BOOK.balance).toFixed(2),
      parseFloat(eventData.SPORT_BOOK.bonusBalance).toFixed(2),
      "sportbook"
    );
    //  authStore.setPlayerData();
  }
  //"wallet/:playerId",
  topic() {
    const authStore = useAuthStore();
    const player = authStore.$state.player;
    if (player == null) {
      throw new Error("Player not logged In");
    }
    const topic = "wallet/" + player?.id;
    console.log(topic);
    return topic;
  }

  on() {
    return "message";
  }
}

export default CasinoBalanceEvent;
