import { DomainResolver } from "./lib/domain/DomainResolver";
import { brandMapper } from "./services/mappers/brandMapper";
import { initChat } from "../src/services/data/chatComm100API";


export const init = async () => {
  const resolver = new DomainResolver();
  const brandName = await resolver.brandName();
  const brandModule = brandMapper[brandName as keyof typeof brandMapper];
  const initData = await import(`./brands/${brandModule.folder}/data/init.ts`);
  if(brandModule.chatId){
    // initialize chat from Comm100API
    initChat(brandModule.chatId);
  }
  return initData?.appendInitElement();
};
export const removeInitElement = () => {
  const initDiv = document.getElementById("init");
  if (initDiv) {
    initDiv.parentNode?.removeChild(initDiv);
  }
};
export async function bootstrap(): Promise<any> {
  const resolver = new DomainResolver();
  const response = await fetch(
    resolver.api() + "/bootstrap/brand/" + (await resolver.brandName()),
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  );
  if (!response.ok) {
    throw new Error(`Error! status: ${response.status}`);
  }

  const result = await response.json();

  const brandModule = brandMapper[result.data.name as keyof typeof brandMapper];

  let layoutData = null;
  let routes = null;

  routes = await import(`./brands/${brandModule.folder}/router/routes.ts`);
  layoutData = await import(`./brands/${brandModule.folder}/layout/props.ts`);
  result.data.brandSettings.template = brandModule.theme;
  result.data.brandSettings.folder = brandModule.folder;
  return {
    props: {
      props: {
        data: result.data,
        layoutData: layoutData?.layoutData,
        banners: result.data.bannerImages,
      },
    },
    routes: routes?.moduleRoute,
  };
}
