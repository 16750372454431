<script setup lang="ts">
import RadioInputGroup from "@/components/shared/forms/radio/RadioInputGroup.vue";
import RadioInputComponent from "@/components/shared/forms/radio/RadioInputComponent.vue";
import InputValueComponent from "@/components/shared/forms/input/InputValueComponent.vue";
import DropDownComponent from "@/components/shared/forms/dropdown/DropDownComponent.vue";
import { ref, watch, computed, onMounted } from "vue";
import { useLoaderStore } from "@/stores/loader";
import AdditionalDataWrapper from "@/brands/holahola/components/deposit/additionalData/AdditionalDataWrapper.vue";
import { useBalanceStore } from "@/stores/balance";
import type { BalanceKey } from "@/stores/balance";
import { translate } from "@/services/translations/translation";
import { useLayoutModalsStore } from "@/stores/layoutModals";
import { getPaymentMethods } from "@/services/payment/payment";
import type { Deposit } from "@/services/payment/depositAbstract/deposit";
import { generateDepositObject } from "@/services/payment/depositImplementations/depositFactory";
import type { HandlerResponse } from "@/services/payment/depositAbstract/handleDeposit";

const balanceStore = useBalanceStore();
const depositStage = ref(0);
const balance = computed(() => balanceStore.getBalance(selectedWalletName.value));
const finishedLoading = ref(false);
const selectedWallet = ref();
const selectedWalletName = ref<BalanceKey>("casino");
const layoutModalsStore = useLayoutModalsStore();
const bonuses = ref();
const providers = ref();
const wallets = ref();
const currency = ref("");
const pricePoints = ref();
const bonusCodeFlag = ref(false);
const bonusCode = ref("");
const selectedBonus = ref();
const selectedPricePoint = ref();
const depositAmount = ref();
const selectedPiQ = ref();
const loader = useLoaderStore();
const walletsTranslations = ref<Array<any>>([]);
const deposit = ref<Deposit>();
const additionalDataFlag = ref(false);
const handlerResponse = ref<HandlerResponse>();

const loadWalletData = async (walletType: number) => {
  loader.toggleLoader(true);
  selectedPricePoint.value = null;
  depositAmount.value = null;
  bonuses.value = null;
  const response = await getPaymentMethods("deposit", walletType);
  if (response) {
    const loadedBonuses = response.bonuses;
    bonuses.value = [];
    for (const bonus in loadedBonuses) {
      bonuses.value.push(loadedBonuses[bonus]);
    }
    bonuses.value.push({
      title: translate("transactions.deposit.desktop.generals.withBonus"),
      bonusId: null,
    });
    bonuses.value.push({
      title: translate("transactions.deposit.desktop.generals.withoutBonus"),
      bonusId: null,
    });
    providers.value = response.methods;
    wallets.value = response.wallet.options;
    selectedWallet.value = response.wallet.current;
    currency.value = response.currency;
    pricePoints.value = response.amounts;
  }
  loader.toggleLoader(false);
};

const submit = async () => {
  loader.toggleLoader(true);
  localStorage.setItem("depositWallet", selectedWalletName.value);
  localStorage.setItem("balance", balance.value as any);

  deposit.value = generateDepositObject({
    supplier: providers.value[selectedPiQ.value].supplier,
    provider: providers.value[selectedPiQ.value].provider,
    amount: parseFloat(depositAmount.value),
    walletType: selectedWallet.value,
    bonus_code: bonusCode.value,
    bonus_id: selectedBonus.value.bonusId,
  });
  handlerResponse.value = await deposit.value.handleDeposit();
  loader.toggleLoader(false);
};

const init = async () => {
  walletsTranslations.value = [];
  walletsTranslations.value.push(translate("home.desktop.buttonNavigation.casino"));
  walletsTranslations.value.push(translate("home.desktop.buttonNavigation.sport"));
  await loadWalletData(1);
  finishedLoading.value = true;
};

const clearRadioPrice = () => {
  if (depositAmount.value != selectedPricePoint.value) {
    selectedPricePoint.value = 0;
  }
};

const selectWallet = (wallet: number) => {
  selectedWallet.value = wallet;
  loadWalletData(selectedWallet.value);
};

const selectPricePoint = (price: number) => {
  selectedPricePoint.value = price;
  depositAmount.value = price.toString();
};

const setBonus = (event: any) => {
  selectedBonus.value = event;
  if (selectedBonus.value.title == "I have a Bonus code") {
    bonusCodeFlag.value = true;
  } else {
    bonusCodeFlag.value = false;
    bonusCode.value = "";
  }
};

watch(
  depositAmount,
  (value) => {
    clearRadioPrice();
  },
  { deep: true }
);
selectedWalletName;

watch(
  selectedWallet,
  (value) => {
    for (const key in wallets.value) {
      if (wallets.value[key].id == selectedWallet.value) {
        selectedWalletName.value = wallets.value[key].name.toLowerCase();
      }
    }
  },
  { deep: true }
);

init();
</script>

<template>
  <div v-if="finishedLoading">
    <div class="form-size">
      <form @submit.prevent v-if="!additionalDataFlag">
        <div class="space-between flex pdy-2 pdx-4 bg-theme-brand-1 txt-theme-bg-1">
          <p class="title-smallest txt-bold">
            {{ translate("transactions.deposit.mobile.balance") }}
          </p>
          <p id="balance" class="title-smallest txt-bold">${{ balance }}</p>
        </div>
        <div class="ballance-wrapper-content pdx-4 mgb-4">
          <h1 class="pda-4 txt-center title-large txt-theme-brand-1">
            {{ translate("transactions.deposit.mobile.header") }}
          </h1>
          <p class="wallet txt-left txt-theme-txt-1 text-smallest txt-theme-brand-1">
            {{ translate("transactions.deposit.mobile.wallet_type") }}
          </p>
          <RadioInputGroup :class="'border-input radius-5 w-100 mgy-2 hidden-overflow'">
            <RadioInputComponent
              @click="selectWallet(wallet.id)"
              v-for="(wallet, i) of wallets"
              v-bind:key="i"
              :id="wallet.name.toLowerCase() + translate('common.wallet')"
              :wrapperClass="'radio-style-btn-wrapper w-50'"
              :labelText="walletsTranslations[i]"
              :classLabel="
                wallet.id == selectedWallet
                  ? 'bg-theme-brand-1 flex flex-center btn-normal radio-style-btn'
                  : 'no-border flex flex-center btn-normal radio-style-btn'
              "
            >
            </RadioInputComponent>
          </RadioInputGroup>
          <DropDownComponent
            dropdownId="bonus"
            :label="translate('transactions.deposit.mobile.select_bonus')"
            :options="bonuses"
            :selector="'title'"
            @emitSelected="setBonus"
          />
          <InputValueComponent
            inputId="bonusCode"
            v-if="bonusCodeFlag"
            :placeholder="'Bonus Code'"
            v-model="bonusCode"
            :inputType="'text'"
          >
          </InputValueComponent>
          <InputValueComponent
            inputId="amount"
            :placeholder="translate('transactions.deposit.mobile.deposit_amount')"
            v-model="depositAmount"
            :regexValidator="'decimal'"
          >
          </InputValueComponent>

          <RadioInputGroup
            id="amountOption"
            :class="'border-input radius-5 w-100 mgy-4 hidden-overflow '"
          >
            <RadioInputComponent
              @click="selectPricePoint(price)"
              v-for="(price, i) of pricePoints"
              v-bind:key="i"
              :wrapperClass="'radio-style-btn-wrapper w-25'"
              :labelText="price.toString()"
              :classLabel="
                selectedPricePoint == price
                  ? 'bg-brand flex flex-center btn-normal radio-style-btn'
                  : i == pricePoints.length - 1
                  ? 'no-border flex flex-center btn-normal radio-style-btn'
                  : 'flex flex-center btn-normal radio-style-btn'
              "
            >
            </RadioInputComponent>
          </RadioInputGroup>

          <p class="wallet txt-left mgb-4 txt-theme-brand-1 text-smallest">
            {{ translate("transactions.deposit.mobile.payment_methods") }}
          </p>

          <div class="mgb-4">
            <div
              :class="`piq-container txt-center`"
              v-for="(provider, i) in providers"
              v-bind:key="i"
              :id="provider.provider"
            >
              <img
                @click="selectedPiQ = i"
                :src="provider.images.select.src"
                :alt="provider.images.select.alt"
                class="border-piq radius-6 cursor-pointer"
                :class="selectedPiQ == i ? 'border-width-5' : 'not-selected-piq'"
              />
            </div>
          </div>
          <p v-if="handlerResponse?.isError">{{ handlerResponse?.responseMessage }}</p>
          <button
            id="submit"
            class="btn btn-success mg-center btn-large"
            @click="submit"
            :disabled="
              !(selectedPiQ >= 0) || !depositAmount || !selectedWallet || !selectedBonus
            "
          >
            {{ translate("transactions.deposit.mobile.submit") }}
          </button>
        </div>
      </form>

      <AdditionalDataWrapper v-if="additionalDataFlag && deposit" :deposit="deposit" />
    </div>
  </div>
</template>
