import type { QuestionnaireType } from "../interface/questionnaire";

export const questions: Array<QuestionnaireType> = [
  {
    title: "What are you mostly interested in?",
    type: "checkbox",
    minChecked: 1,
    id: 1,
    options: [
      {
        isChecked: false,
        title: "casino",
        id: "1",
      },
      {
        isChecked: false,
        title: "sportsbook",
        id: "2",
      },
      {
        isChecked: false,
        title: "Both Casino & Sportbook",
        id: "3",
      },
      {
        isChecked: false,
        title: "Live Casino",
        id: "3",
      },
    ],
  },
  {
    title: "Which type of bonuses would you like to receive?",
    type: "checkbox",
    minChecked: 1,
    id: 2,
    options: [
      {
        isChecked: false,
        title: "Free Spins upon deposit",
        id: "1",
      },
      {
        isChecked: false,
        title: "Monthly Cashback Bonuses",
        id: "2",
      },
      {
        isChecked: false,
        title: "Percentage Bonuses upon deposit",
        id: "3",
      },
      {
        isChecked: false,
        title: "Combination of Freespins & Percentage Bonuses",
        id: "4",
      },
    ],
  },
  {
    title:
      "Which offer would you like to see more of and be able to participate in?",
    type: "checkbox",
    minChecked: 1,
    id: 3,
    options: [
      {
        isChecked: false,
        title: "Spin until you win (example: 30 seconds of free spins)",
        id: "1",
      },
      {
        isChecked: false,
        title: "Prize drops",
        id: "2",
      },
      {
        isChecked: false,
        title: "Normal Free Spins / Percentage Bonuses",
        id: "3",
      },
      {
        isChecked: false,
        title: "Wildcard promotions",
        id: "4",
      },
      {
        isChecked: false,
        title: "Sportsbook offers",
        id: "5",
      },
      {
        isChecked: false,
        title: "Cashback offers",
        id: "6",
      },
    ],
  },
  {
    title: "You normally play?",
    type: "radio",
    id: 4,
    options: [
      {
        title: "Once in a while",
        id: "1",
        isChecked: false,
      },
      {
        title: "Once a week",
        id: "2",
        isChecked: false,
      },
      {
        title: "Once a month",
        id: "3",
        isChecked: false,
      },
      {
        title: "Every day",
        id: "4",
        isChecked: false,
      },
    ],
  },
  {
    title: "During which period of the day do you mostly prefer to play?",
    type: "radio",
    id: 5,
    options: [
      {
        title: "Day",
        id: "1",
        isChecked: false,
      },
      {
        title: "Night",
        id: "2",
        isChecked: false,
      },
    ],
  },
  {
    title:
      "Are you or any person related to you (for example your wife or close partner) considered to be a PEP (Politically Exposed Person isan individual with a high profile political role, or who has been entrusted with a prominent public function)?",
    type: "checkbox",
    minChecked: 1,
    id: 6,
    options: [
      {
        isChecked: false,
        title: "Phone",
        id: "1",
      },
      {
        isChecked: false,
        title: "Email",
        id: "2",
      },
    ],
  },
];
