<script setup lang="ts">
import OverlayComponent from "../overlay/OverlayComponent.vue";
import IconComponent from "../../icons/IconComponent.vue";
import { ref } from "vue";

const props = defineProps({
  isStandalone: Boolean,
  overlayBackground: String,
  expandableName: String,
  headerText: String,
  headerSubtitle: String,
  subtitleClass: String,
  headerIcon: String,
  arrowClass: String,
  expandableContentClass: String,
  expandableWrapperClass: String,
  isLarge: Boolean,
  isExtraLarge: Boolean,
  isStatic: Boolean,
  titleTxtClass: String,
});
const isOpen = ref(false);
const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};
</script>

<template>
  <OverlayComponent
    v-if="isOpen && isStandalone"
    :bgClass="overlayBackground"
    @closeElement="toggleDropdown()"
  />
  <div class="expandable-wrapper relative w-100" :class="expandableWrapperClass">
    <div
      class="btn-expandable flex"
      role="button"
      aria-haspopup="true"
      :aria-label="!isOpen ? 'Open ' + expandableName : 'Close ' + expandableName"
      :aria-expanded="!isOpen ? false : true"
      tabindex="0"
      @click="toggleDropdown()"
      v-on:keyup.enter="isOpen = true"
      v-on:keyup.esc="isOpen = false"
      :class="
        isLarge
          ? 'padding-large'
          : isExtraLarge
          ? 'xl-button padding-small'
          : 'padding-small'
      "
    >
      <p>
        <span class="flex flex-center">
          <span v-if="headerIcon" :class="headerIcon"></span>
          <span class="flex flex-column" :class="props.titleTxtClass">
            <span>{{ headerText }}</span>
            <span v-if="headerSubtitle" :class="subtitleClass">{{ headerSubtitle }}</span>
          </span>
        </span>
      </p>
      <IconComponent
        :iconClasses="'slow inline-block icon-dropdown icon-18 bg-theme-txt-1'"
        :class="
          !isOpen
            ? isStatic
              ? 'rotate-right90'
              : ''
            : isStatic
            ? 'rotate-right90 rotate-up'
            : 'rotate-up'
        "
      ></IconComponent>
    </div>
    <div v-if="isOpen" class="expandable-content" :class="expandableContentClass">
      <slot></slot>
    </div>
  </div>
</template>
