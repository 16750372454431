<script setup lang="ts">
import InputValueComponent from "@/components/shared/forms/input/InputValueComponent.vue";
import InputPasswordComponent from "@/components/shared/forms/input/InputPasswordComponent.vue";
import { useAuthStore } from "@/stores/auth";
import { ref, onMounted } from "vue";
import { useLoaderStore } from "@/stores/loader";
import SocialsTwoComponent from "@/brands/holahola/components/auth/SocialsTwoComponent.vue";
import { useLayoutModalsStore } from "@/stores/layoutModals";
import { goToRoute } from "@/router/routingService";
import DividerComponent from "@/components/shared/divider/DividerComponent.vue";
import { translate } from "@/services/translations/translation";
import IconComponent from "@/components/icons/IconComponent.vue";

const login = ref({
  username: "",
  password: "",
});

const layoutModalsStore = useLayoutModalsStore();
const loader = useLoaderStore();
const authStore = useAuthStore();
const responseErr = ref();

const close = () => {
  layoutModalsStore.closeModal("login");
};
const submit = async () => {
  loader.toggleLoader(true);
  responseErr.value = await authStore.login(
    login.value.username.toLowerCase(),
    login.value.password
  );
  !responseErr.value ? close() : null;

  loader.toggleLoader(false);
};

const reRoute = (url: string) => {
  goToRoute(url);
  close();
};

onMounted(() => {
  if (authStore.$state.player) {
    close();
  }
});
</script>
<template>
  <div>
    <div class="backdrop"></div>
    <form @submit.prevent>
      <div class="auth-modal bg-theme-bg-1 pda-5">
        <div type="" class="flex flex-right">
          <IconComponent
            :iconClasses="'svg-icon block icon-16  icon-form-clear bg-theme-bg-7 cursor-pointer'"
            @click="close"
          ></IconComponent>
        </div>
        <h1 class="title-large mgt-5 txt-theme-txt-1 txt-bold mgx-auto fit-content">
          {{ translate("auth.login.desktop.header") }}
        </h1>
        <p class="txt-theme-txt-2 mgx-auto fit-content text-smallest txt-bold">
          {{ translate("auth.login.desktop.header_description") }}
        </p>
        <InputValueComponent
          inputId="name"
          :placeholder="translate('auth.login.desktop.username')"
          :inputType="'text'"
          v-model="login.username"
          :disableValidation="true"
          :borderClass="'border-input radius-5 border-color-theme-brand-1'"
        ></InputValueComponent>
        <InputPasswordComponent
          :autocomplete="true"
          passId="password"
          :label="translate('auth.login.desktop.password')"
          v-model="login.password"
          :disableValidation="true"
          :borderClass="'border-input radius-5 border-color-theme-brand-1'"
        ></InputPasswordComponent>
        <p
          v-if="responseErr"
          class="txt-bold txt-error cursor-pointer text-smallest mgy-5"
        >
          {{ responseErr }}
        </p>
        <p
          class="txt-bold txt-theme-brand-2 cursor-pointer text-smallest mgy-5"
          @click="reRoute('/email-password-reset')"
        >
          {{ translate("auth.login.desktop.forgot_password") }}
        </p>
        <button class="radius-10 bg-theme-brand-2 w-100 pdy-3" @click="submit">
          {{ translate("auth.login.desktop.submit") }}
        </button>
        <p class="txt-theme-txt-2 text-smallest mgt-5">
          {{ translate("auth.login.desktop.dont_have_account") }}
          <span class="txt-theme-brand-2 cursor-pointer" @click="reRoute('/register')">{{
            translate("auth.login.desktop.register")
          }}</span>
        </p>
        <DividerComponent
          :dividerClass="'w-90 mgx-auto mgb-6 mgt-6 bg-dark400'"
        ></DividerComponent>
        <SocialsTwoComponent />
      </div>
    </form>
  </div>
</template>
