<script setup lang="ts">
import IconComponent from "@/components/icons/IconComponent.vue";
import { goToRoute } from "@/router/routingService";
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import { translate } from "@/services/translations/translation";
import { useAuthStore } from "@/stores/auth";
import LanguageComponentDropdown from "@/brands/holahola/components/shared/language/LanguageComponentDropdown.vue";
import iconCards from '@/assets/icons/ico-blue-cards.svg'
import iconPromotions from '@/assets/icons/ico-blue-promotions.svg'
import iconChip from '@/assets/icons/ico-blue-chip.svg'
import iconSports from '@/assets/icons/ico-blue-sports.svg'

const store = useAuthStore();
const emit = defineEmits(["toggleMenu"]);
const route = useRoute();

const headerLinks = [
  {
    name: "casino",
    icon: iconCards,
    translation: "navigation.casino",
    link: '/casino'
  },
  {
    name: "promotions",
    icon: iconPromotions,
    translation: "navigation.promotions",
    link: '/promotions'
  },/* 
  {
    name: "support",
    icon: iconSports,
    translation: "common.support",
    link: '/support'
  },
  {
    name: "blog",
    icon: iconChip,
    translation: "navigation.blog",
    link: '/blog'
  } */
]

const goToLink = (link: string) => {
  emit("toggleMenu");
  goToRoute(link);
};

onMounted(() => {
}); 

</script>

<template>
  <div class="hola-header-mobile-menu fixed top-0 right-0 bottom-0 pdx-6">
    
    <ul class="links pdt-3 pdb-5 font-Poppins">
      <li v-for="(link, i) in headerLinks" v-bind:key="i">
        <div
        @click="goToLink(link.link)"
          class="mgy-6 pdy-5 txt-bold flex flex-align-center active-bg-1-3"
          :id="`${link.name}-group`"
        >
          <img :src="link.icon" class="mgr-5" />
          <p class=" txt-size-18">{{ translate(link.translation).toUpperCase() }}</p>
          </div
        >
      </li>
    </ul>
    <LanguageComponentDropdown />
    <div v-if="store?.player" class="mgt-10">
      <div class="flex flex-justify-center flex-column">
        <div @click="goToLink('/navigation')" class="cursor-pointer bg-1-3 radius-10 w-100 pdy-5 flex flex-align-center">
          <div class="mgx-5">
          <IconComponent
            :iconClasses="`icon-26 icon-inline bg-2-1 icon-blue-user`"
          ></IconComponent></div>
          <p class="font-poppins txt-light txt-size-18 inline">
            {{ translate("common.myAccount") }}
          </p>
        </div>
        <div @click="goToLink('/profile')" class="mgb-5 mgt-5 pdy-2 cursor-pointer pdl-12">
          <p class="font-poppins txt-light txt-size-16 ">
            {{ translate("common.profile") }}
          </p>
        </div>
        <div @click="store?.logout" class="pdy-2 cursor-pointer pdl-12 flex">
          <IconComponent
            :iconClasses="`icon-24 icon-inline bg-2-1 icon-logout`"
          ></IconComponent>
          <p class="font-poppins txt-2-1 txt-size-16">
            {{ translate("common.logOut") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.hola-header-mobile-menu {
  margin-top: 52px;
  width: 100%;
  z-index: 400;
  background: rgb(35, 0, 138);
  background: linear-gradient(180deg, rgba(35, 0, 138, 1) 0%, rgba(0, 6, 53, 1) 100%);
}
</style>
