<script setup lang="ts">
import { computed, ref, onMounted, watch } from "vue";
import { useAuthStore } from "@/stores/auth";
import { links, balances } from "@/services/data/personalMenu";
import { useBalanceStore } from "@/stores/balance";
import LanguageComponent from "@/brands/bit1111/components/shared/language/LanguageComponent.vue";
import { RouterSingleton } from "@/router/index";
import { translate } from "@/services/translations/translation";
import ButtonComponent from "@/brands/bit1111/components/shared/buttons/ButtonComponent.vue";
import ConfirmationModalComponent from "../../shared/modal/ConfirmationModalComponent.vue";
import axios from "axios";
// import resp from "./res.json";
import CategoryMobileSlider from "@/brands/bit1111/components/casino/CategoryMenuSlider.vue";
import { useBonusesStore } from "@/stores/bonuses";
import { useRoute } from "vue-router";
import { goToRoute } from "@/router/routingService";

const route = useRoute();
const balanceStore = useBalanceStore();
const sportbookBalance = computed(() => balanceStore.getWallet("sportbook"));
const casinoBalance = computed(() => balanceStore.getWallet("casino"));
const isConfirmationModalActive = ref(false);

const bonusStore = ref(useBonusesStore());

const availableBonuses = ref();
const bonusScrollPane = ref();

const depositPage = () => {
  handleClose();
  RouterSingleton.getInstance().getRouter().push("/deposit");
};

const goToUrl = (item: string) => {
  handleClose();
  RouterSingleton.getInstance().getRouter().push(item);
};

const emit = defineEmits(["closeMenu"]);

const handleClose = () => {
  emit("closeMenu", false);
};

const authStore = useAuthStore();
const logout = () => {
  emit("closeMenu", false);
  authStore.logout();
};

/* const modalAnswer = async (event: boolean) => {
  isConfirmationModalActive.value = false;
  if (event) {
    await axios
      .post("/obtained/bonus/active/cancel", {
        wagering: null,
        bonus_balance: null,
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  }
}; */

const percentage = ref(3);
const sportsbookPercentage = ref(0);
const roundedPercentageClass = ref("w-0");
const roundedPercentageSportsbookClass = ref("w-0");
const categories = ref([]);
onMounted(() => {
  try {
    if (authStore.$state.player) {
      bonusStore.value.getAllBonuses().then((allbonus) => {
        availableBonuses.value = allbonus.availableBonuses;
      });
      // availableBonuses.value = resp.data;
    }
    const remain = casinoBalance.value.remainingWageringAmount ?? 0;
    const total = casinoBalance.value.totalWageringAmount ?? 0;
    percentage.value = (remain / (total == 0 ? 1 : total)) * 100;
    const sportsbookRemain = sportbookBalance.value.remainingWageringAmount ?? 0;
    const sportsbookTotal = sportbookBalance.value.totalWageringAmount ?? 0;
    sportsbookPercentage.value =
      (sportsbookRemain / (sportsbookTotal == 0 ? 1 : total)) * 100;
    axios
      .get("/dropdown/game-category")
      .then((response) => {
        categories.value = response.data.data;
      })
      .catch((error) => {
        //
      });
  } catch (err) {
    percentage.value = 0;
  }

  roundedPercentageClass.value = "w-" + Math.round(percentage.value);
  roundedPercentageSportsbookClass.value = "w-" + Math.round(sportsbookPercentage.value);
});
watch(
  route,
  (value) => {
    handleClose();
  },
  { deep: true }
);
</script>

<template>
  <div class="backdrop scrollbar-style" @click="handleClose">
    <!-- <ConfirmationModalComponent
      v-if="isConfirmationModalActive"
      :title="translate('navigation.header.desktop.confirmBonusDisable')"
      :positive-answer="translate('common.yes').toUpperCase()"
      :negative-answer="translate('common.no').toUpperCase()"
      @answer-from-modal="modalAnswer($event)"
    /> -->
    <div class="w-100 maxw-1184px absolute top-0">
      <div class="personal-menu-wrapper w-330px">
        <div
          class="absolute container-dropdown zindex-dropdown radius-10 bg-5-1 maxw-330px background-img-personal"
        >
          <div class="flex pdr-12px">
            <div class="tap-area flex flex-left-center">
              <p class="txt-18 txt-1-1">
                {{ authStore.$state.player?.firstName }}
                {{ authStore.$state.player?.lastName }}
              </p>
            </div>
          </div>
          <LanguageComponent
            :divider="false"
            bgTheme="bg-4-6"
            :toggleDropdown="true"
            :absolutePosition="true"
            :personalMenuWrapper="true"
            topMargin="60px"
            :backdrop="false"
          >
          </LanguageComponent>
          <!-- BALANCE -->
          <button
            class="bg-1-1 hover-bg-1-2 txt-light mgl-12px mgb-5 pdx-3 pdy-3 radius-5"
            @click="goToUrl('/profile')"
          >
            {{ translate("navigation.header.desktop.account") }}
          </button>

          <div class="flex flex-column pdx-12px mgt-5">
            <p>
              {{ translate("common.casino") }}
            </p>
            <span class="pdl-5">
              <span class="flex space-between">
                <p class="txt-12 no-wrap txt-light pda-0">
                  {{ translate("account.profile.desktop.available_balance") }}
                </p>

                <div class="dots w-100 pdt-3 mgx-2"></div>

                <p class="txt-16 no-wrap pda-0">
                  {{
                    casinoBalance.balance?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) +
                    " " +
                    casinoBalance.currency?.code
                  }}
                </p>
              </span>
              <span class="flex space-between">
                <p class="txt-12 no-wrap txt-light pda-0">
                  {{ translate("account.profile.desktop.reserved_balance") }}
                </p>

                <div class="dots w-100 pdt-3 mgx-2"></div>

                <p class="txt-16 no-wrap pda-0">
                  {{
                    casinoBalance.reservedFunds?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) +
                    " " +
                    casinoBalance.currency?.code
                  }}
                </p>
              </span>
              <span class="flex flex-center">
                <p class="txt-12 no-wrap txt-light pda-0">
                  {{ translate("common.bonus") }}
                </p>
                <div class="dots w-100 pdt-3 mgx-2"></div>

                <p class="txt-16 pda-0 no-wrap">
                  {{
                    casinoBalance.bonusBalance?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) +
                    " " +
                    casinoBalance.currency?.code
                  }}
                </p>
              </span>
              <!-- WAGERING -->
              <div v-if="casinoBalance.remainingWageringAmount! > 0" class="w-100 mgy-4">
                <span class="block txt-light txt-size-14 mgb-3 txt-bold"
                  >{{ translate("account.profile.desktop.wagering_requirement") }}
                  {{ translate("common.casino") }}</span
                >
                <div class="flex space-between txt-size-12">
                  <span class="block txt-light txt-bold">
                    {{
                      casinoBalance.remainingWageringAmount?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}
                    {{ casinoBalance.currency?.code }}
                  </span>
                  <span class="block txt-light txt-bold">
                    {{ percentage.toFixed() }}%
                  </span>
                  <span class="block txt-light txt-bold">
                    {{
                      casinoBalance.totalWageringAmount?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}
                    {{ casinoBalance.currency?.code }}</span
                  >
                </div>
                <div class="radius-6 w-100 bg-1-3 h-6px">
                  <div
                    class="radius-6 bg-1-1 h-6px"
                    :class="roundedPercentageClass"
                  ></div>
                </div>
                <p class="txt-size-10 mgt-3 txt-light">
                  {{ translate("account.profile.desktop.text") }}
                </p>
              </div>
            </span>
          </div>
          <br />

          <div class="flex flex-column pdx-12px sportsbook">
            <p>
              {{ translate("common.sportsbook") }}
            </p>
            <span class="pdl-5">
              <span class="flex space-between">
                <p class="txt-12 no-wrap txt-light pda-0">
                  {{ translate("account.profile.desktop.available_balance") }}
                </p>

                <div class="dots w-100 pdt-3 mgx-2"></div>
                <p class="txt-16 no-wrap pda-0">
                  {{
                    sportbookBalance.balance?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) +
                    " " +
                    sportbookBalance.currency?.code
                  }}
                </p>
              </span>
              <span class="flex space-between">
                <p class="txt-12 no-wrap txt-light pda-0">
                  {{ translate("account.profile.desktop.reserved_balance") }}
                </p>

                <div class="dots w-100 pdt-3 mgx-2"></div>
                <p class="txt-16 no-wrap pda-0">
                  {{
                    sportbookBalance.reservedFunds?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) +
                    " " +
                    sportbookBalance.currency?.code
                  }}
                </p>
              </span>
              <span class="flex flex-center">
                <p class="txt-12 no-wrap txt-light pda-0">
                  {{ translate("common.bonus") }}
                </p>
                <div class="dots w-100 pdt-3 mgx-2"></div>

                <p class="txt-16 no-wrap pda-0">
                  {{
                    sportbookBalance.bonusBalance?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) +
                    " " +
                    sportbookBalance.currency?.code
                  }}
                </p>
              </span>
              <!-- WAGERING -->
              <div
                v-if="sportbookBalance.remainingWageringAmount! > 0"
                class="w-100 mgy-4"
              >
                <span class="block txt-light txt-size-14 mgb-3 txt-bold"
                  >{{ translate("account.profile.desktop.wagering_requirement") }}
                  {{ translate("common.sportsbook") }}</span
                >
                <div class="flex space-between txt-size-12">
                  <span class="block txt-light txt-bold">
                    {{
                      sportbookBalance.remainingWageringAmount?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}
                    {{ sportbookBalance.currency?.code }}
                  </span>
                  <span class="block txt-light txt-bold">
                    {{ sportsbookPercentage.toFixed() }}%
                  </span>
                  <span class="block txt-light txt-bold">
                    {{
                      sportbookBalance.totalWageringAmount?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}
                    {{ sportbookBalance.currency?.code }}</span
                  >
                </div>
                <div class="radius-6 w-100 bg-1-3 h-6px">
                  <div
                    class="radius-6 bg-1-1 h-6px"
                    :class="roundedPercentageSportsbookClass"
                  ></div>
                </div>
                <p class="txt-size-10 mgt-3 txt-light">
                  {{ translate("account.profile.desktop.text") }}
                </p>
              </div>
            </span>
          </div>
          <div class="pdx-12px">
            <ButtonComponent
              id="depositBtn"
              class="mgt-7 mgb-29px"
              :text="translate('account.profile.desktop.deposit').toUpperCase()"
              :width="100"
              :heightPx="30"
              @click="depositPage"
            />
          </div>
          <h3 class="mgt-7 mgl-6 txt-weight-600">Bonuses</h3>

          <div class="flex mgx-auto fit-content mgy-7">
            <div class="w-280px">
              <div
                ref="bonusScrollPane"
                class="scroll-overflow-x flex no-wrap scrollbar-hidden pdy-3"
              >
                <div
                  v-for="(bonus, i) in availableBonuses"
                  :key="i"
                  :style="'box-shadow: 0px 2px 6px 2px #a92779;'"
                  class="w-200px h-250px mgx-5 radius-10"
                >
                  <img
                    :src="bonus.bonusAward.bonus.image"
                    alt=""
                    width="200"
                    height="125"
                    class="radius-top-10"
                  />

                  <p class="txt-1-1 txt-center txt-size-14 txt-weight-600 pre-wrap">
                    {{ bonus.bonusAward.bonus.name }}
                  </p>
                  <p class="txt-weight-600 txt-center txt-size-12">
                    Max Bonus: {{ bonus.bonusAward.bonusMax }}%
                  </p>
                  <p class="txt-weight-600 txt-center txt-size-12">
                    Max Bonus: {{ bonus.amount }}{{ bonus.bonusAward.currencyCode }}
                  </p>
                  <ButtonComponent
                    id="depositBtn"
                    class="mgx-auto mgt-2"
                    :text="'CLAIM NOW'"
                    :widthPx="160"
                    :heightPx="35"
                    @click="goToRoute(`/promotions/${bonus.id}`)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="flex mgx-auto fit-content mgy-7" v-if="categories[0]">
            <div class="w-280px relative">
              <CategoryMobileSlider v-if="categories[0]" :category="categories[0]" />
            </div>
          </div>

          <!-- Logout -->
          <div id="logout" @click="logout" class="flex cursor-pointer">
            <div class="w-100 pdl-12px pdy-3">
              <a class="txt-16 txt-error-1">
                {{ translate("account.user_account_menu.desktop.logout") }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.sportsbook {
  opacity: 0.6;
  position: relative;
  z-index: 0;
}

.sportsbook:before {
  content: "Coming Soon";
  text-align: center;
  position: absolute;
  color: purple;
  font-size: 20px;
  font-weight: bold;
  z-index: 2;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  /* Safari */
  -webkit-transform: rotate(30deg);

  /* Firefox */
  -moz-transform: rotate(30deg);

  /* IE */
  -ms-transform: rotate(30deg);

  /* Opera */
  -o-transform: rotate(30deg);
}
</style>
