<script setup lang="ts">
import { useLayoutModalsStore } from "@/stores/layoutModals";
import IconComponent from "@/components/icons/IconComponent.vue";
import ButtonComponent from "../buttons/ButtonComponent.vue";
import { translate } from "@/services/translations/translation";
import { isMobileCheck } from "@/services/interface/clientInformation";

const layoutModalsStore = useLayoutModalsStore();
const text = layoutModalsStore.getModal('playerAlertMessage')
const isMobile = isMobileCheck()

const close = () => {
  layoutModalsStore.closeModal("playerAlertMessage");
};


</script>
<template>
  <div>
    <div class="backdrop"></div>
    <div :class="isMobile ? 'auth-modal-mobile-bit1111' : 'auth-modal-bit1111'" class=" bg-4-6 pdy-5 pdx-7 radius-15">
        <div type="" class="flex flex-center">
        <span class="w-100 txt-center title-large txt-light">{{ translate('alerts.title').toUpperCase() }}</span>
        <IconComponent
          :iconClasses="'svg-icon block icon-16  icon-form-clear bg-1-1 cursor-pointer mgl-3'"
          @click="close"
        ></IconComponent>
      </div>
      <p class="pdy-7">{{ text.data.message }}</p>
      <ButtonComponent @click="close" :text="translate('common.close').toUpperCase()" :heightPx="35" />
      
    </div>
  </div>
</template>
