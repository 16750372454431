export function validateDropDown(value:Array<any>,multiple:boolean) {

    if (value) {
        if(value.length == 0){
            if(multiple){
                return {isValid:false, message:'At least 1 option is required'};
            }else{
                return {isValid:false, message:'Input is required'};
            }
            
        }
        return {isValid:true,message:''}
    }
    else{
        if(multiple){
            return {isValid:false, message:'At least 1 option is required'};
        }else{
            return {isValid:false, message:'Input is required'};
        }
    }

}
