<script setup lang="ts">
import IconComponent from "../../components/icons/IconComponent.vue";

const title = "Maintenance";
const textBold = "We are currently down for routine website maintenance";
const firstParagraph = "Please check back in about 60 min.";
</script>

<template>
  <div class="errorpages-wrapper row flex flex-center txt-theme-brand-1">
    <div class="col-4 col-size relative box txt-center bg-maintenance">
      <IconComponent
        :iconClasses="'icon-24 inline-block bg-theme-brand-1 tr-icon mgb-7'"
        :svgImage="'src/assets/icons/ico-maintenance.svg'"
      ></IconComponent>
      <h1 class="spacing-xl txt-theme-brand-1">{{ title }}</h1>
      <p class="txt-bold txt-theme-txt-1 title-smallest">{{ textBold }}</p>
      <p class="txt-theme-txt-1 title-smallest">{{ firstParagraph }}</p>
    </div>
  </div>
</template>
