<script setup lang="ts">
import AtomicLimitComponent from "../../../components/limits/AtomicLimitComponent.vue";
import { ref, onMounted } from "vue";
import { isMobileCheck } from "../../../services/interface/clientInformation";
import NotificationComponent from "../../../components/shared/notification/NotificationComponent.vue";
import { axiosGet, axiosPost } from "../../../services/axios/crudOperations";

const depositData = ref({
  daily: 0,
  dailyStartsAt: "",
  dailyExpiresAt: "",
  weekly: 0,
  weeklyStartsAt: "",
  weeklyExpiresAt: "",
  monthly: 0,
  monthlyStartsAt: "",
  monthlyExpiresAt: "",
  dailyCoverage: "",
  weeklyCoverage: "",
  monthlyCoverage: "",
  dailyLocked: false,
  weeklyLocked: false,
  monthlyLocked: false,
  walletTypes: [1, 2],
});

const notificationData = ref({
  isShown: false,
  message: "",
  title: "",
  icon: "",
  bg: "",
});

const isMobile = isMobileCheck();

const init = async () => {
  const response = await axiosGet("/player-limits/deposit");
  if (response) {
    depositData.value = response;
  }
};

init();

const test = async (event: any) => {
  switch (event.type) {
    case "daily":
      depositData.value.daily = parseInt(event.limit);
      depositData.value.dailyCoverage = event.coverage;
      break;
    case "weekly":
      depositData.value.weekly = parseInt(event.limit);
      depositData.value.weeklyCoverage = event.coverage;
      break;
    case "monthly":
      depositData.value.monthly = parseInt(event.limit);
      depositData.value.monthlyCoverage = event.coverage;
      break;
    default:
      console.log("Limit type failure.");
  }
  depositData.value.walletTypes = [1, 2];
  const response = await axiosPost("/player-limits/deposit", depositData.value); //IF RESPONSE SUCCESS
  if (response) {
    depositData.value = await axiosGet("/player-limits/deposit");
    notificationData.value = {
      isShown: true,
      message: `Your ${event.type} deposit limit has been updated successfully.`,
      title: "Limits updated!",
      bg: "bg-success ",
      icon: "icon-success-small",
    };
  } else {
    notificationData.value = {
      isShown: true,
      message: `There has been an error while trying to update limits.`,
      title: "Something went wrong!",
      bg: "bg-error ",
      icon: "icon-warning",
    };
  }
};
</script>

<template>
  <div class="form-size mgy-10">
    <div class="mgx-5">
      <h2 class="mgb-5" v-if="!isMobile">Deposit limit</h2>

      <p class="text-smallest text-height-small text-spacing-xl txt-theme-txt-2 mgy-5">
        Deposit limit allows you to limit the amount of money you can deposit over a given
        period. Once you set a limit, it cannot be revoked or increased before 24 hours
        have passed since the initial setting.
      </p>
      <NotificationComponent
        v-if="notificationData.isShown"
        :title="notificationData.title"
        :messageBody="notificationData.message"
        :bgClass="`${notificationData.bg} mgy-5`"
        :iconClass="`${notificationData.icon} bg-theme-txt-1 icon-40  mga-5`"
        :textColor="'txt-theme-txt-1'"
        @emitDismiss="notificationData.isShown = false"
      ></NotificationComponent>
      <div :class="!isMobile ? 'limit-grid mgb-20' : ''">
        <AtomicLimitComponent
          timeFrame="daily"
          type="deposit"
          :isSet="depositData.daily > 0"
          :limit="depositData.daily"
          :coverage="depositData.dailyCoverage"
          :periodTo="depositData.dailyExpiresAt"
          :periodFrom="depositData.dailyStartsAt"
          :locked="depositData.dailyLocked"
          :hasCoverage="true"
          @emitSave="test"
        />
        <AtomicLimitComponent
          timeFrame="weekly"
          type="deposit"
          :isSet="depositData.weekly > 0"
          :limit="depositData.weekly"
          :coverage="depositData.weeklyCoverage"
          :periodTo="depositData.weeklyExpiresAt"
          :periodFrom="depositData.weeklyStartsAt"
          :locked="depositData.weeklyLocked"
          :hasCoverage="true"
          @emitSave="test"
        />
        <AtomicLimitComponent
          timeFrame="monthly"
          type="deposit"
          :isSet="depositData.monthly > 0"
          :limit="depositData.monthly"
          :coverage="depositData.monthlyCoverage"
          :periodTo="depositData.monthlyExpiresAt"
          :periodFrom="depositData.monthlyStartsAt"
          :locked="depositData.monthlyLocked"
          :hasCoverage="true"
          @emitSave="test"
        />
      </div>
    </div>
  </div>
</template>
