import { defineStore } from "pinia";
import type { LooseObject } from "@/services/interface/utils";

export interface ModalStateInterface {
  active: boolean;
  query?: any;
  data?: any;
}

export interface ActivationPayload {
  name: string;
  query?: any;
  data?: any;
}

export const useLayoutModalsStore = defineStore("layoutmodals", {
  state: () => ({
    modals: <LooseObject>{
      login: <ModalStateInterface>{
        active: false,
      },
      loginMobile: <ModalStateInterface>{
        active: false,
      },
      register: <ModalStateInterface>{
        active: false,
      },
      registerMobile: <ModalStateInterface>{
        active: false,
      },
      registerConfirm: <ModalStateInterface>{
        active: false,
      },
      registerConfirmLast: <ModalStateInterface>{
        active: false,
      },
      emailPasswordReset: <ModalStateInterface>{
        active: false,
      },
      depositModal: <ModalStateInterface>{
        active: false,
      },
      boarding: <ModalStateInterface>{
        active: false,
      },
      playerAlertMessage: <ModalStateInterface>{
        active: false,
      },
      bonusNotification: <ModalStateInterface>{
        active: false
      },
      pageNotification: <ModalStateInterface>{
        active: false
      },
    },
  }),
  actions: {
    emptyStore() {
      for (const prop in this.modals) {
        if (Object.prototype.hasOwnProperty.call(this.modals, prop)) {
          this.modals[prop].active = false;
        }
      }
    },
    getModal(name: string) {
      try {
        return this.modals[name];
      } catch {
        return null;
      }
    },
    isModalActive(name: string) {
      let isActive;
      try {
        isActive = this.modals[name].active;
      } catch {
        isActive = false;
      }
      return isActive;
    },
    closeModal(name: string) {
      try {
        this.modals[name].active = false;
      } catch (error) {
        console.log("error closing modal");
      }
    },
    activateModalNoClose(activation: ActivationPayload) {

      this.modals[activation.name].active = true;
      if (activation.query) {
        this.modals[activation.name].query = activation.query;
      }
      if (activation.data) {
        this.modals[activation.name].data = activation.data;
      }
    },
    activateModal(activation: ActivationPayload) {
      this.emptyStore();
      this.modals[activation.name].active = true;
      if (activation.query) {
        this.modals[activation.name].query = activation.query;
      }
      if (activation.data) {
        this.modals[activation.name].data = activation.data;
      }
    },
  },
});
