<script setup lang="ts">
import AT_Flag from "./flags/AT_Flag.vue";
import CA_Flag from "./flags/CA_Flag.vue";
import ES_Flag from "./flags/ES_Flag.vue";
import FI_Flag from "./flags/FI_Flag.vue";
import GB_Flag from "./flags/GB_Flag.vue";
import IN_Flag from "./flags/IN_Flag.vue";
import NO_Flag from "./flags/NO_Flag.vue";
import NZ_Flag from "./flags/NZ_Flag.vue";
import PT_Flag from "./flags/PT_Flag.vue";
import RU_Flag from "./flags/RU_Flag.vue";
import RS_Flag from "./flags/RS_Flag.vue";
import DE_Flag from "./flags/DE_Flag.vue";
import SE_Flag from "./flags/SE_Flag.vue";
import { ref, watch, shallowRef, onMounted } from "vue";

const props = defineProps({
  selectedFlag: String,
});

const at_flag = shallowRef(AT_Flag);
const ca_flag = shallowRef(CA_Flag);
const es_flag = shallowRef(ES_Flag);
const fi_flag = shallowRef(FI_Flag);
const gb_flag = shallowRef(GB_Flag);
const uk_flag = shallowRef(GB_Flag);
const in_flag = shallowRef(IN_Flag);
const no_flag = shallowRef(NO_Flag);
const nz_flag = shallowRef(NZ_Flag);
const pt_flag = shallowRef(PT_Flag);
const ru_flag = shallowRef(RU_Flag);
const rs_flag = shallowRef(RS_Flag);
const de_flag = shallowRef(DE_Flag);
const se_flag = shallowRef(SE_Flag);

const selectedComponent = shallowRef();

const flagMap = ref([
  {
    element: gb_flag,
    id: "gb",
  },
  {
    element: uk_flag,
    id: "en",
  },
  {
    element: rs_flag,
    id: "sr",
  },
  {
    element: ru_flag,
    id: "ru",
  },
  {
    element: pt_flag,
    id: "pt",
  },
  {
    element: nz_flag,
    id: "en_NZ",
  },
  {
    element: no_flag,
    id: "no",
  },
  {
    element: in_flag,
    id: "en_IN",
  },
  {
    element: in_flag,
    id: "hi",
  },
  {
    element: fi_flag,
    id: "fi",
  },
  {
    element: at_flag,
    id: "de_AT",
  },
  {
    element: ca_flag,
    id: "en_CA",
  },
  {
    element: es_flag,
    id: "es",
  },
  {
    element: de_flag,
    id: "de",
  },
  {
    element: se_flag,
    id: "sv",
  },
]);

onMounted(() => {
  selectedComponent.value = flagMap.value[0].element;
  
});

watch(
  props,
  (value) => {
    flagMap.value.forEach((element) => {
      if (element.id == props.selectedFlag) {
        selectedComponent.value = element.element;
      }
    });
  },
  { deep: true }
);
</script>

<template>
  <component :is="selectedComponent"></component>
</template>
