const codes = {
  de: "de-DE",
  en: "en-GB",
  es: "es-ES",
  sv: "sw-SW",
  fi: "fi-FI",
  ru: "ru-RU",
};

export const getLanguage = (lang) => {
  return codes[lang];
};
