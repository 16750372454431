<script setup lang="ts">
import { ref, computed } from "vue";
import InputValueComponent from "../shared/forms/input/InputValueComponent.vue";
import { useWithdrawStore } from "../../stores/withdraw";
import { useLoaderStore } from "../../stores/loader";
import { axiosPost } from "../../services/axios/crudOperations";

const loader = useLoaderStore();

const withdrawStore = useWithdrawStore();
const withdrawData = computed(() => withdrawStore.getWithdrawData);

const bic = ref("NWBKGB2L");
const iban = ref("GB29NWBK60161331926819");
const beneficiaryName = ref("Jane Doe");

const submit = async () => {
  loader.toggleLoader(true);
  const additionalData = {
    bic: bic.value,
    iban: iban.value,
    beneficiaryName: beneficiaryName.value,
  };

  withdrawData.value.additionalData = additionalData;

  const response = await axiosPost("/payment/initiate", withdrawData.value);
  withdrawStore.setWithdrawStage(2);
  loader.toggleLoader(false);
};
</script>

<template>
  <div class="form-size mgb-4">
    <div class="provider-icon txt-center">
      <img
        src="https://s.b-bets.com/images/payment/solutions/piq/bankiban/bankiban_logo.png"
        alt="piq_bankiban"
        class="provider-logo radius-6"
        loading="lazy"
      />
    </div>
    <InputValueComponent
      inputId="bic"
      :placeholder="'bic'"
      v-model="bic"
      :inputType="'text'"
    ></InputValueComponent>
    <InputValueComponent
      inputId="iban"
      :placeholder="'iban'"
      v-model="iban"
      :inputType="'text'"
    ></InputValueComponent>
    <InputValueComponent
      inputId="name"
      :placeholder="'beneficiaryName'"
      v-model="beneficiaryName"
      :inputType="'text'"
    ></InputValueComponent>

    <button id="submit" class="btn btn-success mgy-4 mgx-auto btn-large" @click="submit">
      COMPLETE WITHDRAW
    </button>
  </div>
</template>
