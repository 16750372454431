<script setup lang="ts">
import { validateDropDown } from "@/services/validation/dropdown";
import { computed, ref, watch } from "vue";
import { useDropdownsStore } from "@/stores/dropdowns.js";
import SelectDropdown from "@/brands/holahola/components/shared/forms/dropdown/SelectDropdownComponent.vue";
import FormWarningComponent from "@/brands/holahola/components/shared/forms/FormWarningComponent.vue";
import { translate } from "@/services/translations/translation";
// import vSelect from "vue-select";

const props = defineProps({
  selected: [Object,String, Number],
  url: String,
  options: Array<any>,
  label: String,
  multiple: Boolean,
  selector: String,
  preselect: Boolean,
  dropdownId: String,
  testId: String,
  borderClass: String,
  textCutBorder: String,
  bgClass: String,
  radiusClass:String,
  isTouched: Boolean,
  arrowIsVisible: {
    default: true,
    type: Boolean,
    required: false,
  },
  resetIsVisible: {
    default: false,
    type: Boolean,
    required: false,
  },
});
const store = useDropdownsStore();
const items = ref<Array<any> | undefined>([]);
const selectedItem = ref();

if (props.selected) {
  selectedItem.value = props.selected;
}

const initialize = async () => {
  if (props.url) {
    items.value = await store.getDropdown(props.url);
  } else {
    items.value = props.options;
  }

  inputHandler(props.selected, true);
};

const emit = defineEmits(["isValid", "emitSelected", "reset", "update:isTouched"]);
const initial = ref(false);
const validation = computed(() =>
  validateDropDown(selectedItem.value, props.multiple)
);

const catchEmit = (event: any) => {
  if (props.multiple) {
    selectedItem.value = event;
  } else {
    selectedItem.value = event;
  }

  inputHandler(selectedItem.value);
};
const reset = (event: boolean) => {
  emit("reset", event);

};
const inputHandler = async (value: any, isFirst = false) => {
  if (!isFirst) {
    touched();
    emit("emitSelected", value);
    emit("update:isTouched", true)
  }
  emit("isValid", validation.value.isValid);
};
const touched = () => {
  initial.value = true;
};
watch(
  props,
  (value) => {
    initialize();
  },
  { deep: true }
);

initialize();
</script>

<template>
  <div>
    <SelectDropdown
      :selected="selected"
      :radiusClass="radiusClass"
      :multiple="multiple"
      :data="items"
      :label="label"
      :optionId="dropdownId"
      :selector="selector"
      @emitSelected="catchEmit"
      @emitMultiple="catchEmit"
      @reset="reset($event)"
      :preselect="preselect"
      :bgClass="bgClass"
      :testId="testId"
      :borderClass="borderClass"
      :textCutBorder="textCutBorder"
      :arrowIsVisible="arrowIsVisible"
      :resetIsVisible="resetIsVisible"
    />
    <div
      v-if="!validation.isValid && initial"
      class="text-danger"
      :data-testid="testId ? `validation-${testId}` : null"
    >
      {{ validation.message }}
    </div>
    <FormWarningComponent
          v-if="isTouched && !validation.isValid"
          :text="translate('globalNotificationMessages.required_dropdown')"
        />
  </div>
</template>
