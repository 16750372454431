<script setup lang="ts">
import { useBalanceStore } from "@/stores/balance";
import { useAuthStore } from "@/stores/auth";
import { computed } from "vue";
import IconComponent from "@/components/icons/IconComponent.vue";

const props = defineProps({
  props: Boolean,
});

const balanceStore = useBalanceStore();
const authStore = useAuthStore();

const playerData = computed(() => authStore.getPlayer);
const casinoBalance = computed(() => balanceStore.getBalance("casino"));
const sportbookBalance = computed(() => balanceStore.getBalance("sportbook"));
</script>
<template>
  <div>
    <div class="flex txt-theme-txt-2 fit-content mgx-auto mgy-4" v-if="props.props">
      <IconComponent
        :iconClasses="`icon-20 icon-inline align-bottom bg-theme-txt-2 icon-soccer`"
      />
      <p>Hola, {{ playerData ? playerData.firstName : "Unknown" }}</p>
    </div>
    <div class="bg-theme300 profile-card-mobile radius-30 mgy-4 txt-theme850">
      <div class="space-between flex vertical-center pdx-6">
        <p>Casino:</p>
        <p>{{ balanceStore.getBalance("casino") }}.00$</p>
      </div>
    </div>
    <div class="bg-theme300 profile-card-mobile radius-30 txt-theme850">
      <div class="space-between flex vertical-center pdx-6">
        <p>SportsBook:</p>
        <p>{{ balanceStore.getBalance("sportbook") }}.00$</p>
      </div>
    </div>
  </div>
</template>
