<script setup>
import { ref, watch } from "vue";
import DividerComponent from "../../shared/divider/DividerComponent.vue";

const props = defineProps({
  tableData: Array,
  headers: Array,

  testId: String,
});

const localData = ref();

const init = () => {
  localData.value = props.tableData;
};

init();

watch(
  props,
  () => {
    init();
  },
  { deep: true }
);
</script>

<template>
  <table class="mg-center text-smallest">
    <tr class="table-border">
      <th class="pda-3" v-for="header in headers" :key="header">{{ header }}</th>
    </tr>

    <tr class="table-border" v-for="(item, i) in localData" :key="i">
      <td
        :data-testid="testId ? `row-${header}-${testId}` : null"
        class="pda-3"
        v-for="header in headers"
        :key="header"
      >
        {{ item[header] }}
      </td>
    </tr>
  </table>
</template>

<style lang="scss">
.table-border {
  border-top: 2px solid #a8a8a8;
}
table {
  border-collapse: collapse;
}
</style>
