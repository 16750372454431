<script setup lang="ts">
import { translate } from "@/services/translations/translation";
import { useLayoutModalsStore } from "@/stores/layoutModals";
import { ref } from "vue";
import InputValueComponent from "@/brands/holahola/components/shared/forms/input/InputValueComponent.vue";
import IconComponent from "@/components/icons/IconComponent.vue";
import { goToRoute } from "@/router/routingService";
import axios from "axios";
import { ErrorHandler } from "@/lib/exceptions/ErrorHandler";
import { computeFormValidation } from "@/services/validation/formValidation";

const layoutModalsStore = useLayoutModalsStore();
const emailSent = ref(false);
const loader = ref(false);
const errorMessage = ref();
const email = ref();

const close = () => {
  /* layoutModalsStore.closeModal("auth.emailPasswordReset"); */
  goToRoute("/login")
};

const submit = async () => {
  loader.value = true;
  let key: ValidationKey;
  for (key in validationList.value) {
    validationList.value[key].isTouched = true;
  }
  if (formisValid.value) {
  emailSent.value = false;
  errorMessage.value = null;
  
  const body = {
    email: email.value,
  };
  await axios
    .post("/api/auth/forgot-password/send-email", body)
    .then(() => {
      emailSent.value = true;
    })
    .catch((error) => {
      const err = new ErrorHandler();
      const errObj = err.handle(error);
      errorMessage.value = errObj.message;
    });
  }
  loader.value = false;
};
// VALIDATION START
const validationList = ref({
  email: { isTouched: false, valid: false },
});
type ValidationKey = keyof typeof validationList.value;

const formisValid = ref<boolean>(false);
const callbackValid = (pointer: ValidationKey, value: boolean) => {
  validationList.value[pointer].valid = value;
  computeFormValidation(validationList.value, formisValid);
};
// VALIDATION END
</script>
<template>
  <div>
    <div class="backdrop" @click="close"></div>
    <div class="modal-Centered bg-linear-1-1-1-5 zindex-modal radius-15 pdy-5 pdx-5 minw-400px">
      <div type="" class="flex flex-right">
        <IconComponent
          :iconClasses="'svg-icon block icon-16 icon-form-clear bg-4-1 cursor-pointer '"
          @click="close"
        ></IconComponent>
      </div>
      <div v-if="!emailSent">
        <h1 class="title-large mgt-5 txt-theme-txt-1 txt-bold mgx-auto fit-content">
          {{ translate("auth.emailPasswordReset.desktop.title") }}
        </h1>

        <InputValueComponent
          inputId="email"
          :placeholder="translate('auth.emailPasswordReset.desktop.email')"
          :inputType="'text'"
          v-model="email"
          :minLength="0"
          :maxLength="255"
          textCutBorder="label-cut bg-1-3 border-top-1-2 border-width-1"
          textCutBorderFocused="label-cut bg-1-3 border-top-1-2 border-width-1"
          bgColor="bg-1-3 radius-26"
          :borderClass="' radius-26 border-1-2 border-width-1'"
          v-model:isTouched="validationList.email.isTouched"
          @isValid="callbackValid('email', $event)"
        ></InputValueComponent>

        <p
          v-if="errorMessage"
          class="txt-bold txt-error cursor-pointer text-smallest mgy-5"
        >
          {{ errorMessage }}
        </p>

        <button class="mgx-auto bg-2-1 radius-10 w-150px h-50px font-Poppins txt-size-14 txt-weight-600 txt-light mgt-6" @click="submit">
          {{ translate("auth.emailPasswordReset.desktop.submit") }}
        </button>
      </div>

      <div class="form-size" v-else>
        <div class="reset_password_msg pda-4">
          <IconComponent
            :iconClasses="'svg-icon block icon-90 mgx-auto icon-success bg-success'"
          ></IconComponent>
          <h1 class="title-large mgt-5 txt-theme-txt-1 txt-bold mgx-auto fit-content">
            {{ translate("auth.emailPasswordReset.desktop.title_reset") }}
          </h1>
          <p
            class="flex flex-center pda-4 txt-theme-txt-2 mgx-auto fit-content text-smallest txt-center"
          >
            {{ translate("auth.emailPasswordReset.desktop.content_reset") }}
          </p>
          <p
            class="flex flex-center pda-4 txt-theme-txt-2 mgx-auto fit-content text-smallest"
          >
            {{ translate("auth.emailPasswordReset.desktop.info_reset") }}
          </p>
        </div>

        <div class="row">
          <div class="col-6 txt-center pdx-3">
            <button
              id="login"
              class="btn-auth txt-theme-txt-2 pdx-5 bg-success txt-theme-bg-1"
              @click="submit"
            >
              {{
                translate("auth.emailPasswordReset.desktop.submit_resend").toUpperCase()
              }}
            </button>
          </div>

          <div class="col-6 txt-center pdx-3">
            <button
              id="register"
              class="btn-auth bg-theme-brand-2 txt-theme-bg-1 pdx-5"
              @click="emailSent = false"
            >
              {{
                translate(
                  "auth.emailPasswordReset.desktop.submit_changeEmail"
                ).toUpperCase()
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
