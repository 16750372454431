interface NavItem {
  icon: string | null;
  name: string;
  url: string;
  id: string;
  hide?: boolean;
  children: Array<NavItem>;
}

export const desktopHeaderLinks = [
  {
    group: "play",
    icon: "casino",
    translation: "navigation.play",
    linkGroup: [
      {
        group: "play",
        translation: "navigation.sportsbook",
        link: "/sportsbook",
        icon: "slot",
        iconAlt: "Icon placeholder",
        linkText: "SportsBook",
        linkGroup: [] ,
      },
      {
        group: "play",
        link: "/casino",
        translation: "navigation.casino",
        icon: "casino",
        iconAlt: "Icon casino",
        linkText: "Casino",
        linkGroup: [],
      },
      {
        group: "play",
        link: "/livecasino",
        translation: "navigation.livecasino",
        icon: "live-casino",
        iconAlt: "Icon live casino",
        linkText: "Live Casino",
        linkGroup: [],
      },
    ],
  },

  {
    group: "Transactions",
    translation: "navigation.transactions",
    icon: "soccer",
    linkGroup: [
      {
        group: "transactions",
        link: "/deposit",
        icon: "gift",
        iconAlt: "Icon gift",
        translation: "navigation.deposit",
        linkText: "Deposit",
        linkGroup: [],
      },
      {
        group: "transactions",
        link: "/withdraw",
        translation: "navigation.withdrawal",
        icon: "gift",
        iconAlt: "Icon gift",
        linkText: "Withdrawal",
        linkGroup: [],
      },
      {
        group: "transactions",
        link: "/transfer",
        translation: "navigation.transfer",
        icon: "gift",
        iconAlt: "Icon gift",
        linkText: "Transfer",
        linkGroup: [],
      },
    ],
  },
  {
    group: "Account",
    translation: "navigation.account",
    icon: "soccer",
    linkGroup: [
      {
        group: "Account",
        link: "/profile",
        icon: "gift",
        translation: "navigation.profile",
        iconAlt: "Icon gift",
        linkText: "Profile",
        linkGroup: [],
      },
      {
        group: "Account",
        link: "/personal-information",
        icon: "gift",
        iconAlt: "Icon gift",
        translation: "navigation.personalInfo",
        linkText: "Personal Information",
        linkGroup: [],
      },
      {
        group: "Account",
        link: "/change-password",
        icon: "gift",
        translation: "navigation.changePass",
        iconAlt: "Icon gift",
        linkText: "Change Password",
        linkGroup: [],
      },
      {
        group: "Account",
        link: "/limits",
        icon: "gift",
        translation: "navigation.protectionSettings",
        iconAlt: "Icon gift",
        linkText: "Protection Settings",
        linkGroup: [],
      },

      {
        group: "Account",
        link: "/kyc",
        icon: "gift",
        translation: "navigation.kyc",
        iconAlt: "Icon gift",
        linkText: "KYC",
        linkGroup: [],
      },
    ],
  },
  {
    group: "Promotions",
    icon: "soccer",
    translation: "navigation.promotions",
    linkGroup: [
      {
        group: "promotions",
        link: "/promotions",
        icon: "gift",
        iconAlt: "Icon gift",
        translation: "navigation.promotions",
        linkText: "Promotions",
        linkGroup: [],
      },
    ],
  },
  {
    group: "History",
    icon: "gift",
    translation: "navigation.history",
    linkGroup: [
      {
        group: "history",
        link: "/bets-history",
        icon: "gift",
        iconAlt: "Icon gift",
        translation: "navigation.betsHistory",
        linkText: "Bets History",
      },
      {
        group: "history",
        link: "/bonus-history",
        icon: "gift",
        iconAlt: "Icon gift",
        translation: "navigation.bonusHistory",
        linkText: "Bonus History",
      },
      {
        group: "history",
        link: "/transaction-history",
        icon: "gift",
        iconAlt: "Icon gift",
        translation: "navigation.transactionHistory",
        linkText: "Transaction History",
      },
    ],
  },
];

export const headerLinks = [
  {
    group: "play",
    icon: "casino",
    translation: "navigation.play",
    linkGroup: [
      {
        group: "play",
        link: "/sportsbook",
        icon: "slot",
        iconAlt: "Icon placeholder",
        linkText: "SportsBook",
        translation: "navigation.sportsbook",
        linkGroup: [],
      },
      {
        group: "play",
        link: "/casino",
        icon: "casino",
        iconAlt: "Icon casino",
        linkText: "Casino",
        translation: "navigation.casino",
        linkGroup: [],
      },
      {
        group: "play",
        link: "/livecasino",
        icon: "live-casino",
        iconAlt: "Icon live casino",
        linkText: "Live Casino",
        translation: "navigation.livecasino",
        linkGroup: [],
      },
    ],
  },

  {
    group: "Transactions",
    icon: "soccer",
    translation: "navigation.transactions",
    linkGroup: [
      {
        group: "transactions",
        link: "/deposit",
        icon: "gift",
        iconAlt: "Icon gift",
        translation: "navigation.deposit",
        linkText: "Deposit",
        linkGroup: [],
      },
      {
        group: "transactions",
        link: "/withdraw",
        icon: "gift",
        iconAlt: "Icon gift",
        translation: "navigation.withdrawal",
        linkText: "Withdrawal",
        linkGroup: [],
      },
      {
        group: "transactions",
        link: "/transfer",
        icon: "gift",
        iconAlt: "Icon gift",
        translation: "navigation.transfer",
        linkText: "Transfer",
        linkGroup: [],
      },
    ],
  },
  {
    group: "Account",
    icon: "soccer",
    translation: "navigation.account",
    linkGroup: [
      {
        group: "Account",
        link: "/profile",
        icon: "gift",
        iconAlt: "Icon gift",
        linkText: "Profile",
        translation: "navigation.profile",
        linkGroup: [],
      },
      {
        group: "Account",
        link: "/personal-information",
        icon: "gift",
        iconAlt: "Icon gift",
        linkText: "Personal Information",
        translation: "navigation.personalInfo",
        linkGroup: [],
      },
      {
        group: "Account",
        link: "/change-password",
        icon: "gift",
        iconAlt: "Icon gift",
        linkText: "Change Password",
        translation: "navigation.changePass",
        linkGroup: [],
      },
      {
        group: "Account",
        link: "/limits",
        icon: "gift",
        iconAlt: "Icon gift",
        linkText: "Protection Settings",
        translation: "navigation.protectionSettings",
        linkGroup: [],
      },

      {
        group: "Account",
        link: "/kyc",
        icon: "gift",
        iconAlt: "Icon gift",
        linkText: "KYC",
        translation: "navigation.kyc",
        linkGroup: [],
      },
      {
        group: "Account",
        icon: "gift",
        link: "/bets-history",
        iconAlt: "Icon gift",

        linkText: "History",
        translation: "navigation.history",
        linkGroup: [
          {
            group: "history",
            link: "/bets-history",
            icon: "gift",
            iconAlt: "Icon gift",
            linkText: "Bets History",
            translation: "navigation.betsHistory",
          },
          {
            group: "history",
            link: "/bonus-history",
            icon: "gift",
            iconAlt: "Icon gift",
            linkText: "Bonus History",
            translation: "navigation.bonusHistory",
          },
          {
            group: "history",
            link: "/transaction-history",
            icon: "gift",
            iconAlt: "Icon gift",
            linkText: "Transaction History",
            translation: "navigation.transactionHistory",
          },
        ],
      },
    ],
  },
  {
    group: "Promotions",
    icon: "soccer",
    translation: "navigation.promotions",
    linkGroup: [
      {
        group: "promotions",
        link: "/promotions",
        icon: "gift",
        iconAlt: "Icon gift",
        linkText: "Promotions",
        translation: "navigation.promotions",
        linkGroup: [],
      },
    ],
  },
];

export const helpLinks = [
  {
    routerLink: "",
    icon: "placeholder",
    iconAlt: "Icon FAQ",
    linkText: "FAQ",
  },
  {
    routerLink: "support",
    icon: "placeholder",
    iconAlt: "Icon Support",
    linkText: "support",
  },
  {
    routerLink: "",
    icon: "placeholder",
    iconAlt: "Icon chat",
    linkText: "chat",
  },
];

export const bottomLinks = [
  {
    routerLink: "",
    icon: "placeholder",
    iconAlt: "Icon home",
    linkText: "Home",
  },
  {
    routerLink: "",
    icon: "soccer",
    iconAlt: "Icon sport",
    linkText: "Sport",
  },
  {
    routerLink: "casino",
    icon: "casino",
    iconAlt: "Icon casino",
    linkText: "Casino",
  },
  {
    routerLink: "",
    icon: "percent",
    iconAlt: "Icon percent",
    linkText: "Promo",
  },
];
