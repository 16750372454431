<script setup lang="ts">
const props = defineProps({
  bgClass: String,
  iconClass: String,
  textColor: String,
  title: String,
  titleClass: String,
  messageBody: String,
  info: Boolean,
  timer: Number,
  exitClass: String,
});
const emit = defineEmits(["emitDismiss"]);

const dismiss = () => {
  emit("emitDismiss", true);
};

if (props.timer) {
  setTimeout(() => {
    dismiss();
  }, props.timer);
}
</script>

<template>
  <div :class="bgClass" class="radius-8">
    <div class="flex flex-center">
      <div :class="iconClass"></div>
      <div class="w-100 txt-center">
        <h6 :class="titleClass">
          {{ title }}
        </h6>
        <p :class="textColor">{{ messageBody }}</p>
      </div>
      <div
      :class="
        exitClass
          ? exitClass
          : 'bg-2-2 icon-form-clear bg-theme-txt-1 icon-24  mga-5 cursor-pointer'
      "
      @click="dismiss"
    ></div>
    </div>
   
  </div>
</template>
