import type {
  DepositHandler,
  HandlerResponse,
} from "@/services/payment/depositAbstract/handleDeposit";

export class NoAdditionalDataHandler implements DepositHandler {
  public async handleDeposit(): Promise<HandlerResponse> {
    //
    return {
      isAdditionalData: false,
      isError: false,
      responseMessage: "success",
    };
  }
}

export class AdditionalDataHandler implements DepositHandler {
  private additionalDataSelector: string;
  constructor(selector: string) {
    this.additionalDataSelector = selector;
  }
  public async handleDeposit(): Promise<HandlerResponse> {
    //
    return {
      isAdditionalData: true,
      additionalDataSelector: this.additionalDataSelector,
      isError: false,
      responseMessage: "success",
    };
  }
}

export class NotImplemented implements DepositHandler {
  public async handleDeposit(): Promise<HandlerResponse> {
    //
    return {
      isAdditionalData: false,
      isError: true,
      responseMessage: "Payment not supported.",
    };
  }
}
