<script setup lang="ts">
import IconComponent from "@/components/icons/IconComponent.vue";
import { ref } from "vue";
import { profileNavigation } from "@/brands/bit1111/data/navigation/profile";
import { useAuthStore } from "@/stores/auth";
import { RouterSingleton } from "@/router/index";
import { translate } from "@/services/translations/translation";
import type {
  Item,
  ItemChild,
} from "@/brands/holahola/interface/userAccountSideMenuInterface";
import { links } from "@/services/data/userAccountSideMenuLinks";
import ButtonComponent from "../shared/buttons/ButtonComponent.vue";

const store = useAuthStore();

const route = RouterSingleton.getInstance().getRouter().currentRoute.value.path;

const items = ref<Array<Item>>(links);

const logout = () => {
  store.logout();
};
items.value.forEach((item) => {
  profileNavigation.forEach((element) => {
    if (translate(item.name) === element.group) {
      item.children = element.linkGroup;
      item.children.forEach((child) => {
        child.active = false;
      });
    }
  });

  //setting active parent and child when .link == route
  for (let i = 0; i < items.value.length; i++) {
    for (let j = 0; j < items.value[i].children.length; j++) {
      if (items.value[i].children[j].link == route) {
        items.value[i].children[j].active = true;
        items.value[i].active = true;
      }
    }
  }
});

const handleParent = (item: Item) => {
  items.value.forEach((element) => {
    if (element.id == item.id) {
      item.active = !item.active;
    } else {
      element.active = false;
    }
  });
};

const handleChild = (item: Item, child: ItemChild) => {
  item.children.forEach((element) => {
    if (element.link == child.link) {
      element.active = true;
    } else {
      element.active = false;
    }
  });
};
</script>

<template>
  <div
    class="bg-4-6 user-account-side-menu-wrapper w-240px h-920px relative radius-8 pdx-6 pdy-7 mgb-10"
  >
    <div class="grid">
      <span class="txt-size-25 txt-weight-600 txt-1-1 text-height-medium-small">{{
        store.$state.player?.firstName
      }}</span>
      <span class="txt-size-25 txt-weight-600 txt-1-1">{{
        store.$state.player?.lastName
      }}</span>
      <span class="txt-light email-text txt-size-12">{{
        store.$state.player?.email
      }}</span>
    </div>
    <div class="" v-for="(item, i) in items" :key="i">
      <RouterLink :to="item.link">
        <ButtonComponent
          v-if="item.active"
          class="mgy-4"
          type="primaryVariantFilled"
          :heightPx="42"
          @click="handleParent(item)"
        >
          <span class="pdl-2 w-100 flex flex-left-center">
            <IconComponent
              class="pdx-5 mgl-1 cursor-pointer mgr-2"
              :iconClasses="`svg-icon icon-18 scale-1-4 inline-block bg-1-5 icon-${item.icon}`"
            ></IconComponent>
            <p class="title-small">{{ translate(item.name) }}</p>
            <span class="w-100">
              <IconComponent
                class="pdx-5 mgl-1 cursor-pointer float-right mgr-2"
                :iconClasses="`svg-icon icon-18 scale-0-7 inline-block bg-1-5 icon-dropdown`"
              ></IconComponent> </span></span
        ></ButtonComponent>
        <ButtonComponent
          v-else
          class="mgy-4"
          type="primaryFilled"
          :heightPx="42"
          @click="handleParent(item)"
        >
          <span class="pdl-2 w-100 flex flex-left-center">
            <IconComponent
              class="pdx-5 mgl-1 cursor-pointer mgr-2"
              :iconClasses="`svg-icon icon-18 scale-1-4 inline-block bg-light icon-${item.icon}`"
            ></IconComponent>
            <p class="title-small">{{ translate(item.name) }}</p>
            <span class="w-100">
              <IconComponent
                class="pdx-5 mgl-1 cursor-pointer float-right mgr-2"
                :iconClasses="`svg-icon icon-18 rotate-right-90 inline-block bg-light icon-dropdown`"
              ></IconComponent> </span></span
        ></ButtonComponent>
      </RouterLink>
      <span v-if="item.active">
        <span
          v-for="(child, j) in item.children"
          :key="j"
          @click="handleChild(item, child)"
          class="flex flex-right-center"
        >
          <RouterLink :to="child.link">
            <ButtonComponent
              v-if="child.active"
              class="flex flex-left-center mgy-2 pdl-5"
              :width-px="180"
              :height-px="42"
              type="primaryVariantFilled"
              :text="child.linkText"
            />
            <ButtonComponent
              v-else
              class="flex flex-left-center mgy-2 pdl-5"
              :width-px="180"
              :height-px="42"
              type="primaryFilled"
              :text="child.linkText"
            />
          </RouterLink>
        </span>
      </span>
    </div>
    <p @click="logout()" class="cursor-pointer txt-error-1 title-small">
      {{ translate("account.user_account_menu.desktop.logout") }}
    </p>
  </div>
</template>
