import { useAuthStore } from "@/stores/auth";
import { EventSourcePolyfill } from "event-source-polyfill";
import type { MercureEventListener } from "@/eventListeners/EventListenerInterface";
import { addEventListener } from "./addEventListener";

export class SSEClient {
  url: URL;
  eventName: string;
  topic: string;
  source: EventSource | EventSourcePolyfill | null;
  listeners: Array<(event: MessageEvent) => void> = [];
  withCredentials: any;
  constructor(event: MercureEventListener) {
    this.url = new URL(import.meta.env.VITE_SSE_MERCURE);
    this.source = null;
    this.listeners.push(event.listener);
    this.eventName = event.on();
    this.topic = event.topic();
  }
  listen(param: string, callback: (event: any) => void) {
    this.url.searchParams.append("topic", param);
    this.source = new EventSource(this.url, {
      withCredentials: this.withCredentials,
    });
    this.source.addEventListener(this.eventName, callback);
  }
  subscribe() {
    const authStore = useAuthStore();
    const player = authStore.$state.player;
    this.url.searchParams.append("topic", this.topic);
    return new Promise((resolve, reject) => {
      this.source = new EventSourcePolyfill(this.url.href, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${player?.mercure}`,
        },
        heartbeatTimeout: 240000,
      });
      this.source.onopen = () => {
        // Add event listeners that were added before we connected
        for (const listener of this.listeners) {
          addEventListener(this.source, this.eventName, listener);
        }

        this.source!.onerror = null;

        resolve(this);
      };

      this.source.onerror = reject;
    });
  }
  subscribePublic() {
    this.url.searchParams.append("topic", this.topic);
    this.source = new EventSource(this.url, {
      withCredentials: false,
    });
    for (const listener of this.listeners) {
      this.source?.addEventListener(this.eventName, listener);
    }
  }
  unsubscribe() {
    if (this.source !== null) {
      this.source.close();
      this.source = null;
    }
  }
}
