<script setup lang="ts">
import InputValueComponent from "@/components/shared/forms/input/InputValueComponent.vue";
import { useLoaderStore } from "@/stores/loader";
import { ref, computed, onUnmounted } from "vue";
import { axiosPost } from "@/services/axios/crudOperations";
import DropDownComponent from "@/components/shared/forms/dropdown/DropDownComponent.vue";
import { useWithdrawStore } from "@/stores/withdraw";
import iBanAdditionalDataFormComponent from "@/components/payment/iBanAdditionalDataFormComponent.vue";
import NotificationComponent from "@/components/shared/notification/NotificationComponent.vue";
import { useBalanceStore } from "@/stores/balance";
import { useBrandStore } from "@/stores/brand";
import { translate } from "@/services/translations/translation";

const balanceStore = useBalanceStore();
const brandStore = useBrandStore();
const loader = useLoaderStore();
const depositAmount = ref();
const providers = ref();
const wallets = ref();
const selectedWallet = ref();
const currency = ref();
// balanceStore.loadBalance();
const finishedLoading = ref(false);
const routeTranslation = ref();
const balance = computed(() =>
  balanceStore.getBalance(
    selectedWallet.value ? selectedWallet.value.name.toLowerCase() : "casino"
  )
);
const allowedPayout = computed(() =>
  balanceStore.getAllowedPayout(
    selectedWallet.value ? selectedWallet.value.name.toLowerCase() : "casino"
  )
);
const brandName = ref();

interface SelectedPiQ {
  provider: string;
  supplier: string;
}

const selectedPiQ = ref<SelectedPiQ>({
  provider: "",
  supplier: "",
});

const withdrawStore = useWithdrawStore();
const withdrawStage = computed(() => withdrawStore.getWithdrawStage);

const withdraw = () => {
  brandName.value = brandStore.getName;
  const host = window.location.origin;

  const data = {
    supplier: selectedPiQ.value.supplier,
    provider: selectedPiQ.value.provider,
    amount: parseFloat(depositAmount.value),
    walletType: selectedWallet.value.id,
    paymentType: "withdrawal",
    redirectUrls: {
      pending: `${host}/deposit-redirect?status=pend`,
      success: `${host}/deposit-redirect?status=success`,
      error: `${host}/deposit-redirect?status=err`,
    },
  };
  withdrawStore.setWithdrawData(data);
  withdrawStore.setWithdrawStage(1);
};

const withdrawAgain = () => {
  withdrawStore.setWithdrawStage(0);
};
const loadWalletData = async (walletType: number) => {
  loader.toggleLoader(true);

  const body = {
    paymentType: "withdrawal",
    walletType: walletType,
  };
  const response = await axiosPost("/payment/methods", body);
  if (response) {
    const { data } = response.data;
    wallets.value = data.wallet.options;
    providers.value = data.methods;
    currency.value = data.currency;
  }
  loader.toggleLoader(false);
};

onUnmounted(() => {
  withdrawStore.setWithdrawStage(0);
});

const init = async () => {
  await loadWalletData(1);
  selectedWallet.value = wallets.value[0];
  finishedLoading.value = true;
};

const setPiq = (event: SelectedPiQ) => {
  selectedPiQ.value = event;
};
const setWallet = (event: number) => {
  selectedWallet.value = event;
};
init();
</script>

<template>
  <div class="form-size pdt-2 pdx-5 txt-theme-txt-2" v-if="finishedLoading">
    <h1 class="txt-center txt-theme-brand-1">
      {{ translate("transactions.withdrawal.mobile.withdrawal") }}
    </h1>
    <div v-if="withdrawStage == 0">
      <p class="text-smallest mgb-5">
        {{ translate("transactions.withdrawal.mobile.description") }}
      </p>
      <div class="space-between flex">
        <p class="txt-bold">
          {{ translate("transactions.withdrawal.mobile.current_balance") }}
        </p>
        <p id="balance" class="txt-theme-brand-1">
          {{ currency.symbol }}
          {{
            balance?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          }}
        </p>
      </div>
      <div class="space-between flex">
        <p class="txt-bold">
          {{ translate("transactions.withdrawal.mobile.max_withdrawal") }}
        </p>
        <p id="maxAmount" class="txt-theme-brand-1">
          {{ currency.symbol }}
          {{
            allowedPayout?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          }}
        </p>
      </div>
      <DropDownComponent
        dropdownId="method"
        :label="translate('transactions.withdrawal.mobile.preffered_method')"
        :options="providers"
        :selector="'provider'"
        @emitSelected="setPiq"
      />
      <DropDownComponent
        dropdownId="wallet"
        :label="translate('transactions.withdrawal.mobile.select_wallet')"
        :options="wallets"
        :selector="'name'"
        @emitSelected="setWallet"
      />
      <InputValueComponent
        inputId="amount"
        :placeholder="translate('transactions.withdrawal.mobile.withdraw_amount')"
        v-model="depositAmount"
        :regexValidator="'decimal'"
      ></InputValueComponent>
      <button
        id="submit"
        class="btn-large btn-brand-light mgx-auto mgy-6 txt-bold p-0"
        @click="withdraw"
        :disabled="
          selectedPiQ.provider != 'bankiban' || !depositAmount || !selectedWallet
        "
      >
        {{ translate("transactions.withdrawal.mobile.submit") }}
      </button>
    </div>
    <iBanAdditionalDataFormComponent
      v-if="withdrawStage == 1 && selectedPiQ.provider == 'bankiban'"
    />
    <div id="success" v-if="withdrawStage == 2">
      <NotificationComponent
        :title="'Withdrawal request sent'"
        :messageBody="'We have successfully recieved your withdrawal request and will be processing it as soon as possible'"
        :bgClass="'bg-success'"
        :iconClass="'success'"
        :textColor="'txt-theme-bg-4deposit-redirect'"
      />

      <button
        id="submit"
        class="btn-large btn-brand-light mgx-auto mgy-6 txt-bold p-0"
        @click="withdrawAgain"
      >
        {{ translate("transactions.withdrawal.mobile.again").toUpperCase() }}
      </button>
    </div>
  </div>
</template>
