<script setup lang="ts">
import type { PropType } from "vue";
import { useBonusesStore } from "@/stores/bonuses";
import { isMobileCheck } from "@/services/interface/clientInformation";
import ButtonComponent from "@/brands/bit1111/components/shared/buttons/ButtonComponent.vue";
import { translate } from "@/services/translations/translation";
import type { BonusInterface } from "@/services/interface/bonuses";

const props = defineProps({
  bonus: Object as PropType<BonusInterface>
})

const isMobile = isMobileCheck();
const bonusesStore = useBonusesStore();

const emit = defineEmits(["close"])

const bonusAnswer = (bonusId: number, value?: number) => {
  if(value){
    bonusesStore.acceptOrDeclineOrClaimLaterBonus(bonusId, value);
  }  
  emit("close")
};
</script>

<template>
  <div class="backdrop">
    <div
      class="modal-bg relative border-piq radius-12"
      :class="isMobile ? 'sign-up-wrapper-mobile' : 'sign-up-wrapper'"
    >
      <div class="mgt-7">
        <h3 class="txt-center">
          {{ translate("common.bonus") }}:
          <span class="txt-1-1">{{ bonus?.bonusAward.bonus.name }}</span>
        </h3>
        <div class="space-between flex pdx-3 mgb-5">
          <p>{{ translate("common.amount") }}:</p>
          <p id="balance" class="txt-1-1 txt-bold">
            {{ bonus?.amount }} {{ bonus?.currencyCode }}
          </p>
        </div>
        <div v-if="bonus?.obtainableUntil" class="space-between flex pdx-3 mgb-5">
          <p>{{ translate("promotions.desktop.obtainableUntil") }}:</p>
          <p id="balance" class="txt-1-1 txt-bold">
            {{ bonus?.obtainableUntil }}
          </p>
        </div>
        <div class="space-evenly flex">
          <ButtonComponent
            class="mgx-3"
            text="Claim"
            @click="bonusAnswer(bonus!.id, 2)"
            :heightPx="38"
          />
          <ButtonComponent
            class="mgx-3"
            type="errorFilled"
            text="Decline"
            @click="bonusAnswer(bonus!.id, 5)"
            :heightPx="38"
          />
          <ButtonComponent
            class="mgx-3"
            type="secondaryFilled"
            text="Claim Later"
            @click="bonusAnswer(bonus!.id)"
            :heightPx="38"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.modal-bg{
  background: transparent linear-gradient(180deg, #23008A 0%, #000635 100%) 0% 0% no-repeat padding-box;
}
</style>
