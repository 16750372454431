<script setup lang="ts">
import { ref, onMounted } from "vue";
import { loadService } from "@/services/structure/serviceLoader";
import type { LooseObject } from "@/services/interface/utils";
import type { PropType } from "vue";

interface ButtonActionProps {
  action: {
    service: string;
    actions: string;
    params: any;
  };
  class: string;
  label: string;
}

const props = defineProps({
  props: {
    required: true,
    type: Object as PropType<ButtonActionProps>,
  },
});

const action = ref();
onMounted(() => {
  loadService(props.props.action.service).then((module: any) => {
    action.value = module[props.props.action.actions];
  });
});
</script>
<template>
  <div>
    <button :class="props.props.class" @click="action(props.props.action.params)">
      {{ props.props.label }}
    </button>
  </div>
</template>
