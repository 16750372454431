import { bannersFallback } from "@/services/data/bannersFallback";
import { defineStore } from "pinia";
import type { BannerInterface } from "@/services/interface/banner";

export const useBannerStore = defineStore({
  id: "banner",
  state: () => ({
    banners: <Array<BannerInterface>>[],
  }),
  actions: {
    async getBanners(tag: string) {
      let filteredPayload = this.banners.filter((banner) => banner.tag == tag);
      if (filteredPayload.length == 0) {
        filteredPayload = bannersFallback.filter((banner) => banner.tag == tag);
      }
      return filteredPayload;
    },
    async setBanners(banners: Array<BannerInterface>) {
      this.banners = banners;
  },
}
});
