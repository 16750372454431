import { validators } from "./regexValidators";
import { translate } from "../translations/translation";

export function validateInput(
  value: string,
  minLength: number,
  maxLength: number,
  regex: string,
  underaged?: boolean
) {
  type ObjectKey = keyof typeof validators;
  const regexKey = regex as ObjectKey;

  if (value) {
    if (value.length < minLength) {
      return { isValid: false, message: `Minimum length is ${minLength}` };
    }
    if (value.length > maxLength) {
      return { isValid: false, message: `Maximum length is ${maxLength}` };
    }
    if (underaged) {
      return { isValid: false, message: `You must be at least 18 years old.` };
    }
    if (regex) {
      if (!validators[regexKey].regex.test(value)) {
        return { isValid: false, message: translate(`globalErrorMessages.${validators[regexKey].message}`) };
      } else {
        return { isValid: true, message: "" };
      }
    } else {
      return { isValid: true, message: "" };
    }
  } else {
    return { isValid: false, message: "Input field is required" };
  }
}

export function validatePhoneInput(
  selectedCode: string,
  selectedPhoneNumber: number,
  codeMinLength: number,
  codeMaxLength: number,
  phoneMinLength: number,
  phoneMaxLength: number,
  codeRegexValidator: string,
  phoneRegexValidator: string
) {
  type ObjectKey = keyof typeof validators;
  const regexKeyCode = codeRegexValidator as ObjectKey;
  const regexKey = phoneRegexValidator as ObjectKey;
  
  if (selectedCode || selectedPhoneNumber) {
    
    if (selectedCode.length < codeMinLength) {
      return {
        isValid: false,
        message: `Minimum phone code length is ${codeMinLength}`,
      };
    }
    if (selectedCode.length > codeMaxLength) {
      return {
        isValid: false,
        message: `Maximum phone code length is ${codeMaxLength}`,
      };
    }
    if (selectedPhoneNumber.toString().length < phoneMinLength) {
      return {
        isValid: false,
        message: `Minimum phone number length is ${phoneMinLength}`,
      };
    }
    if (selectedPhoneNumber.toString().length > phoneMaxLength) {
      return {
        isValid: false,
        message: `Maximum phone number length is ${phoneMaxLength}`,
      };
    }
    
    if (codeRegexValidator) { 
      if (
        !validators[regexKeyCode].regex.test(
          selectedCode.toString()
        )
      ) {
        console.log(regexKey)
        return { isValid: false, message: validators[regexKeyCode].message };
      } else {
        return { isValid: true, message: "" };
      }
    }
    if (phoneRegexValidator) { 
      if (
        !validators[regexKey].regex.test(
          selectedPhoneNumber.toString()
        )
      ) {
        return { isValid: false, message: validators[regexKey].message };
      } else {
        return { isValid: true, message: "" };
      }
    } 
  } else {
    return { isValid: false, message: "Input field is required" };
  }
}
