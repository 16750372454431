export const loadDefaultTranslation = () => {
  let activeLanguage = {
    id: 1,
    code: "en",
    name: "English",
  };
  const activeLanguageFromLocalStorage = JSON.parse(
    localStorage.getItem("activeLanguage") as any
  );
  if (activeLanguageFromLocalStorage) {
    activeLanguage = { ...activeLanguageFromLocalStorage };
  }
  return activeLanguage;
};
