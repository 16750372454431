<script setup lang="ts">
import { ref } from "vue";
import InputValueComponent from "@/components/shared/forms/input/InputValueComponent.vue";
import { useLoaderStore } from "@/stores/loader";
import type { Deposit } from "@/services/payment/depositAbstract/deposit";
import type { PropType } from "vue";
import type { InitiatorResponse } from "@/services/payment/depositAbstract/handleDeposit";

const props = defineProps({
  deposit: {
    required: true,
    type: Object as PropType<Deposit>,
  },
});

const loader = useLoaderStore();
const account = ref("netellertest1@bambora.com");
const initiatorResponse = ref<InitiatorResponse>();

const submit = async () => {
  loader.toggleLoader(true);
  const additionalData = {
    account: account.value,
  };

  await props.deposit.injectAdditionalData(additionalData);
  initiatorResponse.value = await props.deposit.initiateDeposit();
  loader.toggleLoader(false);
};
</script>

<template>
  <div class="form-size mgb-4">
    <div class="mgb-4 flex">
      <div :class="` txt-center card-deposit`">
        <img
          src="https://i.ibb.co/bRYJPP6/neteller-select.png"
          class="border-piq radius-6 cursor-pointer"
        />
      </div>
    </div>
    <div class="pdx-3 mgy-4">
      <InputValueComponent
        inputId="account"
        :placeholder="'Account'"
        v-model="account"
        :inputType="'text'"
      ></InputValueComponent>
    </div>
    <p v-if="initiatorResponse?.isError">{{ initiatorResponse?.responseMessage }}</p>

    <button id="submit" class="btn btn-success mgy-4 mgx-auto btn-large" @click="submit">
      SUBMIT
    </button>
  </div>
</template>
