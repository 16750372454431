<script setup lang="ts">
import { useLayoutModalsStore } from "@/stores/layoutModals";
const props = defineProps({
  selected: String,
});
const layoutStore = useLayoutModalsStore();

const changeAuthModal = (name: string) => {
  layoutStore.activateModal({ name: name });
};
</script>
<template>
  <div class="w-100 flex flex-center mgy-5 cursor-pointer">
    <div
      @click="changeAuthModal('loginMobile')"
      :class="selected === 'login' ? 'bg-1-1 txt-2-1' : 'bg-2-1  txt-1-1'"
      class="w-190px h-44px radius-top-left-10 border-width-1 border-2-1 flex flex-center"
    >
      <p class="txt-size-18">Login</p>
    </div>
    <div
      @click="changeAuthModal('registerMobile')"
      :class="selected === 'register' ? 'bg-1-1 txt-2-1' : 'bg-2-1 txt-1-1'"
      class="w-190px h-44px radius-top-right-10 border-width-1 border-2-1 flex flex-center"
    >
      <p class="txt-size-18">Register</p>
    </div>
  </div>
</template>
