<script setup lang="ts">
import { useRoute } from "vue-router";
import { ref, watch, onMounted } from "vue";
import { useAuthStore } from "@/stores/auth";
import LoaderVue from "@/components/shared/loader/LoaderComponent.vue";
import { useLoaderStore } from "@/stores/loader";
import eventRegister from "@/lib/mercury/eventRegister";
import HeaderBit1111 from "@/brands/bit1111/components/navigation/header/HeaderComponent.vue";
import BottomNav from "@/brands/bit1111/components/navigation/BottomNavigationComponent.vue";
import MobileNav from "@/brands/bit1111/components/navigation/MobileNavigation.vue";
import FooterBit1111 from "@/brands/bit1111/components/navigation/footer/FooterMobileComponent.vue";
import ModalsMobile from "./ModalsMobile.vue";

const emit = defineEmits(["finishedLoading"]);

onMounted(async () => {
  if (player !== null && player.role == "ROLE_PLAYER") {
    eventRegister.authorizationHook();
  }
  eventRegister.nonAuthorizationHook();
});

const loader = useLoaderStore();
const authStore = useAuthStore();
const route = useRoute();
const routeAuth = ref(false);
const player = authStore.$state.player;
const navigationToggle = ref(false);

const finishedLoading = () => {
  emit("finishedLoading");
};

watch(
  route,
  () => {
    routeAuth.value = ["login", "register"].includes(
      typeof route.name == "string" ? route.name : ""
    );
    window.scrollTo(0, 0);
  },
  { deep: true }
);
</script>

<template>
  <div id="layout-body" class="main flex flex-column">
    <LoaderVue v-if="loader.getIsLoading"></LoaderVue>
    <ModalsMobile />
    <HeaderBit1111
      @toggleNav="navigationToggle = !navigationToggle"
      :navigationFlag="navigationToggle"
      @finishedLoading="finishedLoading"
    />
    <div class="c-body">
      <MobileNav
        v-show="navigationToggle"
        @toggleMenu="navigationToggle = !navigationToggle"
      />
      <RouterView v-show="!navigationToggle" />
    </div>
    <BottomNav v-if="!authStore.player" @toggleMenu="navigationToggle = false" />
    <FooterBit1111 />
  </div>
</template>
