<script setup lang="ts">
import { useBrandStore } from "@/stores/brand";
import type { PropType } from "vue";

const brandStore = useBrandStore();

interface LogoPropsInterface {
  test: string;
}

const props = defineProps({
  props: {
    required: false,
    type: Object as PropType<LogoPropsInterface>,
  },
});
</script>

<template>
  <div class="logo-wrapper">
    <h1 v-if="props.props">{{ props.props.test ?? "nema props" }}</h1>
    <RouterLink :to="{ name: 'home' }">
      <img
        id="logo"
        class="logo-hola align-middle pdl-5"
        :src="brandStore.getLogo"
        :alt="brandStore.getName"
      />
    </RouterLink>
  </div>
</template>
<style scoped>
.logo-hola {
    width: auto;
    display: block;
    max-height: 33px;
    transition: all .2s ease-in-out;
}

.logo-hola:hover {
   transform: scale(0.9);
}
</style>