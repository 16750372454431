import { defineStore } from "pinia";
import { RouterSingleton } from "@/router/index";
import axios from "axios";
import { useBrandStore } from "@/stores/brand";

export const useTranslationStore = defineStore({
  id: "translation",
  state: () => ({
    translation: <any>null,
    translationFallBack: <any>null,
    language: {
      code: "en",
      name: "English",
      id: 1,
    },
  }),
  actions: {
    async loadTranslation(language: any) {
      this.language = language;
      //LOAD TRANSLATION BY LANGUAGE FROM API
      await axios
        .get(`translations/language/${language.id}`)
        .then((response) => {
          this.translation = response.data;
        })
        .catch((error) => {
          console.log("error loading translation: ", error);
          // loadedTranslation = translationJsonES;
        });
      const brandStore = useBrandStore();
      let module = null;
      try {
        module = await import(
          `@/brands/${brandStore.getFolder}/translations/translation_config.json`
        );
        this.translationFallBack = module;
      } catch (error) {
        console.log("error loading fallback translation file");
      }
    },
    async setTranslation(language: any) {
      localStorage.setItem("activeLanguage", JSON.stringify(language));
      await this.loadTranslation(language);
      await RouterSingleton.getInstance().reloadPage();
    },
    translateForRoute(route: string, platform: string) {
      return platform == "desktop"
        ? this.translation[route].desktop
        : this.translation[route].mobile;
    },
    translateValue(selector: string) {
      const selectors = selector.split(".");

      let translationPayload: any = "NO TRANSLATION";

      try {
        translationPayload = this.translation;
        selectors.forEach((sel: string) => {
          translationPayload = translationPayload[sel];
        });
      } catch (error) {
        translationPayload = this.translationFallBack;
        try {
          selectors.forEach((sel: string) => {
            translationPayload = translationPayload[sel];
          });
        } catch (err) {
          console.log("Fallback translation failed for selector: ", selector);
          translationPayload = "NO TRANSLATION";
        }
      }

      
      

      return translationPayload ?? "NO TRANSLATION";
    },
  },
});
