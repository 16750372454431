<script setup lang="ts">
import IconComponent from "@/components/icons/IconComponent.vue";
import { goToRoute } from "@/router/routingService";
import { ref, watch } from "vue";

const emit = defineEmits(["isValid"]);
const checked = ref<any>({
  checkedTerms: false,
  checkedConfirmAge: false,
});
const validate = ref(false);
emit("isValid", null);
const onCheckbox = (checkValue: string) => {
  checked[checkValue] = !checked[checkValue];

  for (const val in checked.value)
    if (!checked[val]) return (validate.value = false);

  return (validate.value = true);
};

watch(
  validate,
  (value) => {
    emit("isValid", value);
  },
  { deep: true }
);
</script>

<template>
  <div>
    <div class="checkbox-wrapper card-form-checkbox relative mgy-4">
      <label class="inline-block">
        <input
          type="checkbox"
          @change="onCheckbox('checkedTerms')"
          class="block"
          v-model="checked.checkedTerms"
          :value="checked.checkedTerms"
        />
        <span
          class="checkmark flex flex-center bg-theme-bg-1 border-input"
          tabindex="0"
        >
          <IconComponent
            v-if="checked.checkedTerms"
            :iconClasses="'icon-12 inline-block icon-checkbox bg-theme-bg-1'"
          ></IconComponent>
        </span>
        <span class="w-85"
          >By creating an account I agree with
          <a
            href="https://www.igaming.com/"
            class="txt-theme-brand-2"
            target="_blank"
            >iGaming.com</a
          >
          Marketing & Communication Agreement and General
          <a id="terms" target="_blank" class="txt-theme-brand-2" href="/terms"
            >Terms And Conditions</a
          >
        </span>
      </label>
    </div>

    <div class="checkbox-wrapper card-form-checkbox relative mgy-4">
      <label class="inline-block">
        <input
          type="checkbox"
          @change="onCheckbox('checkedConfirmAge')"
          class="block"
          v-model="checked.checkedConfirmAge"
          :value="checked.checkedConfirmAge"
        />
        <span
          class="checkmark flex flex-center bg-theme-bg-1 border-input"
          tabindex="0"
        >
          <IconComponent
            v-if="checked.checkedConfirmAge"
            :iconClasses="'icon-12 inline-block icon-checkbox bg-theme-bg-1'"
          ></IconComponent>
        </span>
        <span class="w-85">I confirm that I am over 18 years old</span>
      </label>
    </div>
  </div>
</template>
