<script setup lang="ts">
import eg_flag from "../../../assets/flags/eg.svg";
import ge_flag from "../../../assets/flags/ge.svg";
import ca_flag from "../../../assets/flags/ca.svg";
import af_flag from "../../../assets/flags/af.svg";
import ax_flag from "../../../assets/flags/ax.svg";
import al_flag from "../../../assets/flags/al.svg";
import dz_flag from "../../../assets/flags/dz.svg";
import as_flag from "../../../assets/flags/as.svg";
import ad_flag from "../../../assets/flags/ad.svg";
import ao_flag from "../../../assets/flags/ao.svg";
import ai_flag from "../../../assets/flags/ai.svg";
import aq_flag from "../../../assets/flags/aq.svg";
import ag_flag from "../../../assets/flags/ag.svg";
import ar_flag from "../../../assets/flags/ar.svg";
import am_flag from "../../../assets/flags/am.svg";
import aw_flag from "../../../assets/flags/aw.svg";
import au_flag from "../../../assets/flags/au.svg";
import at_flag from "../../../assets/flags/at.svg";
import az_flag from "../../../assets/flags/az.svg";
import bs_flag from "../../../assets/flags/bs.svg";
import bh_flag from "../../../assets/flags/bh.svg";
import bd_flag from "../../../assets/flags/bd.svg";
import bb_flag from "../../../assets/flags/bb.svg";
import by_flag from "../../../assets/flags/by.svg";
import be_flag from "../../../assets/flags/be.svg";
import bz_flag from "../../../assets/flags/bz.svg";
import bj_flag from "../../../assets/flags/bj.svg";
import bm_flag from "../../../assets/flags/bm.svg";
import bt_flag from "../../../assets/flags/bt.svg";
import bo_flag from "../../../assets/flags/bo.svg";
import bq_flag from "../../../assets/flags/bq.svg";
import ba_flag from "../../../assets/flags/ba.svg";
import bw_flag from "../../../assets/flags/bw.svg";
import bv_flag from "../../../assets/flags/bv.svg";
import br_flag from "../../../assets/flags/br.svg";
import io_flag from "../../../assets/flags/io.svg";
import bn_flag from "../../../assets/flags/bn.svg";
import bg_flag from "../../../assets/flags/bg.svg";
import bf_flag from "../../../assets/flags/bf.svg";
import bi_flag from "../../../assets/flags/bi.svg";
import cv_flag from "../../../assets/flags/cv.svg";
import kh_flag from "../../../assets/flags/kh.svg";
import cm_flag from "../../../assets/flags/cm.svg";
import ky_flag from "../../../assets/flags/ky.svg";
import cf_flag from "../../../assets/flags/cf.svg";
import td_flag from "../../../assets/flags/td.svg";
import cl_flag from "../../../assets/flags/cl.svg";
import cn_flag from "../../../assets/flags/cn.svg";
import cx_flag from "../../../assets/flags/cx.svg";
import cc_flag from "../../../assets/flags/cc.svg";
import co_flag from "../../../assets/flags/co.svg";
import km_flag from "../../../assets/flags/km.svg";
import ck_flag from "../../../assets/flags/ck.svg";
import cr_flag from "../../../assets/flags/cr.svg";
import hr_flag from "../../../assets/flags/hr.svg";
import cu_flag from "../../../assets/flags/cu.svg";
import cw_flag from "../../../assets/flags/cw.svg";
import cy_flag from "../../../assets/flags/cy.svg";
import cz_flag from "../../../assets/flags/cz.svg";
import ci_flag from "../../../assets/flags/ci.svg";
import cd_flag from "../../../assets/flags/cd.svg";
import dk_flag from "../../../assets/flags/dk.svg";
import dj_flag from "../../../assets/flags/dj.svg";
import dm_flag from "../../../assets/flags/dm.svg";
import do_flag from "../../../assets/flags/do.svg";
import ec_flag from "../../../assets/flags/ec.svg";
import eq_flag from "../../../assets/flags/eg.svg";
import sv_flag from "../../../assets/flags/sv.svg";
import gq_flag from "../../../assets/flags/gq.svg";
import er_flag from "../../../assets/flags/er.svg";
import ee_flag from "../../../assets/flags/ee.svg";
import sz_flag from "../../../assets/flags/sz.svg";
import et_flag from "../../../assets/flags/et.svg";
import fk_flag from "../../../assets/flags/fk.svg";
import fo_flag from "../../../assets/flags/fo.svg";
import fm_flag from "../../../assets/flags/fm.svg";
import fj_flag from "../../../assets/flags/fj.svg";
import fi_flag from "../../../assets/flags/fi.svg";
import fr_flag from "../../../assets/flags/fr.svg";
import gf_flag from "../../../assets/flags/gf.svg";
import pf_flag from "../../../assets/flags/pf.svg";
import tf_flag from "../../../assets/flags/tf.svg";
import ga_flag from "../../../assets/flags/ga.svg";
import gm_flag from "../../../assets/flags/gm.svg";
import qe_flag from "../../../assets/flags/ge.svg";
import de_flag from "../../../assets/flags/de.svg";
import gb_flag from "../../../assets/flags/gb.svg";
import gh_flag from "../../../assets/flags/gh.svg";
import gi_flag from "../../../assets/flags/gi.svg";
import gr_flag from "../../../assets/flags/gr.svg";
import gl_flag from "../../../assets/flags/gl.svg";
import gd_flag from "../../../assets/flags/gd.svg";
import gp_flag from "../../../assets/flags/gp.svg";
import gu_flag from "../../../assets/flags/gu.svg";
import gt_flag from "../../../assets/flags/gt.svg";
import gg_flag from "../../../assets/flags/gg.svg";
import gn_flag from "../../../assets/flags/gn.svg";
import gy_flag from "../../../assets/flags/gy.svg";
import gw_flag from "../../../assets/flags/gw.svg";
import ht_flag from "../../../assets/flags/ht.svg";
import hm_flag from "../../../assets/flags/hm.svg";
import va_flag from "../../../assets/flags/va.svg";
import hn_flag from "../../../assets/flags/hn.svg";
import hk_flag from "../../../assets/flags/hk.svg";
import hu_flag from "../../../assets/flags/hu.svg";
import is_flag from "../../../assets/flags/is.svg";
import in_flag from "../../../assets/flags/in.svg";
import id_flag from "../../../assets/flags/id.svg";
import ir_flag from "../../../assets/flags/ir.svg";
import iq_flag from "../../../assets/flags/iq.svg";
import ie_flag from "../../../assets/flags/ie.svg";
import im_flag from "../../../assets/flags/im.svg";
import il_flag from "../../../assets/flags/il.svg";
import it_flag from "../../../assets/flags/it.svg";
import jm_flag from "../../../assets/flags/jm.svg";
import jp_flag from "../../../assets/flags/jp.svg";
import je_flag from "../../../assets/flags/je.svg";
import jo_flag from "../../../assets/flags/jo.svg";
import kz_flag from "../../../assets/flags/kz.svg";
import ke_flag from "../../../assets/flags/ke.svg";
import ki_flag from "../../../assets/flags/ki.svg";
import kw_flag from "../../../assets/flags/kw.svg";
import kg_flag from "../../../assets/flags/kg.svg";
import la_flag from "../../../assets/flags/la.svg";
import lv_flag from "../../../assets/flags/lv.svg";
import lb_flag from "../../../assets/flags/lb.svg";
import ls_flag from "../../../assets/flags/ls.svg";
import lr_flag from "../../../assets/flags/lr.svg";
import ly_flag from "../../../assets/flags/ly.svg";
import li_flag from "../../../assets/flags/li.svg";
import lt_flag from "../../../assets/flags/lt.svg";
import lu_flag from "../../../assets/flags/lu.svg";
import mo_flag from "../../../assets/flags/mo.svg";
import mg_flag from "../../../assets/flags/mg.svg";
import mw_flag from "../../../assets/flags/mw.svg";
import my_flag from "../../../assets/flags/my.svg";
import mv_flag from "../../../assets/flags/mv.svg";
import ml_flag from "../../../assets/flags/ml.svg";
import mt_flag from "../../../assets/flags/mt.svg";
import mh_flag from "../../../assets/flags/mh.svg";
import mq_flag from "../../../assets/flags/mq.svg";
import mr_flag from "../../../assets/flags/mr.svg";
import mu_flag from "../../../assets/flags/mu.svg";
import yt_flag from "../../../assets/flags/yt.svg";
import mx_flag from "../../../assets/flags/mx.svg";
import md_flag from "../../../assets/flags/md.svg";
import mc_flag from "../../../assets/flags/mc.svg";
import mn_flag from "../../../assets/flags/mn.svg";
import me_flag from "../../../assets/flags/me.svg";
import ms_flag from "../../../assets/flags/ms.svg";
import ma_flag from "../../../assets/flags/ma.svg";
import mz_flag from "../../../assets/flags/mz.svg";
import mm_flag from "../../../assets/flags/mm.svg";
import na_flag from "../../../assets/flags/na.svg";
import nr_flag from "../../../assets/flags/nr.svg";
import np_flag from "../../../assets/flags/np.svg";
import nl_flag from "../../../assets/flags/nl.svg";
import nc_flag from "../../../assets/flags/nc.svg";
import nz_flag from "../../../assets/flags/nz.svg";
import ni_flag from "../../../assets/flags/ni.svg";
import ne_flag from "../../../assets/flags/ne.svg";
import ng_flag from "../../../assets/flags/ng.svg";
import nu_flag from "../../../assets/flags/nu.svg";
import nf_flag from "../../../assets/flags/nf.svg";
import kp_flag from "../../../assets/flags/kp.svg";
import mk_flag from "../../../assets/flags/mk.svg";
import no_flag from "../../../assets/flags/no.svg";
import mp_flag from "../../../assets/flags/mp.svg";
import om_flag from "../../../assets/flags/om.svg";
import pk_flag from "../../../assets/flags/pk.svg";
import pw_flag from "../../../assets/flags/pw.svg";
import pa_flag from "../../../assets/flags/pa.svg";
import pg_flag from "../../../assets/flags/pg.svg";
import py_flag from "../../../assets/flags/py.svg";
import pe_flag from "../../../assets/flags/pe.svg";
import ph_flag from "../../../assets/flags/ph.svg";
import pn_flag from "../../../assets/flags/pn.svg";
import pl_flag from "../../../assets/flags/pl.svg";
import pt_flag from "../../../assets/flags/pt.svg";
import pr_flag from "../../../assets/flags/pr.svg";
import qa_flag from "../../../assets/flags/qa.svg";
import cg_flag from "../../../assets/flags/cg.svg";
import ro_flag from "../../../assets/flags/ro.svg";
import ru_flag from "../../../assets/flags/ru.svg";
import rw_flag from "../../../assets/flags/rw.svg";
import re_flag from "../../../assets/flags/re.svg";
import bl_flag from "../../../assets/flags/bl.svg";
import sh_flag from "../../../assets/flags/sh.svg";
import kn_flag from "../../../assets/flags/kn.svg";
import lc_flag from "../../../assets/flags/lc.svg";
import mf_flag from "../../../assets/flags/mf.svg";
import pm_flag from "../../../assets/flags/pm.svg";
import vc_flag from "../../../assets/flags/vc.svg";
import ws_flag from "../../../assets/flags/ws.svg";
import sm_flag from "../../../assets/flags/sm.svg";
import st_flag from "../../../assets/flags/st.svg";
import sa_flag from "../../../assets/flags/sa.svg";
import sn_flag from "../../../assets/flags/sn.svg";
import rs_flag from "../../../assets/flags/rs.svg";
import sc_flag from "../../../assets/flags/sc.svg";
import sl_flag from "../../../assets/flags/sl.svg";
import sg_flag from "../../../assets/flags/sg.svg";
import sx_flag from "../../../assets/flags/sx.svg";
import sk_flag from "../../../assets/flags/sk.svg";
import si_flag from "../../../assets/flags/si.svg";
import sb_flag from "../../../assets/flags/sb.svg";
import so_flag from "../../../assets/flags/so.svg";
import za_flag from "../../../assets/flags/za.svg";
import gs_flag from "../../../assets/flags/gs.svg";
import kr_flag from "../../../assets/flags/kr.svg";
import ss_flag from "../../../assets/flags/ss.svg";
import es_flag from "../../../assets/flags/es.svg";
import lk_flag from "../../../assets/flags/lk.svg";
import ps_flag from "../../../assets/flags/ps.svg";
import sd_flag from "../../../assets/flags/sd.svg";
import sr_flag from "../../../assets/flags/sr.svg";
import sj_flag from "../../../assets/flags/sj.svg";
import se_flag from "../../../assets/flags/se.svg";
import ch_flag from "../../../assets/flags/ch.svg";
import sy_flag from "../../../assets/flags/sy.svg";
import tw_flag from "../../../assets/flags/tw.svg";
import tj_flag from "../../../assets/flags/tj.svg";
import tz_flag from "../../../assets/flags/tz.svg";
import th_flag from "../../../assets/flags/th.svg";
import tl_flag from "../../../assets/flags/tl.svg";
import tg_flag from "../../../assets/flags/tg.svg";
import tk_flag from "../../../assets/flags/tk.svg";
import to_flag from "../../../assets/flags/to.svg";
import tt_flag from "../../../assets/flags/tt.svg";
import tn_flag from "../../../assets/flags/tn.svg";
import tr_flag from "../../../assets/flags/tr.svg";
import tm_flag from "../../../assets/flags/tm.svg";
import tc_flag from "../../../assets/flags/tc.svg";
import tv_flag from "../../../assets/flags/tv.svg";
import ug_flag from "../../../assets/flags/ug.svg";
import ua_flag from "../../../assets/flags/ua.svg";
import ae_flag from "../../../assets/flags/ae.svg";
import en_flag from "../../../assets/flags/en.svg";
import uy_flag from "../../../assets/flags/uy.svg";
import uz_flag from "../../../assets/flags/uz.svg";
import vu_flag from "../../../assets/flags/vu.svg";
import ve_flag from "../../../assets/flags/ve.svg";
import vn_flag from "../../../assets/flags/vn.svg";
import vg_flag from "../../../assets/flags/vg.svg";
import vi_flag from "../../../assets/flags/vi.svg";
import wf_flag from "../../../assets/flags/wf.svg";
import eh_flag from "../../../assets/flags/eh.svg";
import ye_flag from "../../../assets/flags/ye.svg";
import zm_flag from "../../../assets/flags/zm.svg";
import zw_flag from "../../../assets/flags/zw.svg";
import us_flag from "../../../assets/flags/us.svg";
import { onMounted, ref, watch } from "vue";
import favIcn from "../../../assets/icons/favicon.ico";

const props = defineProps({
  flagCode: {
    type: String,
    required: true,
  },
});

const languageCodes = [
  // {
  //   flagIcon: "../src/assets/flags/ca.svg",
  //   icon: ca_flag,
  //   code: "ca",
  //   countryCode: "+",
  // },
  // {
  //   flagIcon: "../src/assets/flags/ca.svg",
  //   icon: ca_flag,
  //   code: "ca",
  //   countryCode: "",
  // },
  {
    flagIcon: "../src/assets/af.svg",
    icon: af_flag,
    code: "af",
    countryCode: "+93",
  },
  {
    flagIcon: "../src/assets/flags/ax.svg",
    icon: ax_flag,
    code: "ax",
    countryCode: "+358",
  },
  {
    flagIcon: "../src/assets/flags/al.svg",
    icon: al_flag,
    code: "al",
    countryCode: "+355",
  },
  {
    flagIcon: "../src/assets/flags/dz.svg",
    icon: dz_flag,
    code: "dz",
    countryCode: "+213",
  },
  {
    flagIcon: "../src/assets/flags/as.svg",
    icon: as_flag,
    code: "as",
    countryCode: "+1-684",
  },
  {
    flagIcon: "../src/assets/flags/ad.svg",
    icon: ad_flag,
    code: "ad",
    countryCode: "+376",
  },
  {
    flagIcon: "../src/assets/flags/ao.svg",
    icon: ao_flag,
    code: "ao",
    countryCode: "+244",
  },
  {
    flagIcon: "../src/assets/flags/ai.svg",
    icon: ai_flag,
    code: "ai",
    countryCode: "+1-264",
  },
  {
    flagIcon: "../src/assets/flags/aq.svg",
    icon: aq_flag,
    code: "aq",
    countryCode: "+672",
  },
  {
    flagIcon: "../src/assets/flags/ag.svg",
    icon: ag_flag,
    code: "ag",
    countryCode: "+1-268",
  },
  {
    flagIcon: "../src/assets/flags/ar.svg",
    icon: ar_flag,
    code: "ar",
    countryCode: "+54",
  },
  {
    flagIcon: "../src/assets/flags/am.svg",
    icon: am_flag,
    code: "am",
    countryCode: "+374",
  },
  {
    flagIcon: "../src/assets/flags/aw.svg",
    icon: aw_flag,
    code: "aw",
    countryCode: "+297",
  },
  {
    flagIcon: "../src/assets/flags/au.svg",
    icon: au_flag,
    code: "au",
    countryCode: "+61",
  },
  {
    flagIcon: "../src/assets/flags/at.svg",
    icon: at_flag,
    code: "at",
    countryCode: "+43",
  },
  {
    flagIcon: "../src/assets/flags/az.svg",
    icon: az_flag,
    code: "az",
    countryCode: "+994",
  },
  {
    flagIcon: "../src/assets/flags/bs.svg",
    icon: bs_flag,
    code: "bs",
    countryCode: "+1-242",
  },
  {
    flagIcon: "../src/assets/flags/bh.svg",
    icon: bh_flag,
    code: "bh",
    countryCode: "+973",
  },
  {
    flagIcon: "../src/assets/flags/bd.svg",
    icon: bd_flag,
    code: "bd",
    countryCode: "+880",
  },
  {
    flagIcon: "../src/assets/flags/bb.svg",
    icon: bb_flag,
    code: "bb",
    countryCode: "+1-246",
  },
  {
    flagIcon: "../src/assets/flags/by.svg",
    icon: by_flag,
    code: "by",
    countryCode: "+375",
  },
  {
    flagIcon: "../src/assets/flags/be.svg",
    icon: be_flag,
    code: "be",
    countryCode: "+32",
  },
  {
    flagIcon: "../src/assets/flags/bz.svg",
    icon: bz_flag,
    code: "bz",
    countryCode: "+501",
  },
  {
    flagIcon: "../src/assets/flags/bj.svg",
    icon: bj_flag,
    code: "bj",
    countryCode: "+229",
  },
  {
    flagIcon: "../src/assets/flags/bm.svg",
    icon: bm_flag,
    code: "bm",
    countryCode: "+1-441",
  },
  {
    flagIcon: "../src/assets/flags/bt.svg",
    icon: bt_flag,
    code: "bt",
    countryCode: "+975	",
  },
  {
    flagIcon: "../src/assets/flags/bo.svg",
    icon: bo_flag,
    code: "bo",
    countryCode: "+591",
  },
  {
    flagIcon: "../src/assets/flags/bq.svg",
    icon: bq_flag,
    code: "bq",
    countryCode: "+599",
  },
  {
    flagIcon: "../src/assets/flags/ba.svg",
    icon: ba_flag,
    code: "ba",
    countryCode: "+387",
  },
  {
    flagIcon: "../src/assets/flags/bw.svg",
    icon: bw_flag,
    code: "bw",
    countryCode: "+267",
  },
  {
    flagIcon: "../src/assets/flags/bv.svg",
    icon: bv_flag,
    code: "bv",
    countryCode: "+47",
  },
  {
    flagIcon: "../src/assets/flags/br.svg",
    icon: br_flag,
    code: "br",
    countryCode: "+55",
  },
  {
    flagIcon: "../src/assets/flags/io.svg",
    icon: io_flag,
    code: "io",
    countryCode: "+246",
  },
  {
    flagIcon: "../src/assets/flags/bn.svg",
    icon: bn_flag,
    code: "bn",
    countryCode: "+673",
  },
  {
    flagIcon: "../src/assets/flags/bg.svg",
    icon: bg_flag,
    code: "bg",
    countryCode: "+359",
  },
  {
    flagIcon: "../src/assets/flags/bf.svg",
    icon: bf_flag,
    code: "bf",
    countryCode: "+226",
  },
  {
    flagIcon: "../src/assets/flags/bi.svg",
    icon: bi_flag,
    code: "bi",
    countryCode: "+257",
  },
  {
    flagIcon: "../src/assets/flags/cv.svg",
    icon: cv_flag,
    code: "cv",
    countryCode: "+238",
  },
  {
    flagIcon: "../src/assets/flags/kh.svg",
    icon: kh_flag,
    code: "kh",
    countryCode: "+855",
  },
  {
    flagIcon: "../src/assets/flags/cm.svg",
    icon: cm_flag,
    code: "cm",
    countryCode: "+237",
  },
  {
    flagIcon: "../src/assets/flags/ca.svg",
    icon: ca_flag,
    code: "ca",
    countryCode: "+1",
  },
  {
    flagIcon: "../src/assets/flags/ky.svg",
    icon: ky_flag,
    code: "ky",
    countryCode: "+1-345",
  },
  {
    flagIcon: "../src/assets/flags/cf.svg",
    icon: cf_flag,
    code: "cf",
    countryCode: "+236",
  },
  {
    flagIcon: "../src/assets/flags/td.svg",
    icon: td_flag,
    code: "td",
    countryCode: "+235",
  },
  {
    flagIcon: "../src/assets/flags/cl.svg",
    icon: cl_flag,
    code: "cl",
    countryCode: "+56",
  },
  {
    flagIcon: "../src/assets/flags/cn.svg",
    icon: cn_flag,
    code: "cn",
    countryCode: "+86",
  },
  {
    flagIcon: "../src/assets/flags/cx.svg",
    icon: cx_flag,
    code: "cx",
    countryCode: "+61",
  },
  {
    flagIcon: "../src/assets/flags/cc.svg",
    icon: cc_flag,
    code: "cc",
    countryCode: "+61",
  },
  {
    flagIcon: "../src/assets/flags/co.svg",
    icon: co_flag,
    code: "co",
    countryCode: "+57",
  },
  {
    flagIcon: "../src/assets/flags/km.svg",
    icon: km_flag,
    code: "km",
    countryCode: "+269",
  },
  {
    flagIcon: "../src/assets/flags/ck.svg",
    icon: ck_flag,
    code: "ck",
    countryCode: "+682",
  },
  {
    flagIcon: "../src/assets/flags/cr.svg",
    icon: cr_flag,
    code: "cr",
    countryCode: "+506",
  },
  {
    flagIcon: "../src/assets/flags/hr.svg",
    icon: hr_flag,
    code: "hr",
    countryCode: "+385",
  },
  {
    flagIcon: "../src/assets/flags/cu.svg",
    icon: cu_flag,
    code: "cu",
    countryCode: "+53",
  },
  {
    flagIcon: "../src/assets/flags/cw.svg",
    icon: cw_flag,
    code: "cw",
    countryCode: "+599",
  },
  {
    flagIcon: "../src/assets/flags/cy.svg",
    icon: cy_flag,
    code: "cy",
    countryCode: "+357",
  },
  {
    flagIcon: "../src/assets/flags/cz.svg",
    icon: cz_flag,
    code: "cz",
    countryCode: "+420",
  },
  {
    flagIcon: "../src/assets/flags/ci.svg",
    icon: ci_flag,
    code: "ci",
    countryCode: "+225",
  },
  {
    flagIcon: "../src/assets/flags/cd.svg",
    icon: cd_flag,
    code: "cd",
    countryCode: "+243",
  },
  {
    flagIcon: "../src/assets/flags/dk.svg",
    icon: dk_flag,
    code: "dk",
    countryCode: "+45",
  },
  {
    flagIcon: "../src/assets/flags/dj.svg",
    icon: dj_flag,
    code: "dj",
    countryCode: "+253",
  },
  {
    flagIcon: "../src/assets/flags/dm.svg",
    icon: dm_flag,
    code: "dm",
    countryCode: "+1-767",
  },
  {
    flagIcon: "../src/assets/flags/do.svg",
    icon: do_flag,
    code: "do",
    countryCode: "+1-809",
  },
  {
    flagIcon: "../src/assets/flags/do.svg",
    icon: do_flag,
    code: "do",
    countryCode: "+1-829,",
  },
  {
    flagIcon: "../src/assets/flags/do.svg",
    icon: do_flag,
    code: "do",
    countryCode: "+1-849",
  },
  {
    flagIcon: "../src/assets/flags/ec.svg",
    icon: ec_flag,
    code: "ec",
    countryCode: "+593",
  },
  {
    flagIcon: "../src/assets/flags/eg.svg",
    icon: eg_flag,
    code: "eg",
    countryCode: "+20",
  },
  {
    flagIcon: "../src/assets/flags/sv.svg",
    icon: sv_flag,
    code: "sv",
    countryCode: "+503",
  },
  {
    flagIcon: "../src/assets/flags/gq.svg",
    icon: gq_flag,
    code: "gq",
    countryCode: "+240",
  },
  {
    flagIcon: "../src/assets/flags/er.svg",
    icon: er_flag,
    code: "er",
    countryCode: "+291",
  },
  {
    flagIcon: "../src/assets/flags/ee.svg",
    icon: ee_flag,
    code: "ee",
    countryCode: "+372",
  },
  {
    flagIcon: "../src/assets/flags/sz.svg",
    icon: sz_flag,
    code: "sz",
    countryCode: "+268",
  },
  {
    flagIcon: "../src/assets/flags/et.svg",
    icon: et_flag,
    code: "et",
    countryCode: "+251",
  },
  {
    flagIcon: "../src/assets/flags/fk.svg",
    icon: fk_flag,
    code: "fk",
    countryCode: "+500",
  },
  {
    flagIcon: "../src/assets/flags/fo.svg",
    icon: fo_flag,
    code: "fo",
    countryCode: "+298",
  },
  {
    flagIcon: "../src/assets/flags/fm.svg",
    icon: fm_flag,
    code: "fm",
    countryCode: "+691",
  },
  {
    flagIcon: "../src/assets/flags/fj.svg",
    icon: fj_flag,
    code: "fj",
    countryCode: "+679",
  },
  {
    flagIcon: "../src/assets/flags/fi.svg",
    icon: fi_flag,
    code: "fi",
    countryCode: "+358",
  },
  {
    flagIcon: "../src/assets/flags/fr.svg",
    icon: fr_flag,
    code: "fr",
    countryCode: "+33",
  },
  {
    flagIcon: "../src/assets/flags/gf.svg",
    icon: gf_flag,
    code: "gf",
    countryCode: "+594",
  },
  {
    flagIcon: "../src/assets/flags/pf.svg",
    icon: pf_flag,
    code: "pf",
    countryCode: "+689",
  },
  {
    flagIcon: "../src/assets/flags/tf.svg",
    icon: tf_flag,
    code: "tf",
    countryCode: "+262",
  },
  {
    flagIcon: "../src/assets/flags/ga.svg",
    icon: ga_flag,
    code: "ga",
    countryCode: "+241",
  },
  {
    flagIcon: "../src/assets/flags/gm.svg",
    icon: gm_flag,
    code: "gm",
    countryCode: "+220",
  },
  {
    flagIcon: "../src/assets/flags/ge.svg",
    icon: ge_flag,
    code: "ge",
    countryCode: "+995",
  },
  {
    flagIcon: "../src/assets/flags/de.svg",
    icon: de_flag,
    code: "de",
    countryCode: "+49",
  },
  {
    flagIcon: "../src/assets/flags/gh.svg",
    icon: gh_flag,
    code: "gh",
    countryCode: "+233",
  },
  {
    flagIcon: "../src/assets/flags/gi.svg",
    icon: gi_flag,
    code: "gi",
    countryCode: "+350",
  },
  {
    flagIcon: "../src/assets/flags/gr.svg",
    icon: gr_flag,
    code: "gr",
    countryCode: "+30",
  },
  {
    flagIcon: "../src/assets/flags/gl.svg",
    icon: gl_flag,
    code: "gl",
    countryCode: "+299",
  },
  {
    flagIcon: "../src/assets/flags/gd.svg",
    icon: gd_flag,
    code: "gd",
    countryCode: "+1-473",
  },
  {
    flagIcon: "../src/assets/flags/gp.svg",
    icon: gp_flag,
    code: "gp",
    countryCode: "+590",
  },
  {
    flagIcon: "../src/assets/flags/gu.svg",
    icon: gu_flag,
    code: "gu",
    countryCode: "+1-671",
  },
  {
    flagIcon: "../src/assets/flags/gt.svg",
    icon: gt_flag,
    code: "gt",
    countryCode: "+502",
  },
  {
    flagIcon: "../src/assets/flags/gg.svg",
    icon: gg_flag,
    code: "gg",
    countryCode: "+44-1481",
  },
  {
    flagIcon: "../src/assets/flags/gn.svg",
    icon: gn_flag,
    code: "gn",
    countryCode: "+224",
  },
  {
    flagIcon: "../src/assets/flags/gy.svg",
    icon: gy_flag,
    code: "gy",
    countryCode: "+592",
  },
  {
    flagIcon: "../src/assets/flags/gw.svg",
    icon: gw_flag,
    code: "gw",
    countryCode: "+245",
  },
  {
    flagIcon: "../src/assets/flags/ht.svg",
    icon: ht_flag,
    code: "ht",
    countryCode: "+509",
  },
  {
    flagIcon: "../src/assets/flags/hm.svg",
    icon: hm_flag,
    code: "hm",
    countryCode: "+672",
  },
  {
    flagIcon: "../src/assets/flags/va.svg",
    icon: va_flag,
    code: "va",
    countryCode: "+379",
  },
  {
    flagIcon: "../src/assets/flags/hn.svg",
    icon: hn_flag,
    code: "hn",
    countryCode: "+504",
  },
  {
    flagIcon: "../src/assets/flags/hk.svg",
    icon: hk_flag,
    code: "hk",
    countryCode: "+852",
  },
  {
    flagIcon: "../src/assets/flags/hu.svg",
    icon: hu_flag,
    code: "hu",
    countryCode: "+36",
  },
  {
    flagIcon: "../src/assets/flags/is.svg",
    icon: is_flag,
    code: "is",
    countryCode: "+354",
  },
  {
    flagIcon: "../src/assets/flags/in.svg",
    icon: in_flag,
    code: "in",
    countryCode: "+91",
  },
  {
    flagIcon: "../src/assets/flags/id.svg",
    icon: id_flag,
    code: "id",
    countryCode: "+62",
  },
  {
    flagIcon: "../src/assets/flags/ir.svg",
    icon: ir_flag,
    code: "ir",
    countryCode: "+98",
  },
  {
    flagIcon: "../src/assets/flags/iq.svg",
    icon: iq_flag,
    code: "iq",
    countryCode: "+964",
  },
  {
    flagIcon: "../src/assets/flags/ie.svg",
    icon: ie_flag,
    code: "ie",
    countryCode: "+353",
  },
  {
    flagIcon: "../src/assets/flags/im.svg",
    icon: im_flag,
    code: "im",
    countryCode: "+44-1624",
  },
  {
    flagIcon: "../src/assets/flags/il.svg",
    icon: il_flag,
    code: "il",
    countryCode: "+972",
  },
  {
    flagIcon: "../src/assets/flags/it.svg",
    icon: it_flag,
    code: "it",
    countryCode: "+39",
  },
  {
    flagIcon: "../src/assets/flags/jm.svg",
    icon: jm_flag,
    code: "jm",
    countryCode: "+1-876",
  },
  {
    flagIcon: "../src/assets/flags/jp.svg",
    icon: jp_flag,
    code: "jp",
    countryCode: "+81",
  },
  {
    flagIcon: "../src/assets/flags/je.svg",
    icon: je_flag,
    code: "je",
    countryCode: "+44-1534",
  },
  {
    flagIcon: "../src/assets/flags/jo.svg",
    icon: jo_flag,
    code: "jo",
    countryCode: "+962",
  },
  {
    flagIcon: "../src/assets/flags/kz.svg",
    icon: kz_flag,
    code: "kz",
    countryCode: "+7",
  },
  {
    flagIcon: "../src/assets/flags/ke.svg",
    icon: ke_flag,
    code: "ke",
    countryCode: "+254",
  },
  {
    flagIcon: "../src/assets/flags/ki.svg",
    icon: ki_flag,
    code: "ki",
    countryCode: "+686",
  },
  {
    flagIcon: "../src/assets/flags/kw.svg",
    icon: kw_flag,
    code: "kw",
    countryCode: "+965",
  },
  {
    flagIcon: "../src/assets/flags/kg.svg",
    icon: kg_flag,
    code: "kg",
    countryCode: "+996",
  },
  {
    flagIcon: "../src/assets/flags/la.svg",
    icon: la_flag,
    code: "la",
    countryCode: "+856",
  },
  {
    flagIcon: "../src/assets/flags/lv.svg",
    icon: lv_flag,
    code: "lv",
    countryCode: "+371",
  },
  {
    flagIcon: "../src/assets/flags/lb.svg",
    icon: lb_flag,
    code: "lb",
    countryCode: "+961",
  },
  {
    flagIcon: "../src/assets/flags/ls.svg",
    icon: ls_flag,
    code: "ls",
    countryCode: "+266",
  },
  {
    flagIcon: "../src/assets/flags/lr.svg",
    icon: lr_flag,
    code: "lr",
    countryCode: "+231",
  },
  {
    flagIcon: "../src/assets/flags/ly.svg",
    icon: ly_flag,
    code: "ly",
    countryCode: "+218",
  },
  {
    flagIcon: "../src/assets/flags/li.svg",
    icon: li_flag,
    code: "li",
    countryCode: "+423",
  },
  {
    flagIcon: "../src/assets/flags/lt.svg",
    icon: lt_flag,
    code: "lt",
    countryCode: "+370",
  },
  {
    flagIcon: "../src/assets/flags/lu.svg",
    icon: lu_flag,
    code: "lu",
    countryCode: "+352",
  },
  {
    flagIcon: "../src/assets/flags/mo.svg",
    icon: mo_flag,
    code: "mo",
    countryCode: "+853",
  },
  {
    flagIcon: "../src/assets/flags/mg.svg",
    icon: mg_flag,
    code: "mg",
    countryCode: "+261",
  },
  {
    flagIcon: "../src/assets/flags/mw.svg",
    icon: mw_flag,
    code: "mw",
    countryCode: "+265",
  },
  {
    flagIcon: "../src/assets/flags/my.svg",
    icon: my_flag,
    code: "my",
    countryCode: "+60",
  },
  {
    flagIcon: "../src/assets/flags/mv.svg",
    icon: mv_flag,
    code: "mv",
    countryCode: "+960",
  },
  {
    flagIcon: "../src/assets/flags/ml.svg",
    icon: ml_flag,
    code: "ml",
    countryCode: "+223",
  },
  {
    flagIcon: "../src/assets/flags/mt.svg",
    icon: mt_flag,
    code: "mt",
    countryCode: "+356",
  },
  {
    flagIcon: "../src/assets/flags/mh.svg",
    icon: mh_flag,
    code: "mh",
    countryCode: "+692",
  },
  {
    flagIcon: "../src/assets/flags/mq.svg",
    icon: mq_flag,
    code: "mq",
    countryCode: "+596",
  },
  {
    flagIcon: "../src/assets/flags/mr.svg",
    icon: mr_flag,
    code: "mr",
    countryCode: "+222",
  },
  {
    flagIcon: "../src/assets/flags/mu.svg",
    icon: mu_flag,
    code: "mu",
    countryCode: "+230",
  },
  {
    flagIcon: "../src/assets/flags/yt.svg",
    icon: yt_flag,
    code: "yt",
    countryCode: "+262",
  },
  {
    flagIcon: "../src/assets/flags/mx.svg",
    icon: mx_flag,
    code: "mx",
    countryCode: "+52",
  },
  {
    flagIcon: "../src/assets/flags/md.svg",
    icon: md_flag,
    code: "md",
    countryCode: "+373",
  },
  {
    flagIcon: "../src/assets/flags/mc.svg",
    icon: mc_flag,
    code: "mc",
    countryCode: "+377",
  },
  {
    flagIcon: "../src/assets/flags/mn.svg",
    icon: mn_flag,
    code: "mn",
    countryCode: "+976",
  },
  {
    flagIcon: "../src/assets/flags/me.svg",
    icon: me_flag,
    code: "me",
    countryCode: "+382",
  },
  {
    flagIcon: "../src/assets/flags/ms.svg",
    icon: ms_flag,
    code: "ms",
    countryCode: "+1-664",
  },
  {
    flagIcon: "../src/assets/flags/ma.svg",
    icon: ma_flag,
    code: "ma",
    countryCode: "+212",
  },
  {
    flagIcon: "../src/assets/flags/mz.svg",
    icon: mz_flag,
    code: "mz",
    countryCode: "+258",
  },
  {
    flagIcon: "../src/assets/flags/mm.svg",
    icon: mm_flag,
    code: "mm",
    countryCode: "+95",
  },
  {
    flagIcon: "../src/assets/flags/na.svg",
    icon: na_flag,
    code: "na",
    countryCode: "+264",
  },
  {
    flagIcon: "../src/assets/flags/nr.svg",
    icon: nr_flag,
    code: "nr",
    countryCode: "+674",
  },
  {
    flagIcon: "../src/assets/flags/np.svg",
    icon: np_flag,
    code: "np",
    countryCode: "+977",
  },
  {
    flagIcon: "../src/assets/flags/nl.svg",
    icon: nl_flag,
    code: "nl",
    countryCode: "+31",
  },
  {
    flagIcon: "../src/assets/flags/nc.svg",
    icon: nc_flag,
    code: "nc",
    countryCode: "+687",
  },
  {
    flagIcon: "../src/assets/flags/nz.svg",
    icon: nz_flag,
    code: "nz",
    countryCode: "+64",
  },
  {
    flagIcon: "../src/assets/flags/ni.svg",
    icon: ni_flag,
    code: "ni",
    countryCode: "+505",
  },
  {
    flagIcon: "../src/assets/flags/ne.svg",
    icon: ne_flag,
    code: "ne",
    countryCode: "+227",
  },
  {
    flagIcon: "../src/assets/flags/ng.svg",
    icon: ng_flag,
    code: "ng",
    countryCode: "+234",
  },
  {
    flagIcon: "../src/assets/flags/nu.svg",
    icon: nu_flag,
    code: "nu",
    countryCode: "+683",
  },
  {
    flagIcon: "../src/assets/flags/nf.svg",
    icon: nf_flag,
    code: "nf",
    countryCode: "+672",
  },
  {
    flagIcon: "../src/assets/flags/kp.svg",
    icon: kp_flag,
    code: "kp",
    countryCode: "+850",
  },
  {
    flagIcon: "../src/assets/flags/mk.svg",
    icon: mk_flag,
    code: "mk",
    countryCode: "+389",
  },
  {
    flagIcon: "../src/assets/flags/no.svg",
    icon: no_flag,
    code: "no",
    countryCode: "+47",
  },
  {
    flagIcon: "../src/assets/flags/mp.svg",
    icon: mp_flag,
    code: "mp",
    countryCode: "+1-670",
  },
  {
    flagIcon: "../src/assets/flags/om.svg",
    icon: om_flag,
    code: "om",
    countryCode: "+968",
  },
  {
    flagIcon: "../src/assets/flags/pk.svg",
    icon: pk_flag,
    code: "pk",
    countryCode: "+92",
  },
  {
    flagIcon: "../src/assets/flags/pw.svg",
    icon: pw_flag,
    code: "pw",
    countryCode: "+680",
  },
  {
    flagIcon: "../src/assets/flags/pa.svg",
    icon: pa_flag,
    code: "pa",
    countryCode: "+507",
  },
  {
    flagIcon: "../src/assets/flags/pg.svg",
    icon: pg_flag,
    code: "pg",
    countryCode: "+675",
  },
  {
    flagIcon: "../src/assets/flags/py.svg",
    icon: py_flag,
    code: "py",
    countryCode: "+595",
  },
  {
    flagIcon: "../src/assets/flags/pe.svg",
    icon: pe_flag,
    code: "pe",
    countryCode: "+51",
  },
  {
    flagIcon: "../src/assets/flags/ph.svg",
    icon: ph_flag,
    code: "ph",
    countryCode: "+63",
  },
  {
    flagIcon: "../src/assets/flags/pn.svg",
    icon: pn_flag,
    code: "pn",
    countryCode: "+64",
  },
  {
    flagIcon: "../src/assets/flags/pl.svg",
    icon: pl_flag,
    code: "pl",
    countryCode: "+48",
  },
  {
    flagIcon: "../src/assets/flags/pt.svg",
    icon: pt_flag,
    code: "pt",
    countryCode: "+351",
  },
  {
    flagIcon: "../src/assets/flags/pr.svg",
    icon: pr_flag,
    code: "pr",
    countryCode: "+1-787",
  },
  {
    flagIcon: "../src/assets/flags/pr.svg",
    icon: pr_flag,
    code: "pr",
    countryCode: "+1-939",
  },
  {
    flagIcon: "../src/assets/flags/qa.svg",
    icon: qa_flag,
    code: "qa",
    countryCode: "+974",
  },
  {
    flagIcon: "../src/assets/flags/cg.svg",
    icon: cg_flag,
    code: "cg",
    countryCode: "+242",
  },
  {
    flagIcon: "../src/assets/flags/ro.svg",
    icon: ro_flag,
    code: "ro",
    countryCode: "+40",
  },
  {
    flagIcon: "../src/assets/flags/ru.svg",
    icon: ru_flag,
    code: "ru",
    countryCode: "+7",
  },
  {
    flagIcon: "../src/assets/flags/rw.svg",
    icon: rw_flag,
    code: "rw",
    countryCode: "+250",
  },
  {
    flagIcon: "../src/assets/flags/re.svg",
    icon: re_flag,
    code: "re",
    countryCode: "+262",
  },
  {
    flagIcon: "../src/assets/flags/bl.svg",
    icon: bl_flag,
    code: "bl",
    countryCode: "+590",
  },
  {
    flagIcon: "../src/assets/flags/sh.svg",
    icon: sh_flag,
    code: "sh",
    countryCode: "+290",
  },
  {
    flagIcon: "../src/assets/flags/kn.svg",
    icon: kn_flag,
    code: "kn",
    countryCode: "+1-869",
  },
  {
    flagIcon: "../src/assets/flags/lc.svg",
    icon: lc_flag,
    code: "lc",
    countryCode: "+1-758",
  },
  {
    flagIcon: "../src/assets/flags/mf.svg",
    icon: mf_flag,
    code: "mf",
    countryCode: "+590",
  },
  {
    flagIcon: "../src/assets/flags/pm.svg",
    icon: pm_flag,
    code: "pm",
    countryCode: "+508",
  },
  {
    flagIcon: "../src/assets/flags/vc.svg",
    icon: vc_flag,
    code: "vc",
    countryCode: "+1-784",
  },
  {
    flagIcon: "../src/assets/flags/ws.svg",
    icon: ws_flag,
    code: "ws",
    countryCode: "+685",
  },
  {
    flagIcon: "../src/assets/flags/sm.svg",
    icon: sm_flag,
    code: "sm",
    countryCode: "+378",
  },
  {
    flagIcon: "../src/assets/flags/st.svg",
    icon: st_flag,
    code: "st",
    countryCode: "+239",
  },
  {
    flagIcon: "../src/assets/flags/sa.svg",
    icon: sa_flag,
    code: "sa",
    countryCode: "+966",
  },
  {
    flagIcon: "../src/assets/flags/sn.svg",
    icon: sn_flag,
    code: "sn",
    countryCode: "+221",
  },
  {
    flagIcon: "../src/assets/flags/rs.svg",
    icon: rs_flag,
    code: "rs",
    countryCode: "+381",
  },
  {
    flagIcon: "../src/assets/flags/sc.svg",
    icon: sc_flag,
    code: "sc",
    countryCode: "+248",
  },
  {
    flagIcon: "../src/assets/flags/sl.svg",
    icon: sl_flag,
    code: "sl",
    countryCode: "+232",
  },
  {
    flagIcon: "../src/assets/flags/sg.svg",
    icon: sg_flag,
    code: "sg",
    countryCode: "+65",
  },
  {
    flagIcon: "../src/assets/flags/sx.svg",
    icon: sx_flag,
    code: "sx",
    countryCode: "+1-721",
  },
  {
    flagIcon: "../src/assets/flags/sk.svg",
    icon: sk_flag,
    code: "sk",
    countryCode: "+421",
  },
  {
    flagIcon: "../src/assets/flags/si.svg",
    icon: si_flag,
    code: "si",
    countryCode: "+386",
  },
  {
    flagIcon: "../src/assets/flags/sb.svg",
    icon: sb_flag,
    code: "sb",
    countryCode: "+677",
  },
  {
    flagIcon: "../src/assets/flags/so.svg",
    icon: so_flag,
    code: "so",
    countryCode: "+252",
  },
  {
    flagIcon: "../src/assets/flags/za.svg",
    icon: za_flag,
    code: "za",
    countryCode: "+27",
  },
  {
    flagIcon: "../src/assets/flags/gs.svg",
    icon: gs_flag,
    code: "gs",
    countryCode: "+672",
  },
  {
    flagIcon: "../src/assets/flags/kr.svg",
    icon: kr_flag,
    code: "kr",
    countryCode: "+82",
  },
  {
    flagIcon: "../src/assets/flags/ss.svg",
    icon: ss_flag,
    code: "ss",
    countryCode: "+211",
  },
  {
    flagIcon: "../src/assets/flags/es.svg",
    icon: es_flag,
    code: "es",
    countryCode: "+34",
  },
  {
    flagIcon: "../src/assets/flags/lk.svg",
    icon: lk_flag,
    code: "lk",
    countryCode: "+94",
  },
  {
    flagIcon: "../src/assets/flags/ps.svg",
    icon: ps_flag,
    code: "ps",
    countryCode: "+970",
  },
  {
    flagIcon: "../src/assets/flags/sd.svg",
    icon: sd_flag,
    code: "sd",
    countryCode: "+249",
  },
  {
    flagIcon: "../src/assets/flags/sr.svg",
    icon: sr_flag,
    code: "sr",
    countryCode: "+597",
  },
  {
    flagIcon: "../src/assets/flags/sj.svg",
    icon: sj_flag,
    code: "sj",
    countryCode: "+47",
  },
  {
    flagIcon: "../src/assets/flags/se.svg",
    icon: se_flag,
    code: "se",
    countryCode: "+46",
  },
  {
    flagIcon: "../src/assets/flags/ch.svg",
    icon: ch_flag,
    code: "ch",
    countryCode: "+41",
  },
  {
    flagIcon: "../src/assets/flags/sy.svg",
    icon: sy_flag,
    code: "sy",
    countryCode: "+963",
  },
  {
    flagIcon: "../src/assets/flags/tw.svg",
    icon: tw_flag,
    code: "tw",
    countryCode: "+886",
  },
  {
    flagIcon: "../src/assets/flags/tj.svg",
    icon: tj_flag,
    code: "tj",
    countryCode: "+992",
  },
  {
    flagIcon: "../src/assets/flags/tz.svg",
    icon: tz_flag,
    code: "tz",
    countryCode: "+255",
  },
  {
    flagIcon: "../src/assets/flags/th.svg",
    icon: th_flag,
    code: "th",
    countryCode: "+66",
  },
  {
    flagIcon: "../src/assets/flags/tl.svg",
    icon: tl_flag,
    code: "tl",
    countryCode: "+670",
  },
  {
    flagIcon: "../src/assets/flags/tg.svg",
    icon: tg_flag,
    code: "tg",
    countryCode: "+228",
  },
  {
    flagIcon: "../src/assets/flags/tk.svg",
    icon: tk_flag,
    code: "tk",
    countryCode: "+690",
  },
  {
    flagIcon: "../src/assets/flags/to.svg",
    icon: to_flag,
    code: "to",
    countryCode: "+676",
  },
  {
    flagIcon: "../src/assets/flags/tt.svg",
    icon: tt_flag,
    code: "tt",
    countryCode: "+1-868",
  },
  {
    flagIcon: "../src/assets/flags/tn.svg",
    icon: tn_flag,
    code: "tn",
    countryCode: "+216",
  },
  {
    flagIcon: "../src/assets/flags/tr.svg",
    icon: tr_flag,
    code: "tr",
    countryCode: "+90",
  },
  {
    flagIcon: "../src/assets/flags/tm.svg",
    icon: tm_flag,
    code: "tm",
    countryCode: "+993",
  },
  {
    flagIcon: "../src/assets/flags/tc.svg",
    icon: tc_flag,
    code: "tc",
    countryCode: "+1-649",
  },
  {
    flagIcon: "../src/assets/flags/tv.svg",
    icon: tv_flag,
    code: "tv",
    countryCode: "+688",
  },
  {
    flagIcon: "../src/assets/flags/ug.svg",
    icon: ug_flag,
    code: "ug",
    countryCode: "+256",
  },
  {
    flagIcon: "../src/assets/flags/ua.svg",
    icon: ua_flag,
    code: "ua",
    countryCode: "+380",
  },
  {
    flagIcon: "../src/assets/flags/ae.svg",
    icon: ae_flag,
    code: "ae",
    countryCode: "+971",
  },
  {
    flagIcon: "../src/assets/flags/en.svg",
    icon: en_flag,
    code: "en",
    countryCode: "+44",
  },
  {
    flagIcon: "../src/assets/flags/uy.svg",
    icon: uy_flag,
    code: "uy",
    countryCode: "+598",
  },
  {
    flagIcon: "../src/assets/flags/uz.svg",
    icon: uz_flag,
    code: "uz",
    countryCode: "+998",
  },
  {
    flagIcon: "../src/assets/flags/vu.svg",
    icon: vu_flag,
    code: "vu",
    countryCode: "+678",
  },
  {
    flagIcon: "../src/assets/flags/ve.svg",
    icon: ve_flag,
    code: "ve",
    countryCode: "+58",
  },
  {
    flagIcon: "../src/assets/flags/vn.svg",
    icon: vn_flag,
    code: "vn",
    countryCode: "+84",
  },
  {
    flagIcon: "../src/assets/flags/vg.svg",
    icon: vg_flag,
    code: "vg",
    countryCode: "+1-284",
  },
  {
    flagIcon: "../src/assets/flags/vi.svg",
    icon: vi_flag,
    code: "vi",
    countryCode: "+1-340",
  },
  {
    flagIcon: "../src/assets/flags/wf.svg",
    icon: wf_flag,
    code: "wf",
    countryCode: "+681",
  },
  {
    flagIcon: "../src/assets/flags/eh.svg",
    icon: eh_flag,
    code: "eh",
    countryCode: "+212",
  },
  {
    flagIcon: "../src/assets/flags/ye.svg",
    icon: ye_flag,
    code: "ye",
    countryCode: "+967",
  },
  {
    flagIcon: "../src/assets/flags/zm.svg",
    icon: zm_flag,
    code: "zm",
    countryCode: "+260",
  },
  {
    flagIcon: "../src/assets/flags/zm.svg",
    icon: zm_flag,
    code: "zm",
    countryCode: "+260",
  },
  {
    flagIcon: "../src/assets/flags/us.svg",
    icon: us_flag,
    code: "us",
    countryCode: "+1",
  },
  {
    flagIcon: "../src/assets/flags/gb.svg",
    icon: gb_flag,
    code: "gb",
    countryCode: "",
  },
  {
    flagIcon: "../src/assets/flags/ca.svg",
    icon: ca_flag,
    code: "en_CA",
    countryCode: "",
  },
  {
    flagIcon: "../src/assets/flags/in.svg",
    icon: in_flag,
    code: "en_IN",
    countryCode: "",
  },
  {
    flagIcon: "../src/assets/flags/in.svg",
    icon: in_flag,
    code: "hi",
    countryCode: "",
  },
  {
    flagIcon: "../src/assets/flags/nz.svg",
    icon: nz_flag,
    code: "en_NZ",
    countryCode: "",
  },
  {
    flagIcon: "../src/assets/flags/rs.svg",
    icon: rs_flag,
    code: "sr",
    countryCode: "",
  },
  {
    flagIcon: "../src/assets/flags/at.svg",
    icon: at_flag,
    code: "de_AT",
    countryCode: "",
  },
  {
    flagIcon: "../src/assets/flags/se.svg",
    icon: se_flag,
    code: "sv",
    countryCode: "",
  },
];

onMounted(() => {
  matchPropWithFlag();
});

const flagIcon = ref();

const matchPropWithFlag = () => {
  let matched = false;
  languageCodes.forEach((element) => {
    if (element.code == props.flagCode) {
      flagIcon.value = element.icon;
      matched = true;
    }
  });
  if (!matched) flagIcon.value = favIcn;
};

watch(props, () => {
  matchPropWithFlag();
});
</script>

<template>
  <img class="icon-28 mgl-4 round-circle" :src="flagIcon" />
</template>
