<script setup lang="ts">
import { ref, watch} from "vue";
import axios from "axios";
import InputValueComponent from "../../../components/shared/forms/input/InputValueComponent.vue";
import IconComponent from "@/components/icons/IconComponent.vue";
import { useLoaderStore } from "../../../stores/loader";

const loader = useLoaderStore();
const captchaImage = ref();
const imgCode = ref();
const captchaHash = ref();
const captchaIsValid = ref(false);
const captchaFailed = ref(false);
const failedRequest = ref(false);
const inputIsValid = ref(false);
const emit = defineEmits(["isValid", "code", "header"]);

function download() {
    emit("isValid", inputIsValid.value)
    loader.toggleLoader(true);
  axios
    .get(`/captcha/get-captcha`, { responseType: "blob" })
    .then((response) => {
      captchaHash.value = response.headers["captcha-hash"];
      const fileURL = URL.createObjectURL(
        new Blob([response.data, { type: "image/png" }])
      );
      captchaImage.value = fileURL;
      loader.toggleLoader(false);
    })
    .catch((err) => {
        loader.toggleLoader(true);
         loader.toggleLoader(false);
        });
    loader.toggleLoader(false);
}
download();

const downloadAgain = () => {
  failedRequest.value = false;
  captchaFailed.value = false;
  download();
}; 

watch(
  imgCode,
  (value) => {
    emit("code", imgCode.value);    
    emit("header", captchaHash.value)
    emit("isValid", inputIsValid.value)
  },
  { deep: true }
);
</script>

<template>
  <div class="w-100 flex flex-center mgy-5">
    <div class="border-input pda-5 radius-5">
      <div v-if="!captchaIsValid && !captchaFailed">
        <div class="w-100 flex flex-right-center">
          <img :src="captchaImage" />
          <IconComponent
            class="pdx-5 mgl-1 cursor-pointer"
            :iconClasses="`icon-20 icon-inline align-bottom bg-theme-brand-1 icon-refresh-arrow`"
            @click="download"
          ></IconComponent>
        </div>
        <InputValueComponent
          placeholder="Enter code"
          inputType="number"
          v-model="imgCode"
          @isValid="inputIsValid = $event"
        ></InputValueComponent>
      </div>
      <div v-if="failedRequest">
        <div class="flex">
          <IconComponent
            class="pdx-5"
            :iconClasses="`icon-20 icon-inline align-bottom bg-error icon-error`"
          ></IconComponent>
          <p>Captcha failed to load!</p>
        </div>
        <button
          class="btn btn-thin-large btn-brand-light mgx-auto mgt-5 txt-bold"
          @click="downloadAgain"
        >
          Try again?
        </button>
      </div>
    </div>
  </div>
</template>
