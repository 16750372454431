<script setup lang="ts">
import { ref } from "vue";
import { goToRoute } from "@/router/routingService"

interface Link {
  link: string;
  url: string;
}

const links = ref([
  {
    link: "Deposit Limit",
    url: "/limits/deposit",
  },
  {
    link: "Wagering Limit",
    url: "/limits/wagering",
  },
  {
    link: "Loss Limit",
    url: "/limits/loss",
  },
  {
    link: "Time Limit",
    url: "/limits/time",
  },
]);

const selected = ref(links.value[0]);
const changeLink = (link: Link) => {
  selected.value = link;
  goToRoute(link.url);
};
</script>

<template>
  <div class="line-nav mgy-10">
    <span
      v-for="(link, i) in links"
      :key="i"
      :class="
        selected == link
          ? 'line-bold-article mgx-5 cursor-pointer'
          : 'mgx-5 cursor-pointer'
      "
      @click="changeLink(link)"
    >
      {{ link.link }}
    </span>
  </div>
</template>
