<script setup lang="ts">
import { ref } from "vue";
import { badges } from "../../services/data/footerData";
import DividerComponent from "../shared/divider/DividerComponent.vue";
import IconComponent from "../icons/IconComponent.vue";
const mainLogo = ref();
const logos = ref();
const content = ref();

const init = () => {
  mainLogo.value = badges[0].mainLogo;
  logos.value = badges[1].badges;
  content.value = badges[2].content;
};

init();
</script>

<template>
  <DividerComponent
    :dividerClass="'w-85 mgx-auto mgy-6 bg-dark400'"
  ></DividerComponent>
  <div class="trust-badges flex">
    <figure class="txt-center mgy-6">
      <img
        src="../../assets/icons/img-mga-logo.svg"
        :alt="mainLogo.alt"
        loading="lazy"
      />
    </figure>
    <div class="badges-warning-info flex mgb-6">
      <div class="badges mgb-6">
        <!-- v-for="badge in logos"
          v-bind:key="badge.logo" -->
        <img
          class="mgx-4"
          src="../../assets/icons/img-18plus.svg"
          :alt="logos[0].alt"
        />
        <img
          class="mgx-4"
          src="../../assets/icons/img-gamcare-logo.svg"
          :alt="logos[1].alt"
        />
        <img
          class="mgx-4"
          src="../../assets/icons/img-no-usa.svg"
          :alt="logos[2].alt"
        />
      </div>
      <p class="txt-center txt-theme-brand-1">{{ content.text }}</p>
    </div>
  </div>
</template>
