<script setup lang="ts">
import InputValueComponent from "@/brands/holahola/components/shared/forms/input/InputValueComponent.vue";
import DropDownComponent from "@/brands/holahola/components/shared/forms/dropdown/DropDownComponent.vue";
import { ref, watch, computed } from "vue";
import { useLoaderStore } from "@/stores/loader";
import { useBalanceStore } from "@/stores/balance";
import type { BalanceKey } from "@/stores/balance";
import { translate } from "@/services/translations/translation";
import { useLayoutModalsStore } from "@/stores/layoutModals";
import type { Deposit } from "@/services/payment/depositAbstract/deposit";
import { generateDepositObject } from "@/services/payment/depositImplementations/depositFactory";
import type { HandlerResponse } from "@/services/payment/depositAbstract/handleDeposit";
import { usePaymentStore } from "@/stores/payments";
import UserAccountSideMenuVue from "@/brands/holahola/components/navigation/UserAccountSideMenu.vue";
import AdditionalDataWrapper from "@/brands/holahola/views/desktop/transactions/additionalData/AdditionalDataWrapper.vue";
import axios from "axios";
import WarningComponent from "@/brands/holahola/components/bonus/WarningComponent.vue";
import FormWarningComponent from "@/brands/holahola/components/shared/forms/FormWarningComponent.vue";
import { computeFormValidation } from "@/services/validation/formValidation";

const balanceStore = useBalanceStore();
const balance = computed(() =>
  balanceStore.getBalance(selectedWalletName.value)
);
const balanceWallet = computed(() =>
  balanceStore.getWallet(selectedWalletName.value)
);
const finishedLoading = ref(false);
const selectedWallet = ref();
const selectedWalletName = ref<BalanceKey>("casino");
const layoutModalsStore = useLayoutModalsStore();
const bonuses = ref();
const providers = ref();
const wallets = ref();
const currency = ref("");
const pricePoints = ref([10, 25, 100, 200]);
const bonusCodeFlag = ref(false);
const bonusCode = ref("");
const selectedBonus = ref();
const selectedPricePoint = ref();
const depositAmount = ref();
const selectedPiQ = ref();
const loader = useLoaderStore();
const walletsTranslations = ref<Array<any>>([]);
const deposit = ref<Deposit>();
const handlerResponse = ref<HandlerResponse | null>();
const paymentStore = usePaymentStore();
const isAnyBonusActive = ref(false);
const back = () => {
  handlerResponse.value = null;
};

const loadWalletData = async (walletType: number) => {
  loader.toggleLoader(true);
  selectedPricePoint.value = null;
  depositAmount.value = null;
  bonuses.value = null;
  // const response = await getPaymentMethods("deposit", walletType);
  let response = paymentStore.getMethod(walletType, "deposit") as any;
  if (response == null) {
    await paymentStore.loadMethodsWait();
    response = paymentStore.getMethod(walletType, "deposit") as any;
  }

  if (response) {
    const loadedBonuses = response.bonuses;
    bonuses.value = [];
    for (const bonus in loadedBonuses) {
      bonuses.value.push(loadedBonuses[bonus]);
    }
    bonuses.value.push({
      title: translate("transactions.deposit.desktop.generals.withoutBonus"),
      bonusId: null,
    });
    providers.value = response.methods;
    wallets.value = response.wallet.options;
    selectedWallet.value = response.wallet.current;
    currency.value = response.currency.code;
    if (response.amounts > 0) {
      pricePoints.value = response.amounts;
    }
  }
  loader.toggleLoader(false);
};
const selectedMethod = (i: number) => {
  selectedPiQ.value = i;
  callbackValid("method", true);
};
const submit = async () => {
  loader.toggleLoader(true);
  let key: ValidationKey;
  for (key in validationList.value) {
    validationList.value[key].isTouched = true;
  }
  if (formisValid.value) {
    localStorage.setItem("depositWallet", selectedWalletName.value);
    localStorage.setItem("balance", balance.value as any);

    deposit.value = generateDepositObject({
      supplier: providers.value[selectedPiQ.value].supplier,
      provider: providers.value[selectedPiQ.value].provider,
      amount: parseFloat(depositAmount.value),
      walletType: selectedWallet.value,
      bonus_code: bonusCode.value,
      bonus_id: selectedBonus.value.bonusId,
    });
    handlerResponse.value = await deposit.value.handleDeposit();
  }
  loader.toggleLoader(false);
};

const clearRadioPrice = () => {
  if (depositAmount.value != selectedPricePoint.value) {
    selectedPricePoint.value = 0;
  }
};

const selectWallet = (wallet: number) => {
  selectedWallet.value = wallet;
  loadWalletData(selectedWallet.value);
};

const selectPricePoint = (price: number) => {
  selectedPricePoint.value = price;
  depositAmount.value = price.toString();
  validationList.value.amount.isTouched = true;
  callbackValid("amount", true);
};

const setBonus = async (event: any) => {
  selectedBonus.value = event;
  if (selectedBonus.value.bonusId == 1) {
    bonusCodeFlag.value = true;
  } else {
    bonusCodeFlag.value = false;
    bonusCode.value = "";
  }

  if (selectedBonus.value.bonusId) {
    await axios
      .get("/obtained/bonus/active/check")
      .then((response) => {
        if (response.data.data.active) {
          isAnyBonusActive.value = true;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } else {
    isAnyBonusActive.value = false;
  }
};

watch(
  depositAmount,
  (value) => {
    clearRadioPrice();
  },
  { deep: true }
);
selectedWalletName;

watch(
  selectedWallet,
  (value) => {
    for (const key in wallets.value) {
      if (wallets.value[key].id == selectedWallet.value) {
        selectedWalletName.value = wallets.value[key].name.toLowerCase();
      }
    }
  },
  { deep: true }
);

const handleClose = () => {
  layoutModalsStore.closeModal("depositModal");
};

const init = async () => {
  walletsTranslations.value = [];
  walletsTranslations.value.push(translate("common.casino"));
  walletsTranslations.value.push(translate("common.sportsbook"));
  await loadWalletData(1);
  finishedLoading.value = true;
};
init();
// VALIDATION START
const validationList = ref({
  wallet: {
    valid: false,
    isTouched: false,
    message: translate("transactions.deposit.desktop.generals.bonus_error"),
  },
  bonus: {
    valid: false,
    isTouched: false,
    message: translate("transactions.deposit.desktop.generals.bonus_error"),
  },
  amount: {
    valid: false,
    isTouched: false,
    message: translate("transactions.withdrawal.desktop.valid_amount"),
  },
  method: {
    valid: false,
    isTouched: false,
    message: translate("transactions.deposit.desktop.generals.method_error"),
  },
});
type ValidationKey = keyof typeof validationList.value;

const formisValid = ref<boolean>(false);
const callbackValid = (pointer: ValidationKey, value: boolean) => {
  console.log("clb: ",pointer,value);
  
  validationList.value[pointer].valid = value;
  computeFormValidation(validationList.value, formisValid);
};
// VALIDATION END
</script>

<template>
  <div class="backdrop">
    <div v-if="finishedLoading" class="bg-theme-bg-1 hola-deposit-container">
      <div class="flex flex-right">
        <span
          @click="handleClose"
          class="svg-icon mga-5 svg-icon block icon-16 icon-form-clear bg-1-3 cursor-pointer"
        ></span>
      </div>
      <div class="form-size">
        <form @submit.prevent v-if="!handlerResponse?.isAdditionalData">
          <p class="txt-size-35 font-Aldrich txt-2-1 txt-center">
            {{ translate("common.deposit") }}
          </p>

          <div class="ballance-wrapper-content pdx-6 mgb-4">
            <div class="flex w-700px mgt-7">
              <div
                class="radius-left-26 bg-1-3 border-1-2 border-width-1 w-30 h-44px flex flex-align-center pdl-7"
              >
                {{ translate("transactions.deposit.mobile.wallet_type") }}
              </div>
              <div class="w-70">
                <DropDownComponent
                  dropdownId="currency"
                  :label="translate('common.wallet')"
                  :options="wallets"
                  :selector="'name'"
                  :borderClass="'  border-1-2 border-width-1'"
                  :textCutBorder="'label-cut bg-1-3 border-top-1-2 border-width-1 txt-light'"
                  :bgClass="'bg-1-3 '"
                  :radiusClass="'radius-right-26'"
                  @emitSelected="selectWallet($event.id)"
                  v-model:isTouched="validationList.wallet.isTouched"
                  @isValid="callbackValid('wallet', $event)"
                ></DropDownComponent>
              </div>
            </div>

            <div class="flex w-700px mgt-5">
              <div
                class="radius-left-26 bg-1-3 border-1-2 border-width-1 w-30 h-44px flex flex-align-center pdl-7"
              >
                {{ translate("transactions.deposit.mobile.select_bonus") }}
              </div>
              <div class="w-70">
                <DropDownComponent
                  dropdownId="bonus"
                  :label="translate('common.bonus')"
                  :options="bonuses"
                  :selector="'title'"
                  @emitSelected="setBonus"
                  :borderClass="'  border-1-2 border-width-1'"
                  :textCutBorder="'label-cut bg-1-3 border-top-1-2 border-width-1 txt-light'"
                  :bgClass="'bg-1-3 '"
                  :radiusClass="'radius-right-26'"
                  v-model:isTouched="validationList.bonus.isTouched"
                  @isValid="callbackValid('bonus', $event)"
                ></DropDownComponent>
              </div>
            </div>

            <div class="flex w-700px mgt-5">
              <div
                class="radius-left-26 bg-1-3 border-1-2 border-width-1 w-30 h-44px flex flex-align-center pdl-7"
              >
                {{ translate("common.amount") }}
              </div>
              <div
                class="w-70 bg-1-3 radius-right-26 border-1-2 border-width-1 flex"
              >
                <div class="flex flex-align-center h-100 pdx-7">
                  {{ currency }}
                </div>

                <div class="flex flex-align-center h-100">
                  <input
                    @input="
                      validationList.amount.isTouched = true;
                      callbackValid('amount', true);
                    "
                    v-model="depositAmount"
                    class="h-70 radius-5 txt-size-15 pdl-3 w-140px"
                  />
                </div>
                <div class="flex space-between w-100 pdl-6 pdr-6">
                  <div
                    v-for="(price, i) in pricePoints"
                    :key="i"
                    class="flex flex-align-center h-100"
                  >
                    <div
                      @click="selectPricePoint(price)"
                      class="h-34px txt-2-1 border-2-1 border-width-1 radius-17 flex flex-center pdx-3 txt-size-18 txt-weight-600 font-Poppins cursor-pointer"
                    >
                      {{ price }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex w-700px">
              <div class="w-30"></div>
              <div class="w-70">
                <span class="pdl-4 txt-error-1" v-if="validationList.amount.isTouched && !validationList.amount.valid">
                {{validationList.amount.message}}</span>
              </div>
            </div>

            <p class="txt-2-1 mgb-4 mgt-7">
              {{ translate("transactions.deposit.mobile.payment_methods") }}
            </p>

            <div
              class="mgb-4 flex deposit-providers flex-wrap fit-content mgx-auto"
            >
              <div
                :class="` txt-center  fit-content mgx-1`"
                v-for="(provider, i) in providers"
                v-bind:key="i"
                :id="provider.provider"
              >
                <div
                  class="border-piq radius-6 h-65px w-119px mgt-2"
                  :class="selectedPiQ == i ? ' selected-shadow' : ' '"
                >
                  <!-- w-117px -->
                  <img
                    @click="selectedMethod(i)"
                    :src="provider.images.select.src"
                    :alt="provider.images.select.alt"
                    class="cursor-pointer radius-6 h-65px w-119px"
                    :class="selectedPiQ == i ? 'shadowbox' : ' '"
                  />
                </div>
              </div>
            </div>
            <p
              v-if="handlerResponse?.isError"
              class="mgt-3 txt-error-1 mgx-5 txt-bold"
            >
              {{ handlerResponse?.responseMessage }}
            </p>
            <FormWarningComponent
              v-if="
                !validationList.method.valid && validationList.method.isTouched
              "
              :text="validationList.method.message"
            />
            <button
              id="submit"
              class="mgx-auto w-100px h-50px bg-2-1 radius-10 font-Poppins txt-weight-600 txt-size-16 txt-1-3 mgy-5 hover-border"
              @click="submit"
            >
              <!-- :disabled="
                !(selectedPiQ >= 0) ||
                !depositAmount ||
                !selectedWallet ||
                !selectedBonus
              " -->
              {{ translate("common.submit") }}
            </button>
          </div>
        </form>
        <div
          class="backdrop"
          v-if="handlerResponse?.isAdditionalData && deposit"
        >
          <div
            class="modal-Centered modal-bg zindex-modal pda-5 selected-shadow"
            v-if="handlerResponse?.isAdditionalData && deposit"
          >
            <AdditionalDataWrapper
              v-if="handlerResponse?.isAdditionalData && deposit"
              :deposit="deposit"
              @back="back"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="css">
.hola-deposit-container {
  box-shadow: 0px 3px 15px #000000;
  border-radius: 10px;
  width: 880px;
  z-index: 500;
  overflow-y: auto;
  max-height: 70vh;
  background: transparent linear-gradient(180deg, #23008a 0%, #000635 100%) 0%
    0% no-repeat padding-box;
}

.selected-shadow {
  box-shadow: 0px 0px 10px 7px #3bbdef;
}
.modal-bg {
  background: transparent linear-gradient(180deg, #23008a 0%, #000635 100%) 0%
    0% no-repeat padding-box;
}
</style>
