<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from "vue";
import type { BonusInterface } from "@/services/interface/bonuses";
import { translate } from "@/services/translations/translation";
import { useBonusesStore } from "@/stores/bonuses";
import dollar from "@/assets/icons/promotions/dollar.svg";
import chip from "@/assets/icons/promotions/chip.svg";
import puzzle from "@/assets/icons/promotions/puzzle.svg";
import BonusComponent from "@/brands/holahola/components/promotions/BonusComponent.vue";
import IconComponent from "@/components/icons/IconComponent.vue";
import BannerElement from "@/brands/holahola/components/promotions/BannerElement.vue";
import { goToRoute } from "@/router/routingService";
import axios from "axios";
import WarningComponent from "@/brands/bit1111/components/bonus/WarningComponent.vue";

const bonusesApplied = ref<Array<BonusInterface>>([]);
const bonusesAvailable = ref<Array<BonusInterface>>([]);
const bonusesUnavailable = ref<Array<BonusInterface>>([]);
const totalItems = ref<number>(0);
const selectedCategory = ref("available");
const selectedCategoryIndex = ref<number>(0);
const bonusList = ref<Array<any>>([]);
const isLoaded = ref(false);
const bonusStore = useBonusesStore();
const isDescriptionPageOpened = ref<boolean>(false);
const descriptionPageBonus = ref<BonusInterface>();
const isAnyBonusActive = ref(false);
const translateStatus = ref();
const translateStatusColor = ref();
const defaultImage = ref(
  "https://bshots.egcvi.com/thumbnail/hippo2_imr_med_M.jpg"
);

const getBonusesForPromotionsPage = async () => {
  const bonusPayload = await bonusStore.getAllBonuses();
  bonusesAvailable.value = bonusPayload.availableBonuses;
  bonusesApplied.value = bonusPayload.appliedBonuses;
  bonusesUnavailable.value = bonusPayload.unavailableBonuses;
  bonusList.value = [];

  switch (selectedCategory.value) {
    case "available":
      bonusList.value = bonusesAvailable.value;
      break;
    case "applied":
      bonusList.value = bonusesApplied.value;
      break;
    case "unavailable":
      bonusList.value = bonusesUnavailable.value;
      break;
    default:
      break;
  }
  totalItems.value = bonusList.value?.length;
  isLoaded.value = true;
};

const catchCategory = (category: any, i: number) => {
  selectedCategory.value = category.case;
  selectedCategoryIndex.value = i;
  getBonusesForPromotionsPage();
};
const readMore = (bonus: BonusInterface) => {
  isDescriptionPageOpened.value = true;
  descriptionPageBonus.value = bonus;
  window.scrollTo(0, 0);
};
const doBonusAction = async (bonus: BonusInterface, status: number) => {
// bonus event 1 == Signup, 2 == Deposit, 4 == referal bonus.
  if(bonus.bonusAward.bonus.bonusType.bonusEvent == 1 || bonus.bonusAward.bonus.bonusType.bonusEvent == 4 ){
    await bonusStore.acceptOrDeclineOrClaimLaterBonus(bonus.id, status);
    await bonusStore.getAllBonuses();
    isDescriptionPageOpened.value = false;  
    getBonusesForPromotionsPage(); 
  }else if (bonus.bonusAward.bonus.bonusType.bonusEvent == 2){
    goToRoute("/deposit")
  }
 
};
const checkForActiveBonus = async() => {
  await axios.get("/obtained/bonus/active/check")
  .then((response) => {
    if(response.data.data.active){
      isAnyBonusActive.value = true;
    }
  })
  .catch((error)=> {
    console.log(error)
  })
  
}
onMounted(() => {
  getBonusesForPromotionsPage();
});
watch(isDescriptionPageOpened, (value) => {
  value ? checkForActiveBonus() : null
})
const categories = [
  {
    name: translate("promotions.desktop.available"),
    icon: dollar,
    case: "available",
  },
  {
    name: translate("promotions.desktop.accepted"),
    icon: chip,
    case: "applied",
  },
  {
    name: translate("promotions.desktop.declined"),
    icon: puzzle,
    case: "unavailable",
  },
];
const getProperStatusText = () => {
  switch (descriptionPageBonus.value?.status) {
    case 2:
      translateStatus.value = "accepted";
      translateStatusColor.value = "txt-success-1";
      break;
    case 3:
      translateStatus.value = "applied";
      translateStatusColor.value = "txt-success-1";
      break;
    case 4:
      translateStatus.value = "expired";
      translateStatusColor.value = "txt-error-1";
      break;
    case 5:
      translateStatus.value = "declined";
      translateStatusColor.value = "txt-error-1";
      break;
  }
};
onMounted(() => {
  getProperStatusText();

  watch(descriptionPageBonus, () => {
  getProperStatusText();
});
});

</script>

<template>
  <div v-if="!isDescriptionPageOpened" class="main-promo">
    <div class="coverImg"></div>
    <div class="zindex-overlay relative flex mgy-5 fit-content mgx-auto">
      <div
        @click="catchCategory(category, i)"
        v-for="(category, i) in categories"
        :key="i"
        class="txt-center mgx-6 cursor-pointer"
      >
        <img :src="category.icon" alt="" class="square-32" />
        <p
          class="no-wrap txt-size-12"
          :class="selectedCategoryIndex == i ? 'txt-3-1' : 'txt-light'"
        >
          {{ category.name }}
        </p>
      </div>
    </div>
    <div v-if="isLoaded" class="flex flex-wrap flex-center mgy-5">
      <BonusComponent
        v-for="(bonus, i) in bonusList"
        :key="i"
        :isMobile="false"
        :bonus="bonus"
        @readMore="readMore($event)"
      />
    </div>
  </div>
  <div v-else class="w-100 bg-light flex flex-center">
    <div class="maxw-1323px w-100 mgx-5 pdt-5">
      <div
        @click="isDescriptionPageOpened = !isDescriptionPageOpened"
        class="cursor-pointer flex flex-align-center"
      >
        <IconComponent
          iconClasses="svg-icon icon-12 icon-angle bg-4-1 block mgx-2 "
        ></IconComponent>
        <p class="txt-4-1 txt-size-18 pdt-1">
          {{ translate("common.goBack").toUpperCase() }}
        </p>
      </div>
      <div class="relative flex flex-center flex-column">
        <div
          v-if="descriptionPageBonus?.banner"
          class="block w-100 h-320px radius-top-10"
        >
          <BannerElement
            :banner="descriptionPageBonus.banner"
            :index="descriptionPageBonus.id"
          />
        </div>
        <img
          v-if="!descriptionPageBonus?.banner"
          :src="
            descriptionPageBonus?.bonusAward.bonus.image
              ? descriptionPageBonus.bonusAward.bonus.image
              : defaultImage
          "
          class="block h-240px radius-10"
        /><div
                v-if="descriptionPageBonus?.status != 1"
                class="absolute top-0 w-100 bonus-status txt-center txt-size-30 radius-top-10"
                :class="translateStatusColor"
              >
                {{
                  translate(
                    `promotions.desktop.${translateStatus}`
                  ).toUpperCase()
                }}
              </div>
        <p class="txt-1-1 txt-size-35">
          {{ descriptionPageBonus?.bonusAward.bonus.name }}
        </p>
        <p class="txt-dark txt-size-16">
          {{ descriptionPageBonus?.bonusAward.bonus.description }}
        </p>
        <div class="w-400px">
        <WarningComponent v-if="isAnyBonusActive" />
      </div>
        <div class="flex">
          <button
          v-if="descriptionPageBonus?.status == 1"
            @click="doBonusAction(descriptionPageBonus!, 2)"
            class="bg-1-1 radius-10 h-50px w-432px txt-light mgy-7 mgx-3 txt-bold"
          >
            {{ translate("common.claim").toUpperCase() }}
          </button>
          <button
          v-if="descriptionPageBonus?.status == 1"
            @click="doBonusAction(descriptionPageBonus!, 5)"
            class="bg-1-1 radius-10 h-50px w-432px txt-light mgy-7 mgx-3 txt-bold"
          >
            {{ translate("common.decline").toUpperCase() }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="css">
.coverImg {
  background: transparent url("https://i.ibb.co/WBc79Y8/promos-page-bg.png") 0%
    0% no-repeat padding-box;
  position: absolute;
  top: 80px;
  left: 0px;
  width: 100%;
  height: 999px;
  z-index: 0;
}
.promo-details {
  background: transparent linear-gradient(0deg, #23008a 0%, #000635 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 3px 0px #000000;
  border-radius: 0px 0px 10px 10px;
  opacity: 0.6;
}
.main-promo {
  background: transparent
    linear-gradient(0deg, #3bbdef 0%, #23008a 20%, #000635 40%) 0% 0% no-repeat
    padding-box;
  min-height: 2000px;
}
.bonus-status {
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
}
</style>
