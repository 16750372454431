import { defineStore } from "pinia";
import type { PlayerData } from "./contracts/PlayerData";

export interface Currency{
  code:string,
  id:number
}

export interface WalletInterface {
  total?: number;
  balance?: number;
  bonusBalance?: number;
  reservedFunds?: number;
  wageredAmount?: number;
  totalWageringAmount?: number;
  remainingWageringAmount?: number;
  allowedPayoutAmount?: number;
  currency?:Currency
}

export interface Balance {
  casino: WalletInterface;
  sportbook: WalletInterface;
}
export type BalanceKey = keyof Balance;
export const useBalanceStore = defineStore({
  id: "balance",
  state: () => ({
    player: <PlayerData | null>null,
    balance: <Balance>{
      casino: {
        total: 0,
        balance: 0,
        bonusBalance: 0,
        reservedFunds: 0,
        wageredAmount: 0,
        totalWageringAmount: 0,
        remainingWageringAmount: 0,
        allowedPayoutAmount: 0,
        currency:{
          code:'USD',
          id:2
        }
      },
      sportbook: {
        total: 0,
        balance: 0,
        bonusBalance: 0,
        reservedFunds: 0,
        wageredAmount: 0,
        totalWageringAmount: 0,
        remainingWageringAmount: 0,
        allowedPayoutAmount: 0,
        currency:{
          code:'USD',
          id:2
        }
      },
    },
  }),
  getters: {},
  actions: {
    loadBalance(data: PlayerData) {
      this.player = data;
      if (this.player != null) {
        this.balance.casino = {
          total:
            parseFloat(this.player.wallets.casino.balance) +
            parseFloat(this.player.wallets.casino.bonusBalance),
          balance: parseFloat(this.player.wallets.casino.balance),
          bonusBalance: parseFloat(this.player.wallets.casino.bonusBalance),
          reservedFunds: parseFloat(this.player.wallets.casino.reservedFunds),
          totalWageringAmount:
            this.player.wallets.casino.wagering.originalWagering,
          remainingWageringAmount:
            this.player.wallets.casino.wagering.remainingWagering,
          allowedPayoutAmount:
            this.player.wallets.casino.wagering.allowedPayoutAmount,
            currency: this.player.wallets.casino.currency
        };
        this.balance.sportbook = {
          total:
            parseFloat(this.player.wallets.sportBook.balance) +
            parseFloat(this.player.wallets.sportBook.bonusBalance),
          balance: parseFloat(this.player.wallets.sportBook.balance),
          bonusBalance: parseFloat(this.player.wallets.sportBook.bonusBalance),
          reservedFunds: parseFloat(this.player.wallets.sportBook.reservedFunds),
          remainingWageringAmount:
            this.player.wallets.sportBook.wagering.remainingWagering,
          totalWageringAmount:
            this.player.wallets.sportBook.wagering.originalWagering,
          allowedPayoutAmount:
            this.player.wallets.sportBook.wagering.allowedPayoutAmount,
            currency: this.player.wallets.sportBook.currency
        };
      }
    },

    getAllowedPayout(wallet: BalanceKey) {
      return this.balance[wallet].allowedPayoutAmount;
    },
    getBalance(wallet: BalanceKey) {
      return this.balance[wallet].total;
    },
    getWallet(wallet: BalanceKey) {
      return this.balance[wallet];
    },
    setBalance(balance: string, bonusBalance: string, wallet: BalanceKey) {
      this.balance[wallet].total =
        parseFloat(balance) + parseFloat(bonusBalance);
      this.balance[wallet].balance = parseFloat(balance);
      this.balance[wallet].bonusBalance = parseFloat(bonusBalance);
    },
    setReservedFunds(fund: string, wallet: BalanceKey) {      
      this.balance[wallet].reservedFunds = parseFloat(fund);
    },
    setWagering(
      allowedPayoutAmount: number,
      totalWageringAmount: number,
      remainingWageringAmount: number,
      wallet: BalanceKey
    ) {
      this.balance[wallet].allowedPayoutAmount = allowedPayoutAmount;
      this.balance[wallet].totalWageringAmount = totalWageringAmount;
      this.balance[wallet].remainingWageringAmount = remainingWageringAmount;
    },
    setWallet(walletData: WalletInterface, wallet: BalanceKey) {
      this.balance[wallet] = walletData;
    },
  },
});
