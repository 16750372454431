<script setup lang="ts">
import {ref} from 'vue';
import axios from 'axios';
import { useBrandStore } from "../../stores/brand";
import InputValueComponent from "../../components/shared/forms/input/InputValueComponent.vue";
import TextareaValueComponent from "../../components/shared/forms/input/TextareaValueComponent.vue";
import NotificationComponent from "../../components/shared/notification/NotificationComponent.vue";
import { useAuthStore } from "../../stores/auth";
import Captcha from "../../components/shared/captcha/CaptchaComponent.vue";
import { useLoaderStore } from "../../stores/loader";

const loader = useLoaderStore();

const authStore = useAuthStore();

const brandStore = useBrandStore();
const name = ref();
const email = ref();
const username = ref();
const disabledInputs = ref(false);
const subject = ref();
const textArea = ref();
const submited = ref(false);
const captchaCode = ref();
const captchaHash = ref();
const submitFailed = ref(false);
const errorMsg = ref();

if (authStore.player !== null) {
    name.value = authStore.player?.firstName + " " + authStore.player?.lastName;
    email.value = authStore.player?.email;
    username.value = authStore.player?.username;
    disabledInputs.value = true;
}


// VALIDATION START
const newValidationList = ref({
  name: String, 
  email: String, 
  username: String,
  textarea: String,
  captcha: String
});
type ValidationKey = keyof typeof newValidationList.value;

const formisValid = ref();
const callbackValid = (pointer: ValidationKey, value: StringConstructor) => {
  newValidationList.value[pointer] = value;
  computeFormValidation();
};
const computeFormValidation = () => {
  let flag = true;
  let key: ValidationKey;
  for (key in newValidationList.value) {
    if (!newValidationList.value[key]) flag = false;
  }
  formisValid.value = flag;
};
// VALIDATION END




//TODO: Waiting on BE endpoint
const submit = async () => {
  submited.value = false;
  submitFailed.value = false;
  loader.toggleLoader(true);
    await axios.post("/contact/us", {
      name: name.value,
      email: email.value,
      username: username.value, 
      subject: subject.value,
      message: textArea.value, 
      captcha: captchaCode.value
    }, {
      headers : {
        "Captcha-Hash" : captchaHash.value
      }
    })
    .then((response) => {
      submited.value = true;
      loader.toggleLoader(false);
    })
    .catch((err) => {
      submitFailed.value = true;
      errorMsg.value = err.response.data.message
      loader.toggleLoader(false);
    })
      loader.toggleLoader(false);
};

</script>

<template>
<div class="form-size"> 
    <form class="complete-registration-form pdx-4 form-size txt-theme-brand-1" @submit.prevent>
        <h3 class=" title-large text-spacing-xl txt-center mgt-6">Support</h3>
        <p class=" title-smallest txt-bold txt-center mgy-6">Contact our {{brandStore.name}} Support Team!</p>
        <p class=" text-smallest txt-center inline-block">{{brandStore.name}} offers several options to get in touch. You can use the below email contact form, our
        <p class=" txt-bold inline-block">Live Chat</p> or you can directly send us an email to <a :href="`mailto:support@${brandStore.name}.com`" class="txt-theme-brand-1 txt-bold inline-block">support@{{brandStore.name}}.com</a>.</p>
        <NotificationComponent
        class="mgy-6"
        v-if="submited"
        title="Your message was successfully sent!"
        messageBody="Thank you for contacting us. We will reply to your inquiry as soon as possible."
        bgClass="bg-success"
        iconClass="bg-theme-txt-1 icon-success-small mga-5"
        textColor="txt-theme-txt-1"
        @emitDismiss="submited = false"
        ></NotificationComponent>
        <NotificationComponent
        class="mgy-6"
        v-if="submitFailed"
        title="Your message failed to submit!"
        :messageBody="errorMsg"
        bgClass="bg-error"
        iconClass="bg-theme-txt-1 icon-success-small mga-5"
        textColor="txt-theme-txt-1"
        @emitDismiss="submited = false"
        ></NotificationComponent>
        <InputValueComponent
        inputId="name"
        placeholder="Name"
        inputType="text"
        v-model="name"
        :minLength="0"
        :maxLength="255"
        @isValid="callbackValid('name', $event)"
        :isDisabled="disabledInputs"
        ></InputValueComponent>
        <InputValueComponent
        inputId="email"
        placeholder="Email"
        inputType="email"
        v-model="email"
        :minLength="0"
        :maxLength="255"
        @isValid="callbackValid('email', $event)"
        :isDisabled="disabledInputs"
        ></InputValueComponent>
        <InputValueComponent
        inputId="username"
        placeholder="Username (optional)"
        inputType="text"
        v-model="username"
        :minLength="0"
        :maxLength="255"
        :disableValidation="true"
        :isDisabled="disabledInputs"
        ></InputValueComponent>
        <InputValueComponent
        inputId="subject"
        placeholder="Subject"
        inputType="text"
        v-model="subject"
        :minLength="0"
        :maxLength="255"
        @isValid="callbackValid('username', $event)"
        ></InputValueComponent>
        <TextareaValueComponent
        :lines="10"
        inputId="textarea"
        placeholder="Your Message"
        inputType="text"
        v-model="textArea"
        :minLength="0"
        :maxLength="255"
        @isValid="callbackValid('textarea', $event)"
        ></TextareaValueComponent>
        <Captcha
        v-if="!submited"
        @header="captchaHash = $event"
        @code="captchaCode = $event"
        @isValid="callbackValid('captcha', $event)"
        ></Captcha>
        <button
        id="submit"
        class="btn btn-large btn-brand-light mgx-auto mgy-6 txt-bold"
        :disabled="!formisValid"
        @click="submit"
      >
        SEND
      </button>
    </form>
</div>
</template>