<script setup lang="ts">
import { goToRoute } from "@/router/routingService";
import { translate } from "@/services/translations/translation";
import IconComponent from "@/components/icons/IconComponent.vue";
import { RouterSingleton } from "@/router/index";

const route = RouterSingleton.getInstance().getRouter().currentRoute.value.name;

const props = defineProps({
  isMobile: {
    required: false,
    type: Boolean,
    default: false,
  },
  seeMoreGames: {
    required: false,
    type: Boolean,
    default: true,
  },
  transitionValue: {
    required: true,
    type: Number,
  },
  carouselLength: {
    required: true,
    type: Number,
  },
  currentPage: {
    required: true,
    type: Number,
  },
});

const emit = defineEmits(["moveToPage"]);

const moveToPage = (value: number) => {
  value < 7 ? emit("moveToPage", value) : null;
};

const rerouteWrapper = () => {
  if (route == "casino") {
    RouterSingleton.getInstance().reloadPage();
  } else {
    goToRoute("/casino");
  }
};
</script>
<template>
  <div
    class="mgt-5 flex flex-center flex-column"
    :class="!props.seeMoreGames ? 'pdb-7' : 'pdb-15'"
  >
    <span v-if="carouselLength > 1" class="flex flex-center pdt-5">
      <IconComponent
        @click="moveToPage(currentPage - 1)"
        iconClasses="svg-icon icon-20 icon-angle bg-1-1 block mgx-2 cursor-pointer slider-arrow"
      ></IconComponent>
      <div :class="isMobile ? 'mgx-1' : 'mgx-10'" class="flex">
        <button
          @click="moveToPage(i)"
          v-for="(btn, i) of carouselLength"
          v-show="i < 7"
          :key="i"
          :class="
            i === currentPage
              ? 'bit-carousel-indicator bit-current-slide'
              : 'bit-carousel-indicator'
          "
        ></button>
      </div>
      <IconComponent
        @click="moveToPage(currentPage + 1)"
        iconClasses="svg-icon rotate-180 icon-20 icon-angle bg-1-1 block mgx-2 cursor-pointer slider-arrow"
      ></IconComponent
    ></span>
    <span v-if="props.seeMoreGames" class="h-1px w-100 relative bg-5-2 mgt-7"
      ><p
        @click="rerouteWrapper()"
        :class="isMobile ? 'text-position-mobile' : 'text-position'"
        class="absolute  pdx-5 text-small txt-1-1 underlined cursor-pointer"
      >
        {{ translate("casino.components.see_more_games") }}
      </p></span
    >
  </div>
</template>
