<script setup lang="ts">
import { ref } from "vue";
import CheckBoxGroupComponent from "@/components/shared/forms/checkbox/CheckBoxGroupComponent.vue";
import RadioInputGroup from "@/components/shared/forms/radio/RadioInputGroup.vue";
import IconComponent from "@/components/icons/IconComponent.vue";
import RadioInputComponent from "@/components/shared/forms/radio/RadioInputComponent.vue";
import { questions } from "@/services/data/questionnaire";
import type { QuestionnaireType, OptionsType } from "@/services/interface/questionnaire";

const submitted = ref(false);
const allQuestions = ref(questions);
const formisValid = ref(false);
const checkedItem = ref<Array<number>>([]);
const submit = () => {
  submitted.value = true;
  window.scrollTo(0, 0);
};

const validateForm = (
  question: QuestionnaireType,
  item: OptionsType | null,
  type: string
) => {
  if (type == "radio" && item) {
    validateRadioFields(question, item);
  }
  if (type == "checkbox") {
    validateCheckboxFields(question);
  }
  allowSubmit(question);
};

const validateCheckboxFields = (question: QuestionnaireType) => {
  allQuestions.value.forEach((el) => {
    if (el.id == question.id) {
      el = question;
    }
  });
};

const validateRadioFields = (question: QuestionnaireType, item: OptionsType) => {
  allQuestions.value.forEach((element: QuestionnaireType) => {
    if (element.id == question.id) {
      element?.options?.forEach((el) => {
        el.id == item.id ? (el.isChecked = true) : (el.isChecked = false);
      });
    }
  });
};

const allowSubmit = (question: QuestionnaireType) => {
  let counter = 0;
  question.options?.forEach((item: OptionsType) => {
    if (item.isChecked) {
      counter++;
      if (!checkedItem.value.includes(question.id) && counter > 0)
        checkedItem.value.push(question.id);
    }
    if (counter == 0) {
      checkedItem.value = checkedItem.value.filter((item) => item !== question.id);
    }
  });
  checkedItem.value.length == allQuestions.value.length
    ? (formisValid.value = true)
    : (formisValid.value = false);
};
</script>
<template>
  <div class="kyc-wrapper card flex-center">
    <div class="card-page">
      <div v-if="submitted" class="bg-success radius-6 mgb-4 pdy-6 pdr-6 flex">
        <div class="flex flex-center pdx-6">
          <IconComponent
            :iconClasses="`icon-20 scale-13 inline-block bg-light icon-check`"
          ></IconComponent>
        </div>
        <div>
          <p class="text-smallest txt-bold pda-0">Answers submitted!</p>
          <p class="text-smallest pda-0">Thank you for sharing with us.</p>
        </div>
      </div>
      <div v-if="!submitted">
        <div class="txt-center mgy-5">
          <p class="txt-bold title-small txt-theme-brand-1">
            We would like to get to know you a little bit
          </p>
        </div>
        <div v-for="question in allQuestions" :key="question.title">
          <div v-if="question.type == 'checkbox'">
            <p class="text-small txt-bold pdb-0 pdt-4 txt-theme-brand-1">
              {{ question.title }}
            </p>
            <CheckBoxGroupComponent
              :options="question.options"
              :min-checked="question.minChecked"
              @onCheckboxGroupChange="validateForm(question, null, 'checkbox')"
            >
            </CheckBoxGroupComponent>
          </div>
          <div v-if="question.type == 'radio'">
            <p class="text-small txt-bold pdb-0 pdt-4 txt-theme-brand-1">
              {{ question.title }}
            </p>
            <RadioInputGroup>
              <RadioInputComponent
                :wrapper-class="'radio-style-questionnaire'"
                v-for="item in question.options"
                @click="validateForm(question, item, 'radio')"
                v-bind:key="item.id"
                :labelText="item.title"
                :id="item.id"
                :classLabel="item.isChecked ? 'clicked ml-2 ' : 'no-border ml-2'"
              ></RadioInputComponent>
            </RadioInputGroup>
          </div>
        </div>
        <div class="flex flex-center mgy-6">
          <button
            @click="submit"
            class="btn-large w-100 bg-theme-brand-1 txt-brand-txt-1 txt-bold title-small"
            :disabled="!formisValid"
          >
            SUBMIT YOUR ANSWERS
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
