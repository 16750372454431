<script setup lang="ts">
import { ref, watch } from "vue";
import DropDownComponent from "@/components/shared/forms/dropdown/DropDownComponent.vue";
import { useAuthStore } from "@/stores/auth";
import { exclusionTypes } from "@/services/data/selfExclusion";
import type {
  ActiveExclusionElement,
  DropdownEvent,
} from "@/services/data/selfExclusion";
import IconComponent from "@/components/icons/IconComponent.vue";
import axios from "axios";
import { useLoaderStore } from "@/stores/loader";
import NotificationComponent from "@/components/shared/notification/NotificationComponent.vue";
// import DatePickerValidation from "@/components/shared/forms/input/DatePickerValidation.vue";
import { formatDatePicker } from "@/services/formatting/formatDatePicker";
import { translate } from "@/services/translations/translation";

const loader = useLoaderStore();
const selectedPeriod = ref();
const exclusionType = ref();
const gameOrSupplierId = ref<Array<number> | null>(null);
const selectedSupplier = ref<Array<DropdownEvent> | null>(null);
const selectedGame = ref<Array<DropdownEvent> | null>(null);
const date = ref();
const dateFormated = ref();
const time = ref("180");
const formHidden = ref(useAuthStore().player?.hiddenExclusionForm);
const openForm = ref(false);
const notificationError = ref(false);
const notificationErrorMsg = ref();
const activeExclusionElement = ref();
const selectedType = ref();

watch(
  selectedPeriod,
  (value) => {
    if (value !== "until_date") {
      callbackValid("date", value);
    }
  },
  { deep: true }
);

watch(
  selectedType,
  (value) => {
    if (value !== "game" || value !== "supplier") {
      callbackValid("supplierOrGame", value);
    }
  },
  { deep: true }
);
const formatDateValue = () => {
  dateFormated.value = formatDatePicker(date.value);
};

const activeExclusions = async () => {
  loader.toggleLoader(true);
  useAuthStore().setPlayerData();
  await axios
    .get("/exclusion/list-active")
    .then((response) => {
      if (response.data.data.length > 0) {
        activeExclusionElement.value = response.data.data;
        if (formHidden.value) {
          openForm.value = false;
        }
      }
      loader.toggleLoader(false);
    })
    .catch((e) => {
      console.log("activeExclusions error", e), loader.toggleLoader(false);
    });
  loader.toggleLoader(false);
};
activeExclusions();
const authStore = useAuthStore();
const submit = async () => {
  if (selectedPeriod.value == "until_date" && date.value) {
    formatDateValue();
    time.value = dateFormated.value;
  } else if (selectedPeriod.value == "period") {
    time.value = "180";
  } else {
    time.value = "10";
  }
  loader.toggleLoader(true);

  await axios
    .post("/exclusion", {
      exclusionType: selectedType.value,
      exclusionValue: gameOrSupplierId.value,
      timeType: selectedPeriod.value,
      timeValue: time.value,
      isActive: true,
      isHidden: false,
      behaviorIssue: false,
    })
    .catch((error) => {
      loader.toggleLoader(false);
      notificationError.value = true;
      notificationErrorMsg.value = error.response.data.errors[0];
    });
  selectedType.value == "all" ? authStore.logout() : null;
  loader.toggleLoader(false);
  formHidden.value = true;
  activeExclusions();
};

// VALIDATION START
const newValidationList = ref({
  // gender: Boolean,
  serviceTypes: Boolean,
  exclusionType: Boolean,
  supplierOrGame: Boolean,
  period: Boolean,
  date: Boolean,
});
type ValidationKey = keyof typeof newValidationList.value;

const formisValid = ref(false);
const callbackValid = (pointer: ValidationKey, value: BooleanConstructor) => {
  newValidationList.value[pointer] = value;
  computeFormValidation();
};

const exclusionValue = (event: Array<DropdownEvent>) => {
  if (event) {
    gameOrSupplierId.value = [];
    event.forEach((element: DropdownEvent) => {
      gameOrSupplierId.value?.push(element.id);
    });
    selectedType.value.id == "supplier"
      ? (selectedSupplier.value = event)
      : (selectedGame.value = event);
  }
};
const computeFormValidation = () => {
  let flag = true;
  let key: ValidationKey;
  for (key in newValidationList.value) {
    if (!newValidationList.value[key]) flag = false;
  }
  formisValid.value = flag;
};
// VALIDATION END
</script>

<template>
  <div class="complete-registration-form pdt-6">
    <div v-if="activeExclusionElement" class="bg-theme-bg-2 radius-6 pda-4 mgt-4">
      <div v-for="(item, i) in activeExclusionElement">
        <p class="text-small text-spacing-large txt-bold mgt-2">
          {{ translate("responsible_gaming.self_exclusion.active") }}:
          {{ item.exclusionType.toUpperCase() }}
        </p>
        <p class="text-small text-spacing-xl">
          {{ translate("responsible_gaming.self_exclusion.editable") }}:
          {{ item.editableFrom.split("T").join(" ") }}
        </p>
        <p v-if="item.exclusionValue.length > 0" class="text-small text-spacing-xl">
          {{ translate("responsible_gaming.self_exclusion.excluded") }}:
        </p>
        <p
          class="text-small text-spacing-xl pdl-4"
          v-for="(game, i) in item.exclusionValue"
          :key="i"
        >
          - {{ game.name }}
        </p>
      </div>
    </div>
    <NotificationComponent
      v-show="notificationError"
      class="mgy-3"
      :title="translate('responsible_gaming.self_exclusion.notification_fail')"
      :messageBody="notificationErrorMsg"
      bgClass="bg-error"
      @emitDismiss="notificationError = false"
    ></NotificationComponent>

    <div
      v-if="!formHidden"
      @click="openForm = !openForm"
      :class="openForm ? 'radius-top-6' : 'radius-6'"
      class="mgt-3 w-100 bg-theme-brand-2 pdx-3 flex flex-left-center cursor-pointer"
    >
      <h2 class="txt-left mgy-2 title-medium txt-theme-bg-1 inline-block w-100">
        {{ translate("responsible_gaming.self_exclusion.form") }}
      </h2>
      <IconComponent
        :class="openForm ? 'rotate-up mgt-3' : 'mgb-1'"
        :iconClasses="`icon-18 inline-block bg-theme-bg-1 icon-dropdown mgl-4 mgr-1`"
      ></IconComponent>
    </div>
    <div v-if="openForm" class="pdx-3 border-brand-2 radius-bottom-6">
      <div class="col-12 flex flex-left-center">
        <div class="col-6">
          <p class="text-smallest">
            {{ translate("responsible_gaming.self_exclusion.sure") }}
          </p>
        </div>

        <div class="col-6">
          <DropDownComponent
            :options="exclusionTypes"
            dropdownId="exclusionType"
            :selector="'name'"
            :label="translate('responsible_gaming.self_exclusion.type_label')"
            @emitSelected="exclusionType = $event.id"
            @isValid="callbackValid('exclusionType', $event)"
          ></DropDownComponent>
        </div>
      </div>
      <div class="col-12 flex flex-left-center">
        <div class="col-6">
          <p class="text-smallest">
            {{ translate("responsible_gaming.self_exclusion.period") }}
          </p>
        </div>
        <div class="col-6">
          <DropDownComponent
            dropdownId="period"
            url="exclusion-time-types"
            :selector="'name'"
            :label="translate('responsible_gaming.self_exclusion.exclusion_label')"
            @emitSelected="selectedPeriod = $event.id"
            @isValid="callbackValid('period', $event)"
          ></DropDownComponent>
        </div>
      </div>
      <div
        v-if="selectedPeriod ? selectedPeriod == 'until_date' : ''"
        class="col-12 flex flex-left-center"
      >
        <div class="col-6">
          <p class="text-smallest">
            {{ translate("responsible_gaming.self_exclusion.amount") }}
          </p>
        </div>
        <!-- <div class="col-6 mgb-4">
          <DatePickerValidation
            @emitSelected="date = $event"
            :date="date"
            @isValid="callbackValid('date', $event)"
          >
          </DatePickerValidation>
        </div> -->
      </div>
      <div class="col-12 flex flex-left-center">
        <div class="col-6">
          <p class="text-smallest">
            {{ translate("responsible_gaming.self_exclusion.type") }}
          </p>
        </div>
        <div class="col-6">
          <DropDownComponent
            url="exclusion-types"
            dropdownId="serviceTypes"
            :selector="'name'"
            :label="translate('responsible_gaming.self_exclusion.service_label')"
            @emitSelected="selectedType = $event.id"
            @isValid="callbackValid('serviceTypes', $event)"
          ></DropDownComponent>
        </div>
      </div>
      <div
        v-if="selectedType ? selectedType == 'supplier' : ''"
        class="col-12 flex flex-left-center"
      >
        <div class="col-6 txt-up">
          <p>{{ translate("responsible_gaming.self_exclusion.provider") }}</p>
        </div>
        <div class="col-6">
          <DropDownComponent
            :multiple="true"
            :dropdownId="'provider'"
            :label="translate('responsible_gaming.self_exclusion.providers_label')"
            url="/dropdown/game-supplier"
            :selector="'name'"
            @emitSelected="exclusionValue"
            @isValid="callbackValid('supplierOrGame', $event)"
          ></DropDownComponent>
        </div>
      </div>
      <div
        v-if="selectedType ? selectedType == 'game' : ''"
        class="col-12 flex flex-left-center"
      >
        <div class="col-6">
          <p>{{ translate("responsible_gaming.self_exclusion.game") }}</p>
        </div>
        <div class="col-6">
          <DropDownComponent
            :multiple="true"
            :dropdownId="'provider'"
            :label="translate('responsible_gaming.self_exclusion.games_label')"
            url="games"
            :selector="'name'"
            @emitSelected="exclusionValue"
            @isValid="callbackValid('supplierOrGame', $event)"
          ></DropDownComponent>
        </div>
      </div>
      <button
        @click="submit"
        id="submit"
        class="btn-large btn-brand-light mgx-auto txt-bold pdy-0 mgb-3"
        :disabled="!formisValid"
      >
        {{ translate("responsible_gaming.self_exclusion.submit") }}
      </button>
    </div>
  </div>
</template>
