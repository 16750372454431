<script setup lang="ts">
import FlagWrapperComponent from "@/components/icons/FlagWrapperComponent.vue";
import IconComponent from "@/components/icons/IconComponent.vue";
import { languagesFlags } from "@/services/data/languagesFlags";
import axios from "axios";
import { ref } from "vue";
import type { LanguageElement } from "@/services/interface/language";
import { useTranslationStore } from "@/stores/translation";
import AllFlagsComponent from "@/components/icons/flags/AllFlagsComponent.vue";
import iconPlanet from "@/assets/icons/ico-blue-planet.svg";

const languagesList = ref();
const openLanguages = ref(false);
const selectedFlag = ref();
const LANGUAGE_API = "bootstrap/available-languages";
const translationStore = useTranslationStore();

const handleFlag = async (language: LanguageElement) => {
  openLanguages;
  selectedFlag.value = language.code;
  openLanguages.value = false;

  const translationPayload = JSON.parse(JSON.stringify(language));
  await translationStore.setTranslation(translationPayload);
};

const findDefaultLanguage = (languages: Array<LanguageElement>) => {
  return languages.find((item) => item.default == true);
};

const getWhitelistedLanguages = async () => {
  axios
    .get(LANGUAGE_API)
    .then((response) => {
      const { data } = response.data;
      languagesList.value = data;
      const defaultLang = findDefaultLanguage(data);
      selectedFlag.value = defaultLang?.code;
    })
    .catch((error) => {
      // console.log("error at LANGUAGE_API", error);
    });
};
getWhitelistedLanguages();

const handleClose = () => {
  openLanguages.value = false;
};
</script>
<template>
  <div class="relative">
  <div
    id="lang"
    class="flex space-between cursor-pointer flag-area bg-2-1 pdx-4 pdy-4 h-42px radius-10 box-shadow-down-1-2 w-100"
    @click="openLanguages = !openLanguages"
  >
    <span class="flex flex-center pdt-1">
      <img :src="iconPlanet" class="h-22px" />
      <p class="txt-18 txt-1-3 txt-bold to-upper-case mgl-2">
        {{ selectedFlag }}
      </p>
    </span>
    <div class="">
      <IconComponent
        :class="openLanguages ? ' rotate-up mgt-2' : null"
        :iconClasses="'icon-18 inline-block bg-1-3 icon-dropdown'"
      ></IconComponent>
    </div>
  </div>

  <div @click.self="handleClose" v-if="openLanguages">
    <div
      class="absolute zindex-dropdown bg-2-1 border-top-1-3 radius-10 border-width-1 w-100"
    >
      <div>
        <div
          class="cursor-pointer pdt-2 txt-1-3 txt-bold"
          v-for="(language, i) in languagesList"
          :key="i"
        >
          <div
            :id="language.id"
            @click="handleFlag(language)"
            class="flex flex-left-center pdx-5 pdy-1"
          >
            <div><AllFlagsComponent :flagCode="language.code" /></div>
            <p class="mgl-3">{{ language.language }}</p>
          </div>
          <div v-if="i < languagesList.length - 1" class="pdx-12px">
            <div class="w-100 separator-divider bg-1-3 mgb-2"></div>
          </div>
        </div>
      </div>
    </div>
  </div></div>
</template>
