<script setup lang="ts">
import { translate } from "@/services/translations/translation";
import { useLayoutModalsStore } from "@/stores/layoutModals";
import { ref } from "vue";
import InputValueComponent from "@/brands/bit1111/components/shared/forms/input/InputValueComponent.vue";
import IconComponent from "@/components/icons/IconComponent.vue";
import { goToRoute } from "@/router/routingService";
import axios from "axios";
import { ErrorHandler } from "@/lib/exceptions/ErrorHandler";
import ButtonComponent from "@/brands/bit1111/components/shared/buttons/ButtonComponent.vue";
import { computeFormValidation } from "@/services/validation/formValidation";

const reset = ref({
  email: "",
});

const layoutModalsStore = useLayoutModalsStore();
const emailSent = ref(false);
const loader = ref(false);
const errorMessage = ref();
const email = ref();

const close = () => {
  layoutModalsStore.closeModal("emailPasswordReset");
};

const submit = async () => {
  loader.value = true;
  let key: ValidationKey;
  for (key in validationList.value) {
    validationList.value[key].isTouched = true;
  }
  if (formisValid.value) {
    emailSent.value = false;
    errorMessage.value = null;

    const body = {
      email: email.value,
    };
    await axios
      .post("/api/auth/forgot-password/send-email", body)
      .then(() => {
        emailSent.value = true;
      })
      .catch((error) => {
        const err = new ErrorHandler();
        const errObj = err.handle(error);
        errorMessage.value = errObj.message;
      });
  }
  loader.value = false;
};
// VALIDATION START
const validationList = ref({
  email: { isTouched: false, valid: false },
});
type ValidationKey = keyof typeof validationList.value;

const formisValid = ref<boolean>(false);
const callbackValid = (pointer: ValidationKey, value: boolean) => {
  validationList.value[pointer].valid = value;
  errorMessage.value=null
  computeFormValidation(validationList.value, formisValid);
};
// VALIDATION END
</script>
<template>
  <div>
    <div class="backdrop"></div>
    <div class="auth-modal-bit1111 bg-4-6 pdy-5 pdx-7">
      <div type="" class="flex flex-right">
        <IconComponent
          :iconClasses="'svg-icon block icon-16  icon-form-clear bg-1-1 cursor-pointer'"
          @click="close"
        ></IconComponent>
      </div>
      <div v-if="!emailSent">
        <h1
          class="title-medium mgy-5 txt-1-1 txt-bold mgx-auto to-upper-case fit-content"
        >
          {{ translate("auth.emailPasswordReset.desktop.title") }}
        </h1>

        <InputValueComponent
          inputId="name"
          :placeholder="translate('auth.emailPasswordReset.desktop.email')"
          :inputType="'text'"
          v-model="email"
          regexValidator="email"
          :disableValidation="false"
          textCutBorder="label-cut-border-light"
          :borderClass="'border-input radius-10'"
          v-model:isTouched="validationList.email.isTouched"
          @isValid="callbackValid('email', $event)"
        ></InputValueComponent>

        <p
          v-if="errorMessage"
          class="txt-bold txt-error-1 cursor-pointer text-smallest mgy-5 mgl-5"
        >
          {{ errorMessage }}
        </p>
        <div class="flex flex-center mgt-6 mgb-5">
          <ButtonComponent
            class="mgx-2"
            id="back"
            type="secondaryFilled"
            :text="translate('common.back')"
            @click="goToRoute('/login')"
          />
          <ButtonComponent
            class="mgx-2"
            id="back"
            :text="translate('auth.emailPasswordReset.desktop.submit')"
            @click="submit"
          />
        </div>
      </div>

      <div class="form-size" v-else>
        <div class="reset_password_msg pda-4">
          <IconComponent
            :iconClasses="'svg-icon block icon-90 mgx-auto icon-success bg-success-1'"
          ></IconComponent>
          <h1
            class="title-large mgt-5 txt-success-1 txt-bold mgx-auto fit-content"
          >
            {{ translate("auth.emailPasswordReset.desktop.title_reset") }}
          </h1>
          <p
            class="flex flex-center pda-4 txt-light mgx-auto fit-content text-smallest txt-center"
          >
            {{ translate("auth.emailPasswordReset.desktop.content_reset") }}
          </p>
          <p
            class="flex flex-center pda-4 txt-light mgx-auto fit-content text-smallest"
          >
            {{ translate("auth.emailPasswordReset.desktop.info_reset") }}
          </p>
        </div>

        <div class="row mgb-6">
          <div class="col-6 txt-center pdx-3">
            <button
              id="login"
              class="btn-auth txt-light pdx-5 bg-success-1 txt-4-1"
              @click="submit"
            >
              {{
                translate(
                  "auth.emailPasswordReset.desktop.submit_resend"
                ).toUpperCase()
              }}
            </button>
          </div>

          <div class="col-6 txt-center pdx-3">
            <button
              id="register"
              class="btn-auth bg-2-3 txt-4-1 pdx-5"
              @click="emailSent = false"
            >
              {{
                translate(
                  "auth.emailPasswordReset.desktop.submit_changeEmail"
                ).toUpperCase()
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
