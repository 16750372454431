<script setup lang="ts">
import { navigation } from "@/brands/holahola/data/footer";
import FooterPaymentInfoDesktopComponent from "@/brands/holahola/components/navigation/footer/FooterPaymentInfoDesktopComponent.vue";
import FooterCompanyInfoComponent from "@/components/footer/FooterCompanyInfoComponent.vue";
import { ref, onMounted } from "vue";
import { RouterLink } from "vue-router";
import FooterGuidelinesComponent from "@/components/footer/FooterGuidelinesComponent.vue";
import { translate } from "@/services/translations/translation";
import { goToRoute } from "@/router/routingService";
import LogoComponent from "@/components/icons/LogoComponent.vue";
import FooterCarouselWrapperComponent from "./FooterCarouselWrapperComponent.vue";
import TextareaValueComponent from "@/brands/holahola/components/shared/forms/input/TextareaValueComponent.vue";
import InputValueComponent from "@/brands/holahola/components/shared/forms/input/InputValueComponent.vue";
import Captcha from "@/brands/holahola/components/captcha/CaptchaComponent.vue";
import axios from "axios";
import { computeFormValidation } from "@/services/validation/formValidation";
import { useLayoutModalsStore } from "@/stores/layoutModals";

const isTablet = ref(false);
const layoutModalsStore = useLayoutModalsStore();
const name = ref("");
const email = ref("");
const textArea = ref("");
const captchaCode = ref();
const captchaHash = ref();
const resetCaptchaCode = ref(false);
const subject = ref();

// VALIDATION START
const validationList = ref({
  name: { isTouched: false, valid: false },
  email: { isTouched: false, valid: false },
  username: { isTouched: false, valid: true },
  textArea: { isTouched: false, valid: false },
  subject: { isTouched: false, valid: true },
  captcha: { isTouched: false, valid: false },
});
type ValidationKey = keyof typeof validationList.value;

const formisValid = ref<boolean>(false);
const callbackValid = (pointer: ValidationKey, value: boolean) => {
  validationList.value[pointer].valid = value;
  computeFormValidation(validationList.value, formisValid);
};
// VALIDATION END

//TODO: Waiting on BE endpoint
const submit = async () => {
  // submited.value = false;
  // submitFailed.value = false;
  // loader.toggleLoader(true);
  let key: ValidationKey;
  for (key in validationList.value) {
    validationList.value[key].isTouched = true;
  }
  if (formisValid.value) {
    await axios
      .post(
        "/contact/us",
        {
          name: name.value,
          email: email.value,
          username: "No username",
          subject: "Support form from footer",
          message: textArea.value,
          captcha: captchaCode.value,
        },
        {
          headers: {
            "Captcha-Hash": captchaHash.value,
          },
        }
      )
      .then((response) => {
        layoutModalsStore.activateModalNoClose({
          name: "pageNotification",
          data: {
            type: "success",
          },
        });
      })
      .catch((err) => {
        layoutModalsStore.activateModalNoClose({
          name: "pageNotification",
          data: {
            type: "error",
          },
        });
      });
  }
};

const platformHandler = () => {
  if (window.innerWidth > 1248) {
    isTablet.value = false;
  } else {
    isTablet.value = true;
  }
};

onMounted(() => {
  platformHandler();
  window.addEventListener("resize", platformHandler);
});
</script>

<template>
  <div class="">
    <div class="flex flex-center bg-1-4 pdy-6">
      <img
        src="https://i.ibb.co/cgDsNzV/Icon-awesome-instagram.png"
        alt=""
        class="mgx-4 cursor-pointer"
      />
      <img
        src="https://i.ibb.co/SrJBc71/Icon-awesome-facebook.png"
        alt=""
        class="mgx-4 cursor-pointer"
      />
      <img
        src="https://i.ibb.co/VJywwY3/Icon-awesome-twitter.png"
        alt=""
        class="mgx-4 cursor-pointer"
      />
    </div>
    <div class="contact-form pdy-8">
      <div class="">
        <div class="txt-center">
          <LogoComponent></LogoComponent>
        </div>
        <div class="scroll-overflow-x flex no-wrap mgy-7 scrollbar-hidden">
          <RouterLink to="/guide" class="no-underlined txt-light"
            ><p class="mgx-4">{{ translate("navigation.blog") }}</p></RouterLink
          >
          <RouterLink to="/support" class="no-underlined txt-light"
            ><p class="mgx-4">{{ translate("common.support") }}</p></RouterLink
          >
          <p class="mgx-4">
            {{ translate("navigation.footer.casinoBonusTerms") }}
          </p>
          <p class="mgx-4">
            {{ translate("navigation.footer.sportbookBonusTerms") }}
          </p>
          <p class="mgx-4">{{ translate("navigation.footer.terms") }}</p>
          <p class="mgx-4">{{ translate("navigation.footer.security") }}</p>
          <p class="mgx-4">{{ translate("navigation.footer.payments") }}</p>
          <p class="mgx-4">{{ translate("navigation.footer.responsible") }}</p>
        </div>
        <div class="pdx-5">
          <p class="txt-1-2 txt-size-24 txt-weight-600 mgb-6">
            {{ translate("navigation.footer.contact_form_title") }}
          </p>
          <p class="txt-size-18">
            {{ translate("navigation.footer.contact_form_subtitle") }}
          </p>
          <p class="txt-size-18 mgb-6">
            {{ translate("navigation.footer.contact_form_subtext") }}
          </p>
          <div class="mgb-5">
          <InputValueComponent
            :placeholder="translate('common.name')"
            inputType="text"
            v-model="name"
            :minLength="0"
            :maxLength="255"
            textCutBorder="label-cut bg-1-1"
            textCutBorderFocused="label-cut bg-1-1 border-top-light border-width-1"
            bgColor="bg-1-1 "
            :borderClass="' radius-10 '"
            @isValid="callbackValid('name', $event)"
            v-model:isTouched="validationList.name.isTouched"
          /></div>
          <div class="mgb-5">
          <InputValueComponent
            :placeholder="translate('common.email')"
            inputType="text"
            v-model="email"
            :minLength="0"
            :maxLength="255"
            textCutBorder="label-cut bg-1-1"
            textCutBorderFocused="label-cut bg-1-1 border-top-light border-width-1"
            bgColor="bg-1-1 "
            :borderClass="' radius-10 '"
            @isValid="callbackValid('email', $event)"
            v-model:isTouched="validationList.email.isTouched"
          /></div>
          <div class="mgb-5">
            <InputValueComponent
              inputId="subject"
              :placeholder="translate('common.subject')"
              inputType="text"
              v-model="subject"
              :minLength="0"
              :maxLength="255"
              textCutBorder="label-cut bg-1-1"
              textCutBorderFocused="label-cut bg-1-1 border-top-light border-width-1"
              bgColor="bg-1-1 "
              :borderClass="' radius-10 '"
              @isValid="callbackValid('subject', $event)"
              v-model:isTouched="validationList.subject.isTouched"
            ></InputValueComponent>
          </div>
          <TextareaValueComponent
            :lines="10"
            inputId="textarea"
            :placeholder="translate('navigation.footer.contact_form_message')"
            inputType="text"
            v-model="textArea"
            :minLength="0"
            :maxLength="255"
            textCutBorder="label-cut bg-1-1"
            textCutBorderFocused="label-cut bg-1-1 border-top-light border-width-1"
            bgColor="bg-1-1 "
            :borderClass="' radius-10 '"
            :disableValidation="true"
            @isValid="callbackValid('textArea', $event)"
            v-model:isTouched="validationList.textArea.isTouched"
          />
          <p
            class="txt-error-1"
            v-if="
              validationList.textArea.isTouched &&
              !validationList.textArea.valid
            "
          >
            {{ "Input field is required." }}
          </p>
          <div class="flex">
            <div class="w-50">
              <Captcha
                :resetCode="resetCaptchaCode"
                @header="captchaHash = $event"
                @code="captchaCode = $event"
                @imgCodeReseted="resetCaptchaCode = false"
                @isValid="callbackValid('captcha', $event)"
                v-model:isTouched="validationList.captcha.isTouched"
              ></Captcha>
            </div>
            <div class="w-50 flex flex-center">
              <button
                class="pdx-8 h-50px border-3-1 border-width-1 radius-10 txt-3-1 txt-size-20 txt-weight-600"
                @click="submit"
              >
                {{ translate("common.send").toUpperCase() }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-dark pdy-6">
      <FooterCarouselWrapperComponent :isMobile="true" />
      <div class="mgx-auto mgt-10">
        <div class="fit-content mgx-auto mgy-10">
          <img src="https://i.ibb.co/2WzbJb7/Group-796.png" alt="" class="" />
        </div>
        <div class="pdx-7">
          <p class="font-Poppins txt-size-14 txt-5-2 txt-center">
            {{ translate("navigation.footer.description") }}
          </p>
          <br />
          <p class="font-Poppins txt-size-14 txt-5-2 txt-right">
            &copy; {{ translate("navigation.footer.address") }} ®
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="css" scoped>
.contact-form {
  background: transparent linear-gradient(0deg, #23008a 0%, #000635 100%) 0% 0%
    no-repeat padding-box;
}
</style>
