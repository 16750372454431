export const guidelines = {
  title: "home.desktop.guidelines.title",
  content: "home.desktop.guidelines.content",
};

export const badges = [
  {
    mainLogo: {
      src: "../../../src/assets/icons/img-mga-logo.svg",
      alt: "main logo",
    },
  },
  {
    badges: [
      {
        logo: "/icons/img-18plus.svg",
        alt: "no 18plus",
      },
      {
        logo: "../../../src/assets/icons/img-gamcare-logo.svg",
        alt: "gamcare",
      },
      {
        logo: "../../../src/assets/icons/img-no-usa.svg",
        alt: "no usa",
      },
    ],
  },
  {
    content: {
      text: '"gambling can be addictive, please play responsibly"',
    },
  },
];

export const media = [
  {
    title: "follow us on social media",
    logos: [
      {
        img: "soc-fb",
        link: "https://www.facebook.com/login.php?skip_api_login=1&api_key=1517799865179507&kid_directed_site=0&app_id=1517799865179507&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fv3.3%2Fdialog%2Foauth%3Fapp_id%3D1517799865179507%26cbt%3D1646649357774%26channel_url%3Dhttps%253A%252F%252Fstaticxx.facebook.com%252Fx%252Fconnect%252Fxd_arbiter%252F%253Fversion%253D46%2523cb%253Dfab92abb97a2bc%2526domain%253Dwww.b-bets.com%2526is_canvas%253Dfalse%2526origin%253Dhttps%25253A%25252F%25252Fwww.b-bets.com%25252Ff2a274696f88464%2526relation%253Dopener%26client_id%3D1517799865179507%26display%3Dpopup%26domain%3Dwww.b-bets.com%26e2e%3D%257B%257D%26fallback_redirect_uri%3Dhttps%253A%252F%252Fwww.b-bets.com%252Fsr%252Fslots%26locale%3Dsr_RS%26logger_id%3Df3552e9cda12d98%26origin%3D1%26redirect_uri%3Dhttps%253A%252F%252Fstaticxx.facebook.com%252Fx%252Fconnect%252Fxd_arbiter%252F%253Fversion%253D46%2523cb%253Dfff4bf1f240afc%2526domain%253Dwww.b-bets.com%2526is_canvas%253Dfalse%2526origin%253Dhttps%25253A%25252F%25252Fwww.b-bets.com%25252Ff2a274696f88464%2526relation%253Dopener%2526frame%253Df26b6255fd4346c%26response_type%3Dtoken%252Csigned_request%252Cgraph_domain%26scope%3Demail%26sdk%3Djoey%26version%3Dv3.3%26ret%3Dlogin%26fbapp_pres%3D0%26tp%3Dunspecified&cancel_url=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Dfff4bf1f240afc%26domain%3Dwww.b-bets.com%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fwww.b-bets.com%252Ff2a274696f88464%26relation%3Dopener%26frame%3Df26b6255fd4346c%26error%3Daccess_denied%26error_code%3D200%26error_description%3DPermissions%2Berror%26error_reason%3Duser_denied&display=popup&locale=en_US&pl_dbl=0",
        alt: "HolaHola Facebook",
      },
      {
        img: "soc-in",
        link: "",
        alt: "HolaHola Instagram",
      },
      {
        img: "soc-tw",
        link: "https://api.twitter.com/oauth/authorize?oauth_token=tSI3fAAAAAAAi2sRAAABf2P0zus",
        alt: "HolaHola Twitter",
      },
      {
        img: "soc-yt",
        link: "",
        alt: "HolaHola Youtube",
      },
    ],
  },
];

export const paymentsAndPartners = [
  {
    element: [
      {
        title: "partners & providers",
        translation:"navigation.footer.partnersAndProviders",
        logos: [
          {
            src: "https://s.b-bets.com/images/footer/payment-methods/footer_logo_visa.png",
          },
          {
            src: "https://s.b-bets.com/images/footer/payment-methods/footer_logo_visa.png",
          },
          {
            src: "https://s.b-bets.com/images/footer/payment-methods/footer_logo_visa.png",
          },
          {
            src: "https://s.b-bets.com/images/footer/payment-methods/footer_logo_visa.png",
          },
          {
            src: "https://s.b-bets.com/images/footer/payment-methods/footer_logo_visa.png",
          },
          {
            src: "https://s.b-bets.com/images/footer/payment-methods/footer_logo_visa.png",
          },
          {
            src: "https://s.b-bets.com/images/footer/payment-methods/footer_logo_visa.png",
          },
          {
            src: "https://s.b-bets.com/images/footer/payment-methods/footer_logo_visa.png",
          },
        ],
      },
    ],
  },
  {
    element: [
      {
        title: "payment options",
        translation:"navigation.footer.paymentOptions",
        logos: [
          {
            src: "https://s.b-bets.com/images/footer/providers/footer_logo_netent.png",
          },
          {
            src: "https://s.b-bets.com/images/footer/providers/footer_logo_netent.png",
          },
          {
            src: "https://s.b-bets.com/images/footer/providers/footer_logo_netent.png",
          },
          {
            src: "https://s.b-bets.com/images/footer/providers/footer_logo_netent.png",
          },
        ],
      },
    ],
  },
];

export const links = [
  {
    id: 1,
    name: "About HolaHola",
    path: "/about",
  },
  {
    id: 11,
    name: "Security & Privacy",
    path: "/security",
  },
  {
    id: 8,
    name: "Responisible Gaming",
    path: "/responsibility",
  },
  {
    id: 9,
    name: "Terms & Conditions",
    path: "/terms",
  },
  {
    id: 10,
    name: "Personal information",
    path: "/personal-information",
  },
  {
    id: 5,
    name: "Bonuses",
    path: "/promotions",
  },
  {
    id: 6,
    name: "Casino",
    path: "/casino",
  },
  {
    id: 7,
    name: "Sporstbook",
    path: "/sportsbook",
  },
  {
    id: 2,
    name: "Deposit",
    path: "/deposit",
  },
  {
    id: 3,
    name: "Withdrawal",
    path: "/withdraw",
  },
];
